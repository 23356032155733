import { useInfiniteQuery } from "@tanstack/react-query";
import {
	ColumnDef,
	createColumnHelper,
	SortingState,
} from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useMemo, useState } from "react";

import { FloraSpeciesForSeedApplicationArg } from "@/atoms/floraSpeciesAtoms";
import { SeedApplicationForPlanQueryAtom } from "@/atoms/seedAppAtoms";
import { DataTable } from "@/components/data-table/data-table";
import {
	DataTableFloraSpeciesCell,
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import {
	FloraSpeciesDto,
	floraSpeciesGetFloraSpeciesForSeedApplicationWithPagination,
	FloraSpeciesOrderBy,
	floraSpeciesOrderBy,
} from "@/lib/gen/eis";
import { FLORA_SPECIES_SEED_APP_FILTER_OPTIONS } from "@/types/filters/flora-species-filters";

type FormProps = {
	title: string;
	onSpeciesSelected: (selected: FloraSpeciesDto) => void;
	filters: ColumnFilter<FloraSpeciesForSeedApplicationArg>[];
	setFilters: (
		setter:
			| ColumnFilter<FloraSpeciesForSeedApplicationArg>[]
			| ((
					prev: ColumnFilter<FloraSpeciesForSeedApplicationArg>[],
			  ) => ColumnFilter<FloraSpeciesForSeedApplicationArg>[]),
	) => void;
	seedAppSpeciesId?: string | null;
};

export const FloraSpeciesForSeedAppTable = ({
	title,
	onSpeciesSelected,
	filters,
	setFilters,
	seedAppSpeciesId,
}: FormProps) => {
	const { data: seedApp } = useAtomValue(SeedApplicationForPlanQueryAtom);
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: floraSpeciesOrderBy.FullName,
			desc: true,
		},
	]);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"floraSpeciesGetFloraSpeciesForSeedApplicationWithPagination",
			sorting,
			filters,
		],
		queryFn: async ({ pageParam }) => {
			const sort = sorting?.[0];
			return floraSpeciesGetFloraSpeciesForSeedApplicationWithPagination({
				pageNumber: pageParam as number,
				pageSize: 25,
				orderBy: sort?.id as FloraSpeciesOrderBy,
				descending: sort?.desc ?? true,
				seedApplicationId: seedApp?.id,
				seedApplicationSpeciesId: seedAppSpeciesId,
				...parseFilterAguments(filters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
		enabled: !!seedApp?.id,
	});

	const columns = useMemo(() => {
		return FloraSpeciesForSeedAppColumns();
	}, []);

	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);

	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	return (
		<DataTable
			title={title}
			columns={columns}
			data={rows}
			totalRows={totalRows}
			isLoading={isLoading}
			sorting={sorting}
			setSorting={setSorting}
			serverSide={true}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			filteredSearchBox={{
				options: [...FLORA_SPECIES_SEED_APP_FILTER_OPTIONS],
				filters: filters,
				setFilters: setFilters,
			}}
			onRowClick={(row) => onSpeciesSelected(row.original)}
		/>
	);
};

const columnHelper = createColumnHelper<FloraSpeciesDto>();

export const FloraSpeciesForSeedAppColumns = () => {
	return [
		columnHelper.accessor("family", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Family" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: false,
			enableHiding: false,
			size: 100,
		}),
		columnHelper.accessor("fullName", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Species" />
			),
			cell: ({ getValue }) => (
				<DataTableFloraSpeciesCell value={getValue()} />
			),
			enableSorting: false,
			enableHiding: false,
			size: 210,
		}),
		columnHelper.accessor("lifeform", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Lifeform" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: false,
			enableHiding: false,
			size: 100,
		}),
		columnHelper.accessor("availableQuantity", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Available Qty (g)"
					rightAlign
				/>
			),
			cell: ({ getValue }) => (
				<DataTableNumberCell value={getValue() ?? undefined} />
			),
			enableSorting: false,
			enableHiding: false,
			size: 110,
		}),
	] as ColumnDef<FloraSpeciesDto>[];
};
