import client from "@kubb/plugin-client/client";
import type { SeedsGetSeedsWithPaginationMutationRequest, SeedsGetSeedsWithPaginationMutationResponse } from "../../ts/SeedsGetSeedsWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Inventory/Seeds/paginated
 */
export async function seedsGetSeedsWithPagination(data: SeedsGetSeedsWithPaginationMutationRequest, config: Partial<RequestConfig<SeedsGetSeedsWithPaginationMutationRequest>> = {}) {
    const res = await client<SeedsGetSeedsWithPaginationMutationResponse, Error, SeedsGetSeedsWithPaginationMutationRequest>({ method: "POST", url: `/api/Inventory/Seeds/paginated`, data, ...config });
    return res.data;
}