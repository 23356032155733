import { z } from "zod";

import type { TileMatrixSetOutput } from "@/lib/gen/titiler/types/TileMatrixSetOutput";

import { crsSchema } from "./crsSchema";
import { tileMatrixSchema } from "./tileMatrixSchema";
import { tmsBoundingBoxOutputSchema } from "./tmsBoundingBoxOutputSchema";

/**
 * @description Tile Matrix Set Definition\n\nA definition of a tile matrix set following the Tile Matrix Set standard.\nFor tileset metadata, such a description (in `tileMatrixSet` property) is only required for offline use,\nas an alternative to a link with a `http://www.opengis.net/def/rel/ogc/1.0/tiling-scheme` relation type.\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/tileMatrixSet.json
 */
export const tileMatrixSetOutputSchema = z
	.object({
		title: z
			.union([z.string(), z.null()])
			.describe(
				"Title of this tile matrix set, normally used for display to a human",
			)
			.optional(),
		description: z
			.union([z.string(), z.null()])
			.describe(
				"Brief narrative description of this tile matrix set, normally available for display to a human",
			)
			.optional(),
		keywords: z
			.union([z.array(z.string()), z.null()])
			.describe(
				"Unordered list of one or more commonly used or formalized word(s) or phrase(s) used to describe this tile matrix set",
			)
			.optional(),
		id: z
			.union([z.string(), z.null()])
			.describe(
				"Tile matrix set identifier. Implementation of 'identifier'",
			)
			.optional(),
		uri: z
			.union([z.string(), z.null()])
			.describe("Reference to an official source for this tileMatrixSet")
			.optional(),
		orderedAxes: z
			.union([z.array(z.string()).min(2).max(2), z.null()])
			.describe(
				"Ordered list of names of the dimensions defined in the CRS",
			)
			.optional(),
		crs: z.lazy(() => crsSchema),
		wellKnownScaleSet: z
			.union([z.string(), z.null()])
			.describe("Reference to a well-known scale set")
			.optional(),
		boundingBox: z
			.union([z.lazy(() => tmsBoundingBoxOutputSchema), z.null()])
			.describe(
				"Minimum bounding rectangle surrounding the tile matrix set, in the supported CRS",
			)
			.optional(),
		tileMatrices: z
			.array(z.lazy(() => tileMatrixSchema))
			.describe("Describes scale levels and its tile matrices"),
	})
	.describe(
		"Tile Matrix Set Definition\n\nA definition of a tile matrix set following the Tile Matrix Set standard.\nFor tileset metadata, such a description (in `tileMatrixSet` property) is only required for offline use,\nas an alternative to a link with a `http://www.opengis.net/def/rel/ogc/1.0/tiling-scheme` relation type.\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/tileMatrixSet.json",
	) as z.ZodType<TileMatrixSetOutput>;
