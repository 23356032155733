import {
	ColumnDef,
	ColumnSort,
	createColumnHelper,
} from "@tanstack/react-table";
import { useAtomValue, useSetAtom } from "jotai";
import { memo, useMemo } from "react";

import { GoToErosionPolygon } from "@/atoms/erosionAtoms";
import {
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import { ErosionVectorLayerClusterSourceAtom } from "@/components/MapOpenLayers/Erosion/erosionVectorLayer";
import { ErosionFeaturePagedDto } from "@/lib/gen/eis";

const columnHelper = createColumnHelper<ErosionFeaturePagedDto>();

type Props = {
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>;
};

export const ErosionColumns = ({ setColumnSort }: Props) =>
	[
		columnHelper.accessor("id", {
			enableGlobalFilter: true,
			header: () => <div />,
			cell: ({ getValue }) => <ErosionLocationCell id={getValue()} />,
			enableSorting: false,
			enableResizing: false,
			minSize: 26,
			maxSize: 26,
			meta: {
				title: "Location",
			},
		}),
		columnHelper.accessor("name", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Feature Name"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ row }) => (
				<DataTableSmallTextCell value={row.original?.name ?? "-"} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Feature Name",
			},
		}),
		columnHelper.accessor((row) => new Date(row.rehabilitationSurveyDate), {
			id: "surveyDate",
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Survey Date"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell
					value={getValue().toLocaleDateString("en-AU", {
						year: "numeric",
						month: "short",
						day: "numeric",
					})}
				/>
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Survey Date",
			},
		}),
		columnHelper.accessor((row) => row.mineSiteName, {
			id: "site",
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Site"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableSorting: true,
			size: 100,
		}),
		columnHelper.accessor((row) => row.rehabilitationPolygonName, {
			id: "polygon",
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Polygon Name"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Polygon Name",
			},
		}),
		columnHelper.accessor("volume", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Volume (m³)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
		}),
		columnHelper.accessor("length", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Length (m)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
		}),
		columnHelper.accessor("area", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Area (m²)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
		}),
		columnHelper.accessor("depthMean", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Mean Depth (m)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
			meta: {
				title: "Mean Depth",
			},
		}),
		columnHelper.accessor("depthMin", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Min Depth (m)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
			meta: {
				title: "Min Depth",
			},
		}),
		columnHelper.accessor("depthMax", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Max Depth (m)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
			meta: {
				title: "Max Depth",
			},
		}),
		columnHelper.accessor("widthMean", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Mean Width (m)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
			meta: {
				title: "Mean Width",
			},
		}),
		columnHelper.accessor("widthMin", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Min Width (m)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
			meta: {
				title: "Min Width",
			},
		}),
		columnHelper.accessor("widthMax", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Max Width (m)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => <DataTableNumberCell value={getValue()} />,
			enableSorting: true,
			size: 80,
			meta: {
				title: "Width Max",
			},
		}),
	] as ColumnDef<ErosionFeaturePagedDto>[];

export const ErosionLocationCell = memo(function ({ id }: { id: string }) {
	const source = useAtomValue(ErosionVectorLayerClusterSourceAtom);
	const disabled = useMemo(() => {
		return source.source?.getFeatureById(id) == null;
	}, [id, source]);
	const goToPolygon = useSetAtom(GoToErosionPolygon);

	return (
		<DataTableLocationPinCell
			GoToLocation={() => goToPolygon(id)}
			disabled={disabled}
		/>
	);
});
