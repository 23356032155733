import client from "@kubb/plugin-client/client";
import type { RehabilitationSurveysGetRehabilitationSurveyQueryQueryResponse, RehabilitationSurveysGetRehabilitationSurveyQueryPathParams } from "../../ts/RehabilitationSurveysGetRehabilitationSurveyQuery.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";
import { rehabilitationSurveysGetRehabilitationSurveyQueryQueryResponseSchema } from "../../zod/rehabilitationSurveysGetRehabilitationSurveyQuerySchema.ts";

 /**
 * @link /api/RehabilitationSurveys/:id
 */
export async function rehabilitationSurveysGetRehabilitationSurveyQuery(id: RehabilitationSurveysGetRehabilitationSurveyQueryPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationSurveysGetRehabilitationSurveyQueryQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationSurveys/${id}`, ...config });
    return rehabilitationSurveysGetRehabilitationSurveyQueryQueryResponseSchema.parse(res.data);
}