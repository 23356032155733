import { PaginationState } from "@tanstack/react-table";

import api from "@/api";
import { EisColumnSort } from "@/components/data-table/table-types";
import { prepareForUtcConversion } from "@/lib/utils";
import {
	MetricGridAnalysisDto,
	PaginatedListOfRiparianLocationComparisonDto,
	RiparianLocationType,
	RiparianMetricDataType,
} from "@/web-api-client";

export const getRiparianSurveyComparisonPaginated = async (
	survey1Id: string | undefined,
	survey2Id: string | undefined,
	pagination: PaginationState | undefined,
	columnSort: EisColumnSort | undefined,
	structureOrCondition: "structure" | "condition",
	sortByValueOrChange: ("sortByValue" | "sortByChange") | undefined,
	riparianLocationType: RiparianLocationType,
	zoneId: string | undefined,
): Promise<PaginatedListOfRiparianLocationComparisonDto> => {
	const urlSearchParams = new URLSearchParams();
	urlSearchParams.set("riparianSurvey1Id", survey1Id ?? "");
	if (survey2Id) {
		urlSearchParams.set("riparianSurvey2Id", survey2Id ?? "");
	}
	urlSearchParams.set("riparianLocationType", riparianLocationType);
	if (zoneId) {
		urlSearchParams.set("zoneId", zoneId);
	}

	urlSearchParams.set(
		"riparianMetricDataType",
		structureOrCondition === "structure"
			? RiparianMetricDataType.Percent
			: RiparianMetricDataType.MSAVI,
	);

	urlSearchParams.set("pageNumber", (pagination?.pageIndex ?? 1).toString());
	urlSearchParams.set("pageSize", (pagination?.pageSize ?? 5).toString());
	if (columnSort) {
		urlSearchParams.set("descending", columnSort.desc ? "true" : "false");
		if (columnSort.isDynamicColumn) {
			urlSearchParams.set("orderByDynamicColumn", columnSort.id);
			urlSearchParams.set(
				"orderBy",
				sortByValueOrChange === "sortByValue"
					? "MetricSurveyT1"
					: "MetricSurveyChange",
			);
		} else {
			urlSearchParams.set("orderBy", columnSort.id);
		}
	}

	const paramsString = urlSearchParams.toString();

	const response = await api().get(
		`api/RiparianSurvey/surveycomparison?${paramsString}`,
	);
	return response.data;
};

export const getRiparianGridAnalysis = async (
	survey1Id: string | undefined,
	survey2Id: string | undefined,
	zoneId: string | undefined,
	metricType: RiparianMetricDataType,
): Promise<MetricGridAnalysisDto[]> => {
	const response = await api().get("api/RiparianSurvey/gridanalysis", {
		params: {
			riparianSurvey1Id: survey1Id,
			riparianSurvey2Id: survey2Id,
			riparianZoneId: zoneId,
			riparianMetricDataType: metricType,
		},
	});
	return response.data;
};

export const postRiparianSurvey = async (form: {
	mineSiteId: string;
	name?: string | undefined;
	publicationDate?: Date | undefined;
	onGroundDate?: Date | undefined;
	remoteSensingDate?: Date | undefined;
	imageryTileLayerUrl?: string | undefined;
	imageryTileLayerUrlType?: string | undefined;
	classificationTileLayerUrl?: string | undefined;
	classificationTileLayerUrlType?: string | undefined;
	msaviTileLayerUrl?: string | undefined;
	msaviTileLayerUrlType?: string | undefined;
}): Promise<string> => {
	if (form.publicationDate) {
		form.publicationDate = prepareForUtcConversion(form.publicationDate);
	}
	if (form.onGroundDate) {
		form.onGroundDate = prepareForUtcConversion(form.onGroundDate);
	}
	if (form.remoteSensingDate) {
		form.remoteSensingDate = prepareForUtcConversion(
			form.remoteSensingDate,
		);
	}

	const content = JSON.stringify(form);
	const response = await api().post<string>("api/ripariansurvey/", content, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});
	return response.data;
};

export const uploadMetricsFile = async (
	id: string,
	file: File,
	locationType: string,
) => {
	const formData = new FormData();
	formData.append("FileName", file.name);
	formData.append("CsvFile", file, file.name);
	formData.append("RiparianLocationType", locationType);
	const response = await api().post(
		"api/ripariansurvey/" + id + "/metric-values-import",
		formData,
	);
	return response.data;
};

export const deleteRiparianSurvey = async (id: string) => {
	const response = await api().delete("api/ripariansurvey/" + id);
	return response.data;
};
