import { useAtom } from "jotai";
import { useEffect } from "react";

import { DialogsAtom } from "./dialogs.atom";

export const Dialogs = () => {
	const [dialogs, setDialog] = useAtom(DialogsAtom);

	// Prevent potential issue where <body> gets 'pointer-events-none` stuck on it
	// Can happen in cases when you use a combination of dialogs, popovers, etc
	useEffect(() => {
		if (dialogs.length > 0 && dialogs.some(([, d]) => d.open)) {
			// Pushing the change to the end of the call stack
			const timer = setTimeout(() => {
				document.body.style.pointerEvents = "";
			}, 0);
			return () => clearTimeout(timer);
		} else {
			document.body.style.pointerEvents = "auto";
		}
	}, [dialogs]);

	return (
		<>
			{dialogs.map(([key, dialog]) => (
				<dialog.component
					key={key}
					open={dialog.open}
					setOpen={() => setDialog(null, key)}
				/>
			))}
		</>
	);
};
