import "rc-slider/assets/index.css";

import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { EllipsisIcon, FileImageIcon } from "lucide-react";
import Slider from "rc-slider";
import React, { startTransition, useEffect, useRef, useState } from "react";
import {
	Bar,
	CartesianGrid,
	ComposedChart,
	Line,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from "recharts";

import { DrawerIsOpenAtom } from "@/atoms/bottomDrawerAtoms.ts";
import { SelectedMineSiteIdAtom } from "@/atoms/miningAtoms.ts";
import { WeatherStationsAtom } from "@/atoms/weatherStationAtoms.ts";
import Icon from "@/components/icons/icon.tsx";
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import {
	Menubar,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarTrigger,
} from "@/components/ui/menubar.tsx";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select.tsx";
import { ExportImage } from "@/helpers/image-export-helpers.ts";
import { cn } from "@/lib/utils.ts";
import { getTimeSeriesDays } from "@/services/mining.service.ts";

const RainTempGraphCard: React.FC = () => {
	const mineSiteId = useAtomValue(SelectedMineSiteIdAtom);
	const setDrawerIsOpen = useSetAtom(DrawerIsOpenAtom);
	const [rainfallVisible, setRainfallVisible] = useState<boolean>(true);
	const [temperatureVisible, setTemperatureVisible] = useState<boolean>(true);
	const [weatherStations] = useAtom(WeatherStationsAtom);

	const [earliest, setEarliest] = useState<Date>();
	const [dates, setDates] = useState<Date[]>();

	const [selectedWeatherStation, setSelectedWeatherStation] =
		useState<string>(weatherStations?.length ? weatherStations[0].id : "");
	const [startDate, setStartDate] = useState<Date>();
	const [endDate, setEndDate] = useState<Date>();

	useEffect(() => {
		getTimeSeriesDays(
			selectedWeatherStation,
			mineSiteId,
			new Date("01-01-1900"),
			new Date(),
		).then((result) => {
			const start = new Date(result[0].day);
			const end = new Date(result[result.length - 1].day);
			setEarliest(start);
			setEndDate(end);
			const year =
				2020 < start.getFullYear() ? start.getFullYear() : 2020;
			setStartDate(new Date(new Date().setFullYear(year)));
			setDates(result.flatMap((r) => r.day));
		});
	}, [selectedWeatherStation, mineSiteId]);

	const dataQuery = useQuery({
		queryKey: ["currentClimatePoints", mineSiteId, startDate, endDate],
		queryFn: async () =>
			getTimeSeriesDays(
				selectedWeatherStation,
				mineSiteId,
				startDate,
				endDate,
			),
	});

	const graphData = dataQuery.data?.flatMap((d) => {
		return {
			id: dataQuery.data.indexOf(d),
			day: d.day,
			Rainfall: d.totalMonthlyRainfall,
			Temperature: d.averageMaximumTemp,
		};
	});

	type Mark = {
		style: React.CSSProperties;
		label: React.ReactNode;
	};

	type Marks = {
		[key: number]: Mark;
	};
	function markRecords() {
		const marks: Marks = {};
		dates?.forEach((d) => {
			const day = dayjs(d, { utc: true });
			const unix = day.unix();
			const exists = marks[unix];
			if (!exists) {
				marks[unix] = {
					style: {},
					label: <strong>{}</strong>,
				};
			}
		});

		return marks;
	}

	const hideMonthAxis =
		startDate &&
		endDate &&
		endDate?.getFullYear() - startDate?.getFullYear() > 10;
	const hideYearAxis =
		startDate &&
		endDate &&
		endDate?.getFullYear() - startDate?.getFullYear() > 20;

	const exportRef = useRef<HTMLDivElement | null>(null);

	return (
		<div className="flex h-full flex-col">
			<div className="flex flex-row items-center justify-between gap-1 rounded-t border border-module-climate-border bg-module-climate-header p-1 text-core-primary-button-text">
				<div
					className={cn(
						"flex w-full cursor-pointer flex-row rounded-tl bg-white bg-opacity-0 p-1 hover:bg-opacity-15",
					)}
					onClick={() => {
						setDrawerIsOpen(false);
					}}
				>
					<div className="flex items-center gap-2 font-dmSans font-medium leading-4">
						<Icon name="climate" className="size-5 items-center" />
						<span className="text-nowrap">
							Rainfall and Temperature
						</span>
					</div>
				</div>

				<Menubar className="mr-2 flex items-center">
					<MenubarMenu>
						<MenubarTrigger>
							<EllipsisIcon />
						</MenubarTrigger>
						<MenubarContent className="z-50 bg-core-primary-background">
							<MenubarItem
								className="flex cursor-pointer gap-1"
								onClick={async (e) => {
									e.stopPropagation();
									if (exportRef.current) {
										ExportImage(
											exportRef.current,
											"RainAndTemperatureHistory",
										);
									}
								}}
							>
								<FileImageIcon className="" />
								Export image
							</MenubarItem>
						</MenubarContent>
					</MenubarMenu>
				</Menubar>
			</div>
			<div
				ref={exportRef}
				className="flex grow flex-row overflow-auto bg-core-primary-background"
			>
				<div className="flex w-[200px] flex-col gap-2 overflow-auto p-2 text-sm">
					<div className="flex flex-col">
						<span>Weather Station: </span>
						<Select
							defaultValue={selectedWeatherStation}
							onValueChange={setSelectedWeatherStation}
						>
							<SelectTrigger>
								<SelectValue />
							</SelectTrigger>
							<SelectContent>
								{weatherStations.map((ws) => (
									<SelectItem key={ws.id} value={ws.id}>
										{ws.name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
					<div className="flex flex-col">
						<div className="flex items-center gap-1 rounded p-1 px-2 hover:bg-core-secondary-background">
							<Checkbox
								key="rainfall"
								className={`size-4`}
								checked={rainfallVisible}
								onClick={() =>
									setRainfallVisible(!rainfallVisible)
								}
							/>
							<div
								style={{
									backgroundColor: "#587DFF",
								}}
								className="h-2 rounded p-2"
							></div>
							Rainfall
						</div>
						<div className="flex items-center gap-1 rounded p-1 px-2 hover:bg-core-secondary-background">
							<Checkbox
								key="temperature"
								className={`size-4`}
								checked={temperatureVisible}
								onClick={() =>
									setTemperatureVisible(!temperatureVisible)
								}
							/>
							<div
								style={{
									backgroundColor: "#D99221",
								}}
								className="h-2 rounded p-2"
							></div>
							Temperature
						</div>
					</div>
				</div>
				<div className="w-[calc(100%-150px)] border-l border-core-primary-border p-2">
					<ResponsiveContainer>
						<div className="flex size-full flex-col items-center gap-1 rounded bg-core-secondary-background p-1 font-dmSans">
							<ChartContainer
								config={{}}
								className="flex h-[calc(100%-36px)] w-full"
							>
								<ComposedChart
									data={graphData}
									margin={{
										top: 10,
										right: 0,
										left: 0,
										bottom: 0,
									}}
								>
									<CartesianGrid
										vertical={false}
										strokeDasharray="2"
									/>
									<XAxis
										dataKey="day"
										xAxisId={0}
										interval={0}
										type="category"
										axisLine={false}
										tickLine={false}
										hide={hideMonthAxis}
										tickFormatter={(value) =>
											new Date(value).toLocaleDateString(
												"en-AU",
												{
													month: "narrow",
												},
											)
										}
									/>
									<XAxis
										dataKey="day"
										xAxisId={1}
										type="category"
										interval={11}
										axisLine={false}
										tickLine={false}
										hide={
											// hide year axis when more than 20 years of data showing
											hideYearAxis
										}
										tickFormatter={(value) =>
											new Date(value).toLocaleDateString(
												"en-AU",
												{
													year: "numeric",
												},
											)
										}
									/>

									<ChartTooltip
										filterNull={false}
										content={
											<ChartTooltipContent
												labelFormatter={(value) =>
													new Date(
														value,
													).toLocaleDateString(
														"en-AU",
														{
															month: "short",
															year: "2-digit",
														},
													)
												}
												formatter={(value, name) => {
													if (name === "Rainfall")
														return value !== null
															? `${(value as number).toFixed(1)}mm`
															: "No Data";
													if (name === "Temperature")
														return value !== null
															? `${(value as number).toFixed(1)}C°`
															: "No Data";
												}}
												className="min-w-fit text-core-primary-text"
											/>
										}
									/>
									{rainfallVisible && (
										<Bar
											dataKey="Rainfall"
											radius={2}
											fill="#587DFF"
											yAxisId="Rainfall"
										></Bar>
									)}
									{temperatureVisible && (
										<Line
											dataKey="Temperature"
											fill="#D99221"
											stroke="#D99221"
											strokeDasharray={3}
											yAxisId="Temperature"
										></Line>
									)}
									{rainfallVisible && (
										<YAxis
											dataKey="Rainfall"
											type="number"
											yAxisId="Rainfall"
											axisLine={false}
											tickCount={10}
											tickLine={false}
											label={{
												value: "RAINFALL (mm)",
												angle: -90,
												dx: 25,
												position: "left",
												style: {
													textAnchor: "middle",
													fontFamily: "roboto",
												},
											}}
										/>
									)}
									{temperatureVisible && (
										<YAxis
											dataKey="Temperature"
											type="number"
											yAxisId="Temperature"
											axisLine={false}
											tickCount={10}
											tickLine={false}
											orientation="right"
											label={{
												value: "TEMPERATURE (C°)",
												angle: 90,
												dx: -30,
												position: "right",
												style: {
													textAnchor: "middle",
													fontFamily: "roboto",
												},
											}}
										/>
									)}
								</ComposedChart>
							</ChartContainer>
							<div className="flex h-[36px] w-[95%] items-center justify-between gap-4 text-xs">
								<span className="text-nowrap">
									{earliest
										? new Date(earliest).toLocaleDateString(
												"en-AU",
												{
													year: "numeric",
												},
											)
										: ""}
								</span>
								<Slider
									min={dayjs(earliest, {
										utc: true,
									}).unix()}
									max={dayjs(new Date(), {
										utc: true,
									}).unix()}
									range
									pushable={true}
									value={[
										dayjs(startDate, {
											utc: true,
										}).unix(),
										dayjs(endDate, {
											utc: true,
										}).unix(),
									]}
									marks={markRecords()}
									onChange={(e) => {
										if (typeof e !== "number") {
											const numberArray = e as number[];
											if (numberArray.length === 2) {
												const startDateUnix = Math.min(
													...numberArray,
												);
												const start = dayjs
													.unix(startDateUnix)
													.toDate();
												const endDateUnix = Math.max(
													...numberArray,
												);
												const end = dayjs
													.unix(endDateUnix)
													.toDate();
												if (start && end) {
													startTransition(() => {
														setStartDate(start);
														setEndDate(end);
													});
												}
											}
										}
									}}
									dotStyle={{
										width: "6px",
										height: "6px",
										borderColor:
											"hsl(var(--core-primary-border))",
										backgroundColor:
											"hsl(var(--core-primary-border))",
									}}
									activeDotStyle={{
										width: "6px",
										height: "6px",
										borderColor:
											"hsl(var(--core-link-text))",
										backgroundColor:
											"hsl(var(--core-link-text))",
									}}
									style={{
										alignContent: "start",
										width: "100%",
									}}
									styles={{
										track: {
											width: "0px",
											height: "0px",
											borderColor: "none",
											backgroundColor: "none",
										},
										handle: {
											backgroundColor:
												"hsl(var(--core-link-text))",
											borderColor:
												"hsl(var(--core-link-text))",
											opacity: 1,
											height: 18,
											width: 18,
											cursor: "pointer",
										},
										rail: {
											height: 6,
											background:
												"hsl(var(--core-primary-border))",
											border: "hsl(var(--core-primary-border))",
											cursor: "pointer",
										},
									}}
								></Slider>
								<span className="text-nowrap">
									{new Date().toLocaleDateString("en-AU", {
										year: "numeric",
									})}
								</span>
							</div>
						</div>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
};
export default RainTempGraphCard;
