import { z } from "zod";

import type { Geo } from "@/lib/gen/titiler/types/Geo";

/**
 * @description rio-cogeo validation GEO information.
 */
export const geoSchema = z
	.object({
		CRS: z.union([z.string(), z.null()]).optional(),
		BoundingBox: z.tuple([z.number(), z.number(), z.number(), z.number()]),
		Origin: z.tuple([z.number(), z.number()]),
		Resolution: z.tuple([z.number(), z.number()]),
		MinZoom: z.union([z.number().int(), z.null()]).optional(),
		MaxZoom: z.union([z.number().int(), z.null()]).optional(),
	})
	.describe("rio-cogeo validation GEO information.") as z.ZodType<Geo>;
