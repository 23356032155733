import client from "@kubb/plugin-client/client";
import type { TopsoilStockpilesGetStockpilesWithPaginationQueryResponse, TopsoilStockpilesGetStockpilesWithPaginationQueryParams } from "../../ts/TopsoilStockpilesGetStockpilesWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/TopsoilStockpiles
 */
export async function topsoilStockpilesGetStockpilesWithPagination(params?: TopsoilStockpilesGetStockpilesWithPaginationQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<TopsoilStockpilesGetStockpilesWithPaginationQueryResponse, Error, unknown>({ method: "GET", url: `/api/TopsoilStockpiles`, params, ...config });
    return res.data;
}