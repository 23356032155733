import { Getter } from "jotai";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

import { ImageryObject } from "@/atoms/map/imageryUrlAtoms";
import {
	ProjectionTMSAtomFamily,
	TiTilerBoundsAtomFamily,
	TiTilerTileGridAtomFamily,
} from "@/atoms/mapAtoms";
import { LayerUrlType } from "@/web-api-client";

export const ImageryAtomEffectFunction = (
	get: Getter,
	group: LayerGroup,
	urls: Map<string, ImageryObject>,
	createLayer: (
		get: Getter,
		img: ImageryObject,
	) => TileLayer<XYZ> | undefined,
) => {
	let changed = false;
	const existingUrls = new Set<string>();
	for (const layer of group.getLayersArray()) {
		if (!urls.has(layer.get("url"))) {
			layer.setVisible(false);
			changed = true;
		} else {
			existingUrls.add(layer.get("url"));
			layer.setVisible(true);
		}
	}

	for (const [url, obj] of urls) {
		if (existingUrls.has(url)) continue;
		const newLayer = createLayer(get, obj);
		if (!newLayer) continue;
		newLayer.set("url", url);
		group.getLayers().push(newLayer);
		changed = true;
	}

	if (changed) group.changed();
};

export function PopulateImageryLayer(
	get: Getter,
	img: ImageryObject | undefined,
	arcgisToken: string | undefined,
) {
	if (!img) {
		return {
			source: undefined,
			bounds: undefined,
		};
	}
	const { projection, url, tileGrid, bounds } = parseImageryUrl(
		get,
		img,
		arcgisToken,
	);
	if (!url) return { source: undefined, bounds: undefined };

	const source = new XYZ({
		cacheSize: 2048,
		transition: 0,
		url: url,
		tileGrid: tileGrid,
		crossOrigin: "anonymous",
		projection: projection,
	});
	return {
		source: source,
		bounds: bounds,
	};
}

const parseImageryUrl = (
	get: Getter,
	obj: ImageryObject,
	arcgisToken: string | undefined,
) => {
	switch (obj.type) {
		case LayerUrlType.Esri:
			if (!arcgisToken) return { url: undefined, bounds: undefined };
			return {
				url: `${obj.url}/tile/{z}/{y}/{x}?token=${arcgisToken}`,
				bounds: undefined,
				tileGrid: undefined,
				projection: undefined,
			};
		case LayerUrlType.MapServer:
			return {
				url: `${obj.url}?bbox={bbox-epsg-3857}&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=${obj.layers}&TILED=true&WIDTH=256&HEIGHT=256&SRS=EPSG%3A3857&STYLES=`,
				bounds: undefined,
				tileGrid: undefined,
				projection: undefined,
			};
		case LayerUrlType.TiTiler: {
			const { data: tmsQuery } = get(ProjectionTMSAtomFamily(obj.url));
			const { data: boundsQuery } = get(TiTilerBoundsAtomFamily(obj.url));
			if (!tmsQuery?.tileMatrixSet || !boundsQuery)
				return {
					url: undefined,
					bounds: undefined,
					tileGrid: undefined,
					projection: undefined,
				};
			const { data: tileGrid } = get(
				TiTilerTileGridAtomFamily(tmsQuery.tileMatrixSet),
			);
			if (!tileGrid)
				return {
					url: undefined,
					bounds: undefined,
					tileGrid: undefined,
					projection: undefined,
				};
			const { projection } = tmsQuery;
			const { bounds, url } = boundsQuery;
			return {
				url: url,
				bounds: bounds,
				tileGrid: tileGrid,
				projection: projection,
			};
		}
		default:
			throw new Error(`Unknown imagery type: ${obj.type}`);
	}
};
