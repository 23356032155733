import { atom } from "jotai";
import React from "react";

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import { DialogsAtom } from "./dialogs.atom";

interface ConfirmationDialogProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	title: React.ReactNode;
	description?: React.ReactNode;
	onConfirm: () => void;
	onSkip?: () => void;
	confirmText?: string;
	skipText?: string;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
	const {
		open,
		setOpen,
		title,
		description,
		onConfirm,
		confirmText,
		onSkip,
		skipText,
	} = props;
	return (
		<AlertDialog open={open} onOpenChange={setOpen}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{title}</AlertDialogTitle>
				</AlertDialogHeader>
				<AlertDialogDescription className="text-sm text-muted-foreground">
					{description}
				</AlertDialogDescription>
				<AlertDialogFooter>
					<AlertDialogCancel>Cancel</AlertDialogCancel>
					{onSkip && (
						<AlertDialogAction onClick={onSkip}>
							{skipText ?? "Skip"}
						</AlertDialogAction>
					)}
					<AlertDialogAction onClick={onConfirm}>
						{confirmText ?? "Confirm"}
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export const ConfirmationDialogAtom = atom(
	null,
	async (
		_get,
		set,
		args: {
			title?: React.ReactNode;
			description?: React.ReactNode;
			confirmText?: string;
		} = {},
	) => {
		return await new Promise<boolean>((resolve) => {
			set(
				DialogsAtom,
				(props) => (
					<ConfirmationDialog
						{...props}
						onConfirm={() => resolve(true)}
						title={args.title ?? "Are you sure?"}
						description={args.description}
						confirmText={args.confirmText}
					/>
				),
				"confirm-dialog",
				{ onClose: () => resolve(false) },
			);
		});
	},
);
