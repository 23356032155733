import { TytonEisModuleType } from "@/atoms/eisViewAtoms";

export const MonitoringClasses = {
	text: "text-module-monitoring-primary",
	bgBackground: "bg-module-monitoring-background",
	borderPrimary: "border-module-monitoring-primary",
	borderBorder: "border-module-monitoring-border",
	hoverBorderBorder: "hover:border-module-monitoring-border",
	bgPrimary: "bg-module-monitoring-primary",
} as const satisfies ModuleClassesType;

export const ErosionClasses = {
	text: "text-module-erosion-primary",
	hoverBorderBorder: "hover:border-module-erosion-border",
	borderPrimary: "border-module-erosion-primary",
	borderBorder: "border-module-erosion-border",
	bgBackground: "bg-module-erosion-background",
	bgPrimary: "bg-module-erosion-primary",
} as const satisfies ModuleClassesType;

export const WeedsClasses = {
	text: "text-module-weeds-primary",
	hoverBorderBorder: "hover:border-module-weeds-border",
	borderPrimary: "border-module-weeds-primary",
	borderBorder: "border-module-weeds-border",
	bgBackground: "bg-module-weeds-background",
	bgPrimary: "bg-module-weeds-primary",
} as const satisfies ModuleClassesType;

export const SeedsClasses = {
	text: "text-module-seeds-primary",
	hoverBorderBorder: "hover:border-module-seeds-border",
	borderPrimary: "border-module-seeds-primary",
	borderBorder: "border-module-seeds-border",
	bgBackground: "bg-module-seeds-background",
	bgPrimary: "bg-module-seeds-primary",
} as const satisfies ModuleClassesType;

export const TopsoilClasses = {
	text: "text-module-topsoil-primary",
	bgBackground: "bg-module-topsoil-background",
	borderPrimary: "border-module-topsoil-primary",
	borderBorder: "border-module-topsoil-border",
	hoverBorderBorder: "hover:border-module-topsoil-border",
	bgPrimary: "bg-module-topsoil-primary",
} as const satisfies ModuleClassesType;

export const HealthClasses = {
	text: "text-module-health-primary",
	hoverBorderBorder: "hover:border-module-health-border",
	borderPrimary: "border-module-health-primary",
	borderBorder: "border-module-health-border",
	bgBackground: "bg-module-health-background",
	bgPrimary: "bg-module-health-primary",
} as const satisfies ModuleClassesType;

type ModuleClassesType = {
	text: string;
	bgPrimary: string;
	bgBackground: string;
	borderPrimary: string;
	borderBorder: string;
	hoverBorderBorder: string;
};

export const ModuleClasses = {
	Monitoring: MonitoringClasses,
	Erosion: ErosionClasses,
	Weeds: WeedsClasses,
	Seeds: SeedsClasses,
	Topsoil: TopsoilClasses,
	Health: HealthClasses,
	SeedApplication: SeedsClasses,
} as const satisfies Record<TytonEisModuleType, ModuleClassesType>;
