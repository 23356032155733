import client from "@kubb/plugin-client/client";
import type { SiteInspectionsGetFilteredImagesMutationRequest, SiteInspectionsGetFilteredImagesMutationResponse } from "../../ts/SiteInspectionsGetFilteredImages.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/SiteInspections/filtered-image-export
 */
export async function siteInspectionsGetFilteredImages(data?: SiteInspectionsGetFilteredImagesMutationRequest, config: Partial<RequestConfig<SiteInspectionsGetFilteredImagesMutationRequest>> = {}) {
    const res = await client<SiteInspectionsGetFilteredImagesMutationResponse, Error, SiteInspectionsGetFilteredImagesMutationRequest>({ method: "POST", url: `/api/SiteInspections/filtered-image-export`, data, ...config });
    return res.data;
}