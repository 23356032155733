import client from "@kubb/plugin-client/client";
import type { SeedApplicationsReplaceTargetSpeciesMutationRequest, SeedApplicationsReplaceTargetSpeciesMutationResponse } from "../../ts/SeedApplicationsReplaceTargetSpecies.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Inventory/Seeds/Applications/replaceSpecies
 */
export async function seedApplicationsReplaceTargetSpecies(data: SeedApplicationsReplaceTargetSpeciesMutationRequest, config: Partial<RequestConfig<SeedApplicationsReplaceTargetSpeciesMutationRequest>> = {}) {
    const res = await client<SeedApplicationsReplaceTargetSpeciesMutationResponse, Error, SeedApplicationsReplaceTargetSpeciesMutationRequest>({ method: "PATCH", url: `/api/Inventory/Seeds/Applications/replaceSpecies`, data, ...config });
    return res.data;
}