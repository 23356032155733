export const samplingSiteLandformType = {
    "Flat": "Flat",
    "Crest": "Crest",
    "Slope": "Slope",
    "Moonscape": "Moonscape",
    "Clear": "Clear",
    "Sewage": "Sewage",
    "Other": "Other",
    "NVCP": "NVCP"
} as const;

 export type SamplingSiteLandformTypeEnum = (typeof samplingSiteLandformType)[keyof typeof samplingSiteLandformType];

 export type SamplingSiteLandformType = SamplingSiteLandformTypeEnum;