import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, ResolverOptions, ResolverResult } from "react-hook-form";

export const ZodResolverWrapper: typeof zodResolver = (
	schema,
	options,
	factoryOptions,
) => {
	const resolver = zodResolver(schema, options, factoryOptions);
	return <TFieldValues extends FieldValues, TContext>(
		values: TFieldValues,
		context: TContext | undefined,
		options: ResolverOptions<TFieldValues>,
	): Promise<ResolverResult<TFieldValues>> => {
		const checkedValues: Record<string, unknown> = {};
		Object.entries(values).forEach(([key, value]) => {
			const checkedValue = RemoveEmptyObjects(value);
			if (checkedValue === undefined) return;
			checkedValues[key] = checkedValue;
		});
		return resolver(checkedValues as TFieldValues, context, options);
	};
};

export const RemoveEmptyObjects = (value: unknown) => {
	if (typeof value !== "object" || !value) {
		return removeEmptyData(value);
	}
	Object.entries(value).forEach(([k, v]) => {
		if (RemoveEmptyObjects(v) === undefined) {
			delete value[k as keyof typeof value];
		}
	});
	if (Object.keys(value).length === 0) return undefined;
	return value;
};

const removeEmptyData = (data: unknown) => {
	if (Array.isArray(data)) {
		return data.length === 0 ? undefined : data;
	}
	if (data == null) return undefined;
	if (typeof data === "string" && data === "") return undefined;
	return data;
};
