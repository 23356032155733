import { FilterItemType, ValueRendererProps } from "./filter-search-box-types";
import { DateValueRenderer } from "./filter-value-date";
import { DefaultValueRenderer } from "./filter-value-default";

export const ValueRenderers: Record<
	FilterItemType,
	(props: ValueRendererProps) => React.ReactNode
> = {
	string: DefaultValueRenderer,
	distance: DefaultValueRenderer,
	area: DefaultValueRenderer,
	number: DefaultValueRenderer,
	percentage: DefaultValueRenderer,
	date: DateValueRenderer,
	enum: DefaultValueRenderer,
	guid: DefaultValueRenderer,
	boolean: DefaultValueRenderer,
};
