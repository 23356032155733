import "@/globals.scss";

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { queryClientAtom } from "jotai-tanstack-query";
import { AuthProvider } from "oidc-react";
import posthog from "posthog-js";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	RouterProvider,
	useLocation,
	useNavigationType,
} from "react-router";

import { API_URL, APP_URL, ENV } from "@/env_variables";
import routes from "@/routes/routes";

const queryClient = new QueryClient();
const router = createBrowserRouter(routes);

if (ENV.PROD) {
	posthog.init(ENV.VITE_PUBLIC_POSTHOG_KEY, {
		api_host: ENV.VITE_PUBLIC_POSTHOG_HOST,
	});

	Sentry.init({
		release: APP_VERSION,
		dsn: ENV.VITE_SENTRY_DSN,
		environment: ENV.VITE_SENTRY_ENVIRONMENT,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			posthog.sentryIntegration({
				organization: ENV.VITE_PUBLIC_POSTHOG_ORG,
				projectId: ENV.VITE_PUBLIC_POSTHOG_PROJECT,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: ENV.VITE_SENTRY_SAMPLE_RATE,
	});
}

export const Root = () => {
	return (
		<AuthProvider
			authority={API_URL}
			clientId={ENV.VITE_CLIENT_ID}
			redirectUri={`${APP_URL}/authentication/login-callback`}
			postLogoutRedirectUri={`${APP_URL}/authentication/logout-callback`}
			responseType="code"
			scope="TytonEIS.WebUIAPI openid profile offline_access"
		>
			<RouterProvider router={router} />
		</AuthProvider>
	);
};

type HydrateAtomsProps<TChild> = {
	children: TChild;
};

const HydrateAtoms = <TChild,>({ children }: HydrateAtomsProps<TChild>) => {
	useHydrateAtoms([[queryClientAtom, queryClient]]);
	return children;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider>
				<HydrateAtoms>
					<Root />
				</HydrateAtoms>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>,
);
