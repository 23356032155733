import saveAs from "file-saver";
import { atom, Getter } from "jotai";

import { AnaloguePolyFiltersAtom } from "@/atoms/analoguePolyAtoms";
import { ErosionFeaturesFiltersAtom } from "@/atoms/erosionAtoms";
import {
	MonitoringScheduleFiltersAtom,
	RehabPolyFiltersAtom,
	SamplingSiteFiltersAtom,
} from "@/atoms/rehabPolyAtoms";
import { SiteInspectionFiltersAtom } from "@/atoms/siteInspectionAtoms";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import {
	analoguePolygonsGetFilteredExport,
	erosionGetFilteredExport,
	ExportFormat,
	monitoringScheduleGetFilteredExport,
	rehabilitationPolygonsGetFilteredExport,
	samplingSitesGetFilteredExport,
	siteInspectionsGetFilteredExport,
	siteInspectionsGetFilteredImages,
} from "@/lib/gen/eis";

import { TimeSeriesDateAtom } from "./timeseriesAtoms";

const ExportOptions = {
	rehabpolygons: {
		prefix: "Polygons",
		query: rehabilitationPolygonsGetFilteredExport,
		getFilters: (get: Getter) => get(RehabPolyFiltersAtom),
	},
	analoguepolygons: {
		prefix: "AnaloguePolygons",
		query: analoguePolygonsGetFilteredExport,
		getFilters: (get: Getter) => get(AnaloguePolyFiltersAtom),
	},
	erosionfeatures: {
		prefix: "ErosionFeatures",
		query: erosionGetFilteredExport,
		getFilters: (get: Getter) => get(ErosionFeaturesFiltersAtom),
	},
	monitoringschedule: {
		prefix: "MonitoringSchedule",
		query: monitoringScheduleGetFilteredExport,
		getFilters: (get: Getter) => get(MonitoringScheduleFiltersAtom),
	},
	samplingsites: {
		prefix: "SamplingSites",
		query: samplingSitesGetFilteredExport,
		getFilters: (get: Getter) => get(SamplingSiteFiltersAtom),
	},
	siteinspections: {
		prefix: "SiteInspections",
		query: siteInspectionsGetFilteredExport,
		getFilters: (get: Getter) => get(SiteInspectionFiltersAtom),
	},
};

type ExportOptionType = keyof typeof ExportOptions;

export const ExportFilteredAtom = atom(
	null,
	async (get, _set, type: ExportOptionType, exportFormat: ExportFormat) => {
		const { prefix, query, getFilters } = ExportOptions[type];
		const filters = getFilters(get);

		const timeSereisDate = get(TimeSeriesDateAtom);
		const blob = await query(
			{
				exportFormat,
				timeSeriesDate: timeSereisDate.toISOString(),
				...parseFilterAguments(filters),
			},
			{
				responseType: "blob",
				headers: {
					Accept: "application/octet-stream",
				},
			},
		);
		const nowString = new Date().toISOString();
		saveAs(
			blob,
			`${prefix}_${nowString}.${exportFormat === "Shapefile" ? "zip" : "csv"}`,
		);
	},
);

const ExportImageOptions = {
	siteinspections: {
		prefix: "SiteInspections",
		query: siteInspectionsGetFilteredImages,
		getFilters: (get: Getter) => get(SiteInspectionFiltersAtom),
	},
};

type ExportImageOptionsType = keyof typeof ExportImageOptions;

export const ExportFilteredImagesAtom = atom(
	null,
	async (get, _set, type: ExportImageOptionsType) => {
		const { prefix, query, getFilters } = ExportImageOptions[type];
		const filters = getFilters(get);

		const blob = await query(
			{
				...parseFilterAguments(filters),
			},
			{
				responseType: "blob",
				headers: {
					Accept: "application/octet-stream",
				},
			},
		);
		const nowString = new Date().toISOString();
		saveAs(blob, `${prefix}_images_${nowString}.zip`);
	},
);
