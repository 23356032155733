import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomWithQuery } from "jotai-tanstack-query";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import {
	GetWeedGridCellsWithPaginationQuery,
	weedsSurveysGetWeedsVegetationClassesQuery,
	WeedsVegetationClassDto,
} from "@/lib/gen/eis";
import { getWeedSurveysWithPagination } from "@/services/weedsurvey.service";
import { GridSize, WeedsSurveyDto } from "@/web-api-client";

import {
	Perm_Weeds_Std,
	UserHasPermissionAtom,
	UserHasPermissionsAtom,
} from "./authAtoms";
import { CurrentViewAtom } from "./eisViewAtoms";
import { MapZoomAtom } from "./mapAtoms";
import { SelectedMineSiteIdAtom } from "./miningAtoms";

export const AutoSelectedVegClassAtomEffect = atomEffect((get, set) => {
	const selectedVegClass = get(SelectedWeedVegetationClassAtom);
	const { data: weedVegClasses } = get(WeedsVegetationClassesAtom);
	if (!selectedVegClass && weedVegClasses) {
		set(SelectedWeedVegetationClassAtom, weedVegClasses[0]);
	}
});

export const WeedsVegetationClassesAtom = atomWithQuery((get) => {
	const userPerms = get(UserHasPermissionsAtom);
	return {
		queryKey: ["weedvegclasses"],
		queryFn: async () => {
			return await weedsSurveysGetWeedsVegetationClassesQuery({});
		},
		enabled: userPerms[Perm_Weeds_Std],
	};
});

export const SelectedWeedVegetationClassAtom = atom<
	WeedsVegetationClassDto | undefined
>(undefined);

const _weedsSurveysAtom = atom<WeedsSurveyDto[] | undefined>(undefined);
export const WeedsSurveysAtom = atom(
	(get) => {
		const surveys = get(_weedsSurveysAtom);
		return surveys;
	},
	async (get, set) => {
		const mineSiteId = get(SelectedMineSiteIdAtom);
		const comparisonMode = get(WeedsComparisonModeAtom);
		const currentView = get(CurrentViewAtom);

		if (
			mineSiteId &&
			currentView?.name === "Weeds" &&
			get(UserHasPermissionAtom(Perm_Weeds_Std))
		) {
			const surveys = await getWeedSurveysWithPagination(mineSiteId);

			set(_weedsSurveysAtom, surveys);

			if (surveys && surveys.length === 0) {
				set(WeedsSurvey1Atom, undefined);
				set(WeedsSurvey2Atom, undefined);
			}
			if (surveys && surveys.length > 0) {
				set(WeedsSurvey1Atom, surveys[0]);
			}
			if (comparisonMode && surveys && surveys.length > 1) {
				set(WeedsSurvey2Atom, surveys[1]);
			}
			if (comparisonMode && surveys && surveys.length === 1) {
				set(WeedsComparisonModeAtom, false);
			}
		}
	},
);

const _weedsSurvey1Atom = atom<WeedsSurveyDto | undefined>(undefined);
export const WeedsSurvey1Atom = atom(
	(get) => {
		const survey1 = get(_weedsSurvey1Atom);
		return survey1;
	},
	async (_get, set, value: WeedsSurveyDto | undefined) => {
		set(_weedsSurvey1Atom, value);
	},
);
const _weedsSurvey2Atom = atom<WeedsSurveyDto | undefined>(undefined);
export const WeedsSurvey2Atom = atom(
	(get) => {
		const survey2 = get(_weedsSurvey2Atom);
		return survey2;
	},
	async (_get, set, value: WeedsSurveyDto | undefined) => {
		set(_weedsSurvey2Atom, value);
	},
);

export const WeedsComparisonModeAtom = atom<boolean>(false);

export const SelectedWeedGridCellIdAtom = atom<string | undefined>(undefined);

export const WeedGridSizeAtom = atom<GridSize>(GridSize.FourHundredMetres);
export const WeedGridSizeLocked = atom<boolean>(false);

export const MapZoomWeedGridSizeAtomEffect = atomEffect((get, set) => {
	const zoom = get(MapZoomAtom);
	const currentGridSize = get(WeedGridSizeAtom);
	const weedGridSizeLocked = get(WeedGridSizeLocked);
	let gridSize = GridSize.FourHundredMetres;
	if (zoom && !weedGridSizeLocked) {
		gridSize =
			zoom < 14
				? GridSize.FourHundredMetres
				: zoom < 15.5
					? GridSize.TwoHundredMetres
					: zoom < 17
						? GridSize.OneHundredMetres
						: GridSize.FiftyMetres;

		if (currentGridSize !== gridSize) {
			set(WeedGridSizeAtom, gridSize);
		}
	}
});

export const WeedPatchesAtom = atom(async (get) => {
	const weedSurvey = await get(WeedsSurvey1Atom);
	if (!weedSurvey) {
		return [];
	}
	return [];
	//return await getWeedPatches(weedSurvey?.id).then((data) => { return data});
});

export type WeedGridCellArg = keyof GetWeedGridCellsWithPaginationQuery;
export const WeedGridCellFiltersAtom = atom<ColumnFilter<WeedGridCellArg>[]>(
	[],
);
