import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Rehab_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import { SelectedRehabPolygonAtom } from "@/atoms/rehabPolyAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const RehabPolySummaryCard = {
	component: lazy(() => import("@/components/Polygon/RehabPolySummaryCard")),
	permission: Perm_Rehab_Std,
} as const satisfies LazyCardComponent;

export const LazyRehabPolySummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedRehabPoly = useAtomValue(SelectedRehabPolygonAtom);
	const selectedSamplingSite = useAtomValue(SelectedSamplingSiteAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(RehabPolySummaryCard, userPerms);
		if (comp) {
			if (
				currentView?.name === "Monitoring" &&
				selectedRehabPoly &&
				!selectedSamplingSite
			)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedRehabPoly, selectedSamplingSite, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"RehabPolySummaryCard"} />}
		</Suspense>
	);
};
