import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { DataTableSmallTextCell } from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { weedApplicationsGetWeedApplicationsWithPagination } from "@/lib/gen/eis";

const WeedApplicationsTableCard = () => {
	const {
		data,
		isLoading,
		isError,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ["weedApplicationsTable"],
		queryFn: async ({ pageParam }) =>
			weedApplicationsGetWeedApplicationsWithPagination({
				PageNumber: pageParam,
				PageSize: 25,
			}),
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	if (isLoading) {
		return <span>Loading...</span>;
	}
	if (isError) {
		return <span>Error: {error.message}</span>;
	}

	return (
		<div className="flex size-full flex-wrap">
			<BottomDrawDataTable
				data={rows}
				totalRows={totalRows}
				isLoading={isLoading}
				title="Weed Applications"
				moduleType="weeds"
				icon="weeds"
				columns={[
					{
						accessorKey: "name",
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title="Name"
							/>
						),
						cell: ({ row }) => (
							<DataTableSmallTextCell
								value={row.original?.name ?? "-"}
							/>
						),
						enableSorting: true,
						enableHiding: false,
						size: 200,
					},
					{
						accessorKey: "scheduleDate",
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title={"Scheduled Date"}
							/>
						),
						cell: ({ row }) => (
							<div className="">
								{row.original?.scheduledDate
									? new Date(
											row.original.scheduledDate,
										).toDateString()
									: "-"}
							</div>
						),
						enableSorting: true,
						enableHiding: false,
					},
					{
						accessorKey: "startDate",
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title={"Start Date"}
							/>
						),
						cell: ({ row }) => (
							<div className="">
								{row.original?.applicationStartDate
									? new Date(
											row.original.applicationStartDate,
										).toDateString()
									: "-"}
							</div>
						),
						enableSorting: true,
						enableHiding: false,
					},
					{
						accessorKey: "endDate",
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title={"End Date"}
							/>
						),
						cell: ({ row }) => (
							<div className="">
								{row.original?.applicationEndDate
									? new Date(
											row.original.applicationEndDate,
										).toDateString()
									: "-"}
							</div>
						),
						enableSorting: true,
						enableHiding: false,
					},
					{
						accessorKey: "status",
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title={"Status"}
							/>
						),
						cell: ({ row }) => (
							<div className="">{row.original?.status ?? ""}</div>
						),
						enableSorting: true,
						enableHiding: false,
					},
				]}
				fetch={{
					isFetching,
					isFetchingNextPage,
					fetchNextPage,
					hasNextPage,
				}}
				tabType={DrawerTabEnum.WEED_APPLICATION_TABLE}
			/>
		</div>
	);
};

export default WeedApplicationsTableCard;
