import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cva, type VariantProps } from "class-variance-authority";

import { useWindow } from "@/components/PoppedOutWindows/hook.ts";
import { cn } from "@/lib/utils";

const tooltipVariants = cva("", {
	variants: {
		variant: {
			default:
				"border border-core-primary-border bg-core-primary-background text-core-primary-text",
			white: "bg-white text-neutral-950",
			negative: "bg-red-600 text-white",
			warning: "bg-warning text-warning-foreground",
			positive: "bg-green-600 text-white",
			popover: "bg-popover text-popover-foreground",
			"pre-wrap": "whitespace-pre-wrap bg-white text-neutral-950",
			failing:
				"border border-card-failing-border bg-card-failing text-white",
			approaching:
				"border border-card-approaching-border bg-card-approaching text-white",
			passing:
				"border border-card-passing-border bg-card-passing text-white",
			unassessed:
				"border border-card-unassessed-border bg-card-unassessed text-white",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});
const tooltipArrowVariants = cva("", {
	variants: {
		variant: {
			default:
				"fill-core-primary-background stroke-core-primary-border stroke-2",
			white: "fill-white",
			negative: "fill-red-600",
			warning: "fill-warning",
			positive: "fill-green-600",
			popover: "fill-popover",
			"pre-wrap": "fill-white",
			failing: "fill-card-failing stroke-card-failing-border stroke-2",
			approaching:
				"fill-card-approaching stroke-card-approaching-border stroke-2",
			passing: "fill-card-passing stroke-card-passing-border stroke-2",
			unassessed:
				"fill-card-unassessed stroke-card-unassessed-border stroke-2",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

interface TooltipProps extends VariantProps<typeof tooltipVariants> {
	children?: React.ReactNode;
	tip: React.ReactNode;
	/** @defaultValue "top"*/
	side?: "top" | "right" | "bottom" | "left";
	/** @defaultValue 5*/
	sideOffset?: number;
	/**
	 * The duration from when the pointer enters the trigger until the tooltip gets opened. This will
	 * override the prop with the same name passed to Provider.
	 * @defaultValue 400
	 */
	delayDuration?: number | undefined;
	className?: string;
}

export const Tooltip = (props: TooltipProps) => {
	const {
		children,
		tip,
		side,
		sideOffset,
		delayDuration,
		variant,
		className,
	} = props;
	const win = useWindow();
	return (
		<TooltipPrimitive.Provider>
			<TooltipPrimitive.Root delayDuration={delayDuration ?? 400}>
				<TooltipPrimitive.Trigger asChild>
					{children}
				</TooltipPrimitive.Trigger>
				<TooltipPrimitive.Portal container={win.document.body}>
					{tip != null && (
						<TooltipPrimitive.Content
							className={cn(
								"data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade",
								"z-[1000] select-none rounded px-2 py-2 text-xs leading-none shadow will-change-[transform,opacity]",
								tooltipVariants({ variant }),
								className,
							)}
							sideOffset={sideOffset ?? 5}
							side={side ?? "top"}
						>
							{tip}
							<TooltipPrimitive.Arrow
								className={cn(
									"-translate-y-px",
									tooltipArrowVariants({ variant }),
								)}
							/>
							<div
								className={cn(
									"absolute inset-x-1 bottom-0 h-px",
									tooltipVariants({ variant }),
									"!border-none",
								)}
							/>
						</TooltipPrimitive.Content>
					)}
				</TooltipPrimitive.Portal>
			</TooltipPrimitive.Root>
		</TooltipPrimitive.Provider>
	);
};
