import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { Outlet } from "react-router";

import { DarkModeAtom } from "@/atoms/configAtoms";
import { Dialogs } from "@/components/dialogs/dialogs";
import { Toaster as SonnerToaster } from "@/components/ui/sonner";
import { useAuthSetup } from "@/hooks/useAuthSetup";

export const Root = () => {
	useAuthSetup();
	const darkMode = useAtomValue(DarkModeAtom);

	useEffect(() => {
		if (darkMode) {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
	}, [darkMode]);

	return (
		<div id="app" className="h-screen w-screen">
			<Outlet />
			<Dialogs />
			<SonnerToaster richColors />
		</div>
	);
};
