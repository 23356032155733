import { FieldValues, Path, useFormContext } from "react-hook-form";

import { DatePicker } from "@/components/ui/datePicker";
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";

type Props<T extends FieldValues> = {
	name: Path<T>;
	label: string;
	description?: string;
	disabled?: boolean;
	className?: string;
};

export function DateFormControl<T extends FieldValues>({ ...props }: Props<T>) {
	const {
		control,
		formState: { disabled },
	} = useFormContext<T>();

	return (
		<FormField
			key={props.name}
			control={control}
			name={props.name}
			render={({ field }) => {
				return (
					<FormItem className={cn("", props.className)}>
						<FormLabel>{props.label}</FormLabel>
						<FormControl>
							<DatePicker
								disabled={props.disabled ?? disabled}
								formValue={field.value}
								onChange={field.onChange}
							/>
						</FormControl>
						<FormDescription>{props.description}</FormDescription>
						<FormMessage />
					</FormItem>
				);
			}}
		/>
	);
}
