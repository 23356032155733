import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { ROUTES } from "./route-def";

export const LoginRedirect = () => {
	const navigate = useNavigate();
	const auth = useAuth();

	useEffect(() => {
		if (auth.isLoading) {
			return;
		}
		auth.userManager?.getUser().then((u) => {
			if (u) {
				navigate(ROUTES.app.$path());
			}
		});
	}, [auth, navigate]);

	return null;
};

export const LogoutRedirect = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(ROUTES.app.$path());
	}, [navigate]);

	return null;
};
