import Icon from "@/components/icons/icon";
import { Button } from "@/components/ui/button";
import { Tooltip } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface DataTableLocationPinCellProps
	extends React.HTMLAttributes<HTMLDivElement> {
	GoToLocation: () => void;
	disabled?: boolean;
}

export const DataTableLocationPinCell: React.FC<
	DataTableLocationPinCellProps
> = ({ className, GoToLocation, disabled }) => {
	return (
		<div
			className={cn("z-50 flex w-8 items-center justify-end", className)}
		>
			<Tooltip
				tip={
					disabled
						? "Feature not currently available for this time period, please update timeslider."
						: null
				}
				side="left"
			>
				<Button
					variant="ghost"
					size="icon"
					onClick={async () => {
						GoToLocation();
					}}
					className="-mr-2 size-fit justify-end p-1 disabled:pointer-events-auto"
					disabled={disabled}
				>
					<Icon name="location_on" className="text-core-link-text" />
				</Button>
			</Tooltip>
		</div>
	);
};
