import type { SeedBatchesCreateGerminationTestPathParams, SeedBatchesCreateGerminationTest200, SeedBatchesCreateGerminationTestMutationRequest, SeedBatchesCreateGerminationTestMutationResponse } from "../ts/SeedBatchesCreateGerminationTest.ts";
import { createSeedBatchGerminationTestCommandSchema } from "./createSeedBatchGerminationTestCommandSchema.ts";
import { z } from "zod";

 export const seedBatchesCreateGerminationTestPathParamsSchema = z.object({ "id": z.string() }) as z.ZodType<SeedBatchesCreateGerminationTestPathParams>;

 export const seedBatchesCreateGerminationTest200Schema = z.string() as z.ZodType<SeedBatchesCreateGerminationTest200>;

 export const seedBatchesCreateGerminationTestMutationRequestSchema = z.lazy(() => createSeedBatchGerminationTestCommandSchema) as z.ZodType<SeedBatchesCreateGerminationTestMutationRequest>;

 export const seedBatchesCreateGerminationTestMutationResponseSchema = z.lazy(() => seedBatchesCreateGerminationTest200Schema) as z.ZodType<SeedBatchesCreateGerminationTestMutationResponse>;