import api from "@/api";
import { WeatherStationDto } from "@/web-api-client";

export const getWeatherStations = async (): Promise<WeatherStationDto[]> => {
	const response = await api().get("/api/WeatherStations");
	return response.data;
};

export const createWeatherStation = async (form: {
	provider: string;
	providerId: string;
	name: string;
	startDate: Date;
	latitude: number;
	longitude: number;
}) => {
	const startDate = form.startDate;
	if (startDate) {
		const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
		const dayOfMonth = startDate.getDate().toString().padStart(2, "0");
		const dateStr = `${startDate.getFullYear()}-${month}-${dayOfMonth}`;
		form.startDate = new Date(dateStr);
	}
	const content = JSON.stringify(form);
	const response = await api().post("/api/WeatherStations/Create", content, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});
	return response.data;
};

export const uploadWeatherObservations = async (
	fileToUpload: File,
	id: string,
) => {
	const formData = new FormData();
	formData.append("CsvFile", fileToUpload, fileToUpload.name);
	formData.append("FileName", fileToUpload.name);
	const response = await api().post(
		"api/WeatherStations/" + id + "/weather-observations/import",
		formData,
	);
	return response.data;
};

export const uploadWeatherStationAssociations = async (file: File) => {
	const formData = new FormData();
	formData.append("WeatherStationAssociationCsvFile", file, file.name);
	formData.append("WeatherStationAssociationCsvFileName", file.name);
	const response = await api().post(
		"api/WeatherStations/ImportWeatherStationAssociationsCsv",
		formData,
	);
	return response.data;
};

export const createWeatherStationAssociations = async (
	minesiteId: string,
	stations: string[],
) => {
	const associationDtos = stations.map((station) => ({
		mineSiteId: minesiteId,
		weatherStationId: station,
		startDate: new Date(),
	}));
	const content = JSON.stringify({
		weatherStationAssociationCreationDtos: associationDtos,
	});
	const response = await api().post(
		"api/WeatherStations/CreateWeatherStationAssociations",
		content,
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		},
	);
	return response.data;
};
