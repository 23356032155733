import client from "@kubb/plugin-client/client";
import type { SiteInspectionsGetFilteredExportMutationRequest, SiteInspectionsGetFilteredExportMutationResponse } from "../../ts/SiteInspectionsGetFilteredExport.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/SiteInspections/filtered-export
 */
export async function siteInspectionsGetFilteredExport(data: SiteInspectionsGetFilteredExportMutationRequest, config: Partial<RequestConfig<SiteInspectionsGetFilteredExportMutationRequest>> = {}) {
    const res = await client<SiteInspectionsGetFilteredExportMutationResponse, Error, SiteInspectionsGetFilteredExportMutationRequest>({ method: "POST", url: `/api/SiteInspections/filtered-export`, data, ...config });
    return res.data;
}