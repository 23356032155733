"use client";

import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Column, Table } from "@tanstack/react-table";
import { useMemo } from "react";

import Icon from "@/components/icons/icon";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { SortableList } from "@/components/ui/sortable-list";
import { Tooltip } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface ColumnGroup<TData> {
	key: string;
	columns: Column<TData, unknown>[];
}
interface DataTableViewOptionsProps<TData> {
	table: Table<TData>;
	columnOrder?: string[];
	onChangeOrder?: (ids: string[]) => void;
	className?: string;
}

export function DataTableViewOptions<TData>({
	table,
	columnOrder,
	onChangeOrder,
	className,
}: DataTableViewOptionsProps<TData>) {
	const allColumns = table.getAllColumns();
	const columnsGrouped = useMemo(() => {
		const columnsSorted = allColumns
			.filter(
				(column) =>
					typeof column.accessorFn !== "undefined" &&
					column.getCanHide(),
			)
			.sort(
				(a, b) =>
					(columnOrder?.indexOf(a.id) ?? a.getSortIndex()) -
					(columnOrder?.indexOf(b.id) ?? b.getSortIndex()),
			);

		return Object.entries(
			columnsSorted.reduce(
				(g, c) => {
					let group = "Attributes";
					if (c.columnDef.meta?.columnGroup != null)
						group = c.columnDef.meta.columnGroup;
					if (c.columnDef.meta?.defaultHidden != null)
						c.toggleVisibility(!c.columnDef.meta.defaultHidden);

					if (g[group]) g[group].push(c);
					else g[group] = [c];
					return g;
				},
				{} as { [key: string]: Column<TData, unknown>[] },
			),
		)
			.map(([key, columns]) => ({ key, columns }) as ColumnGroup<TData>)
			.sort(
				(a, b) =>
					(columnOrder?.indexOf(a.columns[0].id) ??
						a.columns[0].getSortIndex()) -
					(columnOrder?.indexOf(b.columns[0].id) ??
						b.columns[0].getSortIndex()),
			);
	}, [allColumns, columnOrder]);

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline_primary"
					size="sm"
					className={cn("gap-1", className)}
				>
					<Icon name="column_settings" />
					View
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				align="end"
				side="top"
				className="z-[1000] bg-core-primary-background text-core-primary-text"
			>
				<div className="flex items-center pr-1">
					<DropdownMenuLabel className="pr-10 text-base font-medium">
						Select table columns
					</DropdownMenuLabel>
					<Tooltip tip="Reset Order to Default">
						<Button
							variant="ghost"
							size="min"
							className="ml-auto size-6 p-0.5"
							onClick={() => {
								onChangeOrder?.(allColumns.map((c) => c.id));
							}}
						>
							<Icon name="ListRestart" />
						</Button>
					</Tooltip>
				</div>
				<DropdownMenuSeparator className="bg-core-primary-border" />
				<div
					className="grid size-full gap-2 p-1"
					style={{
						gridTemplateColumns: `repeat(${columnsGrouped.length},minmax(0,1fr))`,
					}}
				>
					{columnsGrouped.map(({ key: group, columns }) => {
						const checked = columns.every(
							(c) => c.getIsVisible() === true,
						)
							? true
							: columns.every((c) => c.getIsVisible() === false)
								? false
								: "indeterminate";
						return (
							<div
								key={`group-${group}`}
								className="flex size-full flex-col"
							>
								<div
									className="mb-1 flex cursor-pointer items-center gap-1 px-1 capitalize"
									onClick={() =>
										columns.forEach((column) =>
											column.toggleVisibility(
												checked === true ? false : true,
											),
										)
									}
								>
									<Checkbox checked={checked} />
									<div className="font-medium">{group}</div>
								</div>
								<div className="flex h-full max-h-96 flex-col gap-1 overflow-y-auto text-nowrap rounded-sm bg-core-secondary-background p-1">
									<SortableList
										items={columns}
										onChangeOrder={(ids) =>
											onChangeOrder?.(
												getFullColumnOrder(
													columnsGrouped,
													group,
													ids,
												),
											)
										}
										itemRenderer={(column) => {
											const isPinned =
												column.getIsPinned();
											return (
												<div
													key={column.id}
													className="flex w-full items-center"
												>
													<div
														className="flex cursor-pointer items-center gap-1 text-sm capitalize"
														onClick={() =>
															column.toggleVisibility(
																!column.getIsVisible(),
															)
														}
													>
														<Checkbox
															checked={column.getIsVisible()}
															onCheckedChange={(
																value,
															) =>
																column.toggleVisibility(
																	!!value,
																)
															}
														/>
														{getColumnTitle(column)}
													</div>
													<Tooltip
														tip={
															isPinned
																? "Unpin"
																: "Pin to left"
														}
														side="right"
													>
														<Button
															size="min"
															variant="min"
															className={cn(
																"ml-auto size-6 p-1 opacity-30 hover:opacity-80",
																isPinned &&
																	"opacity-100 hover:text-core-primary-button-fill",
															)}
															onClick={() =>
																column.pin(
																	isPinned
																		? false
																		: "left",
																)
															}
														>
															<Icon
																name={
																	isPinned
																		? "Lock"
																		: "LockOpen"
																}
															/>
														</Button>
													</Tooltip>
												</div>
											);
										}}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

function getColumnTitle<TData>(column: Column<TData, unknown>) {
	return column.columnDef.meta?.title != null
		? column.columnDef.meta.title
		: column.id.replace("editor_", "");
}

function getFullColumnOrder<TData>(
	groups: ColumnGroup<TData>[],
	group: string,
	ids: string[],
) {
	const fullIds: string[] = [];
	for (const { key, columns } of groups) {
		if (key === group) fullIds.push(...ids);
		else fullIds.push(...columns.map((c) => c.id));
	}
	return fullIds;
}
