import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetOutcomesQueryResponse, RehabilitationPolygonsGetOutcomesPathParams, RehabilitationPolygonsGetOutcomesQueryParams } from "../../ts/RehabilitationPolygonsGetOutcomes.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/RehabilitationPolygons/:id/outcomes
 */
export async function rehabilitationPolygonsGetOutcomes(id: RehabilitationPolygonsGetOutcomesPathParams["id"], params?: RehabilitationPolygonsGetOutcomesQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationPolygonsGetOutcomesQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationPolygons/${id}/outcomes`, params, ...config });
    return res.data;
}