import { ColumnDef, ColumnSort } from "@tanstack/react-table";

import { DataTableNumberCell } from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { weedGridCellOrderBy, WeedGridCellPagedDto } from "@/lib/gen/eis";

export const getWeedGridVegCoverColumn = (
	vegetationClassName: string,
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>,
): ColumnDef<WeedGridCellPagedDto, unknown> => ({
	accessorKey: vegetationClassName,
	header: ({ column }) => (
		<DataTableColumnHeader
			column={column}
			title={`${vegetationClassName} %`}
			columnSortSetter={setColumnSort}
			isDynamicColumn={true}
			orderById={weedGridCellOrderBy.VegClassCoverDynamicColumn}
			rightAlign
		/>
	),
	cell: ({ row }) => (
		<DataTableNumberCell
			value={Number(
				row.original.weedClassCovers?.find(
					(x) => x.vegetationClassName === vegetationClassName,
				)?.coverPercentage ?? 0,
			)}
			numStyle="percent"
		/>
	),
	enableSorting: true,
	size: 140,
	meta: {
		title: `${vegetationClassName} %`,
		columnGroup: "Classification Covers",
	},
});
