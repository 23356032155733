import type { SeedBatchesGetInventorySummaryQueryParams, SeedBatchesGetInventorySummary200, SeedBatchesGetInventorySummaryQueryResponse } from "../ts/SeedBatchesGetInventorySummary.ts";
import { inventorySummaryDtoSchema } from "./inventorySummaryDtoSchema.ts";
import { lifeformSchema } from "./lifeformSchema.ts";
import { longevitySchema } from "./longevitySchema.ts";
import { provenanceLandformSchema } from "./provenanceLandformSchema.ts";
import { seedBatchStatusSchema } from "./seedBatchStatusSchema.ts";
import { z } from "zod";

 export const seedBatchesGetInventorySummaryQueryParamsSchema = z.object({ "SeedId": z.string().nullable().nullish(), "SupplierId": z.string().nullable().nullish(), "FloraSpeciesId": z.string().nullable().nullish(), "SeedName": z.array(z.string()).nullable().nullish(), "ProvenanceZone": z.array(z.string()).nullable().nullish(), "MinAmount": z.number().nullable().nullish(), "MaxAmount": z.number().nullable().nullish(), "Lifeform": z.array(z.lazy(() => lifeformSchema)).nullable().nullish(), "Longevity": z.array(z.lazy(() => longevitySchema)).nullable().nullish(), "SpeciesFullName": z.array(z.string()).nullable().nullish(), "Family": z.array(z.string()).nullable().nullish(), "SeedBatchNo": z.array(z.string()).nullable().nullish(), "SupplierName": z.array(z.string()).nullable().nullish(), "MineSiteName": z.array(z.string()).nullable().nullish(), "StoreLocation": z.array(z.string()).nullable().nullish(), "ProvenanceLandform": z.array(z.lazy(() => provenanceLandformSchema)).nullable().nullish(), "PONumber": z.array(z.string()).nullable().nullish(), "Status": z.array(z.lazy(() => seedBatchStatusSchema)).nullable().nullish() }).optional() as z.ZodType<SeedBatchesGetInventorySummaryQueryParams>;

 export const seedBatchesGetInventorySummary200Schema = z.lazy(() => inventorySummaryDtoSchema) as z.ZodType<SeedBatchesGetInventorySummary200>;

 export const seedBatchesGetInventorySummaryQueryResponseSchema = z.lazy(() => seedBatchesGetInventorySummary200Schema) as z.ZodType<SeedBatchesGetInventorySummaryQueryResponse>;