import { z } from "zod";

import type { Crswkt } from "@/lib/gen/titiler/types/Crswkt";

/**
 * @description Coordinate Reference System (CRS) from WKT encoded as PROJJSON Object.
 */
export const crswktSchema = z
	.object({
		wkt: z
			.object({})
			.describe(
				"An object defining the CRS using the JSON encoding for Well-known text representation of coordinate reference systems 2.0",
			),
	})
	.describe(
		"Coordinate Reference System (CRS) from WKT encoded as PROJJSON Object.",
	) as z.ZodType<Crswkt>;
