import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					"flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
Input.displayName = "Input";

export interface NumberInputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	children?: React.ReactNode;
	affix?: "prefix" | "suffix";
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
	({ className, type, children, affix = "suffix", ...props }, ref) => {
		return (
			<div
				className={cn(
					"flex h-9 w-full items-center justify-end gap-1 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors",
					"has-[:disabled]:cursor-not-allowed has-[:disabled]:opacity-50",
					"has-[:focus-visible]:ring-1 has-[:focus-visible]:ring-ring",
					className,
				)}
			>
				{affix === "prefix" && children}
				<input
					type={type ?? "number"}
					className={cn(
						"w-full bg-transparent text-right placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed",
					)}
					ref={ref}
					{...props}
				/>
				{affix === "suffix" && children}
			</div>
		);
	},
);
NumberInput.displayName = "NumberInput";

export { Input, NumberInput };
