import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Rehab_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const SiteDetailsCard = {
	component: lazy(() => import("@/components/Monitoring/SiteDetailsCard")),
	permission: Perm_Rehab_Std,
} as const satisfies LazyCardComponent;

export const LazySiteDetailsCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SiteDetailsCard, userPerms);
		if (comp) {
			if (currentView?.name === "Monitoring") return comp;
			if (currentView?.name === "Erosion") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"SiteDetailsCard"} />}
		</Suspense>
	);
};
