import type { SeedBatchesGetSeedBatchesWithPaginationQueryParams, SeedBatchesGetSeedBatchesWithPagination200, SeedBatchesGetSeedBatchesWithPaginationQueryResponse } from "../ts/SeedBatchesGetSeedBatchesWithPagination.ts";
import { lifeformSchema } from "./lifeformSchema.ts";
import { longevitySchema } from "./longevitySchema.ts";
import { paginatedListOfSeedBatchDtoSchema } from "./paginatedListOfSeedBatchDtoSchema.ts";
import { provenanceLandformSchema } from "./provenanceLandformSchema.ts";
import { seedBatchOrderBySchema } from "./seedBatchOrderBySchema.ts";
import { seedBatchStatusSchema } from "./seedBatchStatusSchema.ts";
import { z } from "zod";

 export const seedBatchesGetSeedBatchesWithPaginationQueryParamsSchema = z.object({ "SeedId": z.string().nullable().nullish(), "SupplierId": z.string().nullable().nullish(), "FloraSpeciesId": z.string().nullable().nullish(), "PolygonFeatureId": z.string().nullable().nullish(), "SeedName": z.array(z.string()).nullable().nullish(), "ProvenanceZone": z.array(z.string()).nullable().nullish(), "MinAmount": z.number().nullable().nullish(), "MaxAmount": z.number().nullable().nullish(), "Lifeform": z.array(z.lazy(() => lifeformSchema)).nullable().nullish(), "Longevity": z.array(z.lazy(() => longevitySchema)).nullable().nullish(), "SpeciesFullName": z.array(z.string()).nullable().nullish(), "Family": z.array(z.string()).nullable().nullish(), "SeedBatchNo": z.array(z.string()).nullable().nullish(), "SupplierName": z.array(z.string()).nullable().nullish(), "StoreLocation": z.array(z.string()).nullable().nullish(), "ProvenanceLandform": z.array(z.lazy(() => provenanceLandformSchema)).nullable().nullish(), "PONumber": z.array(z.string()).nullable().nullish(), "Status": z.array(z.lazy(() => seedBatchStatusSchema)).nullable().nullish(), "MineSiteName": z.array(z.string()).nullable().nullish(), "PageNumber": z.number().int().optional(), "PageSize": z.number().int().optional(), "OrderBy": z.lazy(() => seedBatchOrderBySchema).optional(), "Descending": z.boolean().optional() }).optional() as z.ZodType<SeedBatchesGetSeedBatchesWithPaginationQueryParams>;

 export const seedBatchesGetSeedBatchesWithPagination200Schema = z.lazy(() => paginatedListOfSeedBatchDtoSchema) as z.ZodType<SeedBatchesGetSeedBatchesWithPagination200>;

 export const seedBatchesGetSeedBatchesWithPaginationQueryResponseSchema = z.lazy(() => seedBatchesGetSeedBatchesWithPagination200Schema) as z.ZodType<SeedBatchesGetSeedBatchesWithPaginationQueryResponse>;