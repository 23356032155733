import client from "@kubb/plugin-client/client";
import type { ErosionGetErosionFeatureSummaryQueryResponse, ErosionGetErosionFeatureSummaryPathParams } from "../../ts/ErosionGetErosionFeatureSummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Erosion/:id/summary
 */
export async function erosionGetErosionFeatureSummary(id: ErosionGetErosionFeatureSummaryPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<ErosionGetErosionFeatureSummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/Erosion/${id}/summary`, ...config });
    return res.data;
}