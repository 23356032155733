import { atom } from "jotai";
import { atomWithCache } from "jotai-cache";
import { atomEffect } from "jotai-effect";

import { Perm_Rehab_Std, UserHasPermissionsAtom } from "@/atoms/authAtoms";
import { hexToRGBI } from "@/helpers/colour-helpers";
import { vegetationClassesGet } from "@/lib/gen/eis";

export const AllVegClassesAtom = atomWithCache(async (get) => {
	const userPerms = get(UserHasPermissionsAtom);
	if (!userPerms[Perm_Rehab_Std]) {
		return [];
	}
	return await vegetationClassesGet();
});

export type ClassificationImageryColors = {
	classValue: number;
	color: string;
	enabled: boolean;
};

const _visibleVegClassGridCodesAtom = atom(
	new Map<number, ClassificationImageryColors>(),
);
export const VisibleVegClassGridCodesAtom = atom(
	(get) => {
		return get(_visibleVegClassGridCodesAtom);
	},
	(get, set, classValues: Map<number, boolean>) => {
		const current = get(_visibleVegClassGridCodesAtom);
		const newMap = new Map(current);
		for (const [classValue, enabled] of classValues) {
			const prev = newMap.get(classValue);
			if (prev) {
				newMap.set(classValue, { ...prev, enabled });
			}
		}
		set(_visibleVegClassGridCodesAtom, newMap);
	},
);

export const RefreshVegClassListAtom = atom(null, (_get, set) => {
	set(_visibleVegClassGridCodesAtom, (prev) => new Map(prev));
});

const GROUND_CLASS_VALUE = 2;
export const VegClassAtomEffect = atomEffect((get, set) => {
	get(AllVegClassesAtom).then((allVegClasses) => {
		const visibleVegClasses = get(VisibleVegClassGridCodesAtom);
		const newMap = new Map<number, ClassificationImageryColors>();
		for (const vc of allVegClasses) {
			if (vc.gridCode == null || vc.displayColour == null) continue;
			const existing = visibleVegClasses.get(vc.gridCode);
			if (existing) {
				newMap.set(vc.gridCode, {
					...existing,
				});
			} else {
				newMap.set(vc.gridCode, {
					classValue: vc.gridCode,
					color: hexToRGBI(vc.displayColour),
					enabled: vc.gridCode !== GROUND_CLASS_VALUE,
				});
			}
		}
		set(_visibleVegClassGridCodesAtom, newMap);
	});
});
