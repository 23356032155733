import { AreaUnits, Units } from "@turf/helpers";

export const AreaUnitOptions: Record<
	Exclude<
		AreaUnits,
		"meters" | "millimeters" | "centimeters" | "kilometers" | "inches"
	>,
	string
> = {
	millimetres: "mm",
	centimetres: "cm",
	metres: "m",
	kilometres: "km",
	miles: "mi",
	nauticalmiles: "NM",
	yards: "yd",
	feet: "ft",
	acres: "ac",
	hectares: "ha",
};
export const UnitOptions: Record<
	Exclude<
		Units,
		"meters" | "millimeters" | "centimeters" | "kilometers" | "inches"
	>,
	string
> = {
	millimetres: "mm",
	centimetres: "cm",
	metres: "m",
	kilometres: "km",
	miles: "mi",
	nauticalmiles: "NM",
	yards: "yd",
	feet: "ft",
	radians: "rad",
	degrees: "°",
};

export const getAreaUnitShorthand = (unit?: AreaUnits) => {
	if (unit == null || !isValidAreaUnit(unit)) return null;
	const shorthand = AreaUnitOptions[unit];
	return `${shorthand}${shorthand != "ha" ? "²" : ""}`;
};
function isValidAreaUnit(
	value: AreaUnits,
): value is keyof typeof AreaUnitOptions {
	return Object.keys(AreaUnitOptions).includes(value);
}

export const getLengthUnitShorthand = (unit?: Units) => {
	if (unit == null || !isValidLengthUnit(unit)) return null;
	const shorthand = UnitOptions[unit];
	return shorthand;
};
function isValidLengthUnit(value: Units): value is keyof typeof UnitOptions {
	return Object.keys(UnitOptions).includes(value);
}

export const getAreaUnitFromShorthand = (
	shorthand?: string,
): AreaUnits | null => {
	if (shorthand == null) return null;
	switch (shorthand.toLowerCase()) {
		case "mm":
		case "mm²":
			return "millimetres";
		case "cm":
		case "cm²":
			return "centimetres";
		case "m":
		case "m²":
			return "metres";
		case "km":
		case "km²":
			return "kilometres";
		case "mi":
		case "mi²":
			return "miles";
		case "nm":
		case "nm²":
			return "nauticalmiles";
		case "yd":
		case "yd²":
			return "yards";
		case "ft":
		case "ft²":
			return "feet";
		case "ac":
			return "acres";
		case "ha":
			return "hectares";
	}
	return null;
};

export const getLengthUnitFromShorthand = (
	shorthand?: string,
): Units | null => {
	if (shorthand == null) return null;
	switch (shorthand.toLowerCase()) {
		case "mm":
			return "millimetres";
		case "cm":
			return "centimetres";
		case "m":
			return "metres";
		case "km":
			return "kilometres";
		case "mi":
			return "miles";
		case "nm":
			return "nauticalmiles";
		case "yd":
			return "yards";
		case "ft":
			return "feet";
	}
	return null;
};
