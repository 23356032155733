import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

import { IconName } from "@/components/icons";
import Icon from "@/components/icons/icon";
import { cn } from "@/lib/utils";

interface SwitchProps
	extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
	checkedIcon?: IconName;
	uncheckedIcon?: IconName;
}

const Switch = React.forwardRef<
	React.ElementRef<typeof SwitchPrimitives.Root>,
	SwitchProps
>(({ className, checkedIcon, uncheckedIcon, ...props }, ref) => (
	<SwitchPrimitives.Root
		className={cn(
			"peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-core-link-text data-[state=unchecked]:bg-input",
			className,
		)}
		{...props}
		ref={ref}
	>
		<SwitchPrimitives.Thumb
			className={cn(
				"pointer-events-none block h-4 w-4 rounded-full bg-core-primary-button-text shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
			)}
		>
			{props.checked && checkedIcon && (
				<Icon
					name={checkedIcon}
					className="size-3 -translate-x-3.5 translate-y-0.5"
				/>
			)}
			{!props.checked && uncheckedIcon && (
				<Icon
					name={uncheckedIcon}
					className="size-3 translate-x-5 translate-y-0.5"
				/>
			)}
		</SwitchPrimitives.Thumb>
	</SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
