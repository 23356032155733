import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetFilteredExportMutationRequest, RehabilitationPolygonsGetFilteredExportMutationResponse } from "../../ts/RehabilitationPolygonsGetFilteredExport.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/RehabilitationPolygons/filtered-export
 */
export async function rehabilitationPolygonsGetFilteredExport(data: RehabilitationPolygonsGetFilteredExportMutationRequest, config: Partial<RequestConfig<RehabilitationPolygonsGetFilteredExportMutationRequest>> = {}) {
    const res = await client<RehabilitationPolygonsGetFilteredExportMutationResponse, Error, RehabilitationPolygonsGetFilteredExportMutationRequest>({ method: "POST", url: `/api/RehabilitationPolygons/filtered-export`, data, ...config });
    return res.data;
}