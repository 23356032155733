import { useInfiniteQuery } from "@tanstack/react-query";
import { Row } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { SeedInventoryFiltersAtom } from "@/atoms/seedAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { EisColumnSort } from "@/components/data-table/table-types";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useFilters } from "@/helpers/filter-hook";
import {
	SeedDto,
	SeedOrderBy,
	seedsGetSeedsWithPagination,
} from "@/lib/gen/eis";
import { SEED_INVENTORY_FILTER_OPTIONS } from "@/types/filters/seed-inventory-filters";

import { SeedInventoryColumns } from "./seed-inventory-columns";
import { SeedInventoryExpandedRow } from "./seed-inventory-expanded-row";

const SeedInventoryCard = () => {
	const [columnSort, setColumnSort] = useState<EisColumnSort>({
		id: "Name",
		desc: false,
	});
	const [seedInventoryFilters, setSeedInventoryFilters] = useFilters(
		SeedInventoryFiltersAtom,
	);
	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"seedsGetSeedsWithPagination",
			columnSort,
			seedInventoryFilters,
		],
		queryFn: async ({ pageParam }) => {
			return seedsGetSeedsWithPagination({
				pageNumber: pageParam as number,
				pageSize: 25,
				orderBy: (columnSort.orderById ?? columnSort.id) as SeedOrderBy,
				descending: columnSort.desc,
				...parseFilterAguments(seedInventoryFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);

	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const columns = useMemo(() => {
		return SeedInventoryColumns({ setColumnSort });
	}, [setColumnSort]);

	const renderSubComponent = useCallback((row: Row<SeedDto>) => {
		return (
			<SeedInventoryExpandedRow
				seedBatches={row.original.seedBatches ?? []}
			/>
		);
	}, []);

	return (
		<BottomDrawDataTable
			columns={columns}
			data={rows}
			totalRows={totalRows}
			title="Seed Inventory"
			moduleType="seeds"
			icon="seed"
			isLoading={isLoading}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			filteredSearchBox={{
				options: [...SEED_INVENTORY_FILTER_OPTIONS],
				filters: seedInventoryFilters,
				setFilters: setSeedInventoryFilters,
			}}
			tabType={DrawerTabEnum.SEED_INVENTORY}
			renderSubComponent={renderSubComponent}
			minColumnSize={48}
		/>
	);
};

export default SeedInventoryCard;
