export function hexToRGBI(hex: string | null | undefined): string {
	if (!hex) {
		return "[255, 255, 255, 255]";
	}
	hex = hex.replace(/^#/, "");

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	return `[${r}, ${g}, ${b}, 255]`;
}
