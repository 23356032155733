import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useSetAtom } from "jotai";
import { memo } from "react";

import { SeedApplicationIdAtom } from "@/atoms/seedAppAtoms";
import { RehabPolygonNameCell } from "@/components/BottomDrawer/Monitoring/RehabPolygonsTableCard/rehab-polygon-table-cells";
import {
	DataTableCustomCell,
	DataTableDateCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { Button } from "@/components/ui/button";
import { seedApplicationOrderBy, SeedApplicationPagedDto } from "@/lib/gen/eis";

const columnHelper = createColumnHelper<SeedApplicationPagedDto>();

export const SeedAppColumns = () =>
	[
		columnHelper.accessor("name", {
			id: seedApplicationOrderBy.Name,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title={seedApplicationOrderBy.Name}
				/>
			),
			cell: ({ row, getValue }) => (
				<SeedAppNameCell id={row.original.id} name={getValue() ?? ""} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: seedApplicationOrderBy.Name,
			},
		}),
		columnHelper.accessor("rehabilitationPolygonName", {
			id: seedApplicationOrderBy.RehabilitationPolygonName,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Polygon" />
			),
			cell: ({ row, getValue }) => {
				const value = getValue();
				if (value == null) return "-";
				return (
					<RehabPolygonNameCell
						id={row.original.rehabPolygonId}
						name={value}
					/>
				);
			},
			enableSorting: true,
			size: 100,
			meta: {
				title: "Rehabilitation Polygon",
			},
		}),
		columnHelper.accessor("dateCreated", {
			id: seedApplicationOrderBy.DateCreated,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Date Created" />
			),
			cell: ({ getValue }) => <DataTableDateCell value={getValue()} />,
			enableSorting: true,
			size: 100,
			meta: {
				title: "Family",
			},
		}),
		columnHelper.accessor("note", {
			id: seedApplicationOrderBy.Note,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Notes" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
		}),
		columnHelper.accessor("status", {
			id: seedApplicationOrderBy.Status,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
		}),
	] as ColumnDef<SeedApplicationPagedDto>[];

export const SeedAppNameCell = memo(function ({
	id,
	name,
}: {
	id: string;
	name: string;
}) {
	const setSeedApp = useSetAtom(SeedApplicationIdAtom);

	return (
		<DataTableCustomCell>
			<Button
				onClick={() => setSeedApp(id)}
				variant="link"
				className="truncate px-0"
			>
				<span className="truncate">{name}</span>
			</Button>
		</DataTableCustomCell>
	);
});
