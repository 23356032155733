import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import { GetOLGeometry } from "@/helpers/geometry-helpers";
import {
	GetMonitoringScheduleQuery,
	GetObservationSpeciesSamplingQuery,
	GetRehabilitationPolygonsWithPaginationQuery,
	GetSamplingSiteObservationsPaginatedQuery,
	GetSamplingSitesPaginatedQuery,
	RehabilitationPolygonByExtentDto,
	rehabilitationPolygonsGetRehabCompletionCriterions,
} from "@/lib/gen/eis";

import { SelectAndZoomToFeatureAtom } from "./map/mapEventAtoms";
import { RehabPolygonBBoxQueryAtom } from "./map/mapQueryAtoms";
import { SelectedMineSiteIdAtom } from "./miningAtoms";

export const SelectedRehabPolygonAtom = atom<
	RehabilitationPolygonByExtentDto | undefined
>((get) => {
	const id = get(SelectedRehabPolygonIdAtom);
	return get(RehabPolygonBBoxQueryAtom).data?.find((r) => r.id === id);
});
export const SelectedRehabPolygonIdAtom = atom<string | undefined>(undefined);

export const RehabPolyGeometriesAtom = atom((get) => {
	return get(RehabPolygonBBoxQueryAtom).data ?? [];
});

export const GoToRehabPolygon = atom(null, async (get, set, id: string) => {
	const rehabPolysQuery = get(RehabPolygonBBoxQueryAtom);
	if (!rehabPolysQuery.data) return;

	const poly = rehabPolysQuery.data.find((rp) => rp.id === id);
	if (poly == null) return;
	set(SelectedMineSiteIdAtom, poly.mineSiteId);
	set(SelectedRehabPolygonIdAtom, poly.id);
	set(
		SelectAndZoomToFeatureAtom,
		id,
		"rehabpolygon",
		false,
		undefined,
		GetOLGeometry(poly.geometry),
	);
});

// keep track of tables' filters
export type RehabPolygonArg =
	keyof GetRehabilitationPolygonsWithPaginationQuery;
export const RehabPolyFiltersAtom = atom<ColumnFilter<RehabPolygonArg>[]>([]);

export type ObservationSpeciesSamplingArg =
	keyof GetObservationSpeciesSamplingQuery;
export const ObservationSpeciesSamplingFiltersAtom = atom<
	ColumnFilter<ObservationSpeciesSamplingArg>[]
>([]);

export type SamplingSiteObservationArg =
	keyof GetSamplingSiteObservationsPaginatedQuery;
export const SamplingSiteObservationFiltersAtom = atom<
	ColumnFilter<SamplingSiteObservationArg>[]
>([]);

export type SamplingSiteArg = keyof GetSamplingSitesPaginatedQuery;
export const SamplingSiteFiltersAtom = atom<ColumnFilter<SamplingSiteArg>[]>(
	[],
);

export type MonitoringScheduleArg = keyof GetMonitoringScheduleQuery;
export const MonitoringScheduleFiltersAtom = atom<
	ColumnFilter<MonitoringScheduleArg>[]
>([]);

const RehabCompletionCriterionsQueryAtom = atomWithQuery(() => {
	return {
		queryKey: ["rehabilitationPolygonsGetRehabCompletionCriterions"],
		queryFn: async () =>
			rehabilitationPolygonsGetRehabCompletionCriterions({}),
		staleTime: Infinity,
	};
});
export const RehabCompletionCriterionsAtom = atom((get) => {
	const query = get(RehabCompletionCriterionsQueryAtom);
	return query.data?.criterions ?? [];
});
