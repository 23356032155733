import { useInfiniteQuery } from "@tanstack/react-query";
import { RowSelectionState } from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { AllVegClassesAtom } from "@/atoms/map/vegClassAtoms";
import {
	SelectedMineSiteAtom,
	SelectedMiningRegionAtom,
} from "@/atoms/miningAtoms";
import {
	MonitoringScheduleArg,
	MonitoringScheduleFiltersAtom,
	RehabCompletionCriterionsAtom,
	SelectedRehabPolygonAtom,
} from "@/atoms/rehabPolyAtoms";
import { TimeSeriesDateAtom } from "@/atoms/timeseriesAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { getRehabPolygonVegCoverColumn } from "@/components/BottomDrawer/Monitoring/RehabPolygonsTableCard/rehab-polygon-columns";
import { EisColumnSort } from "@/components/data-table/table-types";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useInWindow } from "@/components/PoppedOutWindows";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	CompletionCriterionClass,
	CompletionCriterionGroup,
	MonitoringScheduleDto,
	monitoringScheduleGetMonitoringSchedule,
	MonitoringScheduleOrderBy,
} from "@/lib/gen/eis";
import {
	getRehabPolygonDynamicFilterCriteriaStatus,
	getRehabPolygonDynamicFilterVegClassCover,
	MONITORING_SCHEDULE_FILTER_OPTION_MINE_SITE_NAME,
	MONITORING_SCHEDULE_FILTER_OPTION_MINING_REGION_NAME,
	MONITORING_SCHEDULE_FILTER_OPTIONS,
} from "@/types/filters/monitoring-schedule-filters";

import {
	getMonitoringColumns,
	getMonitoringColumnsOther,
	getMonitoringRehabPolygonCriteriaResultColumn,
} from "./monitoring-columns";

const MonitoringScheduleTableCard = () => {
	const rehabPoly = useAtomValue(SelectedRehabPolygonAtom);

	const [columnSort, setColumnSort] = useState<EisColumnSort>({
		id: "Name",
		desc: false,
	});
	const [monitoringScheduleFilters, setMonitoringScheduleFilters] =
		useFilters(MonitoringScheduleFiltersAtom);

	// Auto-set mine site filter
	const inWindow = useInWindow();
	const mineSite = useAtomValue(SelectedMineSiteAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setMonitoringScheduleFilters,
			MONITORING_SCHEDULE_FILTER_OPTION_MINE_SITE_NAME,
			mineSite?.mineSiteName,
		);
	}, [inWindow, mineSite?.mineSiteName, setMonitoringScheduleFilters]);
	const region = useAtomValue(SelectedMiningRegionAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setMonitoringScheduleFilters,
			MONITORING_SCHEDULE_FILTER_OPTION_MINING_REGION_NAME,
			region?.name,
		);
	}, [inWindow, region?.name, setMonitoringScheduleFilters]);

	const rowSelection = useMemo(
		() =>
			(rehabPoly != null
				? { [rehabPoly.id]: true }
				: {}) as RowSelectionState,
		[rehabPoly],
	);

	const timeSereisDate = useAtomValue(TimeSeriesDateAtom);
	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"MonitoringSchedule",
			columnSort,
			monitoringScheduleFilters,
			timeSereisDate,
		],
		queryFn: async ({ pageParam }) => {
			return monitoringScheduleGetMonitoringSchedule({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: (columnSort.orderById ??
					columnSort.id) as MonitoringScheduleOrderBy,
				orderByDynamicColumn: columnSort.isDynamicColumn
					? columnSort.id
					: undefined,
				descending: columnSort.desc,
				timeSeriesDate: timeSereisDate.toISOString(),
				...parseFilterAguments(monitoringScheduleFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});

	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const initialColumns1 = useMemo(
		() => getMonitoringColumns(setColumnSort),
		[],
	);
	const initialColumns2 = useMemo(
		() => getMonitoringColumnsOther(setColumnSort),
		[],
	);
	const vegClasses = useAtomValue(AllVegClassesAtom);
	const criterions = useAtomValue(RehabCompletionCriterionsAtom);
	const columns = useMemo(
		() => [
			...initialColumns1,
			...criterions.map((cr) =>
				getMonitoringRehabPolygonCriteriaResultColumn(
					cr.criterionName ?? "",
					setColumnSort,
				),
			),
			...initialColumns2,
			...vegClasses
				.sort(
					(a, b) =>
						(a.displayPosition ?? 999) - (b.displayPosition ?? 999),
				)
				.map((vc) =>
					getRehabPolygonVegCoverColumn<MonitoringScheduleDto>(
						vc.name ?? "",
						setColumnSort,
						true,
					),
				),
		],
		[criterions, initialColumns1, initialColumns2, vegClasses],
	);

	const [dynamicFilters, setDynamicFilters] = useState<
		ColumnFilterOption<MonitoringScheduleArg>[]
	>([]);
	useEffect(() => {
		setDynamicFilters([
			...criterions.map((cr) =>
				getRehabPolygonDynamicFilterCriteriaStatus(
					cr.criterionName!,
					getCompletionCriterionGroup(cr.completionCriterionClass),
				),
			),
			...vegClasses.map((vc) =>
				getRehabPolygonDynamicFilterVegClassCover(vc),
			),
		]);
	}, [criterions, vegClasses]);

	return (
		<BottomDrawDataTable
			columns={columns}
			data={rows}
			totalRows={totalRows}
			getRowId={(row) => row.rehabPolygonId}
			rowSelection={rowSelection}
			title="Monitoring Schedule"
			moduleType="monitoring"
			icon="polygon"
			isLoading={isLoading}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			exportContext="monitoringschedule"
			filteredSearchBox={{
				options: [
					...MONITORING_SCHEDULE_FILTER_OPTIONS,
					...dynamicFilters,
				],
				filters: monitoringScheduleFilters,
				setFilters: setMonitoringScheduleFilters,
			}}
			tabType={DrawerTabEnum.MONITORING_SCHEDULE}
		/>
	);
};

/** Quick copy of `GetCompletionCriteriaGroup` from backend */
const getCompletionCriterionGroup = (
	completionCriterionClass: CompletionCriterionClass,
): CompletionCriterionGroup => {
	switch (completionCriterionClass) {
		case "Species Richness":
			return "On-Ground";
		case "Erosion":
			return "Erosion";
		default:
			return "Remote Sensing";
	}
};

export default MonitoringScheduleTableCard;
