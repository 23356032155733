import { route } from "react-router-typesafe-routes";

export const ROUTES = route({
	children: {
		app: route({
			path: "",
		}),
		authentication: route({
			path: "authentication",
			children: {
				login: route({
					path: "login-callback",
				}),
				logout: route({
					path: "logout-callback",
				}),
			},
		}),
	},
});
