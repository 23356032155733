import { z } from "zod";

import type { Ifd } from "@/lib/gen/titiler/types/Ifd";

/**
 * @description ImageFileDirectory info.
 */
export const ifdSchema = z
	.object({
		Level: z.number().int(),
		Width: z.number().int(),
		Height: z.number().int(),
		Blocksize: z.tuple([z.number().int(), z.number().int()]),
		Decimation: z.number().int(),
	})
	.describe("ImageFileDirectory info.") as z.ZodType<Ifd>;
