import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { FieldValues, Path } from "react-hook-form";

import { SelectFormControl } from "@/components/form/select-form-control";
import { floraSpeciesGetFloraSpeciesWithPagination } from "@/lib/gen/eis";

type Props<T extends FieldValues> = {
	name: Path<T>;
	label: string;
	description?: string;
	className?: string;
};

export const SelectFloraSpeciesFormField = <T extends FieldValues>({
	name,
	label,
	description,
	className,
}: Props<T>) => {
	// TODO update this selector type to better handle large list of species
	const { data } = useQuery({
		queryKey: [
			"floraSpeciesGetFloraSpeciesWithPagination",
			{ PageSize: -1 },
		],
		queryFn: async () =>
			floraSpeciesGetFloraSpeciesWithPagination({
				pageSize: -1,
				orderBy: "FullName",
				pageNumber: 0,
				descending: false,
			}),
	});
	const options = useMemo(() => {
		return (
			data?.items?.flatMap((s) => {
				if (!s.fullName) return [];
				return [{ id: s.id, value: s.id, label: s.fullName }];
			}) ?? []
		);
	}, [data]);

	return (
		<SelectFormControl
			label={label}
			name={name}
			options={options}
			description={description}
			className={className}
			modal={true}
		/>
	);
};
