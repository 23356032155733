import type { SeedBatchesMakeStockAdjustmentPathParams, SeedBatchesMakeStockAdjustment200, SeedBatchesMakeStockAdjustmentMutationRequest, SeedBatchesMakeStockAdjustmentMutationResponse } from "../ts/SeedBatchesMakeStockAdjustment.ts";
import { makeSeedBatchStockAdjustmentCommandSchema } from "./makeSeedBatchStockAdjustmentCommandSchema.ts";
import { z } from "zod";

 export const seedBatchesMakeStockAdjustmentPathParamsSchema = z.object({ "id": z.string() }) as z.ZodType<SeedBatchesMakeStockAdjustmentPathParams>;

 export const seedBatchesMakeStockAdjustment200Schema = z.instanceof(File) as z.ZodType<SeedBatchesMakeStockAdjustment200>;

 export const seedBatchesMakeStockAdjustmentMutationRequestSchema = z.lazy(() => makeSeedBatchStockAdjustmentCommandSchema) as z.ZodType<SeedBatchesMakeStockAdjustmentMutationRequest>;

 export const seedBatchesMakeStockAdjustmentMutationResponseSchema = z.lazy(() => seedBatchesMakeStockAdjustment200Schema) as z.ZodType<SeedBatchesMakeStockAdjustmentMutationResponse>;