export const vegetationCondition = {
    "Excellent": "Excellent",
    "VeryGood": "Very Good",
    "Good": "Good",
    "Poor": "Poor",
    "Degraded": "Degraded",
    "CompletelyDegraded": "Completely Degraded"
} as const;

 export type VegetationConditionEnum = (typeof vegetationCondition)[keyof typeof vegetationCondition];

 export type VegetationCondition = VegetationConditionEnum;