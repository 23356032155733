import type { SeedApplicationForAllocateDto } from "../ts/SeedApplicationForAllocateDto.ts";
import { lifeformTargetCoversDtoSchema } from "./lifeformTargetCoversDtoSchema.ts";
import { seedApplicationMethodSchema } from "./seedApplicationMethodSchema.ts";
import { seedApplicationProvenanceZoneAllocateDtoSchema } from "./seedApplicationProvenanceZoneAllocateDtoSchema.ts";
import { seedApplicationSpeciesAllocateDtoSchema } from "./seedApplicationSpeciesAllocateDtoSchema.ts";
import { seedApplicationStatusSchema } from "./seedApplicationStatusSchema.ts";
import { seedAppRehabPolyDtoSchema } from "./seedAppRehabPolyDtoSchema.ts";
import { seedMixCriteriaResultDtoSchema } from "./seedMixCriteriaResultDtoSchema.ts";
import { vegetationCommunitySingleDtoSchema } from "./vegetationCommunitySingleDtoSchema.ts";
import { z } from "zod";

 export const seedApplicationForAllocateDtoSchema = z.object({ "id": z.string(), "name": z.string().nullable().nullish(), "rehabilitationPolygon": z.lazy(() => seedAppRehabPolyDtoSchema).nullable().nullish(), "vegetationCommunity": z.lazy(() => vegetationCommunitySingleDtoSchema).nullable().nullish(), "allocationOrderId": z.string(), "seedApplicationSpecies": z.array(z.lazy(() => seedApplicationSpeciesAllocateDtoSchema)).nullable().nullish(), "provenanceZone": z.lazy(() => seedApplicationProvenanceZoneAllocateDtoSchema).nullable().nullish(), "totalQuantity": z.number(), "lifeformDtos": z.array(z.lazy(() => lifeformTargetCoversDtoSchema)).nullable().nullish(), "status": z.lazy(() => seedApplicationStatusSchema), "criteriaResults": z.array(z.lazy(() => seedMixCriteriaResultDtoSchema)).nullable().nullish(), "floraSpeciesCount": z.number().int(), "floraSpeciesAvailableCount": z.number().int(), "nearestQuadrat": z.number(), "seedingDepth": z.number().int().nullable().nullish(), "applicationNotes": z.string().nullable().nullish(), "method": z.lazy(() => seedApplicationMethodSchema), "startDate": z.string().date().nullable().nullish(), "endDate": z.string().date().nullable().nullish() }) as z.ZodType<SeedApplicationForAllocateDto>;