import { atom } from "jotai";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import { GetSeedsWithPaginationQuery } from "@/lib/gen/eis";

export type SeedInventoryArg = keyof GetSeedsWithPaginationQuery;
export const SeedInventoryFiltersAtom = atom<ColumnFilter<SeedInventoryArg>[]>(
	[],
);

const _seedBatchDialogOpenAtom = atom(false);
const _seedBatchDialogSeedBatchIdAtom = atom<string | undefined>(undefined);
export const SeedBatchDialogOpenAtom = atom(
	(get) => {
		return (
			get(_seedBatchDialogOpenAtom) &&
			!!get(_seedBatchDialogSeedBatchIdAtom)
		);
	},
	(_get, set, open: boolean) => {
		set(_seedBatchDialogOpenAtom, open);
		if (!open) set(_seedBatchDialogSeedBatchIdAtom, undefined);
	},
);
export const SeedBatchDialogSeedBatchIdAtom = atom(
	(get) => get(_seedBatchDialogSeedBatchIdAtom),
	(_get, set, id?: string) => {
		set(_seedBatchDialogOpenAtom, !!id);
		set(_seedBatchDialogSeedBatchIdAtom, id);
	},
);
