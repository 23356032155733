import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
	HealthSurvey1Atom,
	SelectedHealthMetricAtom,
} from "@/atoms/healthAtoms";
import {
	AnalogueSurveyUrlsQueryAtom,
	SelectedMineSiteIdAtom,
	SurveyUrlsQueryAtom,
} from "@/atoms/miningAtoms";
import { LayerUrlType } from "@/lib/gen/eis";

export type ImageryObject = {
	url: string;
	type: LayerUrlType;
	layers?: string;
	colorMapName?: string;
};

export const ImageryUrlAtom = atom(new Map<string, ImageryObject>());
export const ImageryUrlAtomEffect = atomEffect((get, set) => {
	const { data: surveyUrls, isLoading } = get(SurveyUrlsQueryAtom);
	const healthSurvey = get(HealthSurvey1Atom);

	if (isLoading) return;

	const urls = new Map<string, ImageryObject>();
	if (surveyUrls) {
		const imageryUrls = surveyUrls
			.filter((rs) => rs.imageryTileLayerUrl)
			.flatMap((rs) => {
				if (!rs.imageryTileLayerUrl || !rs.imageryTileLayerUrlType)
					return [];
				return [
					{
						url: rs.imageryTileLayerUrl,
						type: rs.imageryTileLayerUrlType,
						layers: "Imagery",
					},
				];
			});
		const distinct = imageryUrls.filter(
			(v, index) =>
				imageryUrls.findIndex((v2) => v2.url === v.url) === index,
		);
		for (const url of distinct) {
			urls.set(url.url, url);
		}
	}
	if (
		!!healthSurvey?.imageryTileLayerUrlType &&
		!!healthSurvey.imageryTileLayerUrl
	) {
		urls.set(healthSurvey.imageryTileLayerUrl, {
			url: healthSurvey.imageryTileLayerUrl,
			type: healthSurvey.imageryTileLayerUrlType,
		});
	}
	set(ImageryUrlAtom, urls);
});

export const ClassificationUrlAtom = atom(new Map<string, ImageryObject>());
export const ClassificationUrlAtomEffect = atomEffect((get, set) => {
	const { data: surveyUrls, isLoading } = get(SurveyUrlsQueryAtom);
	const healthSurvey = get(HealthSurvey1Atom);

	if (isLoading) return;

	const urls = new Map<string, ImageryObject>();
	if (surveyUrls) {
		const imageryUrls = surveyUrls.flatMap((rs) => {
			if (
				!rs.classificationTileLayerUrl ||
				!rs.classificationTileLayerUrlType
			)
				return [];
			return [
				{
					url: rs.classificationTileLayerUrl,
					type: rs.classificationTileLayerUrlType,
					layers: "Classification",
				},
			];
		});
		const distinct = imageryUrls.filter(
			(v, index) =>
				imageryUrls.findIndex((v2) => v2.url === v.url) === index,
		);
		for (const url of distinct) {
			urls.set(url.url, url);
		}
	}
	if (
		!!healthSurvey?.classificationTileLayerUrlType &&
		!!healthSurvey.classificationTileLayerUrl
	) {
		urls.set(healthSurvey.classificationTileLayerUrl, {
			url: healthSurvey.classificationTileLayerUrl,
			type: healthSurvey.classificationTileLayerUrlType,
		});
	}

	set(ClassificationUrlAtom, urls);
});

export const AnalogueClassificationUrlAtom = atom(
	new Map<string, ImageryObject>(),
);
export const AnalogueClassificationUrlAtomEffect = atomEffect((get, set) => {
	const { data: surveyUrls, isLoading } = get(AnalogueSurveyUrlsQueryAtom);
	if (isLoading) return;

	const urls = new Map<string, ImageryObject>();
	if (surveyUrls) {
		const imageryUrls = surveyUrls.flatMap((rs) => {
			if (
				!rs.classificationTileLayerUrl ||
				!rs.classificationTileLayerUrlType
			)
				return [];
			return [
				{
					url: rs.classificationTileLayerUrl,
					type: rs.classificationTileLayerUrlType,
					layers: "Analogue Classification",
				},
			];
		});
		const distinct = imageryUrls.filter(
			(v, index) =>
				imageryUrls.findIndex((v2) => v2.url === v.url) === index,
		);
		for (const url of distinct) {
			urls.set(url.url, url);
		}
	}

	set(AnalogueClassificationUrlAtom, urls);
});

export const MsaviUrlAtom = atom(new Map<string, ImageryObject>());
export const MsaviUrlAtomEffect = atomEffect((get, set) => {
	const healthSurvey = get(HealthSurvey1Atom);
	const metric = get(SelectedHealthMetricAtom);

	const urls = new Map<string, ImageryObject>();
	if (
		healthSurvey?.msaviTileLayerUrl &&
		healthSurvey?.msaviTileLayerUrlType
	) {
		urls.set(healthSurvey.msaviTileLayerUrl, {
			url: healthSurvey.msaviTileLayerUrl,
			type: healthSurvey.msaviTileLayerUrlType,
			layers: metric?.columnId ?? undefined,
			colorMapName: "msavi",
		});
	}
	set(MsaviUrlAtom, urls);
});

export const ErosionUrlAtom = atom(new Map<string, ImageryObject>());
export const ErosionUrlAtomEffect = atomEffect((get, set) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const { data: surveyUrls } = get(SurveyUrlsQueryAtom);

	const urls = new Map<string, ImageryObject>();
	if (surveyUrls && mineSiteId) {
		const erosionUrls = surveyUrls.flatMap((rs) => {
			if (!rs.erosionTileLayerUrl || !rs.erosionTileLayerUrlType)
				return [];
			return [
				{
					url: rs.erosionTileLayerUrl,
					type: rs.erosionTileLayerUrlType,
					layers: "Erosion",
					colorMapName: "erosion",
				},
			];
		});
		const distinct = erosionUrls.filter(
			(v, index) =>
				erosionUrls.findIndex((v2) => v2.url === v.url) === index,
		);
		for (const url of distinct) {
			urls.set(url.url, url);
		}
	}

	set(ErosionUrlAtom, urls);
});
