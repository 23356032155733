import { SamplingSiteArg } from "@/atoms/rehabPolyAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import {
	samplingSiteLandformType,
	samplingSiteType,
	siteSamplingMethod,
	slopeAspect,
} from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const SAMPLING_SITE_FILTER_OPTION_MINE_SITE: ColumnFilterOption<SamplingSiteArg> =
	{
		key: "mineSiteName",
		label: "Site",
		icon: "site",
		options: (searchTerm?: string) =>
			getTypeAheadResults("mineSiteName", searchTerm),
		keywords: ["mine", "name"],
		type: "string",
	};
export const SAMPLING_SITE_FILTER_OPTION_MINING_REGION_NAME: ColumnFilterOption<SamplingSiteArg> =
	{
		key: "miningRegionName",
		label: "Region",
		icon: "region",
		options: (searchTerm?: string) =>
			getTypeAheadResults("miningRegionName", searchTerm),
		keywords: ["mine", "name"],
	};
export const SAMPLING_SITE_FILTER_OPTION_REHAB_POLYGON_NAME: ColumnFilterOption<SamplingSiteArg> =
	{
		key: "rehabilitationPolygonName",
		label: "Rehabilitation Polygon Name",
		icon: "polygon",
		options: (searchTerm?: string) =>
			getTypeAheadResults("polygonName", searchTerm),
		type: "string",
	};

export const SAMPLING_SITE_FILTER_OPTIONS: ColumnFilterOption<SamplingSiteArg>[] =
	[
		{
			key: "samplingSiteName",
			label: "Sampling Site Name",
			icon: "quadrat",
			options: (searchTerm?: string) =>
				getTypeAheadResults("samplingSiteName", searchTerm),
			type: "string",
		},
		{
			key: "samplingSiteType",
			label: "Sampling Type",
			icon: "Filter",
			options: Object.values(samplingSiteType).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "siteSamplingMethod",
			label: "Sampling Method",
			icon: "Filter",
			options: Object.values(siteSamplingMethod).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "slopeAspect",
			label: "Slope Aspect",
			icon: "Filter",
			options: Object.values(slopeAspect).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "samplingSiteLandformType",
			label: "Landform",
			icon: "landform",
			options: Object.values(samplingSiteLandformType).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "area",
			label: "Area",
			icon: "total_area",
			type: "area",
		},
		SAMPLING_SITE_FILTER_OPTION_MINE_SITE,
		SAMPLING_SITE_FILTER_OPTION_MINING_REGION_NAME,
		SAMPLING_SITE_FILTER_OPTION_REHAB_POLYGON_NAME,
	];
