export const siteSamplingMethod = {
    "Opportunistic": "Opportunistic",
    "Quadrat": "Quadrat",
    "Releve": "Releve",
    "Transect": "Transect",
    "TargetedSearch": "Targeted Search",
    "Nvcp": "Nvcp"
} as const;

 export type SiteSamplingMethodEnum = (typeof siteSamplingMethod)[keyof typeof siteSamplingMethod];

 export type SiteSamplingMethod = SiteSamplingMethodEnum;