import client from "@kubb/plugin-client/client";
import type { SamplingSitesGetSamplingSiteObservationsPaginatedMutationRequest, SamplingSitesGetSamplingSiteObservationsPaginatedMutationResponse } from "../../ts/SamplingSitesGetSamplingSiteObservationsPaginated.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/SamplingSites/Observations/paginated
 */
export async function samplingSitesGetSamplingSiteObservationsPaginated(data: SamplingSitesGetSamplingSiteObservationsPaginatedMutationRequest, config: Partial<RequestConfig<SamplingSitesGetSamplingSiteObservationsPaginatedMutationRequest>> = {}) {
    const res = await client<SamplingSitesGetSamplingSiteObservationsPaginatedMutationResponse, Error, SamplingSitesGetSamplingSiteObservationsPaginatedMutationRequest>({ method: "POST", url: `/api/SamplingSites/Observations/paginated`, data, ...config });
    return res.data;
}