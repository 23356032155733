import type { GetSeedsWithPaginationQuery } from "../ts/GetSeedsWithPaginationQuery.ts";
import { doubleFilterSchema } from "./doubleFilterSchema.ts";
import { guidFilterSchema } from "./guidFilterSchema.ts";
import { nullableEnumFilterOfLifeformSchema } from "./nullableEnumFilterOfLifeformSchema.ts";
import { nullableEnumFilterOfLongevitySchema } from "./nullableEnumFilterOfLongevitySchema.ts";
import { nullableEnumFilterOfProvenanceLandformSchema } from "./nullableEnumFilterOfProvenanceLandformSchema.ts";
import { seedOrderBySchema } from "./seedOrderBySchema.ts";
import { stringFilterSchema } from "./stringFilterSchema.ts";
import { z } from "zod";

 export const getSeedsWithPaginationQuerySchema = z.object({ "floraSpeciesId": z.array(z.lazy(() => guidFilterSchema)).nullable().nullish(), "speciesFullName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "family": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "seedBatchNo": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "supplierName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "storeLocation": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "poNumber": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "provenanceZone": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "availableQuantity": z.array(z.lazy(() => doubleFilterSchema)).nullable().nullish(), "quantityStock": z.array(z.lazy(() => doubleFilterSchema)).nullable().nullish(), "lifeform": z.lazy(() => nullableEnumFilterOfLifeformSchema).nullable().nullish(), "longevity": z.lazy(() => nullableEnumFilterOfLongevitySchema).nullable().nullish(), "provenanceLandform": z.lazy(() => nullableEnumFilterOfProvenanceLandformSchema).nullable().nullish(), "pageNumber": z.number().int(), "pageSize": z.number().int(), "descending": z.boolean(), "orderBy": z.lazy(() => seedOrderBySchema) }) as z.ZodType<GetSeedsWithPaginationQuery>;