import client from "@kubb/plugin-client/client";
import type { ProvenanceZonesGetProvenanceZonesWithPaginationQueryResponse, ProvenanceZonesGetProvenanceZonesWithPaginationQueryParams } from "../../ts/ProvenanceZonesGetProvenanceZonesWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Inventory/Seeds/ProvenanceZones
 */
export async function provenanceZonesGetProvenanceZonesWithPagination(params?: ProvenanceZonesGetProvenanceZonesWithPaginationQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<ProvenanceZonesGetProvenanceZonesWithPaginationQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/Seeds/ProvenanceZones`, params, ...config });
    return res.data;
}