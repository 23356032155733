import { createContext, ReactNode } from "react";

// Create context with default value of null
export const WindowContext = createContext<Window | null>(null);

// Provider simply passes through the provided window value
export function WindowProvider({
	window,
	children,
}: {
	window: Window;
	children: ReactNode;
}) {
	return (
		<WindowContext.Provider value={window}>
			{children}
		</WindowContext.Provider>
	);
}
