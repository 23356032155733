import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { useAtomValue } from "jotai";
import { ChevronDown, PlusIcon } from "lucide-react";
import { useMemo } from "react";

import { SeedApplicationTemplateAtom } from "@/atoms/seedAppAtoms";
import Icon from "@/components/icons/icon";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Lifeform } from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

import { AddSpeciesDialog } from "./add-species-dialog";
import { LifeformPlanTable } from "./lifeform-plan-table";
import { LifeformArray, LifeformClasses } from "./lifeforms";

export const SeedApplicationPlanDrawer = () => {
	return (
		<div className="flex max-h-full flex-1 flex-col gap-1 overflow-hidden p-3 pr-0">
			<Accordion
				type="multiple"
				className="flex flex-col gap-2 overflow-y-scroll pr-1"
				defaultValue={LifeformArray}
			>
				{LifeformArray.map((lf) => (
					<LifeformAccordionItem key={lf} lf={lf} />
				))}
			</Accordion>
		</div>
	);
};

const LifeformAccordionItem = ({ lf }: { lf: Lifeform }) => {
	const { data } = useAtomValue(SeedApplicationTemplateAtom);
	const classes = LifeformClasses[lf];

	const targetCover = useMemo(() => {
		if (!data) return 0;
		const cover =
			data.lifeformDtos?.find((dto) => dto.lifeform === lf)
				?.targetCover ?? 0;
		return cover.toLocaleString(undefined, {
			style: "percent",
			maximumFractionDigits: 1,
		});
	}, [data, lf]);

	return (
		<AccordionItem
			value={lf}
			className={cn(
				"flex w-full flex-1 flex-col rounded-l-sm border-l-4",
				classes.thickBorder,
			)}
		>
			<AccordionPrimitive.Header asChild>
				<div
					className={cn(
						"flex w-full items-center justify-between gap-1 rounded-r-sm border border-l-0 pl-2 data-[state=open]:rounded-br-none",
						classes.border,
						classes.background,
					)}
				>
					<div className="grid grid-cols-2 gap-2">
						<div className="flex min-w-48 items-center gap-2">
							<Icon
								name={lf}
								className={classes.text}
								size={20}
							/>
							<span className="font-semibold">{lf}</span>
						</div>
						<div className="flex items-center gap-2">
							<span className="text-xs text-muted-foreground">
								Target Lifeform Cover:
							</span>
							<span className="text-sm font-semibold">
								{targetCover}
							</span>
						</div>
					</div>
					<div className="flex">
						<AddSpeciesDialog lf={lf}>
							<Button
								variant="ghost"
								className={cn(
									"flex items-center gap-1 rounded-none border-l text-misc-tyton-green-primary",
									classes.border,
								)}
							>
								<PlusIcon size={16} />
								<span className="text-sm font-semibold">
									Add Species
								</span>
							</Button>
						</AddSpeciesDialog>
						<AccordionPrimitive.Trigger
							asChild
							className={cn(
								"rounded-l-none border-l data-[state=open]:rounded-br-none [&[data-state=open]_svg]:rotate-0",
								classes.border,
							)}
						>
							<Button variant="ghost">
								<ChevronDown
									size={16}
									className="shrink-0 -rotate-90 text-misc-tyton-green-primary transition-transform duration-200"
								/>
							</Button>
						</AccordionPrimitive.Trigger>
					</div>
				</div>
			</AccordionPrimitive.Header>
			<AccordionContent
				className={cn(
					"rounded-br-sm border-b border-r pt-2",
					classes.border,
				)}
			>
				<LifeformPlanTable lf={lf} />
			</AccordionContent>
		</AccordionItem>
	);
};
