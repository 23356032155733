import { SamplingSiteObservationArg } from "@/atoms/rehabPolyAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { vegetationCondition } from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const SAMPLING_SITE_OBSERVATION_FILTER_OPTIONS: ColumnFilterOption<SamplingSiteObservationArg>[] =
	[
		{
			key: "observationName",
			label: "Observation Name",
			icon: "observation",
			options: (searchTerm?: string) =>
				getTypeAheadResults("samplingSiteObservationName", searchTerm),
			type: "string",
		},
		{
			key: "samplingSiteName",
			label: "Sampling Site Name",
			icon: "quadrat",
			options: (searchTerm?: string) =>
				getTypeAheadResults("samplingSiteName", searchTerm),
			type: "string",
		},
		{
			key: "observationOnGroundCampaignName",
			label: "On-Ground Campaign",
			icon: "ClipboardList",
			options: (searchTerm?: string) =>
				getTypeAheadResults(
					"rehabilitationOngroundSurveyName",
					searchTerm,
				),
			type: "string",
		},
		{
			key: "observerName",
			label: "Observer Name",
			icon: "Text",
			type: "string",
		},
		{
			key: "nativeSpeciesCount",
			label: "Native Species Count",
			icon: "Filter",
			type: "number",
		},
		{
			key: "vegetationCondition",
			label: "Vegetation Condition",
			icon: "Filter",
			options: Object.values(vegetationCondition).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "comments",
			label: "Comments",
			icon: "Text",
			type: "string",
		},
		{
			key: "imageCount",
			label: "Photo Count",
			icon: "FileImage",
			type: "number",
			keywords: ["picture", "image", "amount"],
		},
	];
