import client from "@kubb/plugin-client/client";
import type { SeedApplicationsGetSeedApplicationForPlanQueryResponse, SeedApplicationsGetSeedApplicationForPlanPathParams } from "../../ts/SeedApplicationsGetSeedApplicationForPlan.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Inventory/Seeds/Applications/:id/plan
 */
export async function seedApplicationsGetSeedApplicationForPlan(id: SeedApplicationsGetSeedApplicationForPlanPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<SeedApplicationsGetSeedApplicationForPlanQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/Seeds/Applications/${id}/plan`, ...config });
    return res.data;
}