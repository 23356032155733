export type QueryValue =
	| string
	| string[]
	| number
	| number[]
	| boolean
	| null
	| undefined;

export const BuildQueryString = (
	baseQuery: string,
	request: Record<string, QueryValue>,
	keysToIgnore: string[] = [],
) => {
	const searchParams = new URLSearchParams();
	for (const [key, value] of Object.entries(request)) {
		if (keysToIgnore.includes(key)) continue;
		if (value == null || value === "") continue;
		if (Array.isArray(value)) {
			value.forEach((v) => {
				searchParams.append(key, v.toString());
			});
		} else {
			searchParams.append(key, value.toString());
		}
	}
	const query =
		searchParams.toString().length > 0
			? `${baseQuery}?${searchParams.toString()}`
			: baseQuery;
	return query;
};
