import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetRehabCompletionCriterionsMutationRequest, RehabilitationPolygonsGetRehabCompletionCriterionsMutationResponse } from "../../ts/RehabilitationPolygonsGetRehabCompletionCriterions.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/RehabilitationPolygons/completionCriterions
 */
export async function rehabilitationPolygonsGetRehabCompletionCriterions(data?: RehabilitationPolygonsGetRehabCompletionCriterionsMutationRequest, config: Partial<RequestConfig<RehabilitationPolygonsGetRehabCompletionCriterionsMutationRequest>> = {}) {
    const res = await client<RehabilitationPolygonsGetRehabCompletionCriterionsMutationResponse, Error, RehabilitationPolygonsGetRehabCompletionCriterionsMutationRequest>({ method: "POST", url: `/api/RehabilitationPolygons/completionCriterions`, data, ...config });
    return res.data;
}