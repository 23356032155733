import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Collection } from "ol";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

import { ClassificationUrlAtom } from "@/atoms/map/imageryUrlAtoms";
import { CreateImageryXyzLayer } from "@/atoms/map/layerAtoms";
import {
	ClassificationImageryColors,
	RefreshVegClassListAtom,
	VisibleVegClassGridCodesAtom,
} from "@/atoms/map/vegClassAtoms";
import { ArcGisTokenQueryAtom } from "@/atoms/mapAtoms";

import {
	ImageryAtomEffectFunction,
	PopulateImageryLayer,
} from "./imagery-layer-helpers";
import { SwipeBarAtom } from "./swipe-bar";

export const ClassificationLayerGroupAtom = atom(() => {
	return new LayerGroup({ layers: new Collection<TileLayer<XYZ>>() });
});

export const ClassificationLayerAtomEffect = atomEffect((get, set) => {
	const urls = get(ClassificationUrlAtom);
	const group = get(ClassificationLayerGroupAtom);
	const swipeBar = get(SwipeBarAtom);

	ImageryAtomEffectFunction(get, group, urls, (get, img) => {
		const { data: arcgisToken } = get(ArcGisTokenQueryAtom);
		const { source, bounds } = PopulateImageryLayer(get, img, arcgisToken);
		if (!source) return;
		return CreateImageryXyzLayer(source, {
			extent: bounds,
			opacity: 0.6,
			preload: 2,
		});
	});
	// trigger atom effect to add color map
	set(RefreshVegClassListAtom);

	group.getLayers().forEach((l) => {
		if (l instanceof TileLayer) {
			swipeBar.addLayer(l, true);
		}
	});

	return () => {
		group.getLayers().forEach((l) => {
			if (l instanceof TileLayer) {
				swipeBar.removeLayer(l);
			}
		});
	};
});

const _classificationLayerVisibility = atom(true);
export const ClassificationLayerVisibility = atom(
	(get) => {
		return get(_classificationLayerVisibility);
	},
	(get, set, value?: boolean) => {
		const layer = get(ClassificationLayerGroupAtom);
		const bool = value ?? !layer.getVisible();
		set(_classificationLayerVisibility, bool);
		layer.setVisible(bool);
	},
);

export const ClassificationLayerColorMapAtomEffects = atomEffect((get) => {
	const visibleVegClasses = get(VisibleVegClassGridCodesAtom);
	const group = get(ClassificationLayerGroupAtom);

	for (const layer of group.getLayersArray()) {
		if (!(layer instanceof TileLayer)) continue;
		const source: XYZ = layer.getSource();
		const urls = source.getUrls();
		if (!source || !urls) return;
		const newUrls = urls?.flatMap((url) => {
			const colourMap = GetColorMap(visibleVegClasses);
			let newUrl = url.split("&colormap=")[0];
			newUrl = newUrl + colourMap;
			return [newUrl];
		});
		source.setUrls(newUrls);
	}
	group.changed();
});

export const GetColorMap = (
	visibleVegClasses: Map<number, ClassificationImageryColors>,
) => {
	const codes = Array.from(visibleVegClasses).flatMap(([, vc]) =>
		vc.enabled ? [`"${vc.classValue}":${vc.color}`] : [],
	);
	if (codes.length === 0) {
		// add a dummy entry or the layer will render black instead of transparent
		codes.push(`"1":[0, 0, 0, 0]`);
	}
	const distinct = [...new Set(codes)];
	return `&colormap={${distinct.join(",")}}`;
};

export const GetAnyVisibleVegClasses = (
	visibleVegClasses: Map<number, ClassificationImageryColors>,
) => {
	return Array.from(visibleVegClasses).some(([, vc]) => vc.enabled);
};
