import { z } from "zod";

import type { Crs } from "@/lib/gen/titiler/types/Crs";

import { crsRefSchema } from "./crsRefSchema";
import { crsUriSchema } from "./crsUriSchema";
import { crswktSchema } from "./crswktSchema";

/**
 * @description CRS model.\n\nRef: https://github.com/opengeospatial/ogcapi-tiles/blob/master/openapi/schemas/common-geodata/crs.yaml\n\nCode generated using https://github.com/koxudaxi/datamodel-code-generator/
 */
export const crsSchema = z
	.union([
		z.lazy(() => crsUriSchema),
		z.lazy(() => crswktSchema),
		z.lazy(() => crsRefSchema),
		z.string(),
	])
	.describe(
		"CRS model.\n\nRef: https://github.com/opengeospatial/ogcapi-tiles/blob/master/openapi/schemas/common-geodata/crs.yaml\n\nCode generated using https://github.com/koxudaxi/datamodel-code-generator/",
	) as z.ZodType<Crs>;
