import { atom } from "jotai";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import {
	AnaloguePolyDto,
	GetAnaloguePolygonsWithPaginationQuery,
} from "@/lib/gen/eis";

import { SelectAndZoomToFeatureAtom } from "./map/mapEventAtoms";
import { AnaloguePolygonQueryAtom } from "./map/mapQueryAtoms";

export const SelectedAnaloguePolygon = atom<AnaloguePolyDto | undefined>(
	undefined,
);

export const GoToAnaloguePolygon = atom(null, async (get, set, id: string) => {
	const analoguePolysQuery = get(AnaloguePolygonQueryAtom);
	if (!analoguePolysQuery.data) return;

	const poly = analoguePolysQuery.data?.find((ap) => ap.id === id);
	if (poly == null) return;
	set(SelectedAnaloguePolygon, poly);
	set(SelectAndZoomToFeatureAtom, id, "analoguepolygon");
});

export type AnaloguePolygonArg = keyof GetAnaloguePolygonsWithPaginationQuery;
export const AnaloguePolyFiltersAtom = atom<ColumnFilter<AnaloguePolygonArg>[]>(
	[],
);
