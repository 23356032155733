import { z } from "zod";

import { TileJson } from "@/lib/gen/titiler/types/TileJson";

/**
 * @description TileJSON model.\n\nBased on https://github.com/mapbox/tilejson-spec/tree/master/2.2.0
 */
export const tileJsonSchema = z
	.object({
		tilejson: z.string().default("2.2.0").optional(),
		name: z.union([z.string(), z.null()]).optional(),
		description: z.union([z.string(), z.null()]).optional(),
		version: z.string().default("1.0.0").optional(),
		attribution: z.union([z.string(), z.null()]).optional(),
		template: z.union([z.string(), z.null()]).optional(),
		legend: z.union([z.string(), z.null()]).optional(),
		scheme: z.enum(["xyz", "tms"]).default("xyz").optional(),
		tiles: z.array(z.string()),
		grids: z.union([z.array(z.string()), z.null()]).optional(),
		data: z.union([z.array(z.string()), z.null()]).optional(),
		minzoom: z.number().int().min(0).max(30).default(0).optional(),
		maxzoom: z.number().int().min(0).max(30).default(30).optional(),
		bounds: z.array(z.number()).optional(),
		center: z
			.union([
				z.tuple([z.number(), z.number(), z.number().int()]),
				z.null(),
			])
			.optional(),
	})
	.describe(
		"TileJSON model.\n\nBased on https://github.com/mapbox/tilejson-spec/tree/master/2.2.0",
	) as z.ZodType<TileJson>;
