import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import {
	AnaloguePolyFiltersAtom,
	AnaloguePolygonArg,
	SelectedAnaloguePolygon,
} from "@/atoms/analoguePolyAtoms";
import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { AllVegClassesAtom } from "@/atoms/map/vegClassAtoms";
import {
	SelectedMineSiteAtom,
	SelectedMiningRegionAtom,
} from "@/atoms/miningAtoms";
import { TimeSeriesDateAtom } from "@/atoms/timeseriesAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { EisColumnSort } from "@/components/data-table/table-types";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useInWindow } from "@/components/PoppedOutWindows";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	AnaloguePolygonPagedDto,
	analoguePolygonsGetPaginated,
	AnaloguePolysOrderBy,
} from "@/lib/gen/eis";
import {
	ANALOGUE_POLYGON_FILTER_OPTION_MINE_SITE,
	ANALOGUE_POLYGON_FILTER_OPTION_MINING_REGION_NAME,
	ANALOGUE_POLYGON_FILTER_OPTIONS,
	getAnaloguePolygonDynamicFilterVegClassCover,
} from "@/types/filters/analogue-polygon-filters";

import {
	getAnaloguePolygonColumns,
	getAnaloguePolygonVegCoverColumn,
} from "./analogue-polygon-columns";

const AnaloguePolygonsTableCard = () => {
	const analoguePoly = useAtomValue(SelectedAnaloguePolygon);

	const [columnSort, setColumnSort] = useState<EisColumnSort>({
		id: "Name",
		desc: false,
	});
	const [dynamicFilters, setDynamicFilters] = useState<
		ColumnFilterOption<AnaloguePolygonArg>[]
	>([]);
	const [analoguePolyFilters, setAnaloguePolyFilters] = useFilters(
		AnaloguePolyFiltersAtom,
	);

	// Auto-set mine site filter
	const inWindow = useInWindow();
	const mineSite = useAtomValue(SelectedMineSiteAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setAnaloguePolyFilters,
			ANALOGUE_POLYGON_FILTER_OPTION_MINE_SITE,
			mineSite?.mineSiteName,
		);
	}, [inWindow, mineSite?.mineSiteName, setAnaloguePolyFilters]);
	const region = useAtomValue(SelectedMiningRegionAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setAnaloguePolyFilters,
			ANALOGUE_POLYGON_FILTER_OPTION_MINING_REGION_NAME,
			region?.name,
		);
	}, [inWindow, region?.name, setAnaloguePolyFilters]);

	const rowSelection = useMemo(
		() =>
			(analoguePoly != null
				? { [analoguePoly.id]: true }
				: {}) as RowSelectionState,
		[analoguePoly],
	);

	const timeSereisDate = useAtomValue(TimeSeriesDateAtom);
	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"AnaloguePolygons",
			columnSort,
			analoguePolyFilters,
			timeSereisDate,
		],
		queryFn: async ({ pageParam }) => {
			return analoguePolygonsGetPaginated({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: (columnSort.orderById ??
					columnSort.id) as AnaloguePolysOrderBy,
				orderByDynamicColumn: columnSort.isDynamicColumn
					? columnSort.id
					: undefined,
				descending: columnSort.desc,
				timeSeriesDate: timeSereisDate.toISOString(),
				...parseFilterAguments(analoguePolyFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const initialColumns = useMemo(
		() => getAnaloguePolygonColumns(setColumnSort),
		[],
	);
	const [columns, setColumns] =
		useState<ColumnDef<AnaloguePolygonPagedDto, unknown>[]>(initialColumns);

	const vegClasses = useAtomValue(AllVegClassesAtom);

	useEffect(() => {
		setColumns([
			...initialColumns,
			...vegClasses.map((vc) =>
				getAnaloguePolygonVegCoverColumn(vc.name ?? "", setColumnSort),
			),
		]);
	}, [initialColumns, vegClasses]);

	useEffect(() => {
		setDynamicFilters([
			...vegClasses.map((vc) =>
				getAnaloguePolygonDynamicFilterVegClassCover(vc),
			),
		]);
	}, [vegClasses]);

	return (
		<BottomDrawDataTable
			tabType={DrawerTabEnum.ANALOGUE_POLYS}
			columns={columns}
			data={rows}
			totalRows={totalRows}
			getRowId={(row) => row.id}
			rowSelection={rowSelection}
			title="Analogue Polygons"
			moduleType="monitoring"
			icon="analogue_polygon"
			isLoading={isLoading}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			exportContext="analoguepolygons"
			filteredSearchBox={{
				options: [
					...ANALOGUE_POLYGON_FILTER_OPTIONS,
					...dynamicFilters,
				],
				filters: analoguePolyFilters,
				setFilters: setAnaloguePolyFilters,
			}}
		/>
	);
};

export default AnaloguePolygonsTableCard;
