export const setupWindow = (
	newWindow: Window,
	title: string,
	darkMode: boolean,
): HTMLDivElement => {
	// Clone <style> tags
	const newContainer = document.createElement("div");
	newContainer.className =
		"h-screen w-screen bg-core-primary-background p-0 flex-1";

	// Add base styles and meta viewport
	newWindow.document.head.innerHTML = `
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>${title}</title>
        <style>
          body {
            margin: 0;
            overflow: auto;
          }
        </style>
      `;
	// Append container to new window
	newWindow.document.body.appendChild(newContainer);

	//add a class to the window that sets body to full vw and vh and uses flexbox
	newWindow.document.body.className =
		"h-screen w-screen flex flex-col overflow-hidden";

	if (darkMode) {
		newWindow.document.body.classList.add("dark");
	}

	const styleElements = document.getElementsByTagName("style");
	Array.from(styleElements).forEach((style) => {
		newWindow.document.head.appendChild(style.cloneNode(true));
	});

	// Clone <link> tags (external stylesheets)
	const linkElements = document.getElementsByTagName("link");
	Array.from(linkElements).forEach((link) => {
		if (link.rel === "stylesheet") {
			const newLink = newWindow.document.createElement("link");
			newLink.rel = "stylesheet";
			newLink.href = link.href;
			newWindow.document.head.appendChild(newLink);
		}
	});

	// Clone CSS custom properties from :root
	const rootStyles = getComputedStyle(document.documentElement);
	let rootCSSVariables = ":root {";
	for (let i = 0; i < rootStyles.length; i++) {
		const property = rootStyles[i];
		if (property.startsWith("--")) {
			rootCSSVariables += `${property}: ${rootStyles.getPropertyValue(
				property,
			)};`;
		}
	}
	rootCSSVariables += "}";

	const rootStyleElement = newWindow.document.createElement("style");
	rootStyleElement.textContent = rootCSSVariables;
	newWindow.document.head.appendChild(rootStyleElement);

	return newContainer;
};
