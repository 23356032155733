"use client";

import { PopoverAnchor } from "@radix-ui/react-popover";
import { format, isValid, parse } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { forwardRef, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

import { Input } from "./input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./select";

const DATE_FORMAT = "dd MMM yyyy";

export type InputProps = React.InputHTMLAttributes<HTMLDivElement> & {
	formValue: string | Date | undefined | null;
	onChange: (value: string | Date | undefined | null) => void;
};

const DatePicker = forwardRef<HTMLDivElement, InputProps>(
	({ formValue, onChange, className, ...props }, ref) => {
		const [date, setDate] = useState(
			isDateValid(formValue) ? new Date(formValue) : new Date(),
		);
		const [dateStr, setDateStr] = useState("");
		const [dateStrIsValid, setDateStrIsValid] = useState(true);

		useEffect(() => {
			const validDate = isDateValid(formValue) ? formValue : undefined;
			setDate(validDate ? new Date(validDate) : new Date());
			const str = validDate ? format(validDate, DATE_FORMAT) : undefined;
			setDateStr(str ?? "");
		}, [formValue]);

		useEffect(() => {
			if (dateStr) {
				const valid = isDateValid(dateStr);
				setDateStrIsValid(valid);
			}
		}, [dateStr]);

		return (
			<Popover>
				<div className="relative flex">
					<PopoverAnchor asChild>
						<Input
							className={cn(
								"z-10 rounded-r-none border-r-0 bg-background",
								dateStrIsValid === false &&
									"border border-red-500 focus-visible:ring-red-500",
							)}
							placeholder="Type or select date"
							value={dateStr}
							onChange={(e) => {
								setDateStr(e.target.value);
							}}
							onBlur={(e) => {
								const valid = isDateValid(e.target.value);
								setDateStrIsValid(valid);
								if (valid) {
									onChange(
										format(
											new Date(e.target.value),
											"yyyy-MM-dd",
										),
									);
								}
							}}
							disabled={props.disabled}
						/>
					</PopoverAnchor>
					<PopoverTrigger asChild>
						<Button
							variant={"outline"}
							className={cn(
								"w-fit rounded-l-none p-2 font-normal",
								!date && "text-muted-foreground",
								className,
							)}
							disabled={props.disabled}
						>
							<CalendarIcon size={16} className="" />
						</Button>
					</PopoverTrigger>
				</div>

				<PopoverContent
					{...props}
					ref={ref}
					className="w-auto p-0"
					align="start"
				>
					<div className="flex gap-1 p-1">
						<Select
							onValueChange={(value) => {
								setDate(
									new Date(
										date.getFullYear(),
										Number.parseInt(value),
										date.getDate(),
									),
								);
							}}
						>
							<SelectTrigger>
								<SelectValue placeholder="Month" />
							</SelectTrigger>
							<SelectContent position="popper">
								{MONTHS.map((m) => (
									<SelectItem
										key={m}
										value={MONTHS.findIndex(
											(x) => x === m,
										).toString()}
									>
										{m}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						<Select
							onValueChange={(value) => {
								setDate(
									new Date(
										Number.parseInt(value),
										date.getMonth(),
										date.getDate(),
									),
								);
							}}
						>
							<SelectTrigger>
								<SelectValue placeholder="Year" />
							</SelectTrigger>
							<SelectContent position="popper">
								{Array.from(
									{ length: 20 },
									(_, k) =>
										k + (new Date().getFullYear() - 10),
								).map((year) => (
									<SelectItem
										key={year}
										value={year.toString()}
									>
										{year}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
					<Calendar
						mode="single"
						selected={date}
						onSelect={(event) => {
							onChange(event ?? undefined);
						}}
						initialFocus
						month={date}
						onMonthChange={(month) => setDate(month)}
					/>
				</PopoverContent>
			</Popover>
		);
	},
);
DatePicker.displayName = "DatePicker";

const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const isDateValid = (
	date: string | Date | undefined | null,
): date is string | Date => {
	if (date instanceof Date) return isValid(date);
	let parsed = parse(date ?? "", DATE_FORMAT, new Date());
	if (!isValid(parsed)) {
		parsed = new Date(date ?? "");
	}
	return isValid(parsed);
};

export { DatePicker };
