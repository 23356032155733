import { CheckIcon, ChevronsUpDown, X } from "lucide-react";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RefCallBack } from "react-hook-form";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { AreOptionTypes, IsOptionType } from "@/lib/utils/helpers";
import { OptionType, ValueType } from "@/types/tyton-types";

export type SingleSelectProps<T extends ValueType> = {
	selected: OptionType<T> | T | undefined;
	onChange: (value: OptionType<T> | null) => void;
	isMulti?: false;
};

export type MultiSelectProps<T extends ValueType> = {
	selected: OptionType<T>[] | T[];
	onChange: (value: OptionType<T>[]) => void;
	isMulti: true;
};

type Props<T extends ValueType> = {
	options: OptionType<T>[];
	onQueryChange?: React.Dispatch<React.SetStateAction<string>>;
	forwardRef?: RefCallBack;
	className?: string;
	commandClassName?: string;
	placeholder?: string;
	hideSearch?: boolean;
	disabled?: boolean;
	modal?: boolean;
} & (SingleSelectProps<T> | MultiSelectProps<T>);

const MultiSelect = <T extends ValueType>({
	options,
	selected: selectedProp,
	onChange,
	onQueryChange,
	className,
	commandClassName,
	forwardRef,
	disabled,
	hideSearch,
	isMulti,
	modal,
	...props
}: Props<T>) => {
	const [open, setOpen] = useState(false);
	const selected = useMemo((): OptionType<T>[] => {
		if (!selectedProp) return [];
		if (Array.isArray(selectedProp)) {
			if (selectedProp.length <= 0) return [];
			if (AreOptionTypes(selectedProp)) {
				return selectedProp;
			}
			return selectedProp.flatMap((v) => {
				const option = options.find((o) => o.value === v);
				if (option) return [option];
				return [];
			});
		} else {
			if (IsOptionType(selectedProp)) {
				return [selectedProp];
			}
			const option = options.find((o) => o.value === selectedProp);
			if (option) return [option];
			return [];
		}
	}, [options, selectedProp]);

	useEffect(() => {
		if (!open && onQueryChange) {
			onQueryChange("");
		}
	}, [onQueryChange, open]);

	const handleUnselect = useCallback(
		(item: OptionType<T>) => {
			if (isMulti) {
				const newSelected = selected.filter((i) => i.id !== item.id);
				onChange(newSelected);
			} else {
				onChange(null);
			}
		},
		[isMulti, onChange, selected],
	);

	const handleSelect = useCallback(
		(items: OptionType<T>[]) => {
			if (isMulti) {
				onChange(items);
			} else {
				onChange(items.length > 0 ? items[0] : null);
			}
		},
		[isMulti, onChange],
	);

	const hideCommandSearch = useMemo(() => {
		if (hideSearch) {
			return true;
		}
		return !onQueryChange && options.length < 10;
	}, [hideSearch, onQueryChange, options.length]);

	return (
		<Popover open={open} onOpenChange={setOpen} modal={modal}>
			<PopoverTrigger asChild>
				<Button
					ref={forwardRef}
					type="button"
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={cn(
						"group h-9 w-full justify-between overflow-hidden bg-background px-3",
						className,
					)}
					onClick={() => setOpen(!open)}
					disabled={disabled}
				>
					<div className="flex shrink items-center gap-1 truncate">
						{selected.map((item) => (
							<Badge
								key={item.id}
								variant="outline"
								className="flex shrink items-center gap-1 truncate bg-card group-hover:bg-background"
							>
								<span className="truncate">{item.label}</span>
								{!disabled && (
									<Button
										asChild
										variant="min"
										size="min"
										className="border-none"
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												handleUnselect(item);
											}
										}}
										onMouseDown={(e) => {
											e.preventDefault();
											e.stopPropagation();
										}}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											handleUnselect(item);
										}}
									>
										<X
											className="text-muted-foreground hover:text-foreground"
											size={18}
										/>
									</Button>
								)}
							</Badge>
						))}
						<span className={cn("truncate text-muted-foreground")}>
							{selected.length === 0 && (
								<span>{props.placeholder ?? "Select..."}</span>
							)}
						</span>
					</div>
					<ChevronsUpDown size={12} className="shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className="w-full p-0"
				style={{ width: "var(--radix-popover-trigger-width)" }}
			>
				<Command
					shouldFilter={onQueryChange == null}
					className={commandClassName}
				>
					{!hideCommandSearch && (
						<CommandInput
							placeholder="Search..."
							onValueChange={onQueryChange}
						/>
					)}
					{options.length === 0 ? (
						<span className="py-6 text-center text-sm">
							No item found.
						</span>
					) : (
						<ScrollArea>
							<CommandList className="overflow-visible">
								<CommandEmpty>No item found.</CommandEmpty>
								<MultiSelectGroup
									selected={selected}
									options={options}
									isMulti={!!isMulti}
									onChange={handleSelect}
									setOpen={setOpen}
								/>
							</CommandList>
						</ScrollArea>
					)}
				</Command>
			</PopoverContent>
		</Popover>
	);
};

type MultiSelectGroupProps<T extends ValueType> = {
	header?: string;
	selected: OptionType<T>[];
	options: OptionType<T>[];
	isMulti: boolean | undefined;
	onChange: (value: OptionType<T>[]) => void;
	setOpen: (value: React.SetStateAction<boolean>) => void;
};

const MultiSelectGroup = <T extends ValueType>({
	header,
	selected,
	options,
	isMulti,
	onChange,
	setOpen,
}: MultiSelectGroupProps<T>) => {
	return (
		<>
			<CommandGroup heading={header}>
				{options.map((option) => {
					return (
						<CommandItem
							key={option.id}
							value={option.id}
							onSelect={() => {
								if (isMulti) {
									onChange(
										selected.some(
											(item) => item.id === option.id,
										)
											? selected.filter(
													(item) =>
														item.id !== option.id,
												)
											: [...selected, option],
									);
									setOpen(true);
								} else {
									onChange([option]);
								}
							}}
						>
							<span
								className="w-full truncate"
								title={
									typeof option.value === "string"
										? option.value
										: undefined
								}
							>
								{option.label}
							</span>
							<CheckIcon
								size={16}
								className={cn(
									"translate-y-[2px]",
									selected.some(
										(item) => item.id === option.id,
									)
										? "opacity-100"
										: "opacity-0",
								)}
							/>
						</CommandItem>
					);
				})}
			</CommandGroup>
		</>
	);
};

MultiSelect.displayName = "MultiSelect";

export { MultiSelect };
