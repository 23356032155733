import axios from "axios";
import { fromEPSGCode, register } from "ol/proj/proj4";
import { TileGrid } from "ol/tilegrid";
import { Options } from "ol/tilegrid/TileGrid";
import proj4 from "proj4";

import { TITILER_URL } from "@/env_variables";
import { rioCogeoModelsInfoSchema } from "@/lib/gen/titiler/zod/rioCogeoModelsInfoSchema";
import { tileJsonSchema } from "@/lib/gen/titiler/zod/tileJsonSchema";
import { tileMatrixSetOutputSchema } from "@/lib/gen/titiler/zod/tileMatrixSetOutputSchema";

import { BuildQueryString } from "./query-helpers";

register(proj4);

export async function getTiTilerTileGrid(
	tileMatrixSetName: string | undefined,
) {
	if (!tileMatrixSetName) return;
	try {
		const options = await axios
			.get(`${TITILER_URL}/tileMatrixSets/${tileMatrixSetName}`)
			.then((res): Options => {
				const tms = tileMatrixSetOutputSchema.parse(res.data);
				return {
					origins: tms.tileMatrices.map((tm) => tm.pointOfOrigin),
					resolutions: tms.tileMatrices.map((tm) => tm.cellSize),
				};
			});

		return new TileGrid(options);
	} catch (e) {
		throw new Error(
			`Error getting TiTiler matrix with name ${tileMatrixSetName}: ${e}`,
		);
	}
}

export const getTiTilerBounds = async (
	url: string,
	tileMatrixSet: string | undefined,
) => {
	const tileJson = await getTileJSON(url, tileMatrixSet);
	if (!tileJson)
		return {
			bounds: undefined,
			url: url,
		};
	return {
		bounds: tileJson.bounds as [number, number, number, number],
		url: tileJson.tiles[0],
	};
};

export const getTileJSON = async (
	imageUrl: string,
	tileMatrixSet: string | undefined,
) => {
	if (imageUrl.startsWith("http") || !tileMatrixSet) return;
	const tileJSONRequest = GetCogTileJSONRequest(imageUrl, tileMatrixSet);

	const response = await axios
		.get(tileJSONRequest)
		.then((res) => {
			const info = tileJsonSchema.parse(res.data);
			info.tiles = info.tiles.map((tile: string) =>
				tile.replace("http://", "https://"),
			);
			return { ...info };
		})
		.catch((err) => {
			const message = `Failed to get tile json for: ${imageUrl}`;
			console.error(message, err);
			throw new Error(message);
		});
	return response;
};

export const getProjectionAndTMS = async (imageUrl: string) => {
	if (imageUrl.startsWith("http"))
		return {
			projection: undefined,
			tileMatrixSet: undefined,
		};
	const crs = await getProjection(imageUrl);
	if (!crs || crs.split(":").length < 2)
		return {
			projection: undefined,
			tileMatrixSet: undefined,
		};
	const projection = await fromEPSGCode(crs);

	let tileMatrixSet = crs.split(":").join("");
	if (crs.split(":")[1] === "3857") tileMatrixSet = "WebMercatorQuad";

	return {
		projection,
		tileMatrixSet,
	};
};

const getProjection = async (imageUrl: string) => {
	const request = GetCogValidateRequest(imageUrl);
	const response = await axios
		.get(request)
		.then((res) => rioCogeoModelsInfoSchema.parse(res.data));
	return response.GEO.CRS ?? undefined;
};

const GetCogTileJSONRequest = (imageUrl: string, tileMatrixSet: string) => {
	let str = `${TITILER_URL}/cog/${tileMatrixSet}/tilejson.json`;
	str = BuildQueryString(str, { url: imageUrl });
	return str;
};

export const GetCogValidateRequest = (imageUrl: string) => {
	let str = `${TITILER_URL}/cog/validate`;
	str = BuildQueryString(str, { url: imageUrl });
	return str;
};
