import { Column } from "@tanstack/react-table";
import { CSSProperties } from "react";

export const getSafeID = (id: string) =>
	id.replace(" ", "-").replace(/[^a-zA-Z0-9-]/g, "");

export function getCommonPinningStyles<T>(
	column: Column<T>,
	scrolledLeft: boolean,
): CSSProperties {
	const isPinned = column.getIsPinned();
	if (!isPinned) return {};
	const isLastLeftPinnedColumn =
		isPinned === "left" && column.getIsLastColumn("left");
	const isFirstRightPinnedColumn =
		isPinned === "right" && column.getIsFirstColumn("right");
	return {
		boxShadow:
			isLastLeftPinnedColumn && scrolledLeft
				? "-4px 0 4px -4px gray inset"
				: isFirstRightPinnedColumn
					? "4px 0 4px -4px gray inset"
					: undefined,
		left: `${column.getStart("left")}px`,
		right: `${column.getAfter("right")}px`,
		position: "sticky",
		zIndex: 1,
	};
}
