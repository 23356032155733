import client from "@kubb/plugin-client/client";
import type { RehabilitationSurveysGetRehabilitationSurveysWithPaginationQueryResponse, RehabilitationSurveysGetRehabilitationSurveysWithPaginationQueryParams } from "../../ts/RehabilitationSurveysGetRehabilitationSurveysWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";
import { rehabilitationSurveysGetRehabilitationSurveysWithPaginationQueryResponseSchema } from "../../zod/rehabilitationSurveysGetRehabilitationSurveysWithPaginationSchema.ts";

 /**
 * @link /api/RehabilitationSurveys
 */
export async function rehabilitationSurveysGetRehabilitationSurveysWithPagination(params?: RehabilitationSurveysGetRehabilitationSurveysWithPaginationQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationSurveysGetRehabilitationSurveysWithPaginationQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationSurveys`, params, ...config });
    return rehabilitationSurveysGetRehabilitationSurveysWithPaginationQueryResponseSchema.parse(res.data);
}