import type { SeedBatchesActivatePathParams, SeedBatchesActivate200, SeedBatchesActivateMutationRequest, SeedBatchesActivateMutationResponse } from "../ts/SeedBatchesActivate.ts";
import { activateSeedBatchCommandSchema } from "./activateSeedBatchCommandSchema.ts";
import { z } from "zod";

 export const seedBatchesActivatePathParamsSchema = z.object({ "id": z.string() }) as z.ZodType<SeedBatchesActivatePathParams>;

 export const seedBatchesActivate200Schema = z.instanceof(File) as z.ZodType<SeedBatchesActivate200>;

 export const seedBatchesActivateMutationRequestSchema = z.lazy(() => activateSeedBatchCommandSchema) as z.ZodType<SeedBatchesActivateMutationRequest>;

 export const seedBatchesActivateMutationResponseSchema = z.lazy(() => seedBatchesActivate200Schema) as z.ZodType<SeedBatchesActivateMutationResponse>;