import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
	ErosionFeaturesFiltersAtom,
	SelectedErosionFeatureIdAtom,
} from "@/atoms/erosionAtoms";
import {
	ErosionClusterLayerAtom,
	ErosionVectorLayerAtom,
	LayerSource,
} from "@/atoms/map/layerAtoms";
import {
	ErosionExtentGeometryQueryAtom,
	ErosionFeatureIdsFilteredQueryAtom,
	ErosionGeometryQueryAtom,
} from "@/atoms/map/mapQueryAtoms";
import { SelectedGeometryAtom } from "@/atoms/mapAtoms";
import {
	populateClusterVectorLayer,
	populateVectorLayer,
} from "@/components/MapOpenLayers/vector-layer-populate";

export const ErosionVectorLayerAtomEffect = atomEffect((get) => {
	get(populateAtomEffect);
	get(populateClusterAtomEffect);
	get(filterAtomEffect);
	get(layerSourceAtomEffect);
	get(clusterLayerSourceAtomEffect);
	get(selectionAtomEffect);
});

export const ErosionVectorLayerClusterSourceAtom = atom<LayerSource>({
	source: undefined,
});

export const ErosionVectorLayerSourceAtom = atom<LayerSource>({
	source: undefined,
});

const layerSourceAtomEffect = atomEffect((get, set) => {
	const source = get(ErosionVectorLayerAtom).getSource();
	if (!source) return;
	const onChange = () => {
		set(ErosionVectorLayerSourceAtom, { source });
	};
	source.on("change", onChange);

	return () => {
		source.un("change", onChange);
	};
});

const selectionAtomEffect = atomEffect((get, set) => {
	const source = get(ErosionVectorLayerSourceAtom).source;
	const selected = get(SelectedErosionFeatureIdAtom);
	if (selected == null || source == null) return;
	const feature = source.getFeatureById(selected);
	if (feature == null) return;
	feature?.set("selected", true);
	set(SelectedGeometryAtom, feature);
});

const populateAtomEffect = atomEffect((get, set) => {
	const erosionGeometriesQuery = get(ErosionGeometryQueryAtom);
	if (erosionGeometriesQuery.data == null) return;

	const data = erosionGeometriesQuery.data;
	const layer = get(ErosionVectorLayerAtom);

	populateVectorLayer({
		data,
		layer,
		source: "erosionvector",
		onUnselect: () => set(SelectedGeometryAtom, undefined),
		onSelect: (data, feature) => {
			set(SelectedGeometryAtom, feature);
			set(SelectedErosionFeatureIdAtom, data.id);
		},
	});
});

const clusterLayerSourceAtomEffect = atomEffect((get, set) => {
	const layer = get(ErosionClusterLayerAtom);
	const onChange = () => {
		set(ErosionVectorLayerClusterSourceAtom, {
			source: layer.getSource()?.getSource() ?? undefined,
		});
	};
	layer.on("change", onChange);

	return () => {
		layer.un("change", onChange);
	};
});

const populateClusterAtomEffect = atomEffect((get) => {
	const { data: clusterData } = get(ErosionExtentGeometryQueryAtom);
	if (clusterData == null) return;

	const { data: filteredData } = get(ErosionFeatureIdsFilteredQueryAtom);
	const filteredIds = filteredData?.ids ?? [];

	const data = filteredData?.ids
		? clusterData.filter((d) => filteredIds.indexOf(d.id) !== -1)
		: clusterData;
	const layer = get(ErosionClusterLayerAtom);

	populateClusterVectorLayer({
		data,
		layer,
		source: "erosionCluster",
	});
});

/** Update feature dimming on analogue polygon filter change */
const filterAtomEffect = atomEffect((get) => {
	const layer = get(ErosionVectorLayerAtom);
	const source = layer.getSource();
	if (source == null) return;

	const filters = get(ErosionFeaturesFiltersAtom);
	if (filters.length <= 0) {
		source.forEachFeature((f) => f.unset("filtered"));
		return;
	}

	const filteredQuery = get(ErosionFeatureIdsFilteredQueryAtom);
	const filteredIds = filteredQuery.data?.ids ?? [];

	source.forEachFeature((f) => {
		f.set("filtered", filteredIds.indexOf(f.get("id")) === -1);
	});
});
