import type { RehabPolygonCompletionCriteriaDto } from "../ts/RehabPolygonCompletionCriteriaDto.ts";
import { criterionLatestOutcomeResultDtoSchema } from "./criterionLatestOutcomeResultDtoSchema.ts";
import { inspectionSiteMonitoringDtoSchema } from "./inspectionSiteMonitoringDtoSchema.ts";
import { polygonGeometryDtoSchema } from "./polygonGeometryDtoSchema.ts";
import { polygonLandformTypeSchema } from "./polygonLandformTypeSchema.ts";
import { polygonOverallResultSchema } from "./polygonOverallResultSchema.ts";
import { quadratMonitoringDtoSchema } from "./quadratMonitoringDtoSchema.ts";
import { rehabilitationPolygonStatusSchema } from "./rehabilitationPolygonStatusSchema.ts";
import { rehabilitationPolygonTagSingleDtoSchema } from "./rehabilitationPolygonTagSingleDtoSchema.ts";
import { z } from "zod";

 export const rehabPolygonCompletionCriteriaDtoSchema = z.object({ "rehabilitationPolygonId": z.string(), "name": z.string().nullable().nullish(), "status": z.lazy(() => rehabilitationPolygonStatusSchema), "area": z.number(), "landform": z.lazy(() => polygonLandformTypeSchema), "rehabilitatedDate": z.string().date(), "rehabPhaseId": z.string().nullable().nullish(), "rehabPhaseName": z.string().nullable().nullish(), "mineSiteId": z.string(), "mineSite": z.string().nullable().nullish(), "miningRegion": z.string().nullable().nullish(), "quadrats": z.number().int(), "siteInspections": z.number().int(), "erosionFeatures": z.number().int(), "overallCompletionCriteriaResult": z.lazy(() => polygonOverallResultSchema).nullable().nullish(), "criteriaLatestOutcomeResults": z.array(z.lazy(() => criterionLatestOutcomeResultDtoSchema)).nullable().nullish(), "polygonGeometry": z.lazy(() => polygonGeometryDtoSchema).nullable().nullish(), "quadratMonitoringDtos": z.array(z.lazy(() => quadratMonitoringDtoSchema)).nullable().nullish(), "inspectionSiteMonitoringDtos": z.array(z.lazy(() => inspectionSiteMonitoringDtoSchema)).nullable().nullish(), "rehabilitationPolygonTags": z.array(z.lazy(() => rehabilitationPolygonTagSingleDtoSchema)).nullable().nullish() }) as z.ZodType<RehabPolygonCompletionCriteriaDto>;