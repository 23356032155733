import client from "@kubb/plugin-client/client";
import type { SeedApplicationsGetSeedApplicationsWithPaginationMutationRequest, SeedApplicationsGetSeedApplicationsWithPaginationMutationResponse } from "../../ts/SeedApplicationsGetSeedApplicationsWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Inventory/Seeds/Applications/paginated
 */
export async function seedApplicationsGetSeedApplicationsWithPagination(data: SeedApplicationsGetSeedApplicationsWithPaginationMutationRequest, config: Partial<RequestConfig<SeedApplicationsGetSeedApplicationsWithPaginationMutationRequest>> = {}) {
    const res = await client<SeedApplicationsGetSeedApplicationsWithPaginationMutationResponse, Error, SeedApplicationsGetSeedApplicationsWithPaginationMutationRequest>({ method: "POST", url: `/api/Inventory/Seeds/Applications/paginated`, data, ...config });
    return res.data;
}