import { SeedInventoryArg } from "@/atoms/seedAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { provenanceLandform } from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const SEED_INVENTORY_FILTER_OPTIONS: ColumnFilterOption<SeedInventoryArg>[] =
	[
		{
			key: "speciesFullName",
			label: "Flora Species",
			icon: "polygon",
			options: (searchTerm?: string) =>
				getTypeAheadResults("speciesFullName", searchTerm),
			keywords: ["name"],
		},
		{
			key: "family",
			label: "Family",
			icon: "tag",
			options: (searchTerm?: string) =>
				getTypeAheadResults("family", searchTerm),
		},
		{
			key: "seedBatchNo",
			label: "Seed Batch Number",
			icon: "quadrat",
			options: (searchTerm?: string) =>
				getTypeAheadResults("seedBatchNo", searchTerm),
		},
		{
			key: "supplierName",
			label: "Supplier",
			icon: "site_inspection",
			options: (searchTerm?: string) =>
				getTypeAheadResults("supplierName", searchTerm),
		},
		{
			key: "storeLocation",
			label: "Storage Location",
			options: (searchTerm?: string) =>
				getTypeAheadResults("storeLocation", searchTerm),
			keywords: ["location"],
		},
		{
			key: "provenanceLandform",
			label: "Provenance Landforms",
			icon: "landform",
			options: Object.values(provenanceLandform).map((value) => ({
				value,
			})),
		},
	];
