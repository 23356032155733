import { formatDistanceToNowStrict, isValid } from "date-fns";
import { memo, useMemo } from "react";

import { ItalicSpeciesName } from "@/components/italic-species-name";
import { cn } from "@/lib/utils";

interface DataTableBasicCellProps extends React.HTMLAttributes<HTMLDivElement> {
	value: string | number | undefined | null;
}

type DataTableNumberCellProps = DataTableBasicCellProps & {
	isInteger?: boolean;
	numStyle?: keyof Intl.NumberFormatOptionsStyleRegistry;
	value: number | null | undefined;
	currency?: string;
	currencyDisplay?: keyof Intl.NumberFormatOptionsCurrencyDisplayRegistry;
	affix?: "prefix" | "suffix";
};

export const DataTableNumberCell = memo(function ({
	className,
	value,
	isInteger,
	numStyle,
	currency = "AUD",
	currencyDisplay = "symbol",
	children,
	affix = "suffix",
	...props
}: DataTableNumberCellProps) {
	const str = useMemo(() => {
		if (value == null) return undefined;
		return value.toLocaleString("en-AU", {
			maximumFractionDigits: isInteger ? 0 : 2,
			minimumFractionDigits: isInteger ? 0 : 2,
			style: numStyle,
			currency: currency,
			currencyDisplay: currencyDisplay,
		});
	}, [currency, currencyDisplay, isInteger, numStyle, value]);
	// TODO fix fonts, DM Sans doesn't support tabular-nums
	return (
		<div
			title={str}
			{...props}
			className={cn(
				"flex w-full items-center justify-end gap-1 truncate font-roboto",
				className,
			)}
		>
			{affix === "prefix" && children}
			<span className="tabular-nums">{str ?? "-"}</span>
			{affix === "suffix" && children}
		</div>
	);
});

export const DataTableSmallTextCell = memo(function ({
	className,
	value,
	...props
}: DataTableBasicCellProps) {
	return (
		<div
			{...props}
			title={value?.toString()}
			className={cn(
				"w-fit truncate text-nowrap pr-1 text-left",
				className,
			)}
		>
			<span>{value ?? "-"}</span>
		</div>
	);
});

export const DataTableLargeTextCell = memo(function ({
	className,
	value,
	...props
}: DataTableBasicCellProps) {
	return (
		<div
			{...props}
			title={value ? value.toString() : undefined}
			className={cn(
				"line-clamp-2 w-full overflow-hidden text-ellipsis text-left",
				className,
			)}
		>
			{value ?? "-"}
		</div>
	);
});

export const DataTableDateCell = memo(function ({
	value,
	...props
}: DataTableBasicCellProps & {
	value?: string;
}) {
	return (
		<DataTableSmallTextCell
			{...props}
			value={
				value &&
				new Date(value).toLocaleDateString("en-AU", {
					year: "numeric",
					month: "short",
					day: "numeric",
				})
			}
		/>
	);
});

export const DataTableTimeSinceCell = memo(function ({
	value,
	unit,
	roundingMethod,
	addSuffix,
	...props
}: DataTableBasicCellProps & {
	unit?: "year" | "second" | "minute" | "hour" | "day" | "month";
	roundingMethod?: "floor" | "round" | "ceil";
	addSuffix?: boolean;
}) {
	const timeSince = useMemo(() => {
		if (!value) return undefined;
		const date = new Date(value);
		if (!isValid(date)) {
			console.debug("Invalid date", value);
			return undefined;
		}
		const str = formatDistanceToNowStrict(new Date(value), {
			unit: unit,
			roundingMethod: roundingMethod ? roundingMethod : "floor",
			addSuffix: addSuffix ? addSuffix : false,
		});
		if (str.charAt(0) === "0" && str.charAt(1) === " ") {
			return "< 1" + str.substring(1, str.length - 1);
		}
		return str;
	}, [addSuffix, roundingMethod, unit, value]);
	return (
		<DataTableSmallTextCell
			{...props}
			title={`${value}`}
			value={timeSince}
		/>
	);
});

export const DataTableCustomCell: React.FC<
	React.HTMLAttributes<HTMLDivElement>
> = ({ className, children, ...props }) => {
	return (
		<div className={cn("flex w-full", className)} {...props}>
			{children}
		</div>
	);
};

export const DataTableFloraSpeciesCell = memo(function ({
	className,
	value,
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	value: string | null | undefined;
}) {
	return (
		<div
			{...props}
			title={value?.toString()}
			className={cn(
				"w-fit truncate text-nowrap pr-1 text-left",
				className,
			)}
		>
			<ItalicSpeciesName fullName={value} />
		</div>
	);
});
