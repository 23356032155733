import {
	ColumnDef,
	ColumnSort,
	createColumnHelper,
} from "@tanstack/react-table";
import { ChevronDown, ChevronRight } from "lucide-react";

import {
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { Button } from "@/components/ui/button";
import { SeedDto, seedOrderBy } from "@/lib/gen/eis";

const columnHelper = createColumnHelper<SeedDto>();

type Props = {
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>;
};

export const SeedInventoryColumns = ({ setColumnSort }: Props) =>
	[
		columnHelper.display({
			id: "expand",
			cell: ({ row }) => {
				if (!row.getCanExpand()) return null;
				return (
					<Button
						size="icon"
						variant="ghost"
						onClick={row.getToggleExpandedHandler()}
					>
						{row.getIsExpanded() ? (
							<ChevronDown size={16} />
						) : (
							<ChevronRight size={16} />
						)}
					</Button>
				);
			},
			size: 48,
			enableResizing: false,
		}),
		columnHelper.accessor((row) => row.floraSpecies?.fullName, {
			id: seedOrderBy.Name,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Species"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Species",
			},
		}),
		columnHelper.accessor((row) => row.provenanceZone?.name, {
			id: seedOrderBy.Provenance,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Provenance"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Provenance",
			},
		}),
		columnHelper.accessor((row) => row.floraSpecies?.family, {
			id: seedOrderBy.Family,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Family"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Family",
			},
		}),
		columnHelper.accessor((row) => row.status, {
			id: seedOrderBy.Status,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Status"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Status",
			},
		}),
		columnHelper.accessor((row) => row.floraSpecies?.lifeform, {
			id: seedOrderBy.Lifeform,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Lifeform"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Lifeform",
			},
		}),
		columnHelper.accessor((row) => row.filteredCost, {
			id: seedOrderBy.Cost,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Cost"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => (
				<DataTableNumberCell
					value={getValue() ?? "-"}
					numStyle="currency"
				/>
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Cost",
			},
		}),
		columnHelper.accessor((row) => row.quantityStock, {
			id: seedOrderBy.QuantityInStock,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="In Stock (g)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => (
				<DataTableNumberCell value={getValue() ?? "-"} isInteger />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "In Stock",
			},
		}),
		columnHelper.accessor((row) => row.availableQuantity, {
			id: seedOrderBy.AvailableQuantity,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Available (g)"
					columnSortSetter={setColumnSort}
					rightAlign
				/>
			),
			cell: ({ getValue }) => (
				<DataTableNumberCell value={getValue() ?? "-"} isInteger />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Available",
			},
		}),
	] as ColumnDef<SeedDto>[];
