import { Feature } from "ol";
import VectorLayer from "ol/layer/Vector";
import { Cluster } from "ol/source";
import VectorSource from "ol/source/Vector";

import { geometryToPoint, GetOLGeometry } from "@/helpers/geometry-helpers";
import { Geometry } from "@/lib/gen/eis";

import { MapClusterSourceType, MapSourceType } from "./map-types";

interface GenericVectorLayerData {
	id: string;
	name?: string | null;
	geometry?: Geometry | null;
	mineSiteId?: string;
}

interface populateVectorLayerArguments<T extends GenericVectorLayerData> {
	data: T[];
	layer: VectorLayer<VectorSource<Feature>, Feature>;
	source: MapSourceType;
	/** For unsetting the current selection if it no longer exists (ie: changed timeSeriesDate) */
	onUnselect?: () => void;
	/** For updating the selection when the selected data/feature changes (ie: changed timeSeriesDate). */
	onSelect?: (data: T, feature: Feature) => void;
	properties?: { [key: string]: keyof T };
}

/** Generic function to populate a vector layer with features */
export function populateVectorLayer<T extends GenericVectorLayerData>(
	args: populateVectorLayerArguments<T>,
) {
	const { data, layer, source, onUnselect, onSelect, properties } = args;

	// Get source
	const layerSource = layer.getSource();
	if (layerSource == null) return;

	// Remove features
	const selectedFeatureId = layerSource
		.getFeatures()
		.find((f) => {
			const selected = f.get("selected") as boolean;
			return selected;
		})
		?.getId();
	layerSource.clear();

	const featuresToAdd: Feature[] = [];
	let removeSelected = selectedFeatureId != null;
	for (const d of data) {
		const feature = new Feature({
			id: d.id,
			name: d.name,
			source: source,
			geometry: GetOLGeometry(d.geometry),
		});
		feature.setId(d.id);
		if (d.mineSiteId != null) feature.set("mineSiteId", d.mineSiteId);
		if (properties != null) {
			for (const [key, value] of Object.entries(properties)) {
				feature.set(key, d[value]);
			}
		}

		feature.set("hover", false);
		if (selectedFeatureId != null && d.id === selectedFeatureId) {
			feature.set("selected", true);
			onSelect?.(d, feature);
			removeSelected = false;
		} else {
			feature.set("selected", false);
		}

		featuresToAdd.push(feature);
	}

	if (removeSelected) onUnselect?.();

	if (featuresToAdd.length > 0) layerSource.addFeatures(featuresToAdd);
	layerSource.changed();
}

interface populateClusterVectorLayerArguments {
	data: {
		id: string;
		name?: string | null;
		mineSiteId?: string | null;
		geometry?: Geometry | null;
	}[];
	layer: VectorLayer<Cluster<Feature>, Feature>;
	source: MapClusterSourceType;
}

/** Updates cluster layer source
 *
 * Note: This completely recreates the source instead of updating the existing features since it's more performant.
 * If the features in the source are updated instead; it causes the clusters to be recalculated on every geometry change.
 */
export const populateClusterVectorLayer = (
	args: populateClusterVectorLayerArguments,
) => {
	const { data, layer, source } = args;
	const features: Feature[] = data.map((d) => {
		const f = new Feature({
			id: d.id,
			name: d.name,
			siteId: d.mineSiteId,
			source: source,
			geometry: GetOLGeometry(d.geometry),
		});
		f.setId(d.id);
		return f;
	});
	const cluster = new Cluster({
		source: new VectorSource({ features: features }),
		distance: 50,
		minDistance: 50,
		geometryFunction: geometryToPoint,
	});
	layer.setSource(cluster);
};
