import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomWithQuery } from "jotai-tanstack-query";

import {
	Geometry,
	RiparianSurveyDto,
	riparianSurveyGetRiparianSpatialSurveyComparison,
	riparianSurveyGetRiparianSurveysWithPagination,
	riparianSystemGetRiparianSpatial,
	riparianSystemGetRiparianSystemMetrics,
	riparianSystemGetRiparianSystemZonesByExtent,
	RiparianSystemMetricListDto,
} from "@/lib/gen/eis";

import {
	Perm_Riparian_Std,
	UserHasPermissionAtom,
	UserHasPermissionsAtom,
} from "./authAtoms";
import { SelectAndZoomToFeatureAtom } from "./map/mapEventAtoms";
import { SelectedMineSiteIdAtom } from "./miningAtoms";

export const AutoSelectMetricAtomEffect = atomEffect((get, set) => {
	const selectedMetric = get(SelectedHealthMetricAtom);
	const structureOrCondition = get(StructureOrConditionSelectionAtom);
	const { data: metrics } = get(HealthMetricsAtom);

	const relevantMetrics = metrics?.filter(
		(m) =>
			(m.riparianMetricDataType === "MSAVI" &&
				structureOrCondition === "condition") ||
			(m.riparianMetricDataType === "Percent" &&
				structureOrCondition === "structure"),
	);
	if (relevantMetrics && relevantMetrics.length > 0) {
		if (selectedMetric) {
			const validMetric = relevantMetrics.find(
				(m) => m.metricId === selectedMetric.metricId,
			);
			if (validMetric) return; // We already have a valid metric selected
		}
		const nameMatched = relevantMetrics?.find(
			(m) => m.displayName === selectedMetric?.displayName,
		); // Match on name if we are switching between condition and structure
		set(SelectedHealthMetricAtom, nameMatched ?? relevantMetrics[0]);
	}
});

export const HealthMetricsAtom = atomWithQuery((get) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const userPerms = get(UserHasPermissionsAtom);
	return {
		queryKey: ["healthMetrics", mineSiteId],
		queryFn: async () => {
			return await riparianSystemGetRiparianSystemMetrics({
				MineSiteId: mineSiteId,
			});
		},
		enabled: !!mineSiteId && userPerms[Perm_Riparian_Std],
	};
});

export const HealthZonesQueryAtom = atomWithQuery((get) => {
	const userPerms = get(UserHasPermissionsAtom);
	return {
		queryKey: ["healthZones"],
		queryFn: async () => {
			return await riparianSystemGetRiparianSystemZonesByExtent({});
		},
		enabled: userPerms[Perm_Riparian_Std],
	};
});

export const SelectedHealthMetricAtom = atom<
	RiparianSystemMetricListDto | undefined
>(undefined);

export const StructureOrConditionSelectionAtom = atom<
	"structure" | "condition"
>("structure");

export const SortByValueOrChangeAtom = atom<"sortByValue" | "sortByChange">(
	"sortByValue",
);

export const SelectedHealthZoneIdAtom = atom<string | undefined>(undefined);

export type HealthGridCellComparison = {
	locationId: string;
	locationName: string;
	geometry: Geometry;
	survey1Result: number | undefined;
	survey2Result: number | undefined;
	colourHexString: string | undefined;
};

export const HealthGridCellsUpdateAtomEffect = atomEffect((get, set) => {
	const { data: gridCellGeometries } = get(_healthGridCellsQueryAtom);
	const { data: gridCellComparisons } = get(
		HealthGridCellsColourComparisonQueryAtom,
	);
	if (gridCellComparisons?.grids != null) {
		const gridComparisons = gridCellComparisons.grids;
		const results = gridCellGeometries?.flatMap((g) => {
			if (!g.locationName || !g.geometry) return [];
			const comparison = gridComparisons[g.locationId];
			if (
				!comparison ||
				comparison.survey1Result == null ||
				!comparison.colourHexString
			)
				return [];

			return [
				{
					locationId: g.locationId,
					locationName: g.locationName,
					geometry: g.geometry,
					survey1Result: comparison.survey1Result,
					survey2Result: comparison.survey2Result ?? undefined,
					colourHexString: comparison.colourHexString,
				},
			];
		});
		set(HealthGridCellsComparisonAtom, results ?? []);
	} else {
		set(HealthGridCellsComparisonAtom, []);
		set(SelectedHealthGridCellAtom, undefined);
	}
});

export const HealthGridCellsComparisonAtom = atom<HealthGridCellComparison[]>(
	[],
);

const _healthGridCellsQueryAtom = atomWithQuery((get) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const userPerms = get(UserHasPermissionsAtom);

	return {
		queryKey: ["healthGridCells", mineSiteId],
		queryFn: async () => {
			return await riparianSystemGetRiparianSpatial({
				MineSiteId: mineSiteId,
			});
		},
		enabled: !!mineSiteId && userPerms[Perm_Riparian_Std],
	};
});

export const HealthGridCellsColourComparisonQueryAtom = atomWithQuery((get) => {
	const survey1 = get(HealthSurvey1Atom);
	const survey2 = get(HealthSurvey2Atom);
	const metric = get(SelectedHealthMetricAtom);
	const userPerms = get(UserHasPermissionsAtom);

	return {
		queryKey: [
			"healthGridCells",
			metric?.metricId,
			survey1?.id,
			survey2?.id,
		],
		queryFn: async () => {
			return await riparianSurveyGetRiparianSpatialSurveyComparison({
				MetricId: metric?.metricId,
				RiparianSurvey1Id: survey1?.id,
				RiparianSurvey2Id: survey2?.id,
			});
		},
		enabled:
			userPerms[Perm_Riparian_Std] && !!metric?.metricId && !!survey1?.id,
	};
});

export const SelectedHealthGridCellAtom = atom<
	HealthGridCellComparison | undefined
>(undefined);

const _healthSurveysAtom = atom<RiparianSurveyDto[] | undefined>(undefined);
export const HealthSurveysAtom = atom(
	(get) => {
		const surveys = get(_healthSurveysAtom);
		return surveys;
	},
	async (get, set) => {
		const mineSiteId = get(SelectedMineSiteIdAtom);
		const comparisonMode = get(HealthComparisonModeAtom);
		const userHasPermission = get(UserHasPermissionAtom(Perm_Riparian_Std));

		if (mineSiteId && userHasPermission) {
			const surveys =
				await riparianSurveyGetRiparianSurveysWithPagination({
					MineSiteId: mineSiteId,
					PageSize: -1,
					IsRemoteSensingSurvey: true,
					OrderBy: "RemoteSensingDate",
					Descending: true,
				});

			set(_healthSurveysAtom, surveys.items ?? []);

			if (surveys?.items && surveys.items.length === 0) {
				set(HealthSurvey1Atom, undefined);
				set(HealthSurvey2Atom, undefined);
			}

			if (surveys?.items && surveys.items.length > 0) {
				set(HealthSurvey1Atom, surveys.items[0]);
			}
			if (comparisonMode && surveys?.items && surveys.items.length > 1) {
				set(HealthSurvey2Atom, surveys.items[1]);
			}
			if (comparisonMode && surveys?.items && surveys.items.length <= 1) {
				set(HealthComparisonModeAtom, false);
			}
		} else {
			set(_healthSurveysAtom, []);
		}
	},
);

const _healthSurvey1Atom = atom<RiparianSurveyDto | undefined>(undefined);
export const HealthSurvey1Atom = atom(
	(get) => {
		const survey1 = get(_healthSurvey1Atom);
		return survey1;
	},
	async (_get, set, value: RiparianSurveyDto | undefined) => {
		set(_healthSurvey1Atom, value);
	},
);
const _healthSurvey2Atom = atom<RiparianSurveyDto | undefined>(undefined);
export const HealthSurvey2Atom = atom(
	(get) => {
		const survey2 = get(_healthSurvey2Atom);
		return survey2;
	},
	async (_get, set, value: RiparianSurveyDto | undefined) => {
		set(_healthSurvey2Atom, value);
	},
);

const _healthComparisonModeAtom = atom<boolean>(true);
export const HealthComparisonModeAtom = atom(
	(get) => {
		const comparisonMode = get(_healthComparisonModeAtom);
		return comparisonMode;
	},
	async (_get, set, value: boolean) => {
		set(_healthComparisonModeAtom, value);
		if (value === false) {
			set(HealthSurvey2Atom, undefined);
		}
	},
);

export const GoToRiparianZone = atom(null, async (get, set, id: string) => {
	const healthZonesQuery = get(HealthZonesQueryAtom);

	const zone = healthZonesQuery.data?.find((rz) => rz.id === id);
	if (zone == null) return;
	set(SelectedHealthZoneIdAtom, zone.id);
	set(SelectAndZoomToFeatureAtom, id, "healthzone");
});
