import type { SeedApplicationSingleDto } from "../ts/SeedApplicationSingleDto.ts";
import { seedApplicationMethodSchema } from "./seedApplicationMethodSchema.ts";
import { seedApplicationProvenanceZoneSchema } from "./seedApplicationProvenanceZoneSchema.ts";
import { seedApplicationSingleRehabPolyDtoSchema } from "./seedApplicationSingleRehabPolyDtoSchema.ts";
import { seedApplicationStatusSchema } from "./seedApplicationStatusSchema.ts";
import { seedMixCriteriaResultDtoSchema } from "./seedMixCriteriaResultDtoSchema.ts";
import { targetSpeciesDtoSchema } from "./targetSpeciesDtoSchema.ts";
import { vegetationCommunityQuadratDtoSchema } from "./vegetationCommunityQuadratDtoSchema.ts";
import { vegetationCommunitySingleDtoSchema } from "./vegetationCommunitySingleDtoSchema.ts";
import { z } from "zod";

 export const seedApplicationSingleDtoSchema = z.object({ "id": z.string(), "name": z.string().nullable().nullish(), "rehabilitationPolygon": z.lazy(() => seedApplicationSingleRehabPolyDtoSchema).nullable().nullish(), "allocationOrderId": z.string(), "targetSpecies": z.array(z.lazy(() => targetSpeciesDtoSchema)).nullable().nullish(), "seedingDepth": z.number().int().nullable().nullish(), "seedingRate": z.number().int(), "applicationNotes": z.string().nullable().nullish(), "status": z.lazy(() => seedApplicationStatusSchema), "method": z.lazy(() => seedApplicationMethodSchema), "creationDate": z.string().datetime({ offset: true }), "startDate": z.string().date().nullable().nullish(), "endDate": z.string().date().nullable().nullish(), "quadrats": z.array(z.lazy(() => vegetationCommunityQuadratDtoSchema)).nullable().nullish(), "nearestQuadrat": z.number(), "vegetationCommunity": z.lazy(() => vegetationCommunitySingleDtoSchema).nullable().nullish(), "provenanceZone": z.lazy(() => seedApplicationProvenanceZoneSchema).nullable().nullish(), "totalQuantity": z.number(), "totalCost": z.number(), "criteriaResults": z.array(z.lazy(() => seedMixCriteriaResultDtoSchema)).nullable().nullish() }) as z.ZodType<SeedApplicationSingleDto>;