import type { Geometry } from "../ts/Geometry.ts";
import { coordinateSchema } from "./coordinateSchema.ts";
import { dimensionSchema } from "./dimensionSchema.ts";
import { envelopeSchema } from "./envelopeSchema.ts";
import { geometryFactorySchema } from "./geometryFactorySchema.ts";
import { ogcGeometryTypeSchema } from "./ogcGeometryTypeSchema.ts";
import { pointSchema } from "./pointSchema.ts";
import { precisionModelSchema } from "./precisionModelSchema.ts";
import { sortIndexValueSchema } from "./sortIndexValueSchema.ts";
import { z } from "zod";

 export const geometrySchema = z.object({ "factory": z.lazy(() => geometryFactorySchema).nullable().nullish(), "userData": z.unknown().nullish(), "srid": z.number().int(), "geometryType": z.string().nullable().nullish(), "ogcGeometryType": z.lazy(() => ogcGeometryTypeSchema), "precisionModel": z.lazy(() => precisionModelSchema).nullable().nullish(), "coordinate": z.lazy(() => coordinateSchema).nullable().nullish(), "coordinates": z.array(z.lazy(() => coordinateSchema)).nullable().nullish(), "numPoints": z.number().int(), "numGeometries": z.number().int(), "isSimple": z.boolean(), "isValid": z.boolean(), "isEmpty": z.boolean(), "area": z.number(), "length": z.number(), "centroid": z.lazy(() => pointSchema).nullable().nullish(), "interiorPoint": z.lazy(() => pointSchema).nullable().nullish(), "pointOnSurface": z.lazy(() => pointSchema).nullable().nullish(), "dimension": z.lazy(() => dimensionSchema), "boundary": z.lazy(() => geometrySchema).nullable().nullish(), "boundaryDimension": z.lazy(() => dimensionSchema), "envelope": z.lazy(() => geometrySchema).nullable().nullish(), "envelopeInternal": z.lazy(() => envelopeSchema).nullable().nullish(), "isGeometryCollection": z.boolean(), "sortIndex": z.lazy(() => sortIndexValueSchema), "isRectangle": z.boolean() }) as z.ZodType<Geometry>;