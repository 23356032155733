import { z } from "zod";

import type { RioCogeoModelsInfo } from "@/lib/gen/titiler/types/RioCogeoModelsInfo";

import { bandMetadataSchema } from "./bandMetadataSchema";
import { geoSchema } from "./geoSchema";
import { ifdSchema } from "./ifdSchema";
import { profileSchema } from "./profileSchema";

/**
 * @description rio-cogeo Info.
 */
export const rioCogeoModelsInfoSchema = z
	.object({
		Path: z.string(),
		Driver: z.string(),
		COG: z.boolean(),
		Compression: z.union([z.string(), z.null()]).optional(),
		ColorSpace: z.union([z.string(), z.null()]).optional(),
		COG_errors: z.union([z.array(z.string()), z.null()]).optional(),
		COG_warnings: z.union([z.array(z.string()), z.null()]).optional(),
		Profile: z.lazy(() => profileSchema),
		GEO: z.lazy(() => geoSchema),
		Tags: z.object({}).catchall(z.object({})),
		"Band Metadata": z
			.object({})
			.catchall(z.lazy(() => bandMetadataSchema))
			.optional(),
		IFD: z.array(z.lazy(() => ifdSchema)),
	})
	.describe("rio-cogeo Info.") as z.ZodType<RioCogeoModelsInfo>;
