export const analoguePolysOrderBy = {
    "Area": "Area",
    "Name": "Name",
    "Landform": "Landform",
    "MineSite": "MineSite",
    "MiningRegion": "MiningRegion",
    "Status": "Status",
    "VegClassCoverDynamicColumn": "VegClassCoverDynamicColumn"
} as const;

 export type AnaloguePolysOrderByEnum = (typeof analoguePolysOrderBy)[keyof typeof analoguePolysOrderBy];

 export type AnaloguePolysOrderBy = AnaloguePolysOrderByEnum;