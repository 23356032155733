import { z } from "zod";

import type { TileMatrix } from "@/lib/gen/titiler/types/TileMatrix";

import { variableMatrixWidthSchema } from "./variableMatrixWidthSchema";

/**
 * @description Tile Matrix Definition\n\nA tile matrix, usually corresponding to a particular zoom level of a TileMatrixSet.\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/tileMatrix.json
 */
export const tileMatrixSchema = z
	.object({
		title: z
			.union([z.string(), z.null()])
			.describe(
				"Title of this tile matrix, normally used for display to a human",
			)
			.optional(),
		description: z
			.union([z.string(), z.null()])
			.describe(
				"Brief narrative description of this tile matrix set, normally available for display to a human",
			)
			.optional(),
		keywords: z
			.union([z.array(z.string()), z.null()])
			.describe(
				"Unordered list of one or more commonly used or formalized word(s) or phrase(s) used to describe this dataset",
			)
			.optional(),
		id: z
			.string()
			.regex(new RegExp("^\\-?[0-9]+$"))
			.describe(
				"Identifier selecting one of the scales defined in the TileMatrixSet and representing the scaleDenominator the tile. Implementation of 'identifier'",
			),
		scaleDenominator: z
			.number()
			.describe("Scale denominator of this tile matrix"),
		cellSize: z.number().describe("Cell size of this tile matrix"),
		cornerOfOrigin: z
			.enum(["topLeft", "bottomLeft"])
			.default("topLeft")
			.describe(
				"The corner of the tile matrix (_topLeft_ or _bottomLeft_) used as the origin for numbering tile rows and columns. This corner is also a corner of the (0, 0) tile.",
			)
			.optional(),
		pointOfOrigin: z
			.tuple([
				z.union([z.number().int(), z.number()]),
				z.union([z.number().int(), z.number()]),
			])
			.describe(
				"Precise position in CRS coordinates of the corner of origin (e.g. the top-left corner) for this tile matrix. This position is also a corner of the (0, 0) tile. In previous version, this was 'topLeftCorner' and 'cornerOfOrigin' did not exist.",
			),
		tileWidth: z
			.number()
			.int()
			.min(1)
			.describe("Width of each tile of this tile matrix in pixels"),
		tileHeight: z
			.number()
			.int()
			.min(1)
			.describe("Height of each tile of this tile matrix in pixels"),
		matrixWidth: z
			.number()
			.int()
			.min(1)
			.describe("Width of the matrix (number of tiles in width)"),
		matrixHeight: z
			.number()
			.int()
			.min(1)
			.describe("Height of the matrix (number of tiles in height)"),
		variableMatrixWidths: z
			.union([z.array(z.lazy(() => variableMatrixWidthSchema)), z.null()])
			.describe("Describes the rows that has variable matrix width")
			.optional(),
	})
	.describe(
		"Tile Matrix Definition\n\nA tile matrix, usually corresponding to a particular zoom level of a TileMatrixSet.\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/tileMatrix.json",
	) as z.ZodType<TileMatrix>;
