import { useSetAtom } from "jotai";
import { useMemo } from "react";

import { SeedBatchDialogSeedBatchIdAtom } from "@/atoms/seedAtoms";
import { DataTable } from "@/components/data-table/data-table";
import { SeedBatchInventoryDto } from "@/lib/gen/eis";

import { SeedBatchColumns } from "./seed-batch-columns";

type Props = {
	seedBatches: SeedBatchInventoryDto[];
};

export const SeedInventoryExpandedRow = ({ seedBatches }: Props) => {
	const openSeedBatch = useSetAtom(SeedBatchDialogSeedBatchIdAtom);
	const columns = useMemo(() => {
		return SeedBatchColumns({ openSeedBatch });
	}, [openSeedBatch]);

	return (
		<DataTable
			columns={columns}
			data={seedBatches}
			totalRows={seedBatches.length}
			title="Seed Batches"
			isLoading={false}
		/>
	);
};
