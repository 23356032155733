import type { SeedAppVegetationCommunityDto } from "../ts/SeedAppVegetationCommunityDto.ts";
import { analoguePolygonDtoSchema } from "./analoguePolygonDtoSchema.ts";
import { baselineSpeciesSamplingDtoSchema } from "./baselineSpeciesSamplingDtoSchema.ts";
import { lifeformTargetCoversDtoSchema } from "./lifeformTargetCoversDtoSchema.ts";
import { seedAppRehabPolyDtoSchema } from "./seedAppRehabPolyDtoSchema.ts";
import { seedMixCriteriaResultDtoSchema } from "./seedMixCriteriaResultDtoSchema.ts";
import { vegCommBaselineQuadratDtoSchema } from "./vegCommBaselineQuadratDtoSchema.ts";
import { vegetationCommunityFloraSpeciesDtoSchema } from "./vegetationCommunityFloraSpeciesDtoSchema.ts";
import { vegetationCommunityLandformTypeSchema } from "./vegetationCommunityLandformTypeSchema.ts";
import { z } from "zod";

 export const seedAppVegetationCommunityDtoSchema = z.object({ "id": z.string(), "name": z.string().nullable().nullish(), "description": z.string().nullable().nullish(), "nearestQuadrat": z.number(), "rehabilitationPolygon": z.lazy(() => seedAppRehabPolyDtoSchema).nullable().nullish(), "provenanceZone": z.string().nullable().nullish(), "landform": z.array(z.lazy(() => vegetationCommunityLandformTypeSchema)).nullable().nullish(), "baselineQuadrats": z.array(z.lazy(() => vegCommBaselineQuadratDtoSchema)).nullable().nullish(), "vegetationCommunityFloraSpeciesDtos": z.array(z.lazy(() => vegetationCommunityFloraSpeciesDtoSchema)).nullable().nullish(), "speciesOmittedDueToStrata": z.array(z.lazy(() => baselineSpeciesSamplingDtoSchema)).nullable().nullish(), "lifeformDtos": z.array(z.lazy(() => lifeformTargetCoversDtoSchema)).nullable().nullish(), "criteriaResults": z.array(z.lazy(() => seedMixCriteriaResultDtoSchema)).nullable().nullish(), "errorString": z.string().nullable().nullish(), "provenanceZoneId": z.string(), "analoguePolygons": z.array(z.lazy(() => analoguePolygonDtoSchema)).nullable().nullish() }) as z.ZodType<SeedAppVegetationCommunityDto>;