import { useQuery } from "@tanstack/react-query";
import { useAtom, useAtomValue } from "jotai";

import {
	SeedBatchDialogOpenAtom,
	SeedBatchDialogSeedBatchIdAtom,
} from "@/atoms/seedAtoms";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { seedBatchesGetSeedBatch } from "@/lib/gen/eis";

import { SeedBatchForm } from "./seed-batch-form";

type Props = {
	children: React.ReactNode;
};

export const SeedBatchDialog = ({ children }: Props) => {
	const [open, setOpen] = useAtom(SeedBatchDialogOpenAtom);
	const seedBatchId = useAtomValue(SeedBatchDialogSeedBatchIdAtom);
	const { data: seedBatch } = useQuery({
		queryKey: ["seedBatchesGetSeedBatch", seedBatchId],
		queryFn: async () => seedBatchesGetSeedBatch(seedBatchId!),
		enabled: !!seedBatchId,
	});

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			{open && (
				<DialogContent className="flex max-h-[calc(100vh-64px)] w-fit max-w-[calc(100vw-32px)] flex-col overflow-hidden">
					<DialogHeader>
						<DialogTitle>Seed Batch Details</DialogTitle>
						<DialogDescription />
					</DialogHeader>
					{seedBatchId && !seedBatch ? null : (
						<SeedBatchForm
							seedBatchId={seedBatchId}
							seedBatch={seedBatch}
						/>
					)}
				</DialogContent>
			)}
		</Dialog>
	);
};
