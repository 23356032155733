import type { RehabilitationSurveysUpdatePathParams, RehabilitationSurveysUpdate204, RehabilitationSurveysUpdateMutationRequest, RehabilitationSurveysUpdateMutationResponse } from "../ts/RehabilitationSurveysUpdate.ts";
import { updateRehabilitationSurveyCommandSchema } from "./updateRehabilitationSurveyCommandSchema.ts";
import { z } from "zod";

 export const rehabilitationSurveysUpdatePathParamsSchema = z.object({ "id": z.string() }) as z.ZodType<RehabilitationSurveysUpdatePathParams>;

 export const rehabilitationSurveysUpdate204Schema = z.unknown() as z.ZodType<RehabilitationSurveysUpdate204>;

 export const rehabilitationSurveysUpdateMutationRequestSchema = z.lazy(() => updateRehabilitationSurveyCommandSchema) as z.ZodType<RehabilitationSurveysUpdateMutationRequest>;

 export const rehabilitationSurveysUpdateMutationResponseSchema = z.lazy(() => rehabilitationSurveysUpdate204Schema) as z.ZodType<RehabilitationSurveysUpdateMutationResponse>;