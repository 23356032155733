import { Cross2Icon } from "@radix-ui/react-icons";
import { PopoverAnchor } from "@radix-ui/react-popover";
import { convertArea, convertLength } from "@turf/helpers";
import { Command as CommandPrimitive } from "cmdk";
import { format, parseISO } from "date-fns";
import { CalendarIcon, CheckIcon, Search, XIcon } from "lucide-react";
import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import Icon from "@/components/icons/icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	CommandEmpty,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent } from "@/components/ui/popover";
import {
	getAreaUnitFromShorthand,
	getLengthUnitFromShorthand,
} from "@/helpers/unit-helpers";
import { Comparator, ValueWithLabel } from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

import { commandScore } from "./command-score";
import {
	ColumnFilter,
	ColumnFilterOption,
	COMPARATOR_LABELS,
	FilterItemComparators,
	isComparatorMultiSelect,
	isFilterItemTypeNumberInput,
	isFilterItemTypeTextInput,
	setFilterValue,
} from "./filter-search-box-types";
import { ValueRenderers } from "./filter-value";
import { InputSuffix } from "./input-suffix";

export interface FilteredSearchBoxArguments<T extends string> {
	filters: ColumnFilter<T>[];
	setFilters: (
		setter:
			| ColumnFilter<T>[]
			| ((prev: ColumnFilter<T>[]) => ColumnFilter<T>[]),
	) => void;
	options: ColumnFilterOption<T>[];
}

interface FilteredSearchBoxProps<T extends string> {
	args: FilteredSearchBoxArguments<T>;
	children?: React.ReactNode;
	placeholder?: string;
	className?: string;
}

export function FilteredSearchBox<T extends string>(
	props: FilteredSearchBoxProps<T>,
) {
	const { children, args, className } = props;
	const placeholder = props.placeholder ?? "Search filters...";

	const { setFilters: _commitFilters, filters: _filters, options } = args;

	const [popoverOpen, setPopoverOpen] = useState(false);

	const [filters, setFilters] = useState<ColumnFilter<T>[]>([]);
	const [text, setText] = useState("");
	const [date, setDate] = useState<Date>(new Date());

	const [isTypingValue, setIsTypingValue] = useState(false);
	const [usingMultiSelect, setUsingMultiSelect] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const commandRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setFilters(_filters.map((f) => ({ ...f })));
	}, [_filters]);

	const commitFilters = useCallback(
		(filters: ColumnFilter<T>[]) => {
			_commitFilters(
				filters.flatMap((f) => {
					if (f.comparator == null || f.values.length <= 0) return [];
					const values = f.values.flatMap((v) => {
						if (v.value == null || v.value.length <= 0) return [];

						const value = v.value;
						if (
							f.filter.type === "distance" ||
							f.filter.type === "area"
						) {
							const match = value.match(
								/^(\d+\.?\d*)([a-zA-Z]*)$/,
							);

							let num = 0;
							let suffix: string | undefined = undefined;
							if (match && match[2]) {
								num = +match[1];
								suffix = match[2];
							} else {
								num = +value;
							}

							if (f.filter.type === "area") {
								const unit =
									getAreaUnitFromShorthand(suffix) ??
									f.filter.defaultUnits?.area;
								if (unit != null)
									v.value = `${convertArea(num, unit, f.filter.sourceUnits?.area ?? "metres")}`;
							} else {
								const unit =
									getLengthUnitFromShorthand(suffix) ??
									f.filter.defaultUnits?.distance;
								if (unit != null)
									v.value = `${convertLength(num, unit, f.filter.sourceUnits?.distance ?? "metres")}`;
							}
						} else if (f.filter.type === "date") {
							v.value = new Date(Date.parse(value)).toISOString();
						}
						return v;
					});
					if (values.length <= 0) return [];

					return [
						{
							filter: f.filter,
							comparator: f.comparator,
							values,
						} as ColumnFilter<T>,
					];
				}),
			);
		},
		[_commitFilters],
	);

	const onSelectSuggestion = useCallback((filter: ColumnFilterOption<T>) => {
		setFilters((prev) => [
			...prev,
			{
				filter,
				values: [],
				comparator: filter.type === "boolean" ? "EQUALS" : undefined,
			} as ColumnFilter<T>,
		]);
		setText("");
		setPopoverOpen(true);
		inputRef.current?.focus();
	}, []);

	const onSelectComparator = useCallback(
		(c: Comparator) => {
			setFilters((prev) => {
				prev[prev.length - 1].comparator = c;
				return [...prev];
			});
			setText("");
			inputRef.current?.focus();

			const current = filters[filters.length - 1];
			if (isFilterItemTypeTextInput(current)) {
				setIsTypingValue(true);
				if (current.filter.type !== "date") setPopoverOpen(false);
			}
		},
		[filters],
	);
	const onSelectValueOption = useCallback(
		(value: ValueWithLabel, multi?: boolean) => {
			if (value.value == null || value.value.length <= 0) return;
			if (multi) setUsingMultiSelect(true);

			const updatedFilters = setFilterValue(filters, value, multi);
			setFilters(updatedFilters);
			commitFilters(updatedFilters);
			setText("");
			inputRef.current?.focus();
		},
		[commitFilters, filters],
	);
	const onRemoveFilterItem = useCallback(
		(i: number) => {
			const updatedFilters = [
				...filters.slice(0, i),
				...filters.slice(i + 1),
			];
			setFilters(updatedFilters);
			commitFilters(updatedFilters);
		},
		[commitFilters, filters],
	);
	const onChangeTextValue = useCallback(
		(index: number, value: string) => {
			const updatedFilters = [...filters];
			updatedFilters[index].values = value
				.split(",")
				.map((v) => ({ value: v }));
			setFilters([...updatedFilters]);
			commitFilters(updatedFilters);
		},
		[commitFilters, filters],
	);
	const onSubmitValue = useCallback(() => {
		const updatedFilters = [...filters];
		if (updatedFilters[updatedFilters.length - 1].filter.type === "date") {
			updatedFilters[updatedFilters.length - 1].values = [
				{ value: format(parseISO(text) ?? new Date(), "yyyy-MM-dd") },
			];
		} else {
			updatedFilters[updatedFilters.length - 1].values = [
				{ value: text },
			];
		}
		setFilters(updatedFilters);
		commitFilters(updatedFilters);
		setText("");
		setIsTypingValue(false);
		setPopoverOpen(true);
	}, [commitFilters, filters, text]);

	const onKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			switch (e.code) {
				case "Backspace":
					if (text.length > 0) break;
					if (filters.length > 0) {
						const current = filters[filters.length - 1];

						// Remove last value (if using multi)
						if (usingMultiSelect && current.values.length > 0) {
							const updatedFilters = [...filters];
							const values =
								updatedFilters[updatedFilters.length - 1]
									.values;
							updatedFilters[updatedFilters.length - 1].values =
								values.slice(0, values.length - 1);
							setFilters(updatedFilters);
							commitFilters(updatedFilters);
							break;
						}

						// Remove comparator
						if (
							current.values.length <= 0 &&
							current.comparator != null
						) {
							setFilters((prev) => {
								prev[prev.length - 1].comparator = undefined;
								return [...prev];
							});
							setIsTypingValue(false);
							setPopoverOpen(true);
							break;
						}

						// Remove value
						if (current.values.length > 0) {
							if (isFilterItemTypeTextInput(current)) {
								e.stopPropagation();
								e.preventDefault();
								setText(
									current.values
										.map((v) => v.value ?? "")
										.join(","),
								);
								setFilters((prev) => {
									prev[prev.length - 1].values = [];
									return [...prev];
								});
								setIsTypingValue(true);
								setPopoverOpen(false);
							} else {
								setFilters((prev) => {
									prev[prev.length - 1].values = [];
									return [...prev];
								});
							}
							break;
						}

						// Remove last filter
						const updatedFilters = filters.slice(
							0,
							filters.length - 1,
						);
						setFilters(updatedFilters);
						commitFilters(updatedFilters);
					}
					setIsTypingValue(false);

					break;
				case "Enter":
				case "NumpadEnter":
					if (isTypingValue) {
						onSubmitValue();
					} else if (popoverOpen) {
						commandRef.current?.dispatchEvent(
							new KeyboardEvent("keydown", {
								key: e.key,
								code: e.code,
								bubbles: true,
								cancelable: true,
							}),
						);
					}
					break;
				case "Tab":
					e.stopPropagation();
					e.preventDefault();
					if (usingMultiSelect) {
						setUsingMultiSelect(false);
					}
					break;
				case "ArrowDown":
				case "ArrowUp":
					e.stopPropagation();
					e.preventDefault();
					commandRef.current?.dispatchEvent(
						new KeyboardEvent("keydown", {
							key: e.key,
							code: e.code,
							bubbles: true,
							cancelable: true,
						}),
					);
					break;
				case "ArrowRight":
					if (usingMultiSelect && text.length <= 0) {
						e.stopPropagation();
						e.preventDefault();
						setUsingMultiSelect(false);
					}
					break;
				case "ArrowLeft":
					if (filters.length > 0) {
						const current = filters[filters.length - 1];
						if (
							current.values.length > 0 &&
							isFilterItemTypeTextInput(current)
						) {
							e.stopPropagation();
							e.preventDefault();
							setText(
								current.values
									.map((v) => v.value ?? "")
									.join(","),
							);
							setFilters((prev) => {
								prev[prev.length - 1].values = [];
								return [...prev];
							});
							setIsTypingValue(true);
							setPopoverOpen(false);
						}
					}
					break;
				default:
					if (!isTypingValue) setPopoverOpen(true);
			}
		},
		[
			text.length,
			filters,
			isTypingValue,
			popoverOpen,
			usingMultiSelect,
			commitFilters,
			onSubmitValue,
		],
	);

	const [currentFilterOptions, setCurrentFilterOptions] = useState<
		ValueWithLabel[]
	>([]);
	useEffect(() => {
		const current = filters[filters.length - 1];
		if (
			current == null ||
			current.filter.options == null ||
			!(current.values.length <= 0 || usingMultiSelect)
		)
			return;

		if (Array.isArray(current.filter.options)) {
			setCurrentFilterOptions(current.filter.options);
			return;
		}
		current.filter.options(text).then((results) => {
			setCurrentFilterOptions(results);
		});

		return;
	}, [filters, text, usingMultiSelect]);

	const suggestions = useMemo(() => {
		if (
			filters.length <= 0 ||
			(filters[filters.length - 1].comparator != null &&
				filters[filters.length - 1].values.length > 0 &&
				!usingMultiSelect)
		) {
			const optionsSorted = options
				.filter(
					(c) =>
						// prevent the same enum filter being added more than once
						c.type !== "enum" ||
						filters.findIndex(
							(f) =>
								f.filter.key === c.key &&
								f.filter.subKey === c.subKey,
						) === -1,
				)
				.flatMap((c) => {
					const score = commandScore(
						c.label ? c.label : c.key,
						text,
						c.group ? [c.group, ...(c.keywords ?? [])] : c.keywords,
					);
					if (score <= 0) return [];

					return [{ option: c, score }];
				})
				.sort((a, b) => b.score - a.score);

			return optionsSorted.map(({ option: c }, i) => {
				return (
					<Fragment key={`column-${c.key}${c.subKey ?? ""}`}>
						{text.length <= 0 &&
							c.group &&
							i > 0 &&
							optionsSorted[i - 1].option.group != c.group && (
								<>
									<CommandItem className="pointer-events-none mt-1 opacity-60">
										{c.group}
									</CommandItem>
								</>
							)}

						<CommandItem
							value={`${c.key}${c.subKey ?? ""}`}
							className="gap-1.5 capitalize"
							onSelect={() => onSelectSuggestion(c)}
						>
							<Icon
								name={c.icon ?? "Filter"}
								size={16}
								className={cn(
									"mr-1 size-4 text-misc-tyton-green-primary",
									c.iconClassName,
								)}
								color={c.iconColor}
							/>
							{c.label ?? c.subKey ?? c.key}
						</CommandItem>
					</Fragment>
				);
			});
		}

		const current = filters[filters.length - 1];

		if (current.comparator == null) {
			return FilterItemComparators[current.filter.type ?? "string"]
				.filter((k) => commandScore(COMPARATOR_LABELS[k], text))
				.map((k) => {
					return (
						<CommandItem
							key={`comparator-${k}`}
							value={`${k}`}
							keywords={[COMPARATOR_LABELS[k]]}
							onSelect={() => onSelectComparator(k)}
						>
							{COMPARATOR_LABELS[k]}
						</CommandItem>
					);
				});
		}

		if (current.values.length <= 0 || usingMultiSelect) {
			if (current.filter.type === "boolean") {
				return ["true", "false"]
					.filter((k) => commandScore(k, text))
					.map((value) => (
						<CommandItem
							key={`option-${value}`}
							value={value}
							className="gap-1.5 capitalize"
							onSelect={() => onSelectValueOption({ value })}
						>
							{value === "true" ? (
								<CheckIcon className="size-4" />
							) : (
								<XIcon className="size-4" />
							)}
							{value}
						</CommandItem>
					));
			}

			if (currentFilterOptions != null) {
				return currentFilterOptions
					.flatMap((k) => {
						const score = commandScore(k.value ?? "", text);
						if (score <= 0) return [];

						return [{ option: k, score }];
					})
					.sort((a, b) => b.score - a.score)
					.map(({ option: v }) => (
						<CommandItem
							key={`option-${v.value ?? ""}`}
							value={v.value ?? ""}
							className="gap-1.5 capitalize"
							onSelect={() =>
								onSelectValueOption(
									v,
									isComparatorMultiSelect(current.comparator),
								)
							}
						>
							{v.label ?? v.value}
						</CommandItem>
					));
			}
		}

		return [];
	}, [
		filters,
		usingMultiSelect,
		options,
		text,
		onSelectSuggestion,
		onSelectComparator,
		currentFilterOptions,
		onSelectValueOption,
	]);

	const isTypingNumber = useMemo(() => {
		if (!isTypingValue || filters.length <= 0) return false;
		const current = filters[filters.length - 1];
		if (!isFilterItemTypeNumberInput(current.filter.type)) return false;
		if (current.comparator == null) return false;
		return true;
	}, [filters, isTypingValue]);

	const isTypingDate = useMemo(() => {
		if (!isTypingValue || filters.length <= 0) return false;
		const current = filters[filters.length - 1];
		if (current.filter.type !== "date") return false;
		if (current.comparator == null) return false;
		return true;
	}, [filters, isTypingValue]);

	useEffect(() => {
		if (!isTypingDate) return;

		const parsedDate = parseISO(text);
		if (parsedDate != null) setDate(parsedDate);
	}, [isTypingDate, text]);

	return (
		<div className={cn("flex w-full items-center", className)}>
			<div
				className="flex flex-1 items-center"
				onFocusCapture={(e) => {
					e.stopPropagation();
				}}
			>
				<Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
					<div className="flex min-h-9 w-full items-center overflow-hidden rounded border border-core-primary-border bg-core-primary-background text-popover-foreground shadow-sm">
						<Search className="ml-2 size-4 shrink-0 opacity-50" />
						<div className="flex min-h-8 flex-1 flex-wrap items-center gap-1 px-2">
							{filters.map((f, i) => {
								const ValueRenderer =
									ValueRenderers[f.filter.type ?? "string"];
								const isMulti = isComparatorMultiSelect(
									f.comparator,
								);
								const valuesJoined = f.values
									.map((v) => v.value ?? "")
									.join(",");
								const labelsJoined = f.values
									.map((v) => v.label ?? v.value ?? "")
									.join(",");
								return (
									<div
										key={`filter-${f.filter.key}${f.filter.subKey ?? ""}-${i}`}
										className={cn(
											"flex items-center gap-1 rounded-md py-0.5 pl-0.5 pr-1",
											f.comparator != null &&
												"bg-card-filter text-card-filter-foreground",
										)}
									>
										<Badge
											variant="filter_chip"
											className="h-6 select-none gap-1 px-2 capitalize"
										>
											<Icon
												name={f.filter.icon ?? "Filter"}
												size={16}
												className={cn(
													"mr-1 size-4 text-misc-tyton-green-primary",
													f.filter.iconClassName,
												)}
												color={f.filter.iconColor}
											/>
											{f.filter.label ??
												f.filter.subKey ??
												f.filter.key}
										</Badge>
										{f.comparator != null && (
											<Badge
												className="h-6 select-none whitespace-nowrap px-2 py-0 text-sm"
												variant="filter_chip"
											>
												{
													COMPARATOR_LABELS[
														f.comparator
													]
												}
											</Badge>
										)}
										{f.values.length > 0 && (
											<Badge
												variant="filter_chip"
												className="flex h-6 p-0"
											>
												{isMulti && (
													<span className="-mt-0.5 pl-0.5 font-mono text-lg text-secondary-foreground/50">
														{"["}
													</span>
												)}
												{isFilterItemTypeTextInput(f) &&
												f.filter.type !== "date" ? (
													<div className="flex items-center gap-0.5 px-1">
														<Input
															value={valuesJoined}
															onChange={(e) =>
																onChangeTextValue(
																	i,
																	e.target
																		.value,
																)
															}
															className="h-6 border-none bg-transparent p-0 font-mono text-xs !outline-none !ring-0"
															style={{
																width:
																	valuesJoined.length <=
																	0
																		? "auto"
																		: `${valuesJoined.length}ch`,
															}}
														/>
														<InputSuffix<T>
															filterItem={f}
														/>
													</div>
												) : (
													<ValueRenderer
														value={labelsJoined}
														onChange={(value) =>
															onChangeTextValue(
																i,
																value,
															)
														}
													/>
												)}
												{isMulti && (
													<span className="-mt-0.5 pr-0.5 font-mono text-lg text-secondary-foreground/50">
														{"]"}
													</span>
												)}
											</Badge>
										)}
										{f.comparator != null &&
											f.values.length > 0 && (
												<Button
													variant="min"
													size="min"
													onClick={() =>
														onRemoveFilterItem(i)
													}
													className="h-6 w-4 min-w-4 opacity-40 transition-opacity hover:text-destructive hover:opacity-100"
												>
													<Cross2Icon className="size-4" />
												</Button>
											)}
									</div>
								);
							})}
							<PopoverAnchor asChild>
								<div className="flex w-auto flex-1 items-center gap-0.5">
									{isTypingDate && (
										<CalendarIcon className="mr-2 size-4" />
									)}
									<Input
										ref={inputRef}
										value={text}
										onChange={(e) =>
											setText(e.currentTarget.value)
										}
										className={cn(
											"flex h-full w-auto border-none bg-transparent p-0 text-sm shadow-none outline-none !ring-0 placeholder:text-muted-foreground",
											isTypingNumber
												? "min-w-[0.5rem] font-mono"
												: "flex-1",
										)}
										placeholder={
											filters.length <= 0
												? placeholder
												: undefined
										}
										onKeyDown={onKeyDown}
										onClick={() => {
											if (!isTypingValue || isTypingDate)
												setPopoverOpen(true);
										}}
										style={
											isTypingNumber || isTypingDate
												? {
														width: `${text.length}ch`,
													}
												: undefined
										}
									/>
									{isTypingNumber && (
										<InputSuffix
											value={text}
											filterItem={
												filters.length <= 0
													? undefined
													: filters[
															filters.length - 1
														]
											}
										/>
									)}
								</div>
							</PopoverAnchor>

							<PopoverContent
								asChild
								className="z-[1000] w-auto p-0"
								align="start"
								onOpenAutoFocus={(e) => {
									e.preventDefault();
									setUsingMultiSelect(false);
								}}
							>
								<div>
									{isTypingDate && (
										<>
											<Calendar
												mode="single"
												selected={date}
												onSelect={(_, d) => {
													setDate(d);
													setText(
														format(d, "yyyy-MM-dd"),
													);
												}}
												required
											/>
											<div className="px-1 pb-1">
												<Button
													variant="outline"
													className="w-full"
													onClick={onSubmitValue}
												>
													Ok
												</Button>
											</div>
										</>
									)}
									<CommandPrimitive
										className={cn(isTypingDate && "hidden")}
										ref={commandRef}
										shouldFilter={false}
										loop
									>
										<CommandPrimitive.Input
											hidden
											value={text}
										/>
										<CommandList>
											<CommandEmpty className="flex h-9 items-center justify-center px-2 py-1.5 text-xs italic">
												No Results
											</CommandEmpty>

											{suggestions}
										</CommandList>
									</CommandPrimitive>
								</div>
							</PopoverContent>
						</div>
					</div>
				</Popover>
			</div>
			{children}
		</div>
	);
}
