import { lazy } from "react";

import { LazyCardComponent } from "./card-components";

const LazyControllerCard = {
	component: lazy(() => import("@/components/ControllerCard")),
	permission: undefined,
} as const satisfies LazyCardComponent;

const LazyLayerCard = {
	component: lazy(() => import("@/components/LayerCard")),
	permission: undefined,
} as const satisfies LazyCardComponent;

export const CardPanelLeft = () => {
	return (
		<>
			<LazyControllerCard.component />
			<LazyLayerCard.component id={"LayerCard"} />
		</>
	);
};
