import client from "@kubb/plugin-client/client";
import type { SamplingSitesGetQueryResponse, SamplingSitesGetPathParams } from "../../ts/SamplingSitesGet.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/SamplingSites/:id
 */
export async function samplingSitesGet(id: SamplingSitesGetPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<SamplingSitesGetQueryResponse, Error, unknown>({ method: "GET", url: `/api/SamplingSites/${id}`, ...config });
    return res.data;
}