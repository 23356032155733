import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Rehab_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { SelectedSiteInspectionAtom } from "@/atoms/siteInspectionAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const SiteInspectionSummaryCard = {
	component: lazy(
		() =>
			import(
				"@/components/Monitoring/Onground/SiteInspectionSummaryCard"
			),
	),
	permission: Perm_Rehab_Std,
} as const satisfies LazyCardComponent;

export const LazySiteInspectionSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const selectedSiteInspection = useAtomValue(SelectedSiteInspectionAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SiteInspectionSummaryCard, userPerms);
		if (comp) {
			if (selectedSiteInspection) return comp;
		}
		return null;
	}, [selectedSiteInspection, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"SiteInspectionSummaryCard"} />}
		</Suspense>
	);
};
