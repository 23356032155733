import { Cell, flexRender, Header, Row } from "@tanstack/react-table";
import { Loader2 } from "lucide-react";
import React, { useMemo } from "react";

import { TableSettings } from "@/atoms/tableSettingsAtoms";
import {
	getCommonPinningStyles,
	getSafeID,
} from "@/components/data-table/helpers";
import { TableCell, TableHead } from "@/components/ui/table";
import { cn } from "@/lib/utils";

interface DataTableRowProps<TData> {
	row: Row<TData>;
	scrolledLeft?: boolean;
	isSelected?: boolean;
	/** This is passed so row rendering is retriggered on column setting changes */
	columnState: TableSettings & { columnCount: number };
}

export const DataTableRow = React.memo(function <TData>(
	props: DataTableRowProps<TData>,
) {
	const { row, scrolledLeft, isSelected } = props;

	if (row == null) {
		return (
			<TableCell className="flex">
				<div className="flex w-full items-center justify-center gap-2">
					<Loader2 className="size-5 animate-spin" />
					<div className="text-core-secondary-text">Loading...</div>
				</div>
			</TableCell>
		);
	}

	return row
		.getVisibleCells()
		.map((cell) => (
			<DataTableCell
				key={cell.id}
				cell={cell}
				scrolledLeft={scrolledLeft}
				isSelected={isSelected}
			/>
		));
});

interface DataTableCellProps<TData> {
	cell: Cell<TData, unknown>;
	scrolledLeft?: boolean;
	isSelected?: boolean;
}

function DataTableCell<TData>(props: DataTableCellProps<TData>) {
	const { cell, scrolledLeft, isSelected } = props;

	const width = useMemo(
		() => `calc(var(--col-${getSafeID(cell.column.id)}-size) * 1px)`,
		[cell.column.id],
	);
	const pinStyles = useMemo(
		() => getCommonPinningStyles(cell.column, scrolledLeft === true),
		[cell.column, scrolledLeft],
	);

	return (
		<TableCell
			key={cell.id}
			className={cn(
				"flex h-full items-center p-1 px-2 transition-colorsAndShadow group-odd:bg-core-primary-background group-even:bg-table-row-alt group-hover:!bg-table-row-hover",
				isSelected && "!bg-table-row-select",
			)}
			style={{
				width,
				...pinStyles,
			}}
		>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</TableCell>
	);
}

interface DataTableHeadProps<TData> {
	header: Header<TData, unknown>;
	scrolledLeft: boolean;
}

export function EisDataTableHead<TData>(props: DataTableHeadProps<TData>) {
	const { header, scrolledLeft } = props;

	const width = useMemo(
		() => `calc(var(--header-${getSafeID(header.id)}-size) * 1px)`,
		[header.id],
	);
	const pinStyles = useMemo(
		() => getCommonPinningStyles(header.column, scrolledLeft === true),
		[header, scrolledLeft],
	);

	return (
		<TableHead
			key={header.id}
			className="relative flex h-full items-center bg-core-secondary-background px-0 transition-shadow"
			style={{
				width,
				...pinStyles,
			}}
		>
			{header.isPlaceholder
				? null
				: flexRender(
						header.column.columnDef.header,
						header.getContext(),
					)}
			{header.column.getCanResize() && (
				<div
					onDoubleClick={() => header.column.resetSize()}
					onMouseDown={header.getResizeHandler()}
					onTouchStart={header.getResizeHandler()}
					className={cn(
						"absolute right-px top-0 h-full w-[5px] cursor-col-resize touch-none select-none bg-core-primary-button-fill opacity-0 hover:opacity-100",
						header.column.getIsResizing() &&
							"bg-core-primary-button-hover opacity-100",
					)}
				/>
			)}
		</TableHead>
	);
}
