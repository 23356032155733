import saveAs from "file-saver";

import api from "@/api";

export const getMetricsCsv = async (
	miningRegionId: string | undefined,
	mineSiteId: string | undefined,
	date: Date | undefined,
) => {
	const url = "/api/CsvExports/metrics";
	const params = {
		miningRegion: miningRegionId,
		mineSite: mineSiteId,
		timeSeriesDate: date,
	};
	await saveBlob(url, params, "Metrics");
};

export const getCriteriaCsv = async (
	miningRegionId: string | undefined,
	mineSiteId: string | undefined,
	date: Date | undefined,
) => {
	const url = "/api/CsvExports/criteria";
	const params = {
		miningRegion: miningRegionId,
		mineSite: mineSiteId,
		timeSeriesDate: date,
	};
	await saveBlob(url, params, "Criteria");
};

export const getPolygonCriteria = async (
	rehabPolyId: string,
	timeSeriesDate: Date | undefined,
) => {
	const url = "api/CsvExports/polygon-criteria-outcomes";
	const params = {
		rehabilitationPolygonId: rehabPolyId,
		timeSeriesDate: timeSeriesDate,
	};
	await saveBlob(url, params, "PolygonCriteria");
};

export const getPolygonVegCovers = async (
	rehabPolyId: string | undefined,
	analoguePolyId: string | undefined,
) => {
	const url = "api/CsvExports/polygon-veg-covers";
	const params = {
		rehabilitationPolygonId: rehabPolyId,
		analoguePolygonId: analoguePolyId,
	};
	await saveBlob(url, params, "VegetationCoverHistory");
};

export const getPolygonTreatments = async (rehabPolyId: string) => {
	const url = "api/CsvExports/polygon-treatments";
	const params = {
		rehabPolygonId: rehabPolyId,
	};
	await saveBlob(url, params, "PolygonTreatments");
};

async function saveBlob(url: string, params: unknown, filePrefix: string) {
	const response = await api().get(url, {
		params,
		responseType: "blob",
		headers: {
			Accept: "application/octet-stream",
		},
	});
	const blob = new Blob([response.data], {
		type: "application/octet-stream",
	});
	const nowString = new Date().toISOString();
	saveAs(blob, `${filePrefix}_${nowString}.csv`);
}
