import client from "@kubb/plugin-client/client";
import type { FloraSpeciesGetFloraSpeciesForSeedApplicationWithPaginationMutationRequest, FloraSpeciesGetFloraSpeciesForSeedApplicationWithPaginationMutationResponse } from "../../ts/FloraSpeciesGetFloraSpeciesForSeedApplicationWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/FloraSpecies/for-seed-application
 */
export async function floraSpeciesGetFloraSpeciesForSeedApplicationWithPagination(data: FloraSpeciesGetFloraSpeciesForSeedApplicationWithPaginationMutationRequest, config: Partial<RequestConfig<FloraSpeciesGetFloraSpeciesForSeedApplicationWithPaginationMutationRequest>> = {}) {
    const res = await client<FloraSpeciesGetFloraSpeciesForSeedApplicationWithPaginationMutationResponse, Error, FloraSpeciesGetFloraSpeciesForSeedApplicationWithPaginationMutationRequest>({ method: "POST", url: `/api/FloraSpecies/for-seed-application`, data, ...config });
    return res.data;
}