import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";

import { MultiSelect } from "@/components/multi-select";
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { storageLocationsGetStorageLocations } from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

type Props<T extends FieldValues> = {
	name: Path<T>;
	label: string;
	description?: string;
	className?: string;
	modal?: boolean;
	disabled?: boolean;
};

export const SelectStorageFormField = <T extends FieldValues>({
	name,
	label,
	description,
	className,
	...props
}: Props<T>) => {
	const {
		control,
		formState: { disabled },
	} = useFormContext<T>();
	const { data } = useQuery({
		queryKey: ["storageLocationsGetStorageLocations", { PageSize: -1 }],
		queryFn: async () =>
			storageLocationsGetStorageLocations({ PageSize: -1 }),
		staleTime: 10000,
	});
	const options = useMemo(() => {
		return (
			data?.items?.flatMap((s) => {
				if (!s.name) return [];
				return [{ id: s.id, value: s.id, label: s.name }];
			}) ?? []
		);
	}, [data]);

	return (
		<FormField
			key={name}
			control={control}
			name={name}
			render={({ field }) => {
				const selected = options.find(
					(option) => option.value === field.value,
				);
				return (
					<FormItem className={cn("", className)}>
						<FormLabel>{label}</FormLabel>
						<FormControl>
							<MultiSelect
								className="w-full"
								options={options}
								selected={selected}
								onChange={(option) => {
									field.onChange(option?.value);
								}}
								modal={!!props.modal}
								disabled={props.disabled ?? disabled}
							/>
						</FormControl>
						<FormDescription>{description}</FormDescription>
						<FormMessage />
					</FormItem>
				);
			}}
		/>
	);
};
