import { useMemo } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";

import {
	MultiSelect,
	MultiSelectProps,
	SingleSelectProps,
} from "@/components/multi-select";
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { AreOptionTypes } from "@/lib/utils/helpers";
import { OptionType } from "@/types/tyton-types";

type SelectProps<
	TForm extends FieldValues,
	TOption extends { toString: () => string },
> = {
	name: Path<TForm>;
	label: string;
	options: TOption[] | OptionType<TOption>[];
	description?: string;
	modal?: boolean;
	disabled?: boolean;
	className?: string;
	isMulti?: boolean;
};

export function SelectFormControl<
	TForm extends FieldValues,
	TOption extends { toString: () => string },
>({ ...props }: SelectProps<TForm, TOption>) {
	const {
		control,
		formState: { disabled },
	} = useFormContext<TForm>();
	const options = useMemo(() => {
		if (AreOptionTypes(props.options)) {
			return props.options;
		}
		return props.options.map((option): OptionType<TOption> => {
			return {
				id: option.toString(),
				label: option.toString(),
				value: option,
			};
		});
	}, [props.options]);

	return (
		<FormField
			key={props.name}
			control={control}
			name={props.name}
			render={({ field }) => {
				let selectProps:
					| SingleSelectProps<TOption>
					| MultiSelectProps<TOption>;
				if (props.isMulti) {
					selectProps = {
						isMulti: true,
						selected: options.filter((option) =>
							field.value?.includes(option.value),
						),
						onChange: (v) => {
							field.onChange(v.map((o) => o.value));
						},
					};
				} else {
					selectProps = {
						isMulti: false,
						selected: options.find(
							(option) => option.value === field.value,
						),
						onChange: (v) => {
							field.onChange(v?.value);
						},
					};
				}
				return (
					<FormItem className={cn("", props.className)}>
						<FormLabel>{props.label}</FormLabel>
						<FormControl>
							<MultiSelect
								className="w-full"
								commandClassName="bg-background"
								options={options}
								modal={!!props.modal}
								disabled={props.disabled ?? disabled}
								{...selectProps}
							/>
						</FormControl>
						<FormDescription>{props.description}</FormDescription>
						<FormMessage />
					</FormItem>
				);
			}}
		/>
	);
}
