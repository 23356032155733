import { ColumnPinningState } from "@tanstack/react-table";
import { atom } from "jotai";
import { atomFamily, atomWithStorage, createJSONStorage } from "jotai/utils";

export interface TableSettings {
	columnVisibility: Record<string, boolean>;
	columnOrder: string[];
	columnSizing: Record<string, number>;
	columnPinning: ColumnPinningState;
}

const _TableSettingsAtomFamily = atomFamily(
	(key: string) =>
		atomWithStorage<TableSettings>(
			`table-settings-${key}`,
			{
				columnVisibility: {},
				columnOrder: [],
				columnSizing: {},
				columnPinning: {},
			},
			createJSONStorage(() => localStorage),
			{ getOnInit: true },
		),
	(param1, param2) => param1 === param2,
);
export const getTableSettingsAtom = (key: string) =>
	atom(
		(get) => {
			return get(_TableSettingsAtomFamily(key));
		},
		(
			_get,
			set,
			setter: TableSettings | ((prev: TableSettings) => TableSettings),
		) => {
			set(_TableSettingsAtomFamily(key), setter);
		},
	);
