import { useAtom, useAtomValue } from "jotai";

import { WeedGridVisibility } from "@/atoms/map/layerVisibilityAtoms";
import {
	AutoSelectedVegClassAtomEffect,
	SelectedWeedVegetationClassAtom,
	WeedGridSizeAtom,
	WeedGridSizeLocked,
	WeedsVegetationClassesAtom,
} from "@/atoms/weedsAtoms";
import Icon from "@/components/icons/icon";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { GridSize } from "@/web-api-client";

const WeedNavBarControls: React.FC = () => {
	const { data: vegClasses } = useAtomValue(WeedsVegetationClassesAtom);
	const [selectedVegClass, setSelectedVegClass] = useAtom(
		SelectedWeedVegetationClassAtom,
	);
	const [weedGridVisibility, setWeedGridVisibility] =
		useAtom(WeedGridVisibility);
	const weedGridSize = useAtomValue(WeedGridSizeAtom);
	const [weedGridSizeLocked, setWeedGridSizeLocked] =
		useAtom(WeedGridSizeLocked);

	useAtom(AutoSelectedVegClassAtomEffect);

	return (
		<div className="-ml-32 flex flex-row gap-2 py-1">
			<div className="border-r px-1">
				<Button
					variant={weedGridVisibility ? "default" : "outline"}
					size={"icon"}
					onClick={() => {
						setWeedGridVisibility(!weedGridVisibility);
					}}
				>
					<Icon name="grid_toggle" />
				</Button>
			</div>
			<div
				className={cn(
					"flex flex-row rounded-sm border-2 border-core-primary-border p-1",
					weedGridSizeLocked
						? "bg-core-secondary-background"
						: "bg-core-primary-background",
				)}
			>
				<Button
					variant={"min"}
					size={"min"}
					onClick={() => {
						setWeedGridSizeLocked(!weedGridSizeLocked);
					}}
				>
					<Label className="cursor-pointer text-sm">
						Grid size
						{weedGridSize === GridSize.FourHundredMetres
							? " 400M"
							: weedGridSize === GridSize.TwoHundredMetres
								? " 200M"
								: weedGridSize === GridSize.OneHundredMetres
									? " 100M"
									: " 50M"}
					</Label>

					<Icon
						className="ml-2 size-4"
						name={weedGridSizeLocked ? "Lock" : "LockOpen"}
					/>
				</Button>
			</div>
			<div className="w-[200px] bg-core-primary-background">
				<Select
					value={selectedVegClass?.id}
					onValueChange={(e) => {
						const vegClass = vegClasses?.find((vc) => vc.id === e);
						setSelectedVegClass(vegClass);
					}}
				>
					<SelectTrigger className="h-8 border-2 border-core-primary-border bg-core-primary-background">
						<SelectValue />
					</SelectTrigger>
					<SelectContent position="popper">
						{vegClasses?.map((vc) => (
							<SelectItem key={vc.id} value={vc.id}>
								<div className="flex">{vc.name}</div>
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};

export default WeedNavBarControls;
