import { z } from "zod";

import type { CrsUri } from "@/lib/gen/titiler/types/CrsUri";

/**
 * @description Coordinate Reference System (CRS) from URI.
 */
export const crsUriSchema = z
	.object({
		uri: z
			.string()
			.url()
			.min(1)
			.describe(
				"Reference to one coordinate reference system (CRS) as URI",
			),
	})
	.describe(
		"Coordinate Reference System (CRS) from URI.",
	) as z.ZodType<CrsUri>;
