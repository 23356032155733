import { OptionType } from "@/types/tyton-types";

export const IsOptionType = <TType>(
	option: OptionType<TType> | TType,
): option is OptionType<TType> => {
	if (typeof option !== "object" || option == null) {
		return false;
	}
	return "id" in option && "value" in option && "label" in option;
};

export const AreOptionTypes = <TType>(
	options: OptionType<TType>[] | TType[],
): options is OptionType<TType>[] => {
	return options[0] != null && options.length > 0 && IsOptionType(options[0]);
};
