import { useSetAtom } from "jotai";
import { FileIcon, FileImageIcon, FileSpreadsheetIcon } from "lucide-react";
import { tv } from "tailwind-variants";

import { DrawerIsOpenAtom, DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import {
	ExportFilteredAtom,
	ExportFilteredImagesAtom,
} from "@/atoms/export.ts";
import { ExportContext } from "@/components/data-table/table-types";
import { IconName } from "@/components/icons";
import Icon from "@/components/icons/icon.tsx";
import { PopoutButton, useInWindow } from "@/components/PoppedOutWindows";
import { Button } from "@/components/ui/button";
import {
	Menubar,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarTrigger,
} from "@/components/ui/menubar";
import { cn } from "@/lib/utils";

interface TableHeaderProps {
	moduleType?:
		| "monitoring"
		| "site"
		| "health"
		| "seeds"
		| "weeds"
		| "topsoil"
		| "erosion"
		| "fauna"
		| "carbon";
	icon?: IconName;
	title: string;
	exportContext?: ExportContext;
	onPopout: () => void;
	tabType?: DrawerTabEnum;
}

const TableHeaderVarients = tv({
	base: "flex flex-row items-center justify-between border p-1",
	variants: {
		moduleType: {
			site: "border-module-site-border bg-module-site-header",
			monitoring:
				"border-module-monitoring-border bg-module-monitoring-header",
			health: "border-module-health-border bg-module-health-header",
			seeds: "border-module-seeds-border bg-module-seeds-header",
			weeds: "border-module-weeds-border bg-module-weeds-header",
			topsoil: "border-module-topsoil-border bg-module-topsoil-header",
			erosion: "border-module-erosion-border bg-module-erosion-header",
			fauna: "border-module-fauna-border bg-module-fauna-header",
			carbon: "border-module-carbon-border bg-module-carbon-header",
		},
	},
	defaultVariants: {
		moduleType: "monitoring",
	},
});

const TableMenu: React.FC<TableHeaderProps> = ({
	moduleType,
	icon,
	title,
	exportContext,
	onPopout,
	tabType,
}) => {
	const exportFiltered = useSetAtom(ExportFilteredAtom);
	const exportFilteredImages = useSetAtom(ExportFilteredImagesAtom);
	const setDrawerIsOpen = useSetAtom(DrawerIsOpenAtom);
	const isInWindow = useInWindow();

	return (
		<div
			className={cn(
				TableHeaderVarients({
					moduleType: `${moduleType ?? "monitoring"}`,
				}),
				"gap-1 rounded-t",
			)}
		>
			<div
				className={cn(
					"flex w-full cursor-pointer flex-row rounded bg-white bg-opacity-0 p-1 hover:bg-opacity-15",
				)}
				onClick={() => {
					setDrawerIsOpen(false);
				}}
			>
				<div className="flex items-center gap-2 font-dmSans font-medium leading-4 text-white">
					<Icon
						name={icon ?? "rehabilitation"}
						className="size-5 items-center"
					/>
					<span className="text-nowrap">{title}</span>
				</div>
			</div>

			<div className="flex gap-1">
				{!isInWindow && tabType != null && (
					<PopoutButton tabType={tabType} onBeforePopout={onPopout} />
				)}

				{exportContext && (
					<Menubar className="flex">
						<MenubarMenu>
							<MenubarTrigger asChild>
								<Button
									variant="ghost"
									className="flex h-6 gap-1 p-2 font-roboto font-medium text-white"
								>
									<Icon name="data_export" />
									<span>Export</span>
								</Button>
							</MenubarTrigger>
							<MenubarContent className="bg-core-primary-background">
								<MenubarItem
									className="cursor-pointer"
									onClick={() =>
										exportFiltered(
											exportContext,
											"Shapefile",
										)
									}
								>
									<FileIcon className="mr-1 h-5" />
									Export to Shapefile
								</MenubarItem>
								<MenubarItem
									className="cursor-pointer"
									onClick={() =>
										exportFiltered(exportContext, "Csv")
									}
								>
									<FileSpreadsheetIcon className="mr-1 h-5" />
									Export to CSV
								</MenubarItem>
								{exportContext === "siteinspections" && (
									<MenubarItem
										className="cursor-pointer"
										onClick={() =>
											exportFilteredImages(exportContext)
										}
									>
										<FileImageIcon className="mr-1 h-5" />
										Export Images
									</MenubarItem>
								)}
							</MenubarContent>
						</MenubarMenu>
					</Menubar>
				)}
			</div>
		</div>
	);
};

export default TableMenu;
