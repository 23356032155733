import { atom } from "jotai";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import {
	GetFloraSpeciesForSeedApplicationWithPaginationQuery,
	GetFloraSpeciesWithPaginationQuery,
} from "@/lib/gen/eis";

export type FloraSpeciesArg = keyof GetFloraSpeciesWithPaginationQuery;

export const FloraSpeciesFiltersAtom = atom<ColumnFilter<FloraSpeciesArg>[]>(
	[],
);

export type FloraSpeciesForSeedApplicationArg =
	keyof GetFloraSpeciesForSeedApplicationWithPaginationQuery;

export const FloraSpeciesForSeedApplicationReplaceFiltersAtom = atom<
	ColumnFilter<FloraSpeciesForSeedApplicationArg>[]
>([]);

export const FloraSpeciesForSeedApplicationAddFiltersAtom = atom<
	ColumnFilter<FloraSpeciesForSeedApplicationArg>[]
>([]);
