import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router";

import { Button } from "@/components/ui/button";

type ErrorResponse = {
	data: unknown;
	status: number;
	statusText: string;
	message?: string;
};

export function ErrorPage() {
	const rawError = useRouteError();
	const error = rawError as ErrorResponse;

	useEffect(() => {
		Sentry.captureException(rawError);
	}, [rawError]);

	return (
		<div className="py-20">
			<div className="mb-10 text-center text-9xl font-extrabold text-gray-500 lg:text-8xl">
				{error?.status}
			</div>
			<h1 className="mb-10 text-center text-4xl font-extrabold lg:text-5xl">
				{error?.statusText}
			</h1>
			<p className="mb-10 text-center text-lg text-gray-500">
				{error?.message}
			</p>
			<div className="flex justify-center">
				<Button
					variant={"default"}
					className="rounded border border-gray-300 px-4 py-2 text-gray-700"
					onClick={() => window.location.reload()}
				>
					Take me back
				</Button>
			</div>
		</div>
	);
}
