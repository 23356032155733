import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import {
	WeedGridCellArg,
	WeedGridCellFiltersAtom,
	WeedGridSizeAtom,
	WeedsSurvey1Atom,
} from "@/atoms/weedsAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { DataTableSmallTextCell } from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { EisColumnSort } from "@/components/data-table/table-types";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useInWindow } from "@/components/PoppedOutWindows";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	WeedGridCellOrderBy,
	WeedGridCellPagedDto,
	weedsSurveysGetWeedGridCellsPaginated,
	weedsSurveysGetWeedsVegetationClassesQuery,
} from "@/lib/gen/eis";
import {
	getWeedGridCellDynamicFilterVegClassCover,
	WEED_GRID_CELL_FILTER_OPTION_GRID_SIZE,
	WEED_GRID_CELL_FILTER_OPTION_WEED_ALERT,
	WEED_GRID_CELL_FILTER_OPTIONS,
} from "@/types/filters/weed-grid-cell-filters";
import { GridSize, WeedAlert } from "@/web-api-client";

import { getWeedGridVegCoverColumn } from "./weed-grid-columns";
import { WeedAlertTableCell } from "./weed-grid-table-cells";

const WeedGridCellTableCard = () => {
	const [survey1Atom] = useAtom(WeedsSurvey1Atom);
	const [gridSize] = useAtom(WeedGridSizeAtom);

	const [columnSort, setColumnSort] = useState<EisColumnSort>({
		id: "WeedAlert",
		desc: true,
	});
	const [dynamicFilters, setDynamicFilters] = useState<
		ColumnFilterOption<WeedGridCellArg>[]
	>([]);
	const [weedGridFilters, setWeedGridFilters] = useFilters(
		WeedGridCellFiltersAtom,
	);

	const inWindow = useInWindow();
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setWeedGridFilters,
			WEED_GRID_CELL_FILTER_OPTION_GRID_SIZE,
			GridSize.FourHundredMetres,
		);
		autoSetFilter(
			setWeedGridFilters,
			WEED_GRID_CELL_FILTER_OPTION_WEED_ALERT,
			WeedAlert.NoAlert,
			"NOT_EQUALS",
		);
	}, [inWindow, setWeedGridFilters]);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ["WeedGridCells", weedGridFilters, columnSort],
		queryFn: async ({ pageParam }) => {
			return weedsSurveysGetWeedGridCellsPaginated({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: (columnSort.orderById ??
					columnSort.id) as WeedGridCellOrderBy,
				orderByDynamicColumn: columnSort.isDynamicColumn
					? columnSort.id
					: undefined,
				descending: columnSort.desc,
				...parseFilterAguments(weedGridFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
		enabled: !!survey1Atom && !!gridSize,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const initialColumns = useMemo<ColumnDef<WeedGridCellPagedDto>[]>(() => {
		return [
			{
				accessorKey: "name",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Name"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell value={row.original?.name ?? ""} />
				),
				enableSorting: true,
				enableHiding: false,
				size: 120,
			},
			{
				accessorKey: "weedAlert",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Weed Alert"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<WeedAlertTableCell
						weedAlert={row.original?.weedAlert ?? WeedAlert.NoAlert}
					/>
				),
				enableSorting: true,
				enableHiding: false,
				size: 120,
				meta: {
					title: "Weed Alert",
				},
			},
		];
	}, []);

	const [columns, setColumns] =
		useState<ColumnDef<WeedGridCellPagedDto, unknown>[]>(initialColumns);

	const vegClassesQuery = useQuery({
		queryKey: ["weedsSurveysGetWeedsVegetationClassesQuery"],
		queryFn: async () => weedsSurveysGetWeedsVegetationClassesQuery({}),
	});

	const vegClasses = useMemo(
		() => vegClassesQuery.data ?? [],
		[vegClassesQuery.data],
	);

	useEffect(() => {
		setColumns([
			...initialColumns,
			...(vegClasses
				?.sort(
					(a, b) =>
						(a.displayPosition ?? 999) - (b.displayPosition ?? 999),
				)
				.map((vc) =>
					getWeedGridVegCoverColumn(vc.name ?? "", setColumnSort),
				) ?? []),
		]);
	}, [initialColumns, vegClasses]);

	useEffect(() => {
		setDynamicFilters([
			...(vegClasses?.map((vc) =>
				getWeedGridCellDynamicFilterVegClassCover(vc),
			) ?? []),
		]);
	}, [vegClasses]);

	return (
		<div className="flex h-full flex-wrap">
			<BottomDrawDataTable
				columns={columns}
				data={rows}
				totalRows={totalRows}
				getRowId={(row) => row.id}
				title="Weed Grid Cells"
				moduleType="weeds"
				icon="weeds"
				isLoading={isLoading}
				fetch={{
					isFetching,
					isFetchingNextPage,
					fetchNextPage,
					hasNextPage,
				}}
				filteredSearchBox={{
					options: [
						...WEED_GRID_CELL_FILTER_OPTIONS,
						...dynamicFilters,
					],
					filters: weedGridFilters,
					setFilters: setWeedGridFilters,
				}}
				tabType={DrawerTabEnum.WEED_GRID_TABLE}
			/>
		</div>
	);
};

export default WeedGridCellTableCard;
