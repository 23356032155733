import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Collection } from "ol";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

import { ImageryUrlAtom } from "@/atoms/map/imageryUrlAtoms";
import { CreateImageryXyzLayer } from "@/atoms/map/layerAtoms";
import { ArcGisTokenQueryAtom } from "@/atoms/mapAtoms";

import {
	ImageryAtomEffectFunction,
	PopulateImageryLayer,
} from "./imagery-layer-helpers";

export const ImageryLayerGroupAtom = atom(() => {
	return new LayerGroup({ layers: new Collection<TileLayer<XYZ>>() });
});

export const ImageryLayerAtomEffect = atomEffect((get) => {
	const urls = get(ImageryUrlAtom);
	const group = get(ImageryLayerGroupAtom);

	ImageryAtomEffectFunction(get, group, urls, (get, img) => {
		const { data: arcgisToken } = get(ArcGisTokenQueryAtom);
		const { source, bounds } = PopulateImageryLayer(get, img, arcgisToken);
		if (!source) return;
		return CreateImageryXyzLayer(source, {
			extent: bounds,
		});
	});
});

const _imageryVisibility = atom(true);
export const ImageryVisibility = atom(
	(get) => {
		return get(_imageryVisibility);
	},
	(get, set, value?: boolean) => {
		const layer = get(ImageryLayerGroupAtom);
		const bool = value ?? !layer.getVisible();
		set(_imageryVisibility, bool);
		layer.setVisible(bool);
	},
);
