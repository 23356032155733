import React from "react";

import { EisPermission } from "@/atoms/authAtoms";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LazyCardComponent<T = any> = {
	component: React.LazyExoticComponent<React.FC<T>>;
	permission: EisPermission | undefined;
};

export const GetCardComponent = <T>(
	comp: LazyCardComponent<T>,
	userPerms: EisPermission[] | undefined,
) => {
	if (!comp) return null;
	if (comp.permission && !userPerms?.some((p) => p === comp.permission))
		return null;
	return comp.component;
};
