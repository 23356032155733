import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import Swipe from "ol-ext/control/Swipe";

export const SwipeBarAtom = atom(
	new Swipe({
		layers: [],
		position: 0.0001,
		className: "swipe-bar",
	}),
);

const _toggleSwipeBarAtom = atom(true);

export const ToggleSwipeBarAtom = atom(
	(get) => get(_toggleSwipeBarAtom),
	(_get, set) => {
		set(_toggleSwipeBarAtom, (prev) => !prev);
	},
);

export const ToggleSwipeBarAtomEffect = atomEffect((get) => {
	const toggleSwipeBar = get(ToggleSwipeBarAtom);
	const swipeBar = get(SwipeBarAtom);

	if (toggleSwipeBar) {
		swipeBar.setProperties({ position: 0.5 });
	} else {
		swipeBar.setProperties({ position: -1 });
	}
});
