import client from "@kubb/plugin-client/client";
import type { ErosionGetFilteredExportMutationRequest, ErosionGetFilteredExportMutationResponse } from "../../ts/ErosionGetFilteredExport.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Erosion/filtered-export
 */
export async function erosionGetFilteredExport(data: ErosionGetFilteredExportMutationRequest, config: Partial<RequestConfig<ErosionGetFilteredExportMutationRequest>> = {}) {
    const res = await client<ErosionGetFilteredExportMutationResponse, Error, ErosionGetFilteredExportMutationRequest>({ method: "POST", url: `/api/Erosion/filtered-export`, data, ...config });
    return res.data;
}