import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnSort } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { ErosionFeaturesFiltersAtom } from "@/atoms/erosionAtoms";
import {
	SelectedMineSiteAtom,
	SelectedMiningRegionAtom,
} from "@/atoms/miningAtoms";
import { SelectedRehabPolygonAtom } from "@/atoms/rehabPolyAtoms";
import {
	TimeSeriesDateAtom,
	TimeSeriesMarkersAtom,
} from "@/atoms/timeseriesAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useInWindow } from "@/components/PoppedOutWindows";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	ErosionFeaturesOrderBy,
	erosionGetErosionFeaturesPaginated,
} from "@/lib/gen/eis";
import {
	EROSION_POLYGON_FILTER_OPTION_MINE_SITE,
	EROSION_POLYGON_FILTER_OPTION_MINING_REGION,
	EROSION_POLYGON_FILTER_OPTION_REHAB_POLYGON_NAME,
	EROSION_POLYGON_FILTER_OPTION_REHABILITATION_SURVEY,
	EROSION_POLYGON_FILTER_OPTIONS,
} from "@/types/filters/erosion-feature-filters";

import { ErosionColumns } from "./erosion-columns";

const ErosionFeaturesTableCard = () => {
	const timeSeriesDate = useAtomValue(TimeSeriesDateAtom);
	const timeSeriesMarkers = useAtomValue(TimeSeriesMarkersAtom);

	const [columnSort, setColumnSort] = useState<ColumnSort>({
		id: "Name",
		desc: false,
	});
	const [erosionFeaturesFilters, setErosionFeaturesFilters] = useFilters(
		ErosionFeaturesFiltersAtom,
	);

	// Auto-set mine site filter
	const inWindow = useInWindow();
	const mineSite = useAtomValue(SelectedMineSiteAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setErosionFeaturesFilters,
			EROSION_POLYGON_FILTER_OPTION_MINE_SITE,
			mineSite
				? [{ label: mineSite.mineSiteName, value: mineSite.id }]
				: undefined,
		);
	}, [inWindow, mineSite, setErosionFeaturesFilters]);
	const region = useAtomValue(SelectedMiningRegionAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setErosionFeaturesFilters,
			EROSION_POLYGON_FILTER_OPTION_MINING_REGION,
			region ? [{ label: region.name, value: region.id }] : undefined,
		);
	}, [inWindow, region, setErosionFeaturesFilters]);

	// Auto-set rehab survey filter
	const surveyReport = useMemo(
		() =>
			timeSeriesMarkers?.find(
				(rs) =>
					rs.mineSiteId === mineSite?.mineSiteId &&
					dayjs(rs.captureDate, { utc: true }).unix() ===
						timeSeriesDate.unix(),
			),
		[mineSite?.mineSiteId, timeSeriesDate, timeSeriesMarkers],
	);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setErosionFeaturesFilters,
			EROSION_POLYGON_FILTER_OPTION_REHABILITATION_SURVEY,
			surveyReport
				? [{ label: surveyReport?.reportTitle, value: surveyReport.id }]
				: undefined,
		);
	}, [inWindow, setErosionFeaturesFilters, surveyReport]);

	// Auto-set rehab polygon filter on click polygon
	const selectedRehabPolygon = useAtomValue(SelectedRehabPolygonAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setErosionFeaturesFilters,
			EROSION_POLYGON_FILTER_OPTION_REHAB_POLYGON_NAME,
			selectedRehabPolygon?.name,
		);
	}, [inWindow, selectedRehabPolygon, setErosionFeaturesFilters]);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"Erosion",
			columnSort,
			erosionFeaturesFilters,
			timeSeriesDate,
		],
		queryFn: async ({ pageParam }) => {
			return erosionGetErosionFeaturesPaginated({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: columnSort.id as ErosionFeaturesOrderBy,
				descending: columnSort.desc,
				...parseFilterAguments(erosionFeaturesFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
		// enabled: !!mineSite?.mineSiteName,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const columns = useMemo(() => {
		return ErosionColumns({
			setColumnSort,
		});
	}, []);

	return (
		<BottomDrawDataTable
			columns={columns ?? []}
			data={rows}
			totalRows={totalRows}
			title="Erosion Features"
			moduleType="erosion"
			icon="erosion"
			isLoading={isLoading || columns == null || columns?.length <= 0}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			filteredSearchBox={{
				options: EROSION_POLYGON_FILTER_OPTIONS,
				filters: erosionFeaturesFilters,
				setFilters: setErosionFeaturesFilters,
			}}
			tabType={DrawerTabEnum.EROSION_FEATURE}
		/>
	);
};

export default ErosionFeaturesTableCard;
