import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Collection } from "ol";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

import { ErosionUrlAtom } from "@/atoms/map/imageryUrlAtoms";
import { CreateImageryXyzLayer } from "@/atoms/map/layerAtoms";
import { ArcGisTokenQueryAtom } from "@/atoms/mapAtoms";
import {
	ImageryAtomEffectFunction,
	PopulateImageryLayer,
} from "@/components/MapOpenLayers/imagery-layer-helpers";
import { SwipeBarAtom } from "@/components/MapOpenLayers/swipe-bar";

export const ErosionImageryGroupAtom = atom(() => {
	return new LayerGroup({ layers: new Collection<TileLayer<XYZ>>() });
});

export const ErosionImageryAtomEffect = atomEffect((get) => {
	const urls = get(ErosionUrlAtom);
	const group = get(ErosionImageryGroupAtom);
	const swipeBar = get(SwipeBarAtom);

	ImageryAtomEffectFunction(get, group, urls, (get, img) => {
		const { data: arcgisToken } = get(ArcGisTokenQueryAtom);
		const { source, bounds } = PopulateImageryLayer(get, img, arcgisToken);
		if (!source) return;
		const urls = source.getUrls();
		if (!source || !urls) return;
		const newUrls = urls?.flatMap((url) => {
			let newUrl = url;
			if (img.colorMapName) {
				newUrl = newUrl + `&colormap_name=${img.colorMapName}`;
			}
			newUrl = newUrl + `&bidx=1`;
			return [newUrl];
		});
		source.setUrls(newUrls);
		return CreateImageryXyzLayer(source, {
			extent: bounds,
			preload: 2,
		});
	});

	group.getLayers().forEach((l) => {
		if (l instanceof TileLayer) {
			swipeBar.addLayer(l, true);
		}
	});

	return () => {
		group.getLayers().forEach((l) => {
			if (l instanceof TileLayer) {
				swipeBar.removeLayer(l);
			}
		});
	};
});

const _erosionImageryVisibility = atom(true);
export const ErosionImageryVisibility = atom(
	(get) => {
		return get(_erosionImageryVisibility);
	},
	(get, set, value?: boolean) => {
		const layer = get(ErosionImageryGroupAtom);
		const bool = value ?? !layer.getVisible();
		set(_erosionImageryVisibility, bool);
		layer.setVisible(bool);
	},
);
