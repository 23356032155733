import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
	LayerSource,
	RehabPolyClusterLayerAtom,
	RehabPolyLayerAtom,
} from "@/atoms/map/layerAtoms";
import {
	RehabPolygonBBoxQueryAtom,
	RehabPolygonGeometriesQueryAtom,
	RehabPolygonIdsFilteredQueryAtom,
} from "@/atoms/map/mapQueryAtoms";
import { SelectedGeometryAtom } from "@/atoms/mapAtoms";
import {
	RehabPolyFiltersAtom,
	SelectedRehabPolygonIdAtom,
} from "@/atoms/rehabPolyAtoms";
import {
	populateClusterVectorLayer,
	populateVectorLayer,
} from "@/components/MapOpenLayers/vector-layer-populate";

export const RehabPolygonLayerAtomEffect = atomEffect((get) => {
	get(populateAtomEffect);
	get(populateClusterAtomEffect);
	get(filterAtomEffect);
	get(layerSourceAtomEffect);
	get(selectionAtomEffect);
	get(clusterLayerSourceAtomEffect);
});

export const RehabPolyLayerClusterSourceAtom = atom<LayerSource>({
	source: undefined,
});

export const RehabPolyLayerSourceAtom = atom<LayerSource>({
	source: undefined,
});

const layerSourceAtomEffect = atomEffect((get, set) => {
	const source = get(RehabPolyLayerAtom).getSource();
	if (!source) return;
	const onChange = () => {
		set(RehabPolyLayerSourceAtom, { source });
	};
	source.on("change", onChange);

	return () => {
		source.un("change", onChange);
	};
});

const selectionAtomEffect = atomEffect((get, set) => {
	const source = get(RehabPolyLayerSourceAtom).source;
	const selected = get(SelectedRehabPolygonIdAtom);
	if (selected == null || source == null) return;
	const feature = source.getFeatureById(selected);
	if (feature == null) return;
	feature?.set("selected", true);
	set(SelectedGeometryAtom, feature);
});

const populateAtomEffect = atomEffect((get, set) => {
	const rehabPolyGeometriesQuery = get(RehabPolygonGeometriesQueryAtom);
	if (rehabPolyGeometriesQuery.data == null) return;

	const data = rehabPolyGeometriesQuery.data;
	const layer = get(RehabPolyLayerAtom);

	populateVectorLayer({
		data,
		layer,
		source: "rehabpolygon",
		onUnselect: () => set(SelectedGeometryAtom, undefined),
		onSelect: (d, f) => {
			set(SelectedGeometryAtom, f);
			set(SelectedRehabPolygonIdAtom, d.id);
		},
	});
});

const clusterLayerSourceAtomEffect = atomEffect((get, set) => {
	const layer = get(RehabPolyClusterLayerAtom);
	const onChange = () => {
		set(RehabPolyLayerClusterSourceAtom, {
			source: layer.getSource()?.getSource() ?? undefined,
		});
	};
	layer.on("change", onChange);

	return () => {
		layer.un("change", onChange);
	};
});

const populateClusterAtomEffect = atomEffect((get) => {
	const { data: clusterData } = get(RehabPolygonBBoxQueryAtom);
	if (clusterData == null) return;

	const { data: filteredData } = get(RehabPolygonIdsFilteredQueryAtom);
	const filteredIds = filteredData?.ids ?? [];
	const data = filteredData?.ids
		? clusterData.filter((d) => filteredIds.indexOf(d.id) !== -1)
		: clusterData;

	const layer = get(RehabPolyClusterLayerAtom);

	populateClusterVectorLayer({
		data,
		layer,
		source: "rehabpolygonCluster",
	});
});

/** Update feature dimming on rehab polygon filter change */
const filterAtomEffect = atomEffect((get) => {
	const layer = get(RehabPolyLayerAtom);
	const source = layer.getSource();
	if (source == null) return;

	const filters = get(RehabPolyFiltersAtom);
	if (
		filters.length <= 0 ||
		(filters.length === 1 && filters[0].filter.key === "mineSiteName")
	) {
		source.forEachFeature((f) => f.unset("filtered"));
		return;
	}

	const filteredQuery = get(RehabPolygonIdsFilteredQueryAtom);
	const filteredIds = filteredQuery.data?.ids ?? [];

	source.forEachFeature((f) => {
		f.set("filtered", filteredIds.indexOf(f.get("id")) === -1);
	});
});
