export const seedApplicationOrderBy = {
    "Name": "Name",
    "RehabilitationPolygonName": "RehabilitationPolygonName",
    "MiningRegion": "MiningRegion",
    "Minesite": "Minesite",
    "provenanceZone": "provenanceZone",
    "NumberOfFloraSpecies": "NumberOfFloraSpecies",
    "Quantity": "Quantity",
    "NumberOfSeedBatches": "NumberOfSeedBatches",
    "StartDate": "StartDate",
    "EndDate": "EndDate",
    "DateCreated": "DateCreated",
    "Status": "Status",
    "Note": "Note"
} as const;

 export type SeedApplicationOrderByEnum = (typeof seedApplicationOrderBy)[keyof typeof seedApplicationOrderBy];

 export type SeedApplicationOrderBy = SeedApplicationOrderByEnum;