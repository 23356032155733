import { atom, WritableAtom } from "jotai";
import { atomEffect } from "jotai-effect";

import { AnalogueClassificationLayerVisibility } from "@/components/MapOpenLayers/analogue-classification-layer";
import { ClassificationLayerVisibility } from "@/components/MapOpenLayers/classification-layer";
import { ErosionImageryVisibility } from "@/components/MapOpenLayers/Erosion/erosion-imagery-layer";
import { MsaviLayerVisibility } from "@/components/MapOpenLayers/Health/msavi-layer";
import { ImageryVisibility } from "@/components/MapOpenLayers/imagery-layer";

import { atomWithToggle } from "./../atomUtils";
import { CurrentViewAtom } from "./../eisViewAtoms";
import {
	AnaloguePolyLayerAtom,
	BareAreasVectorLayerAtom,
	ErosionClusterLayerAtom,
	ErosionVectorLayerAtom,
	HealthGridLayerAtom,
	HealthZoneLayerAtom,
	IndividualTreeLayerAtom,
	MineSiteLayerAtom,
	RehabPolyClusterLayerAtom,
	RehabPolyLayerAtom,
	SamplingSiteLayerAtom,
	SiteInspectionLayerAtom,
	TopsoilStockpileLayerAtom,
	WeedGridLayerAtom,
} from "./layerAtoms";

export const enum MapLayer {
	Imagery = "Imagery",
	Classification = "Classification",
	AnalogueClassification = "AnalogueClassification",
	MSAVI = "MSAVI",
	MineSiteVector = "MineSiteVector",
	RehabPolygonVector = "RehabPolygonVector",
	AnaloguePolygonVector = "AnaloguePolygonVector",
	SamplingSiteVector = "SamplingSiteVector",
	SiteInspectionVector = "SiteInspectionVector",
	WeedPatchVector = "WeedPatchVector",
	WeedGrid = "WeedGrid",
	ErosionRaster = "ErosionRaster",
	ErosionVector = "ErosionVector",
	BareAreas = "BareAreas",
	HealthZoneVector = "HealthZoneVector",
	HealthGridVector = "HealthGridVector",
	TopsoilStockpileVector = "TopsoilStockpileVector",
	IndividualTreeVector = "IndividualTreeVector",
}

export const ClassificationDisabled = atom((get) => {
	const classify = get(ClassificationLayerVisibility);
	const analogue = get(AnalogueClassificationLayerVisibility);
	return !classify && !analogue;
});

export const MineSiteVisibility = atomWithToggle(true);
export const SetMineSiteVisibleEffect = atomEffect((get) => {
	const visible = get(MineSiteVisibility);
	const layer = get(MineSiteLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const RehabPolygonsVisibility = atomWithToggle(true);
export const SetRehabPolygonsVisibleEffect = atomEffect((get) => {
	const visible = get(RehabPolygonsVisibility);
	const layer = get(RehabPolyLayerAtom);
	const clusterLayer = get(RehabPolyClusterLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}

	const module = get(CurrentViewAtom); // Hide rehab poylgon cluster layer when in erosion module
	const clusterVisible = visible && module?.name !== "Erosion";
	if (clusterLayer.getVisible() !== clusterVisible) {
		clusterLayer.setVisible(clusterVisible);
	}
});

export const AnaloguePolygonsVisibility = atomWithToggle(true);
export const SetAnaloguePolygonsVisibleEffect = atomEffect((get) => {
	const visible = get(AnaloguePolygonsVisibility);
	const layer = get(AnaloguePolyLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const SamplingSitesVisibility = atomWithToggle(true);
export const SetSamplingSitesVisibleEffect = atomEffect((get) => {
	const visible = get(SamplingSitesVisibility);
	const layer = get(SamplingSiteLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const SiteInspectionVisibility = atomWithToggle(true);
export const SetSiteInpsectionsVisibleEffect = atomEffect((get) => {
	const visible = get(SiteInspectionVisibility);
	const layer = get(SiteInspectionLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const WeedPatchesVisibility = atomWithToggle(true);
export const WeedGridVisibility = atomWithToggle(true);
export const SetWeedGridVisibleEffect = atomEffect((get) => {
	const visible = get(WeedGridVisibility);
	const layer = get(WeedGridLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const ErosionVectorVisiblity = atomWithToggle(true);
export const SetErosionVectorVisiblityEffect = atomEffect((get) => {
	const visible = get(ErosionVectorVisiblity);
	const layer = get(ErosionVectorLayerAtom);
	const clusterLayer = get(ErosionClusterLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
	if (clusterLayer.getVisible() !== visible) {
		clusterLayer.setVisible(visible);
	}
});

export const BareAreasVisibility = atomWithToggle(true);

export const SetBareAreasVisibileEffect = atomEffect((get) => {
	const visible = get(BareAreasVisibility);
	const layer = get(BareAreasVectorLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const HealthZoneVisibility = atomWithToggle(true);
export const SetHealthZonesVisibleEffect = atomEffect((get) => {
	const visible = get(HealthZoneVisibility);
	const layer = get(HealthZoneLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const HealthGridVisibility = atomWithToggle(true);
export const SetHealthGridsVisibleEffect = atomEffect((get) => {
	const visible = get(HealthGridVisibility);
	const layer = get(HealthGridLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const TopsoilStockpileVisibility = atomWithToggle(true);
export const SetTopsoilStockpileVisibleEffect = atomEffect((get) => {
	const visible = get(TopsoilStockpileVisibility);
	const layer = get(TopsoilStockpileLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

export const IndividualTreeVisibility = atomWithToggle(false);
export const SetIndividualTreeVisibleEffect = atomEffect((get) => {
	const visible = get(IndividualTreeVisibility);
	const layer = get(IndividualTreeLayerAtom);

	if (layer.getVisible() !== visible) {
		layer.setVisible(visible);
	}
});

const VisibilityLayers = {
	[MapLayer.Imagery]: ImageryVisibility,
	[MapLayer.Classification]: ClassificationLayerVisibility,
	[MapLayer.AnalogueClassification]: AnalogueClassificationLayerVisibility,
	[MapLayer.MSAVI]: MsaviLayerVisibility,
	[MapLayer.MineSiteVector]: MineSiteVisibility,
	[MapLayer.RehabPolygonVector]: RehabPolygonsVisibility,
	[MapLayer.AnaloguePolygonVector]: AnaloguePolygonsVisibility,
	[MapLayer.SamplingSiteVector]: SamplingSitesVisibility,
	[MapLayer.SiteInspectionVector]: SiteInspectionVisibility,
	[MapLayer.WeedPatchVector]: WeedPatchesVisibility,
	[MapLayer.WeedGrid]: WeedGridVisibility,
	[MapLayer.ErosionRaster]: ErosionImageryVisibility,
	[MapLayer.ErosionVector]: ErosionVectorVisiblity,
	[MapLayer.BareAreas]: BareAreasVisibility,
	[MapLayer.HealthZoneVector]: HealthZoneVisibility,
	[MapLayer.HealthGridVector]: HealthGridVisibility,
	[MapLayer.TopsoilStockpileVector]: TopsoilStockpileVisibility,
	[MapLayer.IndividualTreeVector]: IndividualTreeVisibility,
} as const satisfies Record<
	MapLayer,
	WritableAtom<boolean, [value?: boolean | undefined], void>
>;

export const SetMapLayerStatesAtomEffect = atomEffect((get, set) => {
	const tytonEisView = get(CurrentViewAtom);

	if (tytonEisView) {
		const layersUpdated = new Set<MapLayer>();
		for (const layer of tytonEisView.layers) {
			set(VisibilityLayers[layer], true);
			layersUpdated.add(layer);
		}
		for (const [key, visibilityAtom] of Object.entries(VisibilityLayers)) {
			const layer = key as unknown as keyof typeof VisibilityLayers; // Typescript does not infer key types of iterated objects
			if (!layersUpdated.has(layer)) {
				set(visibilityAtom, false);
			}
		}
	}
});
