import client from "@kubb/plugin-client/client";
import type { VegetationClassesGetQueryResponse, VegetationClassesGetQueryParams } from "../../ts/VegetationClassesGet.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/VegetationClasses
 */
export async function vegetationClassesGet(params?: VegetationClassesGetQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<VegetationClassesGetQueryResponse, Error, unknown>({ method: "GET", url: `/api/VegetationClasses`, params, ...config });
    return res.data;
}