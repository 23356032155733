// Hook returns true if inside a WindowProvider, false otherwise
import { useContext } from "react";

import { WindowContext } from "./provider";

export function useInWindow(): boolean {
	// First check if we're in the provider context
	const inWindowContext = useContext(WindowContext);
	return !!inWindowContext;
}

export function useWindow(): Window {
	return useContext(WindowContext) || window;
}
