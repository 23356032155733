import { ErosionFeaturesArg } from "@/atoms/erosionAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const EROSION_POLYGON_FILTER_OPTION_MINE_SITE: ColumnFilterOption<ErosionFeaturesArg> =
	{
		key: "mineSite",
		label: "Site",
		icon: "site",
		options: (searchTerm?: string) =>
			getTypeAheadResults("mineSite", searchTerm),
		keywords: ["mine"],
		type: "guid",
	};
export const EROSION_POLYGON_FILTER_OPTION_MINING_REGION: ColumnFilterOption<ErosionFeaturesArg> =
	{
		key: "miningRegion",
		label: "Region",
		icon: "region",
		options: (searchTerm?: string) =>
			getTypeAheadResults("miningRegion", searchTerm),
		keywords: ["mining", "region"],
		type: "guid",
	};

export const EROSION_POLYGON_FILTER_OPTION_REHABILITATION_SURVEY: ColumnFilterOption<ErosionFeaturesArg> =
	{
		key: "rehabilitationSurvey",
		label: "Rehabilitation Survey",
		options: (searchTerm?: string) =>
			getTypeAheadResults("rehabilitationSurveyReport", searchTerm),
		type: "guid",
	};

export const EROSION_POLYGON_FILTER_OPTION_REHAB_POLYGON_NAME: ColumnFilterOption<ErosionFeaturesArg> =
	{
		key: "rehabilitationPolygon",
		label: "Rehabilitation Polygon Name",
		icon: "polygon",
		options: (searchTerm?: string) =>
			getTypeAheadResults("polygonName", searchTerm),
	};

export const EROSION_POLYGON_FILTER_OPTIONS: ColumnFilterOption<ErosionFeaturesArg>[] =
	[
		{
			key: "erosionFeature",
			label: "Erosion Feature Name",
			icon: "erosion",
			options: (searchTerm?: string) =>
				getTypeAheadResults("erosionFeatureName", searchTerm),
		},
		EROSION_POLYGON_FILTER_OPTION_REHAB_POLYGON_NAME,
		EROSION_POLYGON_FILTER_OPTION_REHABILITATION_SURVEY,
		EROSION_POLYGON_FILTER_OPTION_MINE_SITE,
		EROSION_POLYGON_FILTER_OPTION_MINING_REGION,
		{
			key: "volume",
			label: "Volume",
			icon: "total_area",
			type: "area",
		},
		{
			key: "length",
			label: "Length",
			icon: "Ruler",
			type: "distance",
		},
		{
			key: "area",
			label: "Area",
			icon: "total_area",
			type: "area",
		},
		{
			key: "depthMean",
			label: "Mean Depth",
			icon: "Ruler",
			type: "distance",
		},
		{
			key: "depthMin",
			label: "Min Depth",
			icon: "Ruler",
			type: "distance",
		},
		{
			key: "depthMax",
			label: "Max Depth",
			icon: "Ruler",
			type: "distance",
		},
		{
			key: "widthMean",
			label: "Mean Width",
			icon: "Ruler",
			type: "distance",
		},
		{
			key: "widthMin",
			label: "Min Width",
			icon: "Ruler",
			type: "distance",
		},
		{
			key: "widthMax",
			label: "Max Width",
			icon: "Ruler",
			type: "distance",
		},
	];
