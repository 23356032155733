import api from "@/api";
import {
	CreateMineSiteResultDto,
	EcologicalSurveyType,
	GenerateWeedGridCellsResultDto,
	MiningRegionAndSitesDto,
	RehabilitationSurveysTileLayersByMineSiteDto,
	RehabSurveyUrlsDto,
	TimeSeriesDayDto,
	WeatherStationDto,
} from "@/web-api-client";

export const getAllMiningRegionAndSiteDetails = async (): Promise<
	MiningRegionAndSitesDto[]
> => {
	const response = await api().get(
		"api/miningRegions/getAllMiningRegionAndSiteDetails",
	);
	return response.data;
};

export const postMiningRegion = async (name: string): Promise<string> => {
	const content = new FormData();
	content.append("Name", name);
	const response = await api().post("api/miningRegions", content, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});
	return response.data;
};

export const getSurveyMapUrls = async (
	miningRegionId: string | undefined,
	mineSiteId: string | undefined,
	timeSeriesDate: Date | undefined,
	ecologicalSurveyType: EcologicalSurveyType,
): Promise<RehabSurveyUrlsDto[]> => {
	const url = "api/MineSites/mapurls";
	const params = {
		miningRegionId: miningRegionId,
		mineSiteId: mineSiteId,
		timeSeriesDate: timeSeriesDate,
		ecologicalSurveyType: ecologicalSurveyType,
	};

	const response = await api().get(url, { params });
	return response.data;
};

export const getSurveyUrls = async (
	miningRegionId: string | undefined,
	mineSiteId: string | undefined,
): Promise<RehabilitationSurveysTileLayersByMineSiteDto[]> => {
	const url = "api/MineSites/imagery";
	const params = {
		miningRegionId: miningRegionId,
		mineSiteId: mineSiteId,
	};

	const response = await api().get(url, { params });
	return response.data;
};

export const createMineSite = async (
	fileToUpload: File,
): Promise<CreateMineSiteResultDto[]> => {
	const content = new FormData();
	content.append(
		"ShapeFileArchive",
		fileToUpload,
		fileToUpload.name ? fileToUpload.name : "ShapeFileArchive",
	);
	content.append("FileName", fileToUpload.name);
	const response = await api().post("api/MineSites", content);
	return response.data;
};

export const updateMineSite = async (
	id: string,
	name: string | undefined,
	code: string | undefined,
) => {
	const url = "/api/MineSites/" + id;
	const content = new FormData();
	content.append("id", id);
	if (name) content.append("name", name);
	if (code) content.append("code", code);
	const response = await api().put(url, content, {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response.data;
};

export const generateWeedGridCells = async (
	mineSiteId: string,
	disturbanceShapeFile: File,
): Promise<GenerateWeedGridCellsResultDto> => {
	const content = new FormData();
	content.append("MineSiteId", mineSiteId);
	content.append(
		"BufferedDisturbanceShapeArchive",
		disturbanceShapeFile,
		disturbanceShapeFile.name,
	);
	content.append("Srid", (28351).toString());
	const url = "/api/MineSites/generate-weed-grid-cells";
	const response = await api().post(url, content, {
		headers: {
			Accept: "application/json",
		},
	});

	return response.data;
};

export const getWeatherStations = async (
	id: string | undefined,
): Promise<WeatherStationDto[]> => {
	const url = `/api/MineSites/${id}/weather-stations`;
	const response = await api().get(url);
	return response.data;
};

export const getTimeSeriesDays = async (
	weatherStationId: string,
	mineSiteId: string | undefined,
	startRange: Date | undefined,
	endRange?: Date | undefined,
): Promise<TimeSeriesDayDto[]> => {
	const params = {
		mineSiteId: mineSiteId,
		weatherStationId: weatherStationId,
		startRange: startRange,
		endRange: endRange,
	};
	const url = "/api/TimeSeries";
	const response = await api().get(url, { params });
	return response.data;
};
