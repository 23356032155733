import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { toast } from "sonner";

import {
	GetSeedApplicationForPlanQueryKey,
	SeedApplicationIdAtom,
} from "@/atoms/seedAppAtoms";
import { NumberInputCell } from "@/components/data-table/input-cells";
import {
	seedApplicationsUpdateSeedApplicationSpeciesTarget,
	SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest,
	VegetationCommunityFloraSpeciesDto,
} from "@/lib/gen/eis";

type Props = {
	floraSpecies: VegetationCommunityFloraSpeciesDto;
};

export const EditTargetCoverCell = ({ floraSpecies }: Props) => {
	const queryClient = useQueryClient();
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const { mutate, isPending } = useMutation({
		mutationFn: async (
			data: SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest,
		) => {
			return seedApplicationsUpdateSeedApplicationSpeciesTarget(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationForPlanQueryKey(seedApplicationId),
			});
		},
		onError: (error) => {
			toast.error("Updating Target Cover Failed", {
				description: error.message,
			});
		},
	});

	const updateTargetCover = useCallback(
		(value: number) => {
			mutate({
				seedApplicationSpeciesId: floraSpecies.id,
				updatedTargetCover: value,
			});
		},
		[floraSpecies.id, mutate],
	);

	return (
		<NumberInputCell
			input={floraSpecies.targetCover}
			onBlurOrEnter={updateTargetCover}
			isPending={isPending}
			className="flex w-full justify-end [&>div]:max-w-24"
			disabled={seedApplicationId == null}
		>
			%
		</NumberInputCell>
	);
};

export const EditEstablishmentRateCell = ({ floraSpecies }: Props) => {
	const queryClient = useQueryClient();
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const { mutate, isPending } = useMutation({
		mutationFn: async (
			data: SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest,
		) => {
			return seedApplicationsUpdateSeedApplicationSpeciesTarget(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationForPlanQueryKey(seedApplicationId),
			});
		},
		onError: (error) => {
			toast.error("Updating Establishment Rate Failed", {
				description: error.message,
			});
		},
	});

	const updateEstablishmentRate = useCallback(
		(value: number) => {
			mutate({
				seedApplicationSpeciesId: floraSpecies.id,
				updatedEstablishmentRate: value,
			});
		},
		[floraSpecies.id, mutate],
	);

	return (
		<NumberInputCell
			input={floraSpecies.establishmentRate}
			onBlurOrEnter={updateEstablishmentRate}
			isPending={isPending}
			className="flex w-full justify-end [&>div]:max-w-24"
			disabled={seedApplicationId == null}
		>
			%
		</NumberInputCell>
	);
};
