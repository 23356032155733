import { useMemo } from "react";
import { FieldValues, Path } from "react-hook-form";

import { SelectFormControl } from "@/components/form/select-form-control";

type Props<T extends FieldValues> = {
	name: Path<T>;
	label: string;
	enumValue: Record<string, string>;
	description?: string;
	className?: string;
	multi?: boolean;
};

export const EnumFormControl = <T extends FieldValues>({
	name,
	label,
	description,
	className,
	enumValue,
	multi,
}: Props<T>) => {
	const options = useMemo(() => {
		return (
			Object.values(enumValue).map((s) => {
				return { id: s, value: s, label: s };
			}) ?? []
		);
	}, [enumValue]);

	return (
		<SelectFormControl
			label={label}
			name={name}
			options={options}
			description={description}
			className={className}
			modal={true}
			isMulti={multi}
		/>
	);
};
