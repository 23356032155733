export const seedApplicationStatus = {
    "Draft": "Draft",
    "AwaitingAllocation": "Awaiting Allocation",
    "AwaitingCollection": "Awaiting Collection",
    "ReadyToSeed": "Ready To Seed",
    "Seeded": "Seeded"
} as const;

 export type SeedApplicationStatusEnum = (typeof seedApplicationStatus)[keyof typeof seedApplicationStatus];

 export type SeedApplicationStatus = SeedApplicationStatusEnum;