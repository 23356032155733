export const MAP_SOURCES = [
	"minesite",
	"weedgrid",
	"siteinspection",
	"rehabpolygon",
	"siteinspection",
	"samplingsite",
	"erosionvector",
	"healthgrid",
	"healthzone",
	"analoguepolygon",
	"bareareavector",
	"topsoilstockpile",
	"individualtree",
] as const;

export type MapSourceType = (typeof MAP_SOURCES)[number];

export const MAP_CLUSTER_SOURCES = [
	"rehabpolygonCluster",
	"erosionCluster",
] as const;

export type MapClusterSourceType = (typeof MAP_CLUSTER_SOURCES)[number];

export const HOVER_SOURCES: MapSourceType[] = [
	"minesite",
	"weedgrid",
	"siteinspection",
	"rehabpolygon",
	"siteinspection",
	"samplingsite",
	"erosionvector",
	"healthgrid",
	"healthzone",
	"analoguepolygon",
	"individualtree",
] as const;
