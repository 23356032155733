import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { User } from "oidc-react";

export const ModuleManagementVisibleAtom = atom<boolean>(false);

export const UserAtom = atom<User | undefined | null>(undefined);
export const UserPermissionsAtom = atom((get) => {
	const user = get(UserAtom);
	if (user?.profile.permission) {
		return user.profile.permission as EisPermission[];
	}
});

export const Perm_ModuleMgmt_Admin =
	"Permissions.ModuleManagement.Administrator";
export const Perm_UserMgmt_Admin = "Permissions.UserManagement.Administrator";
export const Perm_Rehab_Std = "Permissions.Rehabilitation.Standard";
export const Perm_Erosion_Std = "Permissions.Erosion.Standard";
export const Perm_Riparian_Std = "Permissions.Riparian.Standard";
export const Perm_Seeds_Std = "Permission.Seeds.Standard";
export const Perm_DataMgmt_Admin = "Permissions.DataManagement.Administrator";
export const Perm_Topsoil_Std = "Permissions.Topsoil.Standard";
export const Perm_Weeds_Std = "Permissions.Weeds.Standard";
export const Perm_Flora_Std = "Permissions.Flora.Standard";

const ALL_PERMISSIONS = [
	Perm_ModuleMgmt_Admin,
	Perm_UserMgmt_Admin,
	Perm_Rehab_Std,
	Perm_Erosion_Std,
	Perm_Riparian_Std,
	Perm_Seeds_Std,
	Perm_DataMgmt_Admin,
	Perm_Topsoil_Std,
	Perm_Weeds_Std,
	Perm_Flora_Std,
] as const;

export type EisPermission = (typeof ALL_PERMISSIONS)[number];

export const MineSitePerms = [
	Perm_Rehab_Std,
	Perm_Seeds_Std,
	Perm_Topsoil_Std,
	Perm_Erosion_Std,
	Perm_Weeds_Std,
] as const;

export const UserHasPermissionAtom = atomFamily((permission: EisPermission) => {
	return atom((get) => {
		const perms = get(UserPermissionsAtom);
		if (perms) {
			return perms.includes(permission);
		}
		return false;
	});
});

export const UserHasPermissionsAtom = atom((get) => {
	const permissions: Record<string, boolean> = {};
	for (const perm of ALL_PERMISSIONS) {
		permissions[perm] = get(UserHasPermissionAtom(perm));
	}
	return {
		...(permissions as Record<EisPermission, boolean>),
		mineSitePerms:
			permissions[Perm_Rehab_Std] ||
			permissions[Perm_Seeds_Std] ||
			permissions[Perm_Topsoil_Std] ||
			permissions[Perm_Erosion_Std] ||
			permissions[Perm_Weeds_Std],
	} as const;
});
