import type { TopsoilApplicationDetailsDto } from "../ts/TopsoilApplicationDetailsDto.ts";
import { attachmentDtoSchema } from "./attachmentDtoSchema.ts";
import { topsoilApplicationMachinerySchema } from "./topsoilApplicationMachinerySchema.ts";
import { topsoilApplicationMethodSchema } from "./topsoilApplicationMethodSchema.ts";
import { topsoilApplicationPolygonDtoSchema } from "./topsoilApplicationPolygonDtoSchema.ts";
import { topsoilApplicationStatusSchema } from "./topsoilApplicationStatusSchema.ts";
import { topsoilApplicationStockpileAllocationDtoSchema } from "./topsoilApplicationStockpileAllocationDtoSchema.ts";
import { topsoilStockpileVegCommunityDtoSchema } from "./topsoilStockpileVegCommunityDtoSchema.ts";
import { urlLinkDtoSchema } from "./urlLinkDtoSchema.ts";
import { z } from "zod";

 export const topsoilApplicationDetailsDtoSchema = z.object({ "id": z.string(), "name": z.string().nullable().nullish(), "rehabilitationPolygon": z.lazy(() => topsoilApplicationPolygonDtoSchema).nullable().nullish(), "stockpileAllocations": z.array(z.lazy(() => topsoilApplicationStockpileAllocationDtoSchema)).nullable().nullish(), "status": z.lazy(() => topsoilApplicationStatusSchema), "topsoilApplicationMachinery": z.lazy(() => topsoilApplicationMachinerySchema), "topsoilApplicationMethod": z.lazy(() => topsoilApplicationMethodSchema), "applicationDepth": z.number(), "applicationDate": z.string().date().nullable().nullish(), "applicationNotes": z.string().nullable().nullish(), "appliedByUser": z.string().nullable().nullish(), "scheduledDate": z.string().date().nullable().nullish(), "scheduleNotes": z.string().nullable().nullish(), "scheduledByUser": z.string().nullable().nullish(), "attachments": z.array(z.lazy(() => attachmentDtoSchema)).nullable().nullish(), "urlLinks": z.array(z.lazy(() => urlLinkDtoSchema)).nullable().nullish(), "vegetationCommunity": z.lazy(() => topsoilStockpileVegCommunityDtoSchema).nullable().nullish() }) as z.ZodType<TopsoilApplicationDetailsDto>;