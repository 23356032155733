import { MonitoringScheduleArg } from "@/atoms/rehabPolyAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { IconName } from "@/components/icons";
import {
	CompletionCriterionGroup,
	completionCriterionResult,
	monitoringScheduleMethod,
	polygonLandformType,
	polygonOverallResult,
	rehabilitationPolygonStatus,
	VegetationClassDto,
} from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const MONITORING_SCHEDULE_FILTER_OPTION_MINE_SITE_NAME: ColumnFilterOption<MonitoringScheduleArg> =
	{
		key: "mineSiteName",
		label: "Site",
		icon: "site",
		options: (searchTerm?: string) =>
			getTypeAheadResults("mineSiteName", searchTerm),
		keywords: ["mine", "name"],
	};
export const MONITORING_SCHEDULE_FILTER_OPTION_MINING_REGION_NAME: ColumnFilterOption<MonitoringScheduleArg> =
	{
		key: "miningRegionName",
		label: "Region",
		icon: "region",
		options: (searchTerm?: string) =>
			getTypeAheadResults("miningRegionName", searchTerm),
		keywords: ["mine", "name"],
	};

export const MONITORING_SCHEDULE_FILTER_OPTIONS: ColumnFilterOption<MonitoringScheduleArg>[] =
	[
		{
			key: "monitoringScheduleMethod",
			label: "Method",
			options: Object.values(monitoringScheduleMethod).map((value) => ({
				value,
			})),
			type: "enum",
			keywords: ["result"],
		},
		{
			key: "polygonName",
			label: "Polygon",
			icon: "polygon",
			options: (searchTerm?: string) =>
				getTypeAheadResults("polygonName", searchTerm),
			keywords: ["name"],
		},
		{
			key: "polygonTag",
			label: "Polygon Tag",
			icon: "tag",
			options: (searchTerm?: string) =>
				getTypeAheadResults("polygonTag", searchTerm),
		},
		MONITORING_SCHEDULE_FILTER_OPTION_MINING_REGION_NAME,
		MONITORING_SCHEDULE_FILTER_OPTION_MINE_SITE_NAME,
		{
			key: "polygonOverallResult",
			label: "Criteria Outcome",
			icon: "completion_criteria",
			options: Object.values(polygonOverallResult).map((value) => ({
				value,
			})),
			type: "enum",
			keywords: ["result"],
		},
		{
			key: "quadrats",
			label: "Sampling Sites",
			icon: "quadrat",
			type: "number",
			keywords: ["quadrats"],
		},
		{
			key: "siteInspections",
			label: "Site Inspections",
			icon: "site_inspection",
			type: "number",
		},
		{
			key: "erosionFeatures",
			label: "Erosion Features",
			icon: "erosion",
			type: "number",
		},
		{
			key: "landform",
			label: "Landform",
			icon: "landform",
			options: Object.values(polygonLandformType).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "status",
			label: "Status",
			icon: "check",
			options: Object.values(rehabilitationPolygonStatus).map(
				(value) => ({ value }),
			),
			type: "enum",
		},
		{
			key: "area",
			label: "Area",
			icon: "total_area",
			type: "area",
			sourceUnits: { area: "hectares" },
		},
		{
			key: "rehabilitatedDate",
			label: "Rehabilitation Date",
			icon: "Clock",
			type: "date",
		},
	];

export function getRehabPolygonDynamicFilterCriteriaStatus(
	key: string,
	completionCriteriaGroup?: CompletionCriterionGroup,
): ColumnFilterOption<MonitoringScheduleArg> {
	let icon: IconName = "remote_sensing";
	let iconClassName = "text-card-remotesensing-text";
	switch (completionCriteriaGroup) {
		case "On-Ground":
			icon = "onground";
			iconClassName = "text-card-onground-text";
			break;
		case "Erosion":
			icon = "erosion";
			iconClassName = "text-card-erosion-text";
			break;
	}

	return {
		key: "criteriaStatusQuery",
		subKey: key,
		label: key,
		icon: icon,
		iconClassName: iconClassName,
		options: Object.values(completionCriterionResult).map((value) => ({
			value,
		})),
		type: "enum",
		group: "Completion Criteria",
	};
}

export function getRehabPolygonDynamicFilterVegClassCover(
	vegClassCover: VegetationClassDto,
): ColumnFilterOption<MonitoringScheduleArg> {
	return {
		key: "vegClassCoverQuery",
		subKey: vegClassCover.id,
		label: `${vegClassCover.name} %`,
		icon: "circle_filled",
		iconColor: vegClassCover.displayColour ?? undefined,
		type: "percentage",
		group: "Classification Covers",
	};
}
