import {
	ColumnPinningState,
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getSortedRowModel,
	OnChangeFn,
	Row,
	RowSelectionState,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { getTableSettingsAtom } from "@/atoms/tableSettingsAtoms";
import { DataTableViewOptions } from "@/components/data-table/data-table-view-options";
import {
	FilteredSearchBox,
	FilteredSearchBoxArguments,
} from "@/components/filtered-search-box/filtered-search-box";
import { ENV } from "@/env_variables";
import { cn } from "@/lib/utils";

import {
	DataTableStructure,
	DataTableStructureExternalProps,
} from "./data-table-structure";

type DataTableProps<TData, TValue, TFilterArgs extends string> = {
	title: string;
	minColumnSize?: number;
	getRowId?: (
		originalRow: TData,
		index: number,
		parent?: Row<TData>,
	) => string;
	rowSelection?: RowSelectionState;
	filteredSearchBox?: FilteredSearchBoxArguments<TFilterArgs>;
	serverSide?: boolean;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
} & DataTableStructureExternalProps<TData, TValue>;

export const DataTable = <TData, TValue, TFilterArgs extends string>({
	columns,
	data,
	title,
	isLoading,
	totalRows,
	fetch,
	className,
	overscan,
	children,
	renderSubComponent,
	rowHeight = 34,
	minColumnSize,
	getRowId,
	rowSelection,
	filteredSearchBox,
	hideTotalRows,
	serverSide = true,
	...props
}: DataTableProps<TData, TValue, TFilterArgs>) => {
	const [_tableSettings, setTableSettings] = useAtom(
		useMemo(() => getTableSettingsAtom(title), [title]),
	);
	const [columnVisibility, setColumnVisibility] = useState<{
		[key: string]: boolean;
	}>(_tableSettings.columnVisibility ?? {});
	const [columnOrder, setColumnOrder] = useState<string[]>(
		_tableSettings.columnOrder ?? [],
	);
	const [columnSizing, setColumnSizing] = useState<Record<string, number>>(
		_tableSettings.columnSizing ?? {},
	);
	const [columnPinning, setColumnPinning] = useState<ColumnPinningState>(
		_tableSettings.columnPinning ?? {},
	);

	const [_sorting, _setSorting] = useState<SortingState>([]);
	const sorting = props.sorting ?? _sorting;
	const setSorting = props.setSorting ?? _setSorting;

	const columnState = useMemo(
		() => ({
			columnVisibility,
			columnOrder,
			columnSizing,
			columnPinning,
			columnCount: columns.length,
		}),
		[
			columnOrder,
			columnPinning,
			columnSizing,
			columnVisibility,
			columns.length,
		],
	);

	const table = useReactTable<TData>({
		columns: columns,
		data: data,
		state: {
			columnVisibility,
			columnOrder,
			columnSizing,
			columnPinning,
			rowSelection: rowSelection ?? {},
			sorting,
		},
		getCoreRowModel: getCoreRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		onSortingChange: setSorting,
		getSortedRowModel:
			props.sorting != null || serverSide
				? undefined
				: getSortedRowModel(),
		getRowId: getRowId,
		manualFiltering: serverSide,
		manualSorting: serverSide,
		debugTable: ENV.DEV,
		columnResizeMode: "onChange",
		defaultColumn: {
			minSize: minColumnSize ?? 80,
			maxSize: 800,
		},
		onColumnVisibilityChange: setColumnVisibility,
		onColumnOrderChange: setColumnOrder,
		onColumnSizingChange: setColumnSizing,
		onColumnPinningChange: setColumnPinning,
		getExpandedRowModel: getExpandedRowModel(),
		getRowCanExpand: () => !!renderSubComponent,
		enableExpanding: !!renderSubComponent,
	});

	// Save settings on column changes
	useEffect(() => {
		setTableSettings((s) => {
			s.columnVisibility = columnVisibility;
			return s;
		});
	}, [columnVisibility, setTableSettings]);
	useEffect(() => {
		if (columnOrder.length <= 0) return;
		setTableSettings((s) => {
			s.columnOrder = columnOrder;
			return s;
		});
	}, [columnOrder, setTableSettings]);
	useEffect(() => {
		if (columnSizing.length <= 0) return;
		setTableSettings((s) => {
			s.columnSizing = columnSizing;
			return s;
		});
	}, [columnSizing, setTableSettings]);
	useEffect(() => {
		setTableSettings((s) => {
			s.columnPinning = columnPinning;
			return s;
		});
	}, [columnPinning, setTableSettings]);

	return (
		<DataTableStructure
			table={table}
			columns={columns}
			data={data}
			isLoading={isLoading}
			totalRows={totalRows}
			fetch={fetch}
			rowHeight={rowHeight}
			overscan={overscan}
			renderSubComponent={renderSubComponent}
			columnState={columnState}
			className={cn(
				"flex size-full max-h-screen min-h-0 flex-col gap-1 overflow-hidden",
				className,
			)}
			hideTotalRows={hideTotalRows}
			{...props}
		>
			<>
				{children}

				{filteredSearchBox != null && (
					<FilteredSearchBox<TFilterArgs>
						className="mb-1 px-2"
						args={filteredSearchBox}
					>
						<DataTableViewOptions<TData>
							table={table}
							columnOrder={columnOrder}
							onChangeOrder={setColumnOrder}
							className="ml-1 gap-1 px-3"
						/>
					</FilteredSearchBox>
				)}
			</>
		</DataTableStructure>
	);
};
