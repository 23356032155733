import Feature from "ol/Feature";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";

import { MapSourceType } from "@/components/MapOpenLayers/map-types";

export function getFeatureByIdAndSource(
	map: Map,
	sourceName: MapSourceType,
	featureId: string,
): Feature<Geometry> | undefined {
	const layers: VectorLayer[] = map
		.getLayers()
		.getArray()
		.filter((l) => l instanceof VectorLayer);
	for (const layer of layers) {
		const source = layer.getSource();
		const featureById = source?.getFeatureById(featureId);
		if (featureById) {
			return featureById as Feature<Geometry>;
		}
		const features = source?.getFeatures();
		const feature = features?.find(
			(f) => f.get("id") === featureId && f.get("source") === sourceName,
		);
		if (feature) {
			return feature as Feature<Geometry>;
		}
	}
	return undefined;
}
