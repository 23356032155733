import { z } from "zod";

import type { CrsRef } from "@/lib/gen/titiler/types/CrsRef";

/**
 * @description CRS from referenceSystem.
 */
export const crsRefSchema = z
	.object({
		referenceSystem: z
			.object({})
			.describe(
				"A reference system data structure as defined in the MD_ReferenceSystem of the ISO 19115",
			),
	})
	.describe("CRS from referenceSystem.") as z.ZodType<CrsRef>;
