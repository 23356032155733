import { useAtom } from "jotai";
import { useCallback } from "react";

import {
	ImagerViewerAtom,
	ImagerViewerImagesAtom,
} from "@/atoms/ImageViewerAtoms";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import { cn } from "@/lib/utils";

interface Image {
	thumbnailFileName?: string | undefined;
	fullImageFileName?: string | undefined;
	thumbnailBytes?: string | undefined;
}
interface Props extends React.HTMLAttributes<HTMLDivElement> {
	images: Image[] | undefined;
}

const PhotoCarousel: React.FC<Props> = ({ images, className }) => {
	const [, setImages] = useAtom(ImagerViewerImagesAtom);
	const [, setImagesIndex] = useAtom(ImagerViewerAtom);
	const openImageViewer = useCallback(
		(filename: string | undefined) => {
			if (filename && images) {
				const image = images.find(
					(i) => i.fullImageFileName === filename,
				);
				if (image) {
					const index = images.indexOf(image);
					setImagesIndex(index);
					setImages(images.flatMap((i) => i.fullImageFileName ?? ""));
				}
			}
		},
		[images, setImages, setImagesIndex],
	);

	return (
		<div className={cn("w-full", className)}>
			<Carousel
				className="max-h-16 w-[8.5rem]"
				opts={{
					align: "start",
				}}
			>
				<CarouselContent className="-ml-1 flex items-center">
					{images?.map((i) => (
						<CarouselItem
							className="w-fit basis-1/2 items-center pl-1"
							key={i.fullImageFileName}
						>
							<img
								className="max-h-16 max-w-16 rounded border border-core-primary-border"
								alt={i.thumbnailFileName}
								src={`data:image/jpeg;base64,${i.thumbnailBytes}`}
								onClick={() =>
									openImageViewer(i.fullImageFileName)
								}
							/>
						</CarouselItem>
					))}
				</CarouselContent>
				<CarouselPrevious variant="ghost" />
				<CarouselNext variant="ghost" />
			</Carousel>
		</div>
	);
};

export default PhotoCarousel;
