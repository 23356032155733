import { z } from "zod";

import type { Profile } from "@/lib/gen/titiler/types/Profile";

/**
 * @description rio-cogeo validation Profile information.
 */
export const profileSchema = z
	.object({
		Bands: z.number().int(),
		Width: z.number().int(),
		Height: z.number().int(),
		Tiled: z.boolean(),
		Dtype: z.string(),
		Interleave: z.string(),
		AlphaBand: z.boolean(),
		InternalMask: z.boolean(),
		Nodata: z.unknown().optional(),
		ColorInterp: z.array(z.string()),
		ColorMap: z.boolean(),
		Scales: z.array(z.number()),
		Offsets: z.array(z.number()),
	})
	.describe(
		"rio-cogeo validation Profile information.",
	) as z.ZodType<Profile>;
