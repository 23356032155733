import type { SeedBatchesUpdatePathParams, SeedBatchesUpdate200, SeedBatchesUpdateMutationRequest, SeedBatchesUpdateMutationResponse } from "../ts/SeedBatchesUpdate.ts";
import { updateSeedBatchCommandSchema } from "./updateSeedBatchCommandSchema.ts";
import { z } from "zod";

 export const seedBatchesUpdatePathParamsSchema = z.object({ "id": z.string() }) as z.ZodType<SeedBatchesUpdatePathParams>;

 export const seedBatchesUpdate200Schema = z.instanceof(File) as z.ZodType<SeedBatchesUpdate200>;

 export const seedBatchesUpdateMutationRequestSchema = z.lazy(() => updateSeedBatchCommandSchema) as z.ZodType<SeedBatchesUpdateMutationRequest>;

 export const seedBatchesUpdateMutationResponseSchema = z.lazy(() => seedBatchesUpdate200Schema) as z.ZodType<SeedBatchesUpdateMutationResponse>;