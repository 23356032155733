import client from "@kubb/plugin-client/client";
import type { WeedsSurveysGetWeedsVegetationClassesQueryQueryResponse } from "../../ts/WeedsSurveysGetWeedsVegetationClassesQuery.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/WeedsSurveys/vegetationclasses
 */
export async function weedsSurveysGetWeedsVegetationClassesQuery(config: Partial<RequestConfig> = {}) {
    const res = await client<WeedsSurveysGetWeedsVegetationClassesQueryQueryResponse, Error, unknown>({ method: "GET", url: `/api/WeedsSurveys/vegetationclasses`, ...config });
    return res.data;
}