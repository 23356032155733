import type { SeedBatchDto } from "../ts/SeedBatchDto.ts";
import { pointSchema } from "./pointSchema.ts";
import { provenanceLandformSchema } from "./provenanceLandformSchema.ts";
import { seedBatchGerminationTestDtoSchema } from "./seedBatchGerminationTestDtoSchema.ts";
import { seedBatchQrCodeDtoSchema } from "./seedBatchQrCodeDtoSchema.ts";
import { seedBatchSeedDtoSchema } from "./seedBatchSeedDtoSchema.ts";
import { seedBatchStatusSchema } from "./seedBatchStatusSchema.ts";
import { storageLocationDtoSchema } from "./storageLocationDtoSchema.ts";
import { supplierDtoSchema } from "./supplierDtoSchema.ts";
import { z } from "zod";

 export const seedBatchDtoSchema = z.object({ "id": z.string(), "seedId": z.string(), "seed": z.lazy(() => seedBatchSeedDtoSchema).nullable().nullish(), "seedBatchNo": z.string().nullable().nullish(), "collectionDate": z.string().date().nullable().nullish(), "expiryDate": z.string().date().nullable().nullish(), "provenanceLandforms": z.array(z.lazy(() => provenanceLandformSchema)).nullable().nullish(), "collectionPoint": z.lazy(() => pointSchema).nullable().nullish(), "description": z.string().nullable().nullish(), "cost": z.number(), "initialQuantity": z.number(), "costPerGram": z.number(), "availableQuantity": z.number(), "quantityInStock": z.number(), "supplierId": z.string().nullable().nullish(), "supplier": z.lazy(() => supplierDtoSchema).nullable().nullish(), "storageLocationId": z.string(), "storageLocation": z.lazy(() => storageLocationDtoSchema).nullable().nullish(), "shelfLocation": z.string().nullable().nullish(), "storageMethod": z.string().nullable().nullish(), "poNumber": z.string().nullable().nullish(), "seedIdentificationConfirmed": z.boolean(), "seedBatchGerminationTest": z.lazy(() => seedBatchGerminationTestDtoSchema).nullable().nullish(), "qrCode": z.lazy(() => seedBatchQrCodeDtoSchema).nullable().nullish(), "status": z.lazy(() => seedBatchStatusSchema) }) as z.ZodType<SeedBatchDto>;