import client from "@kubb/plugin-client/client";
import type { MiningRegionsGetAllMetricsMutationRequest, MiningRegionsGetAllMetricsMutationResponse } from "../../ts/MiningRegionsGetAllMetrics.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/MiningRegions/performance
 */
export async function miningRegionsGetAllMetrics(data: MiningRegionsGetAllMetricsMutationRequest, config: Partial<RequestConfig<MiningRegionsGetAllMetricsMutationRequest>> = {}) {
    const res = await client<MiningRegionsGetAllMetricsMutationResponse, Error, MiningRegionsGetAllMetricsMutationRequest>({ method: "POST", url: `/api/MiningRegions/performance`, data, ...config });
    return res.data;
}