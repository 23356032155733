import client from "@kubb/plugin-client/client";
import type { SamplingSitesGetSamplingSitesPaginatedMutationRequest, SamplingSitesGetSamplingSitesPaginatedMutationResponse } from "../../ts/SamplingSitesGetSamplingSitesPaginated.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/SamplingSites/paginated
 */
export async function samplingSitesGetSamplingSitesPaginated(data: SamplingSitesGetSamplingSitesPaginatedMutationRequest, config: Partial<RequestConfig<SamplingSitesGetSamplingSitesPaginatedMutationRequest>> = {}) {
    const res = await client<SamplingSitesGetSamplingSitesPaginatedMutationResponse, Error, SamplingSitesGetSamplingSitesPaginatedMutationRequest>({ method: "POST", url: `/api/SamplingSites/paginated`, data, ...config });
    return res.data;
}