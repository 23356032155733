import { ObservationSpeciesSamplingArg } from "@/atoms/rehabPolyAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const OBSERVATION_SPECIES_SAMPLING_FILTER_OPTIONS: ColumnFilterOption<ObservationSpeciesSamplingArg>[] =
	[
		{
			key: "observationName",
			label: "Observation Name",
			icon: "observation",
			options: (searchTerm?: string) =>
				getTypeAheadResults("samplingSiteObservationName", searchTerm),
			type: "string",
		},
		{
			key: "family",
			label: "Family",
			icon: "Filter",
			options: (searchTerm?: string) =>
				getTypeAheadResults("family", searchTerm),
			type: "string",
		},
		{
			key: "genusSpecies",
			label: "Genus-Species",
			icon: "Filter",
			options: (searchTerm?: string) =>
				getTypeAheadResults("speciesFullName", searchTerm),
			type: "string",
		},
		{
			key: "cover",
			label: "Cover",
			icon: "vegcover",
			type: "number",
		},
		{
			key: "height",
			label: "Height",
			icon: "Ruler",
			type: "distance",
		},
		{
			key: "density",
			label: "Density",
			icon: "Ruler",
			type: "area",
			sourceUnits: { area: "hectares" },
		},
		{
			key: "comments",
			label: "Comments",
			icon: "Text",
			type: "string",
		},
	];
