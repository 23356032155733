export const seedOrderBy = {
    "Name": "Name",
    "Status": "Status",
    "Provenance": "Provenance",
    "Family": "Family",
    "Lifeform": "Lifeform",
    "Cost": "Cost",
    "MinimumBeforeReorder": "MinimumBeforeReorder",
    "QuantityInStock": "QuantityInStock",
    "AvailableQuantity": "AvailableQuantity"
} as const;

 export type SeedOrderByEnum = (typeof seedOrderBy)[keyof typeof seedOrderBy];

 export type SeedOrderBy = SeedOrderByEnum;