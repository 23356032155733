import type { SeedBatchesDeactivatePathParams, SeedBatchesDeactivate200, SeedBatchesDeactivateMutationRequest, SeedBatchesDeactivateMutationResponse } from "../ts/SeedBatchesDeactivate.ts";
import { deactivateSeedBatchCommandSchema } from "./deactivateSeedBatchCommandSchema.ts";
import { z } from "zod";

 export const seedBatchesDeactivatePathParamsSchema = z.object({ "id": z.string() }) as z.ZodType<SeedBatchesDeactivatePathParams>;

 export const seedBatchesDeactivate200Schema = z.instanceof(File) as z.ZodType<SeedBatchesDeactivate200>;

 export const seedBatchesDeactivateMutationRequestSchema = z.lazy(() => deactivateSeedBatchCommandSchema) as z.ZodType<SeedBatchesDeactivateMutationRequest>;

 export const seedBatchesDeactivateMutationResponseSchema = z.lazy(() => seedBatchesDeactivate200Schema) as z.ZodType<SeedBatchesDeactivateMutationResponse>;