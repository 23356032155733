import { memo } from "react";

import { DataTableCustomCell } from "@/components/data-table/data-table-cells";
import { WeedAlertEnum } from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

import { WEEDALERT_BG_CLASS } from "./weed-alert";

export const WeedAlertTableCell = memo(function ({
	weedAlert,
}: {
	weedAlert: WeedAlertEnum;
}) {
	return (
		<DataTableCustomCell>
			<div
				className={cn(
					"flex h-6 w-24 items-center justify-center rounded-md font-dmSans font-medium text-white",
					WEEDALERT_BG_CLASS[weedAlert],
				)}
			>
				{weedAlert}
			</div>
		</DataTableCustomCell>
	);
});
