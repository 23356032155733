import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import {
	DataTableCustomCell,
	DataTableSmallTextCell,
	DataTableTimeSinceCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { SeedBatchDialog } from "@/components/Seeds/seed-batch-dialog";
import { Button } from "@/components/ui/button";
import { SeedBatchInventoryDto } from "@/lib/gen/eis";

const columnHelper = createColumnHelper<SeedBatchInventoryDto>();

type Props = {
	openSeedBatch: (seedBatchId: string) => void;
};

export const SeedBatchColumns = ({ openSeedBatch }: Props) =>
	[
		columnHelper.accessor((row) => row.seedBatchNo, {
			id: "seedBatchNo",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Batch ID" />
			),
			cell: ({ getValue, row }) => (
				<DataTableCustomCell>
					<SeedBatchDialog>
						<Button
							onClick={() => openSeedBatch(row.original.id)}
							variant="link"
							className="truncate px-0"
						>
							<span className="truncate">{getValue()}</span>
						</Button>
					</SeedBatchDialog>
				</DataTableCustomCell>
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Batch ID",
			},
		}),
		columnHelper.accessor((row) => row.poNumber, {
			id: "poNumber",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="PO Number" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "PO Number",
			},
		}),
		columnHelper.accessor("supplierName", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Supplier" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Supplier",
			},
		}),
		columnHelper.accessor("storageLocationName", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Storage Location"
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Storage Location",
			},
		}),
		columnHelper.accessor("provenanceLandforms", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Provenance Landforms"
				/>
			),
			cell: ({ getValue }) => {
				const displayStr = getValue()?.join(", ");
				return <DataTableSmallTextCell value={displayStr} />;
			},
			enableSorting: true,
			size: 100,
			meta: {
				title: "Provenance Landforms",
			},
		}),
		columnHelper.accessor("collectionDate", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Age" />
			),
			cell: ({ getValue }) => (
				<DataTableTimeSinceCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Age",
			},
		}),
		columnHelper.accessor("seedBatchGerminationTest.purity", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Purity" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Purity",
			},
		}),
		columnHelper.accessor("seedBatchGerminationTest.viability", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Viability" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Viability",
			},
		}),
		columnHelper.accessor("quantityInStock", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Stock" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Stock",
			},
		}),
		columnHelper.accessor("availableQuantity", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Available" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
			meta: {
				title: "Available",
			},
		}),
	] as ColumnDef<SeedBatchInventoryDto>[];
