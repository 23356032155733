import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import React from "react";

import { SelectedAnaloguePolygon } from "@/atoms/analoguePolyAtoms";
import { SelectedRehabPolygonAtom } from "@/atoms/rehabPolyAtoms";
import VegCoverGraph from "@/components/BottomDrawer/Common/VegCoverGraph";
import {
	analoguePolygonsGetVegHistoryDates,
	rehabilitationPolygonsGetVegHistoryDates,
} from "@/lib/gen/eis";
import { getPolygonVegCovers } from "@/services/csvExports.service";

const RehabPolyVegGraphCard: React.FC = () => {
	const rehabPoly = useAtomValue(SelectedRehabPolygonAtom);
	const analoguePoly = useAtomValue(SelectedAnaloguePolygon);

	const rehabPolyQuery = useQuery({
		queryKey: ["polyVegHistoryDates", rehabPoly?.id],
		queryFn: async () =>
			rehabilitationPolygonsGetVegHistoryDates(rehabPoly?.id ?? ""),
		enabled: !!rehabPoly?.id,
	});

	const analoguePolyQuery = useQuery({
		queryKey: ["analoguePolyVegHistoryDates", analoguePoly?.id],
		queryFn: async () =>
			analoguePolygonsGetVegHistoryDates(analoguePoly?.id ?? ""),
		enabled: !!analoguePoly?.id,
	});

	const query = rehabPoly ? rehabPolyQuery : analoguePolyQuery;

	if (!rehabPoly && !analoguePoly) {
		return (
			<div className="flex size-full items-center justify-center">
				<h1 className="text-center">
					Select a Rehabilitation or Analogue Polygon
				</h1>
			</div>
		);
	}

	return (
		<VegCoverGraph
			error={query.isError ? query.error : undefined}
			data={query.data ?? []}
			isLoading={query.isLoading}
			onExportCSV={async () =>
				await getPolygonVegCovers(rehabPoly?.id, analoguePoly?.id)
			}
			noDataMessage="No Remote Sensing Results have been recorded for this polygon."
			subtitle={rehabPoly ? rehabPoly.name : analoguePoly?.name}
		/>
	);
};

export default RehabPolyVegGraphCard;
