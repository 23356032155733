import { atom } from "jotai";
import { atomWithRefresh } from "jotai/utils";

import { GetEnumAutoCompleteOptions } from "@/helpers/enum-helpers";
import { getWeatherStations } from "@/services/weatherstations.service";
import { WeatherStationProvider } from "@/web-api-client";

export const WeatherStationsAtom = atomWithRefresh(async () => {
	return getWeatherStations().then((data) => {
		return data;
	});
});

export const WeatherStationProviders = atom(async () => {
	return GetEnumAutoCompleteOptions(WeatherStationProvider);
});
