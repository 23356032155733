import { LucideProps } from "lucide-react";
import { ReactSVG } from "react-svg";

import { allIcons, IconName } from ".";

type NameProp = {
	name: IconName;
	src?: never;
};
type SrcProp = {
	name?: never;
	src: string;
};

export type IconProps = Omit<LucideProps, "ref"> & (NameProp | SrcProp);

const checkName = (name: string) => {
	if (Object.keys(allIcons).includes(name)) {
		return name as IconName;
	}
	console.warn(`Icon ${name} not found`);
	return "Ban";
};

const Icon: React.FC<IconProps> = ({ name, src, ...props }) => {
	if (name) {
		const Icon = allIcons[checkName(name)];
		if (typeof Icon === "string") {
			src = Icon;
		} else {
			return <Icon {...props} />;
		}
	}
	if (!src) return null;
	return (
		<ReactSVG
			src={src}
			className={props.className}
			style={{ ...props.style }}
			beforeInjection={(svg) => {
				const width = props.width ?? props.size;
				if (width) svg.setAttribute("width", width.toString());
				const height = props.height ?? props.size;
				if (height) svg.setAttribute("height", height.toString());
				if (props.color) svg.setAttribute("color", props.color);
			}}
		/>
	);
};

export default Icon;
