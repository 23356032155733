import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";
import { useAtom, useSetAtom } from "jotai";
import { useMemo, useState } from "react";

import { ActiveTabAtom, DrawerTabEnum } from "@/atoms/bottomDrawerAtoms";
import { SelectAndZoomToFeatureAtom } from "@/atoms/map/mapEventAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import {
	ObservationSpeciesSamplingFiltersAtom,
	RehabPolyGeometriesAtom,
	SamplingSiteObservationFiltersAtom,
} from "@/atoms/rehabPolyAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import {
	DataTableCustomCell,
	DataTableLargeTextCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import PhotoCarousel from "@/components/PhotoCarousel";
import { Button } from "@/components/ui/button";
import { useFilters } from "@/helpers/filter-hook";
import {
	SamplingSiteObservationPagedDto,
	SamplingsiteObservationsOrderBy,
	samplingSitesGetSamplingSiteObservationsPaginated,
} from "@/lib/gen/eis";
import { SAMPLING_SITE_OBSERVATION_FILTER_OPTIONS } from "@/types/filters/sampling-site-observation-filters";

const SamplingSiteObservationsTableCard = () => {
	const selectFeature = useSetAtom(SelectAndZoomToFeatureAtom);
	const [rehabPolys] = useAtom(RehabPolyGeometriesAtom);
	const [samplingSiteObservationFilters, setSamplingSiteObservationFilters] =
		useFilters(SamplingSiteObservationFiltersAtom);
	const setObservationSpeciesSamplingFilters = useSetAtom(
		ObservationSpeciesSamplingFiltersAtom,
	);
	const setSamplingSite = useSetAtom(SelectedSamplingSiteAtom);

	const samplingSites = rehabPolys.flatMap((rp) => rp.quadrats ?? []);

	const [columnSort, setColumnSort] = useState<ColumnSort>({
		id: "ObservationName",
		desc: false,
	});
	const setActiveTab = useSetAtom(ActiveTabAtom);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"SamplingSitesObservations",
			columnSort,
			samplingSiteObservationFilters,
		],
		queryFn: async ({ pageParam }) => {
			return samplingSitesGetSamplingSiteObservationsPaginated({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: columnSort.id as SamplingsiteObservationsOrderBy,
				descending: columnSort.desc,
				...parseFilterAguments(samplingSiteObservationFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const columns = useMemo<
		ColumnDef<SamplingSiteObservationPagedDto, unknown>[]
	>(() => {
		return [
			{
				accessorKey: "id",
				enableGlobalFilter: true,
				header: () => <div />,
				cell: ({ row }) => {
					const ss = samplingSites.find(
						(rp) => rp.id === row.original.quadratId,
					);
					return (
						<DataTableLocationPinCell
							disabled={ss == null}
							GoToLocation={() => {
								if (!ss) return;
								setSamplingSite(ss);
								selectFeature(ss.id, "samplingsite");
							}}
						/>
					);
				},
				enableSorting: false,
				enableResizing: false,
				minSize: 26,
				maxSize: 26,
				meta: {
					title: "Location",
				},
			},
			{
				accessorKey: "observationName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Name"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell value={row.original?.name ?? "-"} />
				),
				enableSorting: true,
				size: 130,
				meta: {
					title: "Name",
				},
			},
			{
				accessorKey: "samplingSiteName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Sampling Site Name"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.quadratName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 80,
				meta: {
					title: "Sampling Site Name",
				},
			},
			{
				accessorKey: "observationDate",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Observation Date"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={
							row.original?.observationDate
								? new Date(
										row.original?.observationDate,
									).toLocaleDateString("en-AU", {
										year: "numeric",
										month: "short",
										day: "numeric",
									})
								: ""
						}
					/>
				),
				enableSorting: true,
				enableHiding: true,
				size: 100,
				meta: {
					title: "Observation Date",
				},
			},
			{
				accessorKey: "observationOnGroundCampaignName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="On-Ground Campaign"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.ongroundSurveyName ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
				size: 100,
				meta: {
					title: "On-Ground Campaign",
				},
			},
			{
				accessorKey: "observerName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Observer"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.observerName ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
				size: 80,
				meta: {
					title: "Observer",
				},
			},
			{
				accessorKey: "speciesCount",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Species Count"
						columnSortSetter={setColumnSort}
						rightAlign
					/>
				),
				cell: ({ row }) => (
					<DataTableCustomCell className="items-right w-full justify-end text-right">
						<Button
							variant="link"
							onClick={() => {
								setActiveTab({
									module: "Monitoring",
									name: DrawerTabEnum.OBSERVATION_SAMPLING_SPECIES,
								});
								setObservationSpeciesSamplingFilters([
									{
										filter: {
											key: "observationId",
											label: "Observation",
											icon: "observation",
											type: "guid",
										},
										values: [
											{
												value: row.original.id,
												label:
													row.original.name ??
													row.original.id,
											},
										],
										comparator: "EQUALS",
									},
								]);
							}}
						>
							{row.original?.nativeSpeciesCount}
						</Button>
					</DataTableCustomCell>
				),
				enableSorting: true,
				enableHiding: true,
				size: 80,
				meta: {
					title: "Species Count",
				},
			},
			{
				accessorKey: "vegetationCondition",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Vegetation Condition"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.vegetationCondition ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
				size: 100,
				meta: {
					title: "Vegetation Condition",
				},
			},
			{
				accessorKey: "comments",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Comments"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableLargeTextCell
						value={row.original?.comments ?? "-"}
					/>
				),
				enableSorting: false,
				enableHiding: true,
				size: 100,
			},
			{
				accessorKey: "photos",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Photos"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableCustomCell className="w-32">
						{(row.original?.images?.length ?? 0) > 0 && (
							<PhotoCarousel
								className="px-8"
								images={row.original?.images?.map((i) => {
									return {
										thumbnailFileName:
											i.thumbnailFileName ?? undefined,
										fullImageFileName:
											i.fullImageFileName ?? undefined,
										thumbnailBytes:
											i.thumbnailBytes ?? undefined,
									};
								})}
							></PhotoCarousel>
						)}

						{(row.original?.images?.length ?? 0) === 0 && (
							<div>-</div>
						)}
					</DataTableCustomCell>
				),
				enableSorting: false,
				enableHiding: true,
				size: 200,
				minSize: 200,
			},
		];
	}, [
		samplingSites,
		setSamplingSite,
		selectFeature,
		setActiveTab,
		setObservationSpeciesSamplingFilters,
	]);

	return (
		<BottomDrawDataTable
			columns={columns ?? []}
			data={rows}
			totalRows={totalRows}
			title="Observations"
			moduleType="monitoring"
			icon="quadrat"
			isLoading={isLoading || columns == null || columns?.length <= 0}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			filteredSearchBox={{
				options: SAMPLING_SITE_OBSERVATION_FILTER_OPTIONS,
				filters: samplingSiteObservationFilters,
				setFilters: setSamplingSiteObservationFilters,
			}}
			rowHeight={60}
			tabType={DrawerTabEnum.SAMPLING_SITES_OBSERVATIONS}
		/>
	);
};

export default SamplingSiteObservationsTableCard;
