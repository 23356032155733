import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Erosion_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedErosionFeatureIdAtom } from "@/atoms/erosionAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const ErosionFeatureSummaryCard = {
	component: lazy(
		() => import("@/components/Erosion/ErosionFeatureSummaryCard"),
	),
	permission: Perm_Erosion_Std,
} as const satisfies LazyCardComponent;

export const LazyErosionFeatureSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedErosionFeatureId = useAtomValue(SelectedErosionFeatureIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(ErosionFeatureSummaryCard, userPerms);
		if (comp) {
			if (
				currentView &&
				["Erosion"].includes(currentView.name) &&
				selectedErosionFeatureId
			)
				return comp;
		}
		return null;
	}, [currentView, selectedErosionFeatureId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"ErosionFeatureSummaryCard"} />}
		</Suspense>
	);
};
