import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import {
	HealthSurvey1Atom,
	HealthSurvey2Atom,
	SelectedHealthZoneIdAtom,
	SortByValueOrChangeAtom,
	StructureOrConditionSelectionAtom,
} from "@/atoms/healthAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { getLocationMetricResultComparisonColumn } from "@/components/BottomDrawer/Riparian/RiparianZoneTableCard/riparian-zone-columns";
import { DataTableSmallTextCell } from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { EisColumnSort } from "@/components/data-table/table-types";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { getRiparianSurveyComparisonPaginated } from "@/services/ripariansurvey.service";
import {
	RiparianLocationComparisonDto,
	RiparianLocationType,
} from "@/web-api-client";

const RiparianGridTableCard = () => {
	const survey1Atom = useAtomValue(HealthSurvey1Atom);
	const survey2Atom = useAtomValue(HealthSurvey2Atom);
	const structureOrCondition = useAtomValue(
		StructureOrConditionSelectionAtom,
	);
	const [sortByValueOrChange, setSortByValueOrChange] = useAtom(
		SortByValueOrChangeAtom,
	);
	const riparianZoneId = useAtomValue(SelectedHealthZoneIdAtom);

	const [columnSort, setColumnSort] = useState<EisColumnSort>({
		id: "Name",
		desc: false,
	});
	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"RiparianSurvey/surveycomparison/gridcell",
			columnSort,
			survey1Atom,
			survey2Atom,
			structureOrCondition,
			sortByValueOrChange,
			riparianZoneId,
		],
		queryFn: async ({ pageParam }) => {
			return getRiparianSurveyComparisonPaginated(
				survey1Atom?.id,
				survey2Atom?.id,
				{ pageIndex: pageParam, pageSize: 25 },
				columnSort,
				structureOrCondition,
				sortByValueOrChange,
				RiparianLocationType.GridCell,
				riparianZoneId,
			);
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
		enabled: !!survey1Atom && !!riparianZoneId && !!structureOrCondition,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const initialColumns = useMemo<
		ColumnDef<RiparianLocationComparisonDto, unknown>[]
	>(() => {
		return [
			{
				accessorKey: "locationName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Grid Cell"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.locationName}
					/>
				),
				enableSorting: true,
				enableHiding: false,
				size: 100,
			},
		];
	}, []);
	const [columns, setColumns] =
		useState<ColumnDef<RiparianLocationComparisonDto, unknown>[]>(
			initialColumns,
		);

	useEffect(() => {
		const comparisons =
			rows.length > 0 && rows[0].metricComparisons
				? rows[0].metricComparisons
				: [];

		setColumns([
			...initialColumns,
			...comparisons.map((mc) =>
				getLocationMetricResultComparisonColumn<RiparianLocationComparisonDto>(
					mc.name!,
					setColumnSort,
				),
			),
		]);
	}, [initialColumns, rows]);

	if (!riparianZoneId) {
		return (
			<div className="flex size-full flex-col justify-center text-center text-muted-foreground">
				No zone selected.
			</div>
		);
	}

	return (
		<BottomDrawDataTable
			columns={columns ?? []}
			data={rows}
			totalRows={totalRows}
			title="Health Grids"
			moduleType="health"
			icon="riparian_system"
			isLoading={isLoading}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			tabType={DrawerTabEnum.HEALTH_ZONE_COMPARISON_TABLE}
		>
			{survey2Atom && (
				<RadioGroup
					orientation="horizontal"
					defaultValue={sortByValueOrChange}
					className="flex items-center justify-end px-2 py-0.5"
					onValueChange={(e) =>
						setSortByValueOrChange(
							e as "sortByValue" | "sortByChange",
						)
					}
				>
					<div className="flex items-center space-x-2">
						<RadioGroupItem value="sortByValue" id="r1" />
						<Label className="text-xs" htmlFor="r1">
							Sort By Value
						</Label>
					</div>
					<div className="flex items-center space-x-2">
						<RadioGroupItem value="sortByChange" id="r2" />
						<Label className="text-xs" htmlFor="r2">
							Sort By Change
						</Label>
					</div>
				</RadioGroup>
			)}
		</BottomDrawDataTable>
	);
};

export default RiparianGridTableCard;
