import client from "@kubb/plugin-client/client";
import type { RiparianSystemGetRiparianSystemZonesByExtentQueryResponse, RiparianSystemGetRiparianSystemZonesByExtentQueryParams } from "../../ts/RiparianSystemGetRiparianSystemZonesByExtent.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/RiparianSystem/zones/search
 */
export async function riparianSystemGetRiparianSystemZonesByExtent(params?: RiparianSystemGetRiparianSystemZonesByExtentQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSystemGetRiparianSystemZonesByExtentQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSystem/zones/search`, params, ...config });
    return res.data;
}