import { useAtomValue, useSetAtom } from "jotai";
import { memo, useMemo } from "react";

import {
	GoToRiparianZone,
	HealthSurvey2Atom,
	StructureOrConditionSelectionAtom,
} from "@/atoms/healthAtoms";
import { MapAtom } from "@/atoms/mapAtoms";
import { DataTableCustomCell } from "@/components/data-table/data-table-cells";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import Icon from "@/components/icons/icon";
import { getFeatureByIdAndSource } from "@/helpers/map-helpers";
import { LocationMetricResultComparisonDto } from "@/lib/gen/eis";

export const RiparianZoneLocationCell = memo(function ({ id }: { id: string }) {
	const map = useAtomValue(MapAtom);
	const disabled = useMemo(
		() => getFeatureByIdAndSource(map, "healthzone", id) == null,
		[map, id],
	);
	const goToZone = useSetAtom(GoToRiparianZone);

	return (
		<DataTableLocationPinCell
			GoToLocation={() => goToZone(id)}
			disabled={disabled}
		/>
	);
});

export const RiparianZoneLocationMetricResultCell = memo(function ({
	name,
	metricComparisons,
}: {
	name: string;
	metricComparisons: LocationMetricResultComparisonDto[];
}) {
	const survey2Atom = useAtomValue(HealthSurvey2Atom);
	const structureOrCondition = useAtomValue(
		StructureOrConditionSelectionAtom,
	);

	const comparison = metricComparisons.find((x) => x.name === name);

	return (
		<DataTableCustomCell className="truncate font-mono">
			{!survey2Atom && (
				<div>
					{`${(
						Number(comparison?.survey1Result ?? "") *
						(structureOrCondition === "structure" ? 100 : 1)
					).toFixed(1)}%`}
				</div>
			)}

			{survey2Atom && (
				<div className="flex w-full items-center gap-1 whitespace-nowrap">
					<div>{`${(
						Number(comparison?.survey1Result ?? "") *
						(structureOrCondition === "structure" ? 100 : 1)
					).toFixed(1)}%`}</div>
					<div className="flex items-center whitespace-nowrap">
						{" ("}
						<div className="size-5">
							{comparison?.stability === "Rise" ? (
								<Icon
									name="riparian_stability_up"
									className="text-misc-stability-up"
								/>
							) : comparison?.stability === "Fall" ? (
								<Icon
									name="riparian_stability_down"
									className="text-misc-stability-down"
								/>
							) : (
								<Icon
									name="riparian_stability_stable"
									className="text-misc-stability-stable"
								/>
							)}
						</div>

						{`${(
							Number(comparison?.changeValue ?? "") *
							(structureOrCondition === "structure" ? 100 : 1)
						).toFixed(2)}%)`}
					</div>
				</div>
			)}
		</DataTableCustomCell>
	);
});
