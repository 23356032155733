import { atom } from "jotai";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms";

// Base atom for storing popped out tabs
export const poppedOutTabsAtom = atom<Set<DrawerTabEnum>>(
	new Set<DrawerTabEnum>(),
);

// Write-only atom to add a popped out tab
export const addPoppedOutTabAtom = atom(
	null,
	(get, set, tab: DrawerTabEnum) => {
		const currentTabs = new Set(get(poppedOutTabsAtom));
		currentTabs.add(tab);
		set(poppedOutTabsAtom, currentTabs);
	},
);

// Write-only atom to remove a popped out tab
export const removePoppedOutTabAtom = atom(
	null,
	(get, set, tab: DrawerTabEnum) => {
		const currentTabs = new Set(get(poppedOutTabsAtom));
		currentTabs.delete(tab);
		set(poppedOutTabsAtom, currentTabs);
	},
);
