import { Coordinate } from "ol/coordinate";
import { getCenter } from "ol/extent";
import { FeatureLike } from "ol/Feature";
import { Geometry, LineString, MultiPolygon, Point, Polygon } from "ol/geom";

import { Geometry as EISGeometry } from "@/lib/gen/eis";

export const GetOLGeometry = (geometry: EISGeometry | null | undefined) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const geom: any = geometry;
	if (geom?.type === "Point") {
		return new Point(geom["coordinates"] as Coordinate);
	} else if (geom?.type === "Polygon") {
		return new Polygon(geom["coordinates"] as Coordinate[][]);
	} else if (geom?.type === "MultiPolygon") {
		return new MultiPolygon(geom["coordinates"] as Coordinate[][][]);
	} else if (geom?.type === "LineString") {
		return new LineString(geom["coordinates"] as Coordinate[]);
	}
	return undefined;
};

export const updateOLGeometry = (
	currentGeometry: Geometry,
	geometry: EISGeometry | null | undefined,
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const geom: any = geometry;
	if (geom?.type === "Point" && currentGeometry instanceof Point) {
		currentGeometry.setCoordinates(geom["coordinates"] as Coordinate);
	} else if (geom?.type === "Polygon" && currentGeometry instanceof Polygon) {
		currentGeometry.setCoordinates(geom["coordinates"] as Coordinate[][]);
	} else if (
		geom?.type === "MultiPolygon" &&
		currentGeometry instanceof MultiPolygon
	) {
		currentGeometry.setCoordinates(geom["coordinates"] as Coordinate[][][]);
	} else if (
		geom?.type === "LineString" &&
		currentGeometry instanceof LineString
	) {
		currentGeometry.setCoordinates(geom["coordinates"] as Coordinate[]);
	}
	return undefined;
};

export const geometryToPoint = (feature: FeatureLike) => {
	const geom = feature.getGeometry();
	if (geom instanceof Polygon) {
		const extent = geom.getExtent();
		return new Point(getCenter(extent));
	} else if (geom instanceof MultiPolygon) {
		const extent = geom.getExtent();
		return new Point(getCenter(extent));
	} else if (geom instanceof Point) {
		return geom;
	} else {
		throw new Error(
			"Geometry type is not a point, polygon or multi-polygon",
		);
	}
};
