import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Collection } from "ol";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

import { AnalogueClassificationUrlAtom } from "@/atoms/map/imageryUrlAtoms";
import { CreateImageryXyzLayer } from "@/atoms/map/layerAtoms";
import {
	RefreshVegClassListAtom,
	VisibleVegClassGridCodesAtom,
} from "@/atoms/map/vegClassAtoms";
import { ArcGisTokenQueryAtom } from "@/atoms/mapAtoms";

import { GetColorMap } from "./classification-layer";
import {
	ImageryAtomEffectFunction,
	PopulateImageryLayer,
} from "./imagery-layer-helpers";
import { SwipeBarAtom } from "./swipe-bar";

export const AnalogueClassificationLayerGroupAtom = atom(() => {
	return new LayerGroup({ layers: new Collection<TileLayer<XYZ>>() });
});

export const AnalogueClassificationLayerAtomEffect = atomEffect((get, set) => {
	const urls = get(AnalogueClassificationUrlAtom);
	const group = get(AnalogueClassificationLayerGroupAtom);
	const swipeBar = get(SwipeBarAtom);

	ImageryAtomEffectFunction(get, group, urls, (get, img) => {
		const { data: arcgisToken } = get(ArcGisTokenQueryAtom);
		const { source, bounds } = PopulateImageryLayer(get, img, arcgisToken);
		if (!source) return;
		return CreateImageryXyzLayer(source, {
			extent: bounds,
			opacity: 0.6,
			preload: 2,
		});
	});
	// trigger atom effect to add color map
	set(RefreshVegClassListAtom);

	group.getLayers().forEach((l) => {
		if (l instanceof TileLayer) {
			swipeBar.addLayer(l, true);
		}
	});

	return () => {
		group.getLayers().forEach((l) => {
			if (l instanceof TileLayer) {
				swipeBar.removeLayer(l);
			}
		});
	};
});

const _analogueClassificationLayerVisibility = atom(true);
export const AnalogueClassificationLayerVisibility = atom(
	(get) => {
		return get(_analogueClassificationLayerVisibility);
	},
	(get, set, value?: boolean) => {
		const layer = get(AnalogueClassificationLayerGroupAtom);
		const bool = value ?? !layer.getVisible();
		set(_analogueClassificationLayerVisibility, bool);
		layer.setVisible(bool);
	},
);

export const AnalogueClassificationLayerColorMapAtomEffects = atomEffect(
	(get) => {
		const visibleVegClasses = get(VisibleVegClassGridCodesAtom);
		const group = get(AnalogueClassificationLayerGroupAtom);

		for (const layer of group.getLayersArray()) {
			if (!(layer instanceof TileLayer)) continue;
			const source: XYZ = layer.getSource();
			const urls = source.getUrls();
			if (!source || !urls) return;
			const newUrls = urls?.flatMap((url) => {
				const colourMap = GetColorMap(visibleVegClasses);
				let newUrl = url.split("&colormap=")[0];
				newUrl = newUrl + colourMap;
				return [newUrl];
			});
			source.setUrls(newUrls);
		}
		group.changed();
	},
);
