import type { GetSamplingSiteIdsQuery } from "../ts/GetSamplingSiteIdsQuery.ts";
import { doubleFilterSchema } from "./doubleFilterSchema.ts";
import { enumFilterOfSamplingSiteTypeSchema } from "./enumFilterOfSamplingSiteTypeSchema.ts";
import { enumFilterOfSiteSamplingMethodSchema } from "./enumFilterOfSiteSamplingMethodSchema.ts";
import { guidFilterSchema } from "./guidFilterSchema.ts";
import { nullableEnumFilterOfSamplingSiteLandformTypeSchema } from "./nullableEnumFilterOfSamplingSiteLandformTypeSchema.ts";
import { nullableEnumFilterOfSlopeAspectSchema } from "./nullableEnumFilterOfSlopeAspectSchema.ts";
import { stringFilterSchema } from "./stringFilterSchema.ts";
import { z } from "zod";

 export const getSamplingSiteIdsQuerySchema = z.object({ "usePolygonFilters": z.boolean(), "samplingSiteName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "rehabilitationPolygonName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "mineSiteName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "miningRegionName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "siteSamplingMethod": z.lazy(() => enumFilterOfSiteSamplingMethodSchema).nullable().nullish(), "slopeAspect": z.lazy(() => nullableEnumFilterOfSlopeAspectSchema).nullable().nullish(), "samplingSiteLandformType": z.lazy(() => nullableEnumFilterOfSamplingSiteLandformTypeSchema).nullable().nullish(), "samplingSiteType": z.lazy(() => enumFilterOfSamplingSiteTypeSchema).nullable().nullish(), "area": z.array(z.lazy(() => doubleFilterSchema)).nullable().nullish(), "samplingSiteId": z.lazy(() => guidFilterSchema).nullable().nullish() }) as z.ZodType<GetSamplingSiteIdsQuery>;