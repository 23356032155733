import { format, parseISO } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

import { ValueRendererProps } from "./filter-search-box-types";

export const DateValueRenderer = ({ value, onChange }: ValueRendererProps) => {
	const [date, setDate] = useState<Date>(parseISO(value) ?? new Date());

	useEffect(() => {
		setDate(parseISO(value) ?? new Date());
	}, [value]);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant="min"
					size="min"
					className={cn(
						"w-[110px] justify-start px-1 text-left font-normal",
						!date && "text-muted-foreground",
					)}
				>
					<CalendarIcon className="mr-2 size-4" />
					{format(date, "yyyy-MM-dd")}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0" align="start">
				<Calendar
					mode="single"
					selected={date}
					onSelect={(_, date) => {
						setDate(date);
						onChange(format(date, "yyyy-MM-dd"));
					}}
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	);
};
