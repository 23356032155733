import { DoubleFilter, StringFilter } from "@/lib/gen/eis";
import { IntFilter } from "@/web-api-client";

import { ColumnFilter } from "./filter-search-box-types";

/** Converts an array of ColumnFilters into data that can be passed into a request. */
export function parseFilterAguments<T extends string>(
	filters: ColumnFilter<T>[],
) {
	return filters.reduce(
		(m, f) => {
			const c = m[f.filter.key];

			switch (f.filter.type) {
				case "boolean":
					m[f.filter.key] =
						f.values.length > 0 && f.values[0].value === "true";
					break;
				case "number":
				case "area":
				case "distance":
				case "percentage":
					if (f.comparator == null) return m;

					if (f.filter.subKey) {
						if (m[f.filter.key] == null) m[f.filter.key] = [];
						(
							m[f.filter.key] as {
								key: string;
								value: DoubleFilter;
							}[]
						).push({
							key: f.filter.subKey,
							value: {
								values: f.values.flatMap((v) => {
									if (v.value == null) return [];
									return [
										f.filter.type === "percentage"
											? +v.value / 100
											: +v.value,
									];
								}),
								comparator: f.comparator,
							},
						});
					} else {
						m[f.filter.key] = [
							...(c !== null && Array.isArray(c)
								? (c as DoubleFilter[])
								: []),
							{
								values: f.values.flatMap((v) => {
									if (v.value == null) return [];
									return [
										f.filter.type === "percentage"
											? +v.value / 100
											: +v.value,
									];
								}),
								comparator: f.comparator,
							},
						];
					}
					break;
				case "enum":
				case "guid":
					if (f.comparator == null) return m;

					if (f.filter.subKey) {
						if (m[f.filter.key] == null) m[f.filter.key] = [];
						(
							m[f.filter.key] as {
								key: string;
								value: StringFilter;
							}[]
						).push({
							key: f.filter.subKey,
							value: {
								values: f.values.flatMap((v) =>
									v.value ? [v.value] : [],
								),
								comparator: f.comparator,
							},
						});
					} else {
						m[f.filter.key] = {
							values: f.values.flatMap((v) =>
								v.value ? [v.value] : [],
							),
							comparator: f.comparator,
						};
					}
					break;
				default:
					if (f.comparator == null) return m;

					if (f.filter.subKey) {
						if (m[f.filter.key] == null) m[f.filter.key] = [];
						(
							m[f.filter.key] as {
								key: string;
								value: StringFilter;
							}[]
						).push({
							key: f.filter.subKey,
							value: {
								values: f.values.flatMap((v) =>
									v.value ? [v.value] : [],
								),
								comparator: f.comparator,
							},
						});
					} else {
						m[f.filter.key] = [
							...(c !== null && Array.isArray(c)
								? (c as StringFilter[])
								: []),
							{
								values: f.values.flatMap((v) =>
									v.value ? [v.value] : [],
								),
								comparator: f.comparator,
							},
						];
					}
					break;
			}
			return m;
		},
		{} as {
			[key: string]:
				| StringFilter[]
				| DoubleFilter[]
				| IntFilter[]
				| StringFilter
				| { key: string; value: StringFilter }[]
				| boolean;
		},
	);
}
