"use client";

import { Table } from "@tanstack/react-table";

import { Skeleton } from "@/components/ui/skeleton";
import { TableCell, TableSkeletonRow } from "@/components/ui/table";
import { cn } from "@/lib/utils";

interface DataTableSkeletonProps<TData> {
	table: Table<TData>;
	pageSize: number;
	rowHeight: number;
}

export function DataTableSkeleton<TData>({
	table,
	pageSize,
	rowHeight,
}: DataTableSkeletonProps<TData>) {
	return (
		<>
			{Array.from({
				length: pageSize,
			}).map((_, i) => (
				<TableSkeletonRow key={i}>
					{table.getAllColumns().flatMap((column) => {
						if (!column.getIsVisible()) {
							return [];
						}
						return [
							<TableCell
								key={column.id}
								style={{ height: rowHeight }}
							>
								<Skeleton
									className={cn(
										"h-full",
										column.columnDef.meta?.className,
									)}
								/>
							</TableCell>,
						];
					})}
				</TableSkeletonRow>
			))}
		</>
	);
}
