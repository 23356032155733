import client from "@kubb/plugin-client/client";
import type { ErosionGetErosionFeaturesPaginatedMutationRequest, ErosionGetErosionFeaturesPaginatedMutationResponse } from "../../ts/ErosionGetErosionFeaturesPaginated.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Erosion/paginated
 */
export async function erosionGetErosionFeaturesPaginated(data: ErosionGetErosionFeaturesPaginatedMutationRequest, config: Partial<RequestConfig<ErosionGetErosionFeaturesPaginatedMutationRequest>> = {}) {
    const res = await client<ErosionGetErosionFeaturesPaginatedMutationResponse, Error, ErosionGetErosionFeaturesPaginatedMutationRequest>({ method: "POST", url: `/api/Erosion/paginated`, data, ...config });
    return res.data;
}