import { ColumnDef, ColumnSort } from "@tanstack/react-table";

import {
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { AnaloguePolygonPagedDto, analoguePolysOrderBy } from "@/lib/gen/eis";

import {
	AnaloguePolygonLocationCell,
	AnaloguePolygonNameCell,
} from "./analogue-polygon-table-cells";

export const getAnaloguePolygonColumns = (
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>,
): ColumnDef<AnaloguePolygonPagedDto, unknown>[] => [
	{
		accessorKey: "id",
		enableGlobalFilter: true,
		header: () => <div />,
		cell: ({ row }) => <AnaloguePolygonLocationCell id={row.original.id} />,
		enableSorting: false,
		enableResizing: false,
		minSize: 26,
		maxSize: 26,
		meta: {
			title: "Location",
		},
	},
	{
		accessorKey: "name",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Polygon"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<AnaloguePolygonNameCell
				id={row.original.id}
				name={row.original.name ?? ""}
			/>
		),
		enableSorting: true,
		size: 110,
		meta: {
			title: "Polygon",
		},
	},
	{
		accessorKey: "miningRegion",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Region"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.miningRegion ?? "-"} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Region",
		},
	},
	{
		accessorKey: "mineSite",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Site"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.mineSite ?? ""} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Site",
		},
	},
	{
		accessorKey: "landform",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Landform"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.landform} />
		),
		enableSorting: true,
		enableHiding: true,
		size: 80,
	},
	{
		accessorKey: "area",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Area (ha)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell
				value={Number(row.original?.area ?? 0) / 10000}
			/>
		),
		enableSorting: true,
		enableHiding: true,
		size: 80,
	},
];

export const getAnaloguePolygonVegCoverColumn = (
	vegetationClassName: string,
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>,
): ColumnDef<AnaloguePolygonPagedDto, unknown> => ({
	accessorKey: vegetationClassName,
	header: ({ column }) => (
		<DataTableColumnHeader
			column={column}
			title={`${vegetationClassName} %`}
			columnSortSetter={setColumnSort}
			isDynamicColumn={true}
			orderById={analoguePolysOrderBy.VegClassCoverDynamicColumn}
			rightAlign
		/>
	),
	cell: ({ row }) => (
		<DataTableNumberCell
			value={Number(
				row.original.vegClassCovers?.find(
					(x) => x.vegetationClassName === vegetationClassName,
				)?.percentageCover ?? 0,
			)}
			numStyle="percent"
		/>
	),
	enableSorting: true,
	size: 80,
	meta: {
		columnGroup: "Classification Covers",
	},
});
