import { z } from "zod";

import type { TmsBoundingBoxOutput } from "@/lib/gen/titiler/types/TmsBoundingBoxOutput";

import { crsSchema } from "./crsSchema";

/**
 * @description Bounding box\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/2DBoundingBox.json
 */
export const tmsBoundingBoxOutputSchema = z
	.object({
		lowerLeft: z
			.tuple([
				z.union([z.number().int(), z.number()]),
				z.union([z.number().int(), z.number()]),
			])
			.describe("A 2D Point in the CRS indicated elsewhere"),
		upperRight: z
			.tuple([
				z.union([z.number().int(), z.number()]),
				z.union([z.number().int(), z.number()]),
			])
			.describe("A 2D Point in the CRS indicated elsewhere"),
		crs: z
			.union([z.lazy(() => crsSchema), z.null()])
			.describe("Coordinate Reference System (CRS)")
			.optional(),
		orderedAxes: z
			.union([z.array(z.string()).min(2).max(2), z.null()])
			.describe(
				"Ordered list of names of the dimensions defined in the CRS",
			)
			.optional(),
	})
	.describe(
		"Bounding box\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/2DBoundingBox.json",
	) as z.ZodType<TmsBoundingBoxOutput>;
