import client from "@kubb/plugin-client/client";
import type { MonitoringScheduleGetFilteredExportMutationRequest, MonitoringScheduleGetFilteredExportMutationResponse } from "../../ts/MonitoringScheduleGetFilteredExport.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/MonitoringSchedule/filtered-export
 */
export async function monitoringScheduleGetFilteredExport(data: MonitoringScheduleGetFilteredExportMutationRequest, config: Partial<RequestConfig<MonitoringScheduleGetFilteredExportMutationRequest>> = {}) {
    const res = await client<MonitoringScheduleGetFilteredExportMutationResponse, Error, MonitoringScheduleGetFilteredExportMutationRequest>({ method: "POST", url: `/api/MonitoringSchedule/filtered-export`, data, ...config });
    return res.data;
}