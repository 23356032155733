import client from "@kubb/plugin-client/client";
import type { SamplingSitesGetSamplingSiteIdsMutationRequest, SamplingSitesGetSamplingSiteIdsMutationResponse } from "../../ts/SamplingSitesGetSamplingSiteIds.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/SamplingSites/getids
 */
export async function samplingSitesGetSamplingSiteIds(data: SamplingSitesGetSamplingSiteIdsMutationRequest, config: Partial<RequestConfig<SamplingSitesGetSamplingSiteIdsMutationRequest>> = {}) {
    const res = await client<SamplingSitesGetSamplingSiteIdsMutationResponse, Error, SamplingSitesGetSamplingSiteIdsMutationRequest>({ method: "POST", url: `/api/SamplingSites/getids`, data, ...config });
    return res.data;
}