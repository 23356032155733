import api from "@/api";
import { prepareForUtcConversion } from "@/lib/utils";
import {
	WeedPatchDto,
	WeedsDashboardKeyDetailsDto,
	WeedsOngroundSurveyResultDto,
	WeedsSurveyDetailDto,
	WeedsSurveyDto,
	WeedsSurveyOrderBy,
} from "@/web-api-client";

export const getWeedPatches = async (id: string): Promise<WeedPatchDto[]> => {
	const response = await api().get("api/WeedsSurveys/weedpatchesbyextent", {
		params: { WeedSurveyId: id },
	});
	return response.data;
};
export const getWeedsKeyDetails = async (
	mineSiteId: string,
	weedSurveyId: string,
): Promise<WeedsDashboardKeyDetailsDto> => {
	const response = await api().get<WeedsDashboardKeyDetailsDto>(
		"api/WeedsSurveys/summary",
		{ params: { weedSurveyId: weedSurveyId, mineSiteId: mineSiteId } },
	);
	return response.data;
};

export const getWeedSurveysWithPagination = async (
	mineSiteId: string | undefined,
): Promise<WeedsSurveyDto[]> => {
	const response = await api().get("api/WeedsSurveys", {
		params: {
			mineSiteId: mineSiteId,
			pageSize: -1,
			orderBy: WeedsSurveyOrderBy.PublicationDate,
			descending: true,
		},
	});
	return response.data.items;
};

export const createEditWeedSurvey = async (form: {
	reportTitle?: string | undefined;
	id?: string | undefined;
	publicationDate: Date;
	citation?: string | undefined;
	comments?: string | undefined;
	imageryCaptureId: string;
	mineSiteId: string;
	classificationTileLayerUrl?: string | undefined;
	classificationTileLayerUrlType?: string | undefined;
}): Promise<string> => {
	if (form.publicationDate) {
		form.publicationDate = prepareForUtcConversion(form.publicationDate);
	}

	const content = JSON.stringify(form);
	const response = await api().post("api/WeedsSurveys", content, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});
	return response.data;
};

export const uploadPatches = async (
	weedSurveyId: string,
	patchesShapeFile: File,
) => {
	const content = new FormData();
	content.append("WeedSurveyId", weedSurveyId);
	content.append(
		"PatchesShapeFileArchive",
		patchesShapeFile,
		patchesShapeFile.name
			? patchesShapeFile.name
			: "PatchesShapeFileArchive",
	);
	content.append("Srid", "28351");
	const response = await api().post("/api/WeedsSurveys/patches", content, {
		headers: {
			Accept: "application/json",
		},
	});
	return response.data;
};

export const getWeedsRemoteSensingSurvey = async (
	id: string,
): Promise<WeedsSurveyDetailDto> => {
	const response = await api().get("/api/WeedsSurveys/" + id, {
		headers: {
			Accept: "application/json",
		},
	});
	return response.data;
};

export const deleteWeedsOnGroundSurvey = async (id: string) => {
	const response = await api().delete("/api/WeedsSurveys/onground/" + id, {
		headers: {
			Accept: "application/json",
		},
	});
	return response.data;
};

export const uploadOngroundWeedSurvey = async (
	mineSiteId: string,
	reportTitle: string,
	startDate: Date | undefined,
	endDate: Date | undefined,
	comments: string | undefined,
	surveyAreaShapeFile: File,
	weedSamplingsCsvFile: File,
	imageFilesArchive: File,
	forceUnfoundSpecies: boolean,
): Promise<WeedsOngroundSurveyResultDto> => {
	const content = new FormData();
	content.append("MineSiteId", mineSiteId);
	if (reportTitle !== null && reportTitle !== undefined)
		content.append("ReportTitle", reportTitle.toString());
	if (comments !== null && comments !== undefined)
		content.append("Comments", comments.toString());
	if (startDate !== null && startDate !== undefined) {
		startDate = prepareForUtcConversion(startDate);
		content.append("StartDate", startDate.toJSON());
	}
	if (endDate !== null && endDate !== undefined) {
		endDate = prepareForUtcConversion(endDate);
		content.append("StartDate", endDate.toJSON());
	}
	content.append(
		"SurveyAreaShapeFile",
		surveyAreaShapeFile,
		surveyAreaShapeFile.name
			? surveyAreaShapeFile.name
			: "SurveyAreaShapeFile",
	);
	content.append(
		"WeedSamplingsCsvFile",
		weedSamplingsCsvFile,
		weedSamplingsCsvFile.name
			? weedSamplingsCsvFile.name
			: "WeedSamplingsCsvFile",
	);
	content.append(
		"ImageFilesArchive",
		imageFilesArchive,
		imageFilesArchive.name ? imageFilesArchive.name : "ImageFilesArchive",
	);
	content.append("ForceUnfoundSpecies", forceUnfoundSpecies.toString());

	const response = await api().post("/api/WeedsSurveys/onground", content, {
		headers: {
			Accept: "application/json",
		},
	});
	return response.data;
};

export const deleteWeedsRemoteSensingSurvey = async (id: string) => {
	const response = await api().delete("/api/WeedsSurveys/" + id, {
		headers: {
			Accept: "application/json",
		},
	});
	return response.data;
};
