import { FieldValues, Path, useFormContext } from "react-hook-form";

import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input, NumberInput } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";

type Props<T extends FieldValues> = {
	name: Path<T>;
	label: string;
	description?: string;
	className?: string;
};

export const TextInputFormField = <T extends FieldValues>({
	name,
	label,
	className,
	description,
}: Props<T>) => {
	const {
		control,
		formState: { disabled },
	} = useFormContext<T>();

	return (
		<FormField
			control={control}
			name={name}
			disabled={disabled}
			render={({ field }) => (
				<FormItem className={cn("", className)}>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Input
							{...field}
							value={field.value ?? ""}
							className="bg-background"
						/>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export const LargeTextInputFormField = <T extends FieldValues>({
	name,
	label,
	className,
	description,
}: Props<T>) => {
	const {
		control,
		formState: { disabled },
	} = useFormContext<T>();

	return (
		<FormField
			control={control}
			name={name}
			disabled={disabled}
			render={({ field }) => (
				<FormItem className={cn("", className)}>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Textarea
							{...field}
							value={field.value ?? ""}
							className="bg-background"
						/>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export const NumberInputFormField = <T extends FieldValues>({
	name,
	label,
	className,
	description,
	children,
	affix,
	type = "float",
}: Props<T> & {
	type?: "float" | "integer";
	children?: React.ReactNode;
	affix?: "prefix" | "suffix";
}) => {
	const {
		control,
		formState: { disabled },
	} = useFormContext<T>();

	return (
		<FormField
			control={control}
			name={name}
			disabled={disabled}
			render={({ field }) => (
				<FormItem className={cn("", className)}>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<NumberInput
							{...field}
							value={field.value?.toString()}
							onChange={(e) => {
								const value =
									type === "float"
										? parseFloat(e.target.value)
										: parseInt(e.target.value);
								field.onChange(value);
							}}
							affix={affix}
							className="bg-background"
						>
							{children}
						</NumberInput>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export const PercentInputFormField = <T extends FieldValues>({
	name,
	label,
	className,
	description,
}: Props<T>) => {
	const {
		control,
		formState: { disabled },
	} = useFormContext<T>();

	return (
		<FormField
			control={control}
			name={name}
			disabled={disabled}
			render={({ field }) => (
				<FormItem className={cn("", className)}>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<NumberInput
							{...field}
							value={
								field.value
									? (field.value * 100).toString()
									: ""
							}
							onChange={(e) => {
								field.onChange(e.target.valueAsNumber / 100);
							}}
							className="bg-background"
						>
							%
						</NumberInput>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
