import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";

import Icon from "@/components/icons/icon";
import { cn } from "@/lib/utils";

const Checkbox = React.forwardRef<
	React.ElementRef<typeof CheckboxPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
	<CheckboxPrimitive.Root
		ref={ref}
		className={cn(
			"flex size-4 shrink-0 items-center rounded-[1px] border border-core-check-background focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
			props.checked ? "bg-core-check-background text-white" : "",
			className,
		)}
		{...props}
	>
		<CheckboxPrimitive.Indicator className="-mx-px flex items-center justify-center">
			{props.checked === "indeterminate" ? (
				<Icon
					name="Minus"
					className="flex size-4 items-center justify-center text-current"
				/>
			) : (
				<Icon
					name="check"
					className="flex size-4 items-center justify-center text-current"
				/>
			)}
		</CheckboxPrimitive.Indicator>
	</CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
