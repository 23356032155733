import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Rehab_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedErosionFeatureIdAtom } from "@/atoms/erosionAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import { SelectedRehabPolygonAtom } from "@/atoms/rehabPolyAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const RehabPolyCriteriaCard = {
	component: lazy(() => import("@/components/Polygon/RehabPolyCriteriaCard")),
	permission: Perm_Rehab_Std,
} as const satisfies LazyCardComponent;

export const LazyRehabPolyCriteriaCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedRehabPoly = useAtomValue(SelectedRehabPolygonAtom);
	const selectedSamplingSite = useAtomValue(SelectedSamplingSiteAtom);
	const selectedErosionFeatureId = useAtomValue(SelectedErosionFeatureIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(RehabPolyCriteriaCard, userPerms);
		if (comp) {
			if (
				currentView?.name === "Monitoring" &&
				selectedRehabPoly &&
				!selectedSamplingSite
			)
				return comp;
			if (
				currentView?.name === "Erosion" &&
				selectedRehabPoly &&
				!selectedErosionFeatureId
			)
				return comp;
		}
		return null;
	}, [
		currentView?.name,
		selectedErosionFeatureId,
		selectedRehabPoly,
		selectedSamplingSite,
		userPerms,
	]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"RehabPolyCriteriaCard"} />}
		</Suspense>
	);
};
