import type { GetRehabMetricsQuery } from "../ts/GetRehabMetricsQuery.ts";
import { criteriaStatusFiltersDtoSchema } from "./criteriaStatusFiltersDtoSchema.ts";
import { dateOnlyFilterSchema } from "./dateOnlyFilterSchema.ts";
import { doubleFilterSchema } from "./doubleFilterSchema.ts";
import { enumFilterOfPolygonLandformTypeSchema } from "./enumFilterOfPolygonLandformTypeSchema.ts";
import { enumFilterOfPolygonOverallResultSchema } from "./enumFilterOfPolygonOverallResultSchema.ts";
import { enumFilterOfRehabilitationPolygonStatusSchema } from "./enumFilterOfRehabilitationPolygonStatusSchema.ts";
import { intFilterSchema } from "./intFilterSchema.ts";
import { stringFilterSchema } from "./stringFilterSchema.ts";
import { vegClassCoverFiltersDtoSchema } from "./vegClassCoverFiltersDtoSchema.ts";
import { z } from "zod";

 export const getRehabMetricsQuerySchema = z.object({ "miningRegion": z.string().nullable().nullish(), "mineSite": z.string().nullable().nullish(), "timeSeriesDate": z.string().datetime({ offset: true }), "rehabilitationPolygonIds": z.array(z.string()).nullable().nullish(), "polygonName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "polygonTag": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "rehabPhaseName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "mineSiteName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "miningRegionName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "disturbanceZoneName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "polygonLabel": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "landform": z.lazy(() => enumFilterOfPolygonLandformTypeSchema).nullable().nullish(), "polygonOverallResult": z.lazy(() => enumFilterOfPolygonOverallResultSchema).nullable().nullish(), "status": z.lazy(() => enumFilterOfRehabilitationPolygonStatusSchema).nullable().nullish(), "quadrats": z.array(z.lazy(() => intFilterSchema)).nullable().nullish(), "siteInspections": z.array(z.lazy(() => intFilterSchema)).nullable().nullish(), "erosionFeatures": z.array(z.lazy(() => intFilterSchema)).nullable().nullish(), "area": z.array(z.lazy(() => doubleFilterSchema)).nullable().nullish(), "rehabilitatedDate": z.array(z.lazy(() => dateOnlyFilterSchema)).nullable().nullish(), "criteriaStatusQuery": z.array(z.lazy(() => criteriaStatusFiltersDtoSchema)).nullable().nullish(), "vegClassCoverQuery": z.array(z.lazy(() => vegClassCoverFiltersDtoSchema)).nullable().nullish(), "mineSiteId": z.string().nullable().nullish(), "hasSeedApplications": z.boolean().nullable().nullish() }) as z.ZodType<GetRehabMetricsQuery>;