"use client";

import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
	const { theme = "system" } = useTheme();

	return (
		<Sonner
			theme={theme as ToasterProps["theme"]}
			className="toaster group"
			toastOptions={{
				classNames: {
					toast: "group toast group-[.toaster]:shadow-lg bg-[#F9FFFD]",
					description: " font-normal ",
					title: " font-medium ",
					success: "!bg-[#52A85A] text-white ",
					info: "!bg-[#59ADD2] text-white",
					error: "!bg-[#DB5050] text-white ",
				},
			}}
			{...props}
		/>
	);
};

export { Toaster };
