import client from "@kubb/plugin-client/client";
import type { PredicateBuilderGetTypeAheadResultsQueryResponse, PredicateBuilderGetTypeAheadResultsQueryParams } from "../../ts/PredicateBuilderGetTypeAheadResults.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/PredicateBuilder
 */
export async function predicateBuilderGetTypeAheadResults(params?: PredicateBuilderGetTypeAheadResultsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<PredicateBuilderGetTypeAheadResultsQueryResponse, Error, unknown>({ method: "GET", url: `/api/PredicateBuilder`, params, ...config });
    return res.data;
}