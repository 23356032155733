import client from "@kubb/plugin-client/client";
import type { IndividualTreesCreateMutationRequest, IndividualTreesCreateMutationResponse } from "../../ts/IndividualTreesCreate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/IndividualTrees
 */
export async function individualTreesCreate(data: IndividualTreesCreateMutationRequest, config: Partial<RequestConfig<IndividualTreesCreateMutationRequest>> = {}) {
    const res = await client<IndividualTreesCreateMutationResponse, Error, IndividualTreesCreateMutationRequest>({ method: "POST", url: `/api/IndividualTrees`, data, ...config });
    return res.data;
}