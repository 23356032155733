import { PrimitiveAtom, useAtom } from "jotai";
import { useState } from "react";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import { useInWindow } from "@/components/PoppedOutWindows";

export function useFilters<T extends string>(
	atom: PrimitiveAtom<ColumnFilter<T>[]>,
): [
	ColumnFilter<T>[],
	(
		setter:
			| ColumnFilter<T>[]
			| ((prev: ColumnFilter<T>[]) => ColumnFilter<T>[]),
	) => void,
] {
	const [filtersWindow, setFiltersWindow] = useState<ColumnFilter<T>[]>([]);
	const [filtersAtom, setFiltersAtom] = useAtom(atom);
	const inWindow = useInWindow();

	return [
		inWindow ? filtersWindow : filtersAtom,
		inWindow ? setFiltersWindow : setFiltersAtom,
	];
}
