import { atom } from "jotai";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import { GetSiteInspectionsQuery, PolySiteInspectionDto } from "@/lib/gen/eis";

export const SelectedSiteInspectionAtom = atom<
	PolySiteInspectionDto | undefined
>(undefined);

// keep track of tables' filters
export type SiteInspectionArg = keyof GetSiteInspectionsQuery;
export const SiteInspectionFiltersAtom = atom<
	ColumnFilter<SiteInspectionArg>[]
>([]);
