import client from "@kubb/plugin-client/client";
import type { ClientConfigurationGetClientLogoQueryResponse } from "../../ts/ClientConfigurationGetClientLogo.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/ClientConfiguration
 */
export async function clientConfigurationGetClientLogo(config: Partial<RequestConfig> = {}) {
    const res = await client<ClientConfigurationGetClientLogoQueryResponse, Error, unknown>({ method: "GET", url: `/api/ClientConfiguration`, ...config });
    return res.data;
}