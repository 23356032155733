import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { ObservationSpeciesSamplingFiltersAtom } from "@/atoms/rehabPolyAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import {
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useFilters } from "@/helpers/filter-hook";
import {
	ObservationSpeciesSamplingDto,
	ObservationSpeciesSamplingOrderBy,
	samplingSitesGetSpeciesSampling,
} from "@/lib/gen/eis";
import { OBSERVATION_SPECIES_SAMPLING_FILTER_OPTIONS } from "@/types/filters/observation-species-sampling-filters";

const ObservationSamplingSpeciesTableCard = () => {
	const [columnSort, setColumnSort] = useState<ColumnSort>({
		id: "ObservationName",
		desc: false,
	});
	const [
		observationSpeciesSamplingFilters,
		setObservationSpeciesSamplingFilters,
	] = useFilters(ObservationSpeciesSamplingFiltersAtom);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"ObservationSpeciesSampling",
			columnSort,
			observationSpeciesSamplingFilters,
		],
		queryFn: async ({ pageParam }) => {
			return samplingSitesGetSpeciesSampling({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: columnSort.id as ObservationSpeciesSamplingOrderBy,
				descending: columnSort.desc,
				...parseFilterAguments(observationSpeciesSamplingFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const columns = useMemo<
		ColumnDef<ObservationSpeciesSamplingDto, unknown>[]
	>(() => {
		return [
			{
				accessorKey: "observationName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Observation Name"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.quadratObservationName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 140,
				meta: {
					title: "Observation Name",
				},
			},
			{
				accessorKey: "family",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Family"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						className="italic"
						value={row.original?.familyName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 120,
			},
			{
				accessorKey: "genus-species",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Genus-Species"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						className="italic"
						value={row.original?.fullName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 180,
			},
			{
				accessorKey: "cover",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Cover (%)"
						columnSortSetter={setColumnSort}
						rightAlign
					/>
				),
				cell: ({ row }) => (
					<DataTableNumberCell value={row.original?.cover} />
				),
				enableSorting: true,
				enableHiding: true,
				size: 90,
			},
			{
				accessorKey: "height",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Height (m)"
						columnSortSetter={setColumnSort}
						rightAlign
					/>
				),
				cell: ({ row }) => (
					<DataTableNumberCell value={row.original?.averageHeight} />
				),
				enableSorting: true,
				enableHiding: true,
				size: 90,
			},
			{
				accessorKey: "density",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Density (Plant/ha)"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.density ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
				size: 90,
			},
			{
				accessorKey: "comments",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Comments"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.comments ?? "-"}
					/>
				),
				enableSorting: false,
				enableHiding: true,
				size: 120,
			},
		];
	}, []);

	return (
		<BottomDrawDataTable
			columns={columns ?? []}
			data={rows}
			totalRows={totalRows}
			title="Observation Sampling Species"
			moduleType="monitoring"
			icon="quadrat"
			isLoading={isLoading || columns == null || columns?.length <= 0}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			filteredSearchBox={{
				options: OBSERVATION_SPECIES_SAMPLING_FILTER_OPTIONS,
				filters: observationSpeciesSamplingFilters,
				setFilters: setObservationSpeciesSamplingFilters,
			}}
			tabType={DrawerTabEnum.OBSERVATION_SAMPLING_SPECIES}
		/>
	);
};

export default ObservationSamplingSpeciesTableCard;
