import axios from "axios";
import { atom } from "jotai";
import { atomFamily, atomWithStorage, createJSONStorage } from "jotai/utils";
import { atomEffect } from "jotai-effect";
import { atomWithQuery } from "jotai-tanstack-query";
import {
	Attribution,
	defaults as defaultControls,
	ScaleLine,
} from "ol/control";
import { Coordinate } from "ol/coordinate";
import { Extent } from "ol/extent";
import Feature, { FeatureLike } from "ol/Feature";
import { Geometry } from "ol/geom";
import OlMap from "ol/Map";
import View from "ol/View";

import { IconName } from "@/components/icons";
import {
	AnalogueClassificationLayerAtomEffect,
	AnalogueClassificationLayerColorMapAtomEffects,
	AnalogueClassificationLayerGroupAtom,
} from "@/components/MapOpenLayers/analogue-classification-layer";
import {
	ClassificationLayerAtomEffect,
	ClassificationLayerColorMapAtomEffects,
	ClassificationLayerGroupAtom,
} from "@/components/MapOpenLayers/classification-layer";
import {
	ErosionImageryAtomEffect,
	ErosionImageryGroupAtom,
} from "@/components/MapOpenLayers/Erosion/erosion-imagery-layer";
import {
	MsaviLayerAtomEffect,
	MsaviLayerGroupAtom,
} from "@/components/MapOpenLayers/Health/msavi-layer";
import {
	ImageryLayerAtomEffect,
	ImageryLayerGroupAtom,
} from "@/components/MapOpenLayers/imagery-layer";
import { MapSourceType } from "@/components/MapOpenLayers/map-types";
import { SwipeBarAtom } from "@/components/MapOpenLayers/swipe-bar";
import {
	getProjectionAndTMS,
	getTiTilerBounds,
	getTiTilerTileGrid,
} from "@/helpers/projection-helpers";
import {
	keysGetApiKeys,
	QuadratDto,
	RehabilitationPolygonByExtentDto,
	RiparianSystemZoneByExtentDto,
} from "@/lib/gen/eis";

import { SelectedAnaloguePolygon } from "./analoguePolyAtoms";
import { SelectedErosionFeatureIdAtom } from "./erosionAtoms";
import {
	HealthZonesQueryAtom,
	SelectedHealthGridCellAtom,
	SelectedHealthZoneIdAtom,
} from "./healthAtoms";
import { SelectedIndividualTreeIdAtom } from "./individualTreeAtoms";
import {
	AnaloguePolyLayerAtom,
	BareAreasVectorLayerAtom,
	BaseSatelliteLayerAtomEffect,
	ErosionClusterLayerAtom,
	ErosionVectorLayerAtom,
	HealthGridLayerAtom,
	HealthZoneLayerAtom,
	IndividualTreeLayerAtom,
	MineSiteLayerAtom,
	RehabPolyClusterLayerAtom,
	RehabPolyLayerAtom,
	SamplingSiteLayerAtom,
	SiteInspectionLayerAtom,
	TopsoilStockpileLayerAtom,
	WeedGridLayerAtom,
} from "./map/layerAtoms";
import { RehabPolygonBBoxQueryAtom } from "./map/mapQueryAtoms";
import {
	MineSiteSummariesQueryAtom,
	SelectedMineSiteAtom,
	SelectedMiningRegionAtom,
} from "./miningAtoms";
import { SelectedSamplingSiteAtom } from "./ongroundAtoms";
import {
	SelectedRehabPolygonAtom,
	SelectedRehabPolygonIdAtom,
} from "./rehabPolyAtoms";
import { SelectedSiteInspectionAtom } from "./siteInspectionAtoms";
import { SelectedWeedGridCellIdAtom } from "./weedsAtoms";

/**
	Padding for the 16px padding on all sides
	as well as the 280px left sidebar, 296px right sidebar
	and 40px for the toolbar and 48px time slider on top
	and 48px for the closed bottom drawer. 

	8px padding added to all side to give a little padding to the viewport.

	[top, right, bottom, left]
 */
export const VIEWPORT_PADDING = [
	40 + 48 + 16 + 8, // top
	296 + 16 + 8, // right
	48 + 16 + 8, // bottom
	280 + 16 + 8, // left
] as const;
const MAP = new OlMap({
	target: "map",
	view: new View({
		projection: "EPSG:4326",
		center: [120.0, -22.72],
		zoom: 10,
		padding: [16, 16, 16, 16],
	}),
	maxTilesLoading: 32,
	controls: defaultControls({ attribution: false }).extend([
		new ScaleLine({ units: "metric" }),
		new Attribution({ collapsible: false, collapsed: false }),
	]),
});

export const MapAtom = atom(MAP);

export const MapSetupAtomEffect = atomEffect((get) => {
	const map = get(MapAtom);

	// Controls
	const swipeBar = get(SwipeBarAtom);
	map.addControl(swipeBar);

	//Layer Effects
	map.setLayers([
		get(ImageryLayerGroupAtom),
		get(ClassificationLayerGroupAtom),
		get(AnalogueClassificationLayerGroupAtom),
		get(MsaviLayerGroupAtom),

		get(MineSiteLayerAtom),

		get(WeedGridLayerAtom),

		get(HealthGridLayerAtom),
		get(HealthZoneLayerAtom),
		get(IndividualTreeLayerAtom),

		get(RehabPolyLayerAtom),
		get(RehabPolyClusterLayerAtom),
		get(AnaloguePolyLayerAtom),
		get(SamplingSiteLayerAtom),
		get(SiteInspectionLayerAtom),
		get(BareAreasVectorLayerAtom),

		get(ErosionImageryGroupAtom),
		get(ErosionClusterLayerAtom),
		get(ErosionVectorLayerAtom),

		get(TopsoilStockpileLayerAtom),
	]);

	get(MapViewInitAtomEffect);
	get(BaseSatelliteLayerAtomEffect);
	get(ImageryLayerAtomEffect);
	get(ClassificationLayerColorMapAtomEffects);
	get(ClassificationLayerAtomEffect);
	get(AnalogueClassificationLayerColorMapAtomEffects);
	get(AnalogueClassificationLayerAtomEffect);
	get(MsaviLayerAtomEffect);
	get(ErosionImageryAtomEffect);
});

interface MapViewState {
	center: Coordinate;
	zoom: number;
}

const mapViewStateAtom = atomWithStorage<MapViewState>(
	"map_view",
	{
		center: [120.0, -22.72],
		zoom: 10,
	},
	createJSONStorage(() => localStorage),
	{ getOnInit: true },
);
const mapViewInit = atom(false);
const MapViewInitAtomEffect = atomEffect((_get, set) => {
	set(mapViewInitAtom);
});
const mapViewInitAtom = atom(null, (get, set) => {
	const map = get(MapAtom);

	// Get straight from localStorage to prevent retriggering this effect
	const initViewStateJSON = localStorage.getItem("map_view");
	if (!initViewStateJSON) {
		set(mapViewInit, true);
		return;
	}
	const initViewState = JSON.parse(initViewStateJSON) as MapViewState;

	const view = map.getView();
	if (initViewState.center && initViewState.center.length == 2)
		view.setCenter(initViewState.center);
	if (initViewState.zoom) view.setZoom(initViewState.zoom);

	set(mapViewInit, true);
});
/** Update the currrent map view state in local storage. */
export const UpdateMapViewState = atom(null, (get, set) => {
	const view = get(MapAtom).getView();
	set(mapViewStateAtom, (prev) => ({
		center: view.getCenter() ?? prev.center,
		zoom: view.getZoom() ?? prev.zoom,
	}));
	const zoom = view.getZoom();
	if (zoom) {
		set(MapZoomAtom, zoom);
	}
	const extent = view.calculateExtent();
	if (extent) {
		set(MapExtentAtom, extent);
	}
});
export const MapViewPaddingAtom = atom([...VIEWPORT_PADDING]);

export const MapZoomAtom = atom<number | undefined>(undefined);
export const MapExtentAtom = atom<Extent | undefined>(undefined);

export interface SelectedContext {
	name: string;
	label: string | undefined;
	icon: IconName;
	breadCrumbs: BreadCrumb[];
}

export interface BreadCrumb {
	label: string | undefined;
	icon: IconName;
	featureType: "region" | "site" | "polygon";
}

export const SelectedContextAtom = atom<SelectedContext>((get) => {
	const samplingSite = get(SelectedSamplingSiteAtom);
	const mineSites = get(MineSiteSummariesQueryAtom);
	const healthZones = get(HealthZonesQueryAtom);
	const rehabPolygons = get(RehabPolygonBBoxQueryAtom);
	const rehabPoly = get(SelectedRehabPolygonAtom);
	const healthZoneId = get(SelectedHealthZoneIdAtom);
	const mineSite = get(SelectedMineSiteAtom);
	const miningRegion = get(SelectedMiningRegionAtom);

	if (samplingSite) {
		const poly = rehabPolygons.data?.find(
			(rp) => rp.id === samplingSite.rehabPolygonId,
		);
		const site = mineSites.data?.items?.find(
			(ms) => ms.mineSiteId === poly?.mineSiteId,
		);
		return {
			name: samplingSite.name,
			icon: "quadrat",
			label: "Sampling Site",
			breadCrumbs: [
				{
					label: site?.miningRegionName,
					icon: "region",
					featureType: "region",
				},
				{
					label: site?.mineSiteName,
					icon: "site",
					featureType: "site",
				},
				{
					label: poly?.name,
					icon: "polygon",
					featureType: "polygon",
				},
			],
		} as SelectedContext;
	}
	if (rehabPoly) {
		const site = mineSites.data?.items?.find(
			(ms) => ms.mineSiteId === rehabPoly?.mineSiteId,
		);
		return {
			name: rehabPoly.name,
			icon: "polygon",
			label: "Polygon",
			breadCrumbs: [
				{
					label: site?.miningRegionName,
					icon: "region",
					featureType: "region",
				},
				{
					label: site?.mineSiteName,
					icon: "site",
					featureType: "site",
				},
			],
		} as SelectedContext;
	}
	if (healthZoneId) {
		const healthZone = healthZones.data?.find((z) => z.id === healthZoneId);
		return {
			name: healthZone?.name,
			icon: "zone",
			label: "Zone",
		} as SelectedContext;
	}
	if (mineSite) {
		return {
			name: mineSite.mineSiteName,
			icon: "site",
			label: "Site",
			breadCrumbs: [
				{
					label: mineSite?.miningRegionName,
					icon: "region",
					featureType: "region",
				},
			],
		} as SelectedContext;
	}
	if (miningRegion) {
		return {
			name: miningRegion.name,
			icon: "region",
			label: "Region",
		} as SelectedContext;
	}
	return {
		name: "All Regions",
		icon: "world",
		label: undefined,
	} as SelectedContext;
});

const _selectedGeometryAtom = atom<FeatureLike | undefined>(undefined);
export const GetSelectedGeometryAtom = atom((get) =>
	get(_selectedGeometryAtom),
);
export const SelectedGeometryAtom = atom(
	null,
	(get, set, feature: FeatureLike | undefined) => {
		const previousSelection = get(_selectedGeometryAtom);
		if (
			previousSelection &&
			previousSelection.getId() !== feature?.getId()
		) {
			(previousSelection as Feature<Geometry>)?.set("selected", false);
			const previousSource = previousSelection.get(
				"source",
			) as MapSourceType;
			switch (previousSource) {
				case "siteinspection":
					set(SelectedSiteInspectionAtom, undefined);
					break;
				case "samplingsite":
					set(SelectedSamplingSiteAtom, undefined);
					break;
				case "rehabpolygon":
					set(SelectedRehabPolygonIdAtom, undefined);
					break;
				case "analoguepolygon":
					set(SelectedAnaloguePolygon, undefined);
					break;
				case "healthgrid":
					set(SelectedHealthGridCellAtom, undefined);
					break;
				case "healthzone":
					set(SelectedHealthZoneIdAtom, undefined);
					break;
				case "erosionvector":
					set(SelectedErosionFeatureIdAtom, undefined);
					break;
				case "weedgrid":
					set(SelectedWeedGridCellIdAtom, undefined);
					break;
				case "individualtree":
					set(SelectedIndividualTreeIdAtom, undefined);
					break;
			}
		}
		if (feature) {
			set(_selectedGeometryAtom, feature);
		}
		if (feature) {
			const f = feature as Feature<Geometry>;
			f.set("selected", true);
		}
	},
);

export type ControllerCardSelection = {
	name: string | undefined;
} & (
	| PolygonControllerCardSelection
	| ZoneControllerCardSelection
	| SamplingLocationControllerCardSelection
);

type PolygonControllerCardSelection = {
	feature: RehabilitationPolygonByExtentDto;
	type: "Polygon";
};
type ZoneControllerCardSelection = {
	feature: RiparianSystemZoneByExtentDto;
	type: "Health Zone";
};
type SamplingLocationControllerCardSelection = {
	feature: QuadratDto;
	type: "Sampling Location";
};

export const _recentSelectionsAtom = atomWithStorage<ControllerCardSelection[]>(
	"recent",
	[],
);
export const RecentSelectionsAtom = atom(
	(get) => {
		return get(_recentSelectionsAtom);
	},
	(get, set, value: ControllerCardSelection) => {
		let selections = get(_recentSelectionsAtom);
		selections = selections.filter((s) => s.name !== value.name);
		if (selections.length > 4) {
			selections.shift();
		}
		selections.push(value);
		set(_recentSelectionsAtom, selections);
	},
);

export const ApiKeysQueryAtom = atomWithQuery(() => {
	return {
		queryKey: ["apiKeys"],
		queryFn: async () => keysGetApiKeys(),
		staleTime: Infinity,
	};
});

export const ArcGisTokenQueryAtom = atomWithQuery((get) => {
	const { data: keys } = get(ApiKeysQueryAtom);
	const arcGisClientId = keys?.find((k) => k.keyName === "ARCGIS_CLIENT_ID");
	const arcGisSecret = keys?.find(
		(k) => k.keyName === "ARCGIS_CLIENT_SECRET",
	);

	return {
		queryKey: ["arcGisToken"],
		enabled: !!arcGisClientId && !!arcGisSecret,
		queryFn: async () => {
			return axios
				.post(
					"https://www.arcgis.com/sharing/rest/oauth2/token",
					{
						client_id: arcGisClientId?.keyValue,
						client_secret: arcGisSecret?.keyValue,
						grant_type: "client_credentials",
					},
					{
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					},
				)
				.then((resp) => {
					if (
						resp?.data &&
						typeof resp.data["access_token"] === "string"
					) {
						return resp.data["access_token"];
					} else {
						throw new Error("Error retrieving ArcGis token");
					}
				});
		},
	};
});

export const TiTilerTileGridAtomFamily = atomFamily(
	(tileMatrixSetName: string) => {
		return atomWithQuery(() => {
			return {
				queryKey: ["getTiTilerTileGrid", tileMatrixSetName],
				queryFn: async () => {
					return getTiTilerTileGrid(tileMatrixSetName);
				},
			};
		});
	},
);

export const TiTilerBoundsAtomFamily = atomFamily((url: string) => {
	return atomWithQuery((get) => {
		const { data } = get(ProjectionTMSAtomFamily(url));

		return {
			queryKey: ["getTiTilerBounds", url, data?.tileMatrixSet],
			queryFn: async () => {
				return getTiTilerBounds(url, data?.tileMatrixSet);
			},
			enabled: !!data?.tileMatrixSet,
		};
	});
});

export const ProjectionTMSAtomFamily = atomFamily((url: string) => {
	return atomWithQuery(() => {
		return {
			queryKey: ["getProjectionAndTMS", url],
			queryFn: async () => {
				return getProjectionAndTMS(url);
			},
		};
	});
});
