import { AnaloguePolygonArg } from "@/atoms/analoguePolyAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { polygonLandformType, VegetationClassDto } from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const ANALOGUE_POLYGON_FILTER_OPTION_MINE_SITE: ColumnFilterOption<AnaloguePolygonArg> =
	{
		key: "mineSiteName",
		label: "Site",
		icon: "site",
		options: (searchTerm?: string) =>
			getTypeAheadResults("mineSiteName", searchTerm),
		keywords: ["mine", "name"],
	};
export const ANALOGUE_POLYGON_FILTER_OPTION_MINING_REGION_NAME: ColumnFilterOption<AnaloguePolygonArg> =
	{
		key: "miningRegionName",
		label: "Region",
		icon: "region",
		options: (searchTerm?: string) =>
			getTypeAheadResults("miningRegionName", searchTerm),
		keywords: ["mine", "name"],
	};

export const ANALOGUE_POLYGON_FILTER_OPTIONS: ColumnFilterOption<AnaloguePolygonArg>[] =
	[
		{
			key: "polygonName",
			label: "Polygon",
			icon: "polygon",
			options: (searchTerm?: string) =>
				getTypeAheadResults("analoguePolygonName", searchTerm),
			keywords: ["name"],
		},
		ANALOGUE_POLYGON_FILTER_OPTION_MINING_REGION_NAME,
		ANALOGUE_POLYGON_FILTER_OPTION_MINE_SITE,
		{
			key: "landform",
			label: "Landform",
			icon: "landform",
			options: Object.values(polygonLandformType).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "area",
			label: "Area",
			icon: "total_area",
			type: "area",
			sourceUnits: { area: "hectares" },
		},
	];

export function getAnaloguePolygonDynamicFilterVegClassCover(
	vegClassCover: VegetationClassDto,
): ColumnFilterOption<AnaloguePolygonArg> {
	return {
		key: "vegClassCoverQuery",
		subKey: vegClassCover.id,
		label: `${vegClassCover.name} %`,
		icon: "circle_filled",
		iconColor: vegClassCover.displayColour ?? undefined,
		type: "percentage",
		group: "Classification Covers",
	};
}
