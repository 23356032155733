import { Row } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ColumnDef, Table as TableCore } from "@tanstack/table-core";
import { Loader2 } from "lucide-react";
import {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import React from "react";

import { DataTableSkeleton } from "@/components/data-table/data-table-skeleton";
import { getSafeID } from "@/components/data-table/helpers";
import { DataTableRow, EisDataTableHead } from "@/components/data-table/table";
import { useInWindow } from "@/components/PoppedOutWindows";
import {
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
	TableSubComponentRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";

import { ColumnState, InfiniteQueryFetching } from "./table-types";

export type DataTableStructureExternalProps<TData, TValue> = {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	isLoading: boolean;
	totalRows?: number;
	fetch?: InfiniteQueryFetching;
	rowHeight?: number;
	className?: string;
	overscan?: number;
	children?: JSX.Element;
	renderSubComponent?: (row: Row<TData>) => React.ReactNode;
	hideTotalRows?: boolean;
	onRowClick?: (
		row: Row<TData>,
		e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
	) => void;
};

type DataTableStructureProps<TData, TValue> = {
	table: TableCore<TData>;
	columnState: ColumnState;
} & DataTableStructureExternalProps<TData, TValue>;

export function DataTableStructure<TData, TValue>({
	table,
	columns,
	data,
	isLoading,
	totalRows,
	fetch,
	className,
	overscan,
	children,
	renderSubComponent,
	rowHeight = 34,
	columnState,
	hideTotalRows,
	onRowClick,
}: DataTableStructureProps<TData, TValue>) {
	const isInWindow = useInWindow();

	const { rows, rowsById } = table.getRowModel();

	const getItemKey = useCallback(
		(index: number) => {
			return rows[index]?.id ?? index;
		},
		[rows],
	);

	const tableContainerRef = useRef<HTMLDivElement | null>(null);
	const virtualizer = useVirtualizer({
		count: totalRows ?? rows.length,
		getScrollElement: () => tableContainerRef.current,
		estimateSize: () => rowHeight,
		overscan: overscan ?? 4,
		scrollPaddingEnd: 56,
		enabled: columns.length > 0,
		getItemKey,
	});

	// Fetch on scroll
	const virtualItems = virtualizer.getVirtualItems();
	useEffect(() => {
		if (!fetch) return;
		const [lastItem] = [...virtualItems].reverse();
		if (!lastItem) return;

		if (
			lastItem.index >= data.length - 1 &&
			fetch.hasNextPage &&
			!fetch.isFetchingNextPage
		) {
			fetch.fetchNextPage();
		}
	}, [fetch, data.length, virtualItems]);

	const columnSizingInfo = table.getState().columnSizingInfo;
	const columnSizeVars = useMemo(() => {
		const headers = table.getFlatHeaders();
		const colSizes: { [key: string]: number } = {};
		for (let i = 0; i < headers.length; i++) {
			const header = headers[i]!;
			colSizes[`--header-${getSafeID(header.id)}-size`] =
				header.getSize();
			colSizes[`--col-${getSafeID(header.column.id)}-size`] =
				header.column.getSize();
		}
		return colSizes;
		// TODO can we do this another way?
		// eslint-disable-next-line react-compiler/react-compiler
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		columns,
		columnSizingInfo,
		columnState.columnOrder,
		columnState.columnVisibility,
	]);

	const [scrolledLeft, setScrolledLeft] = useState(false);
	const onScroll = useCallback(
		(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
			if (
				columnState.columnPinning.left == null ||
				columnState.columnPinning.left.length <= 0
			)
				return;
			setScrolledLeft(e.currentTarget.scrollLeft > 0);
		},
		[columnState.columnPinning.left],
	);

	return (
		<div className={cn("@container", className)}>
			{children}
			<div
				className={cn(
					"relative h-full max-w-full overflow-auto border",
					!isInWindow && "rounded",
				)}
				ref={tableContainerRef}
				style={{ ...columnSizeVars }}
				onScroll={onScroll}
			>
				<div
					className={cn(
						"pointer-events-none absolute inset-0 z-50 flex items-center justify-center opacity-0 transition-opacity",
						isLoading && "opacity-100",
					)}
				>
					<Loader2 className="size-10 animate-spin" />
				</div>
				{!isLoading && rows.length <= 0 && (
					<div className="pointer-events-none absolute inset-0 z-50 flex items-center justify-center text-muted-foreground">
						<div>No results.</div>
					</div>
				)}

				<Table className="sticky top-0 z-10 [&_tr]:shadow-md">
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow
								key={headerGroup.id}
								className="flex h-8 w-full items-center bg-core-secondary-background hover:bg-core-secondary-background"
							>
								{headerGroup.headers.map((header) => {
									return (
										<EisDataTableHead
											key={header.id}
											header={header}
											scrolledLeft={scrolledLeft}
										/>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
				</Table>
				<div
					style={{
						height: `${virtualizer.getTotalSize()}px`,
					}}
				>
					<Table>
						<TableBody className="">
							{isLoading && (
								<DataTableSkeleton
									table={table}
									pageSize={10}
									rowHeight={rowHeight}
								/>
							)}
							{!isLoading &&
								(rows.length <= 0 || columns.length <= 0) && (
									<TableRow className="flex w-full justify-center">
										<TableCell></TableCell>
									</TableRow>
								)}

							{virtualItems.map((virtualRow, index) => {
								const row =
									rowsById[virtualRow.key as string] ?? null;
								return (
									<Fragment key={virtualRow.key}>
										<TableRow
											className={cn(
												"group flex w-full items-center border-none odd:bg-core-primary-background even:bg-table-row-alt hover:!bg-table-row-hover data-[state=selected]:bg-table-row-select",
												onRowClick && "cursor-pointer",
											)}
											style={{
												transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`, // this should always be a `style` as it changes on scroll
												height: virtualRow.size,
											}}
											onClick={(e) =>
												onRowClick?.(row, e)
											}
										>
											<DataTableRow
												row={row}
												scrolledLeft={scrolledLeft}
												columnState={columnState}
												isSelected={
													row != null &&
													row.getIsSelected()
												}
											/>
										</TableRow>
										{renderSubComponent &&
											row?.getIsExpanded() && (
												<TableSubComponentRow>
													<TableCell
														colSpan={
															row.getVisibleCells()
																.length
														}
													>
														{renderSubComponent(
															row,
														)}
													</TableCell>
												</TableSubComponentRow>
											)}
									</Fragment>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</div>
			<div className="mx-2 mb-1 flex justify-end text-right text-xs text-core-secondary-text">
				{isLoading ? (
					<Loader2 className="size-4 animate-spin" />
				) : (
					!hideTotalRows && (
						<span>{`${(totalRows ?? 0).toLocaleString()} rows`}</span>
					)
				)}
			</div>
		</div>
	);
}
