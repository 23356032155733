import {
	ColumnFilter,
	getColumnFilterOptionUnitShorthand,
} from "./filter-search-box-types";

export function InputSuffix<T extends string>({
	filterItem,
	value,
}: {
	filterItem?: ColumnFilter<T>;
	value?: string;
}) {
	if (
		filterItem == null ||
		filterItem.comparator == null ||
		!(
			filterItem.filter.type === "area" ||
			filterItem.filter.type === "distance" ||
			filterItem.filter.type === "percentage"
		)
	)
		return null;

	if (filterItem.filter.type === "percentage") {
		return <span className="font-mono text-xs">{"%"}</span>;
	}

	const v = value ?? filterItem.values.map((v) => v.value ?? "").join(",");

	const match = v.match(/^(\d+\.?\d*)([a-zA-Z]*)$/);

	if (
		filterItem.filter.type === "area" ||
		filterItem.filter.type === "distance"
	) {
		if (match && match[2]) {
			const suffix = match[2].toLowerCase();

			if (
				filterItem.filter.type === "area" &&
				!(suffix === "ha" || suffix == "ac" || v.endsWith("²"))
			) {
				return <span className="font-mono text-xs">{"²"}</span>;
			}

			return null;
		}

		return (
			<span className="font-mono text-xs">
				{getColumnFilterOptionUnitShorthand(filterItem.filter)}
			</span>
		);
	}

	return null;
}
