import { useAtom, useAtomValue } from "jotai";
import { startTransition } from "react";

import {
	AutoSelectMetricAtomEffect,
	HealthMetricsAtom,
	SelectedHealthMetricAtom,
	StructureOrConditionSelectionAtom,
} from "@/atoms/healthAtoms";
import { HealthGridVisibility } from "@/atoms/map/layerVisibilityAtoms";
import Icon from "@/components/icons/icon";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";

const HealthNavBarControls: React.FC = () => {
	const { data: metrics } = useAtomValue(HealthMetricsAtom);
	const [selectedMetric, setSelectedMetric] = useAtom(
		SelectedHealthMetricAtom,
	);
	const [structureOrCondition, setStructureOrCondition] = useAtom(
		StructureOrConditionSelectionAtom,
	);
	const [healthGridVisibility, setHealthGridVisibility] =
		useAtom(HealthGridVisibility);
	useAtom(AutoSelectMetricAtomEffect);

	return (
		<div className="-ml-32 flex flex-row gap-1 py-1">
			<div className="border-r px-1">
				<Button
					variant={healthGridVisibility ? "default" : "outline"}
					size={"icon"}
					onClick={() => {
						setHealthGridVisibility(!healthGridVisibility);
					}}
				>
					<Icon name="grid_toggle" />
				</Button>
			</div>
			<div className="flex flex-row rounded-sm border-2 border-core-primary-border bg-core-secondary-background p-1">
				<Icon className="mx-1" name="health" />
				<Label className="text-sm">Condition</Label>
				<Switch
					className="ml-1"
					checked={structureOrCondition === "condition"}
					onCheckedChange={(e) => {
						if (typeof e === "boolean") {
							e.valueOf() === false
								? setStructureOrCondition("structure")
								: setStructureOrCondition("condition");
						}
					}}
				/>
			</div>

			<div className="w-[150px] bg-core-primary-background">
				<Select
					defaultValue={selectedMetric?.metricId}
					value={selectedMetric?.metricId}
					onValueChange={(e) => {
						const metric = metrics?.find((m) => m.metricId === e);
						startTransition(() => {
							setSelectedMetric(metric);
						});
					}}
				>
					<SelectTrigger className="h-8 border-2 border-core-primary-border bg-core-primary-background">
						<SelectValue />
					</SelectTrigger>
					<SelectContent position="popper">
						{metrics
							?.filter((m) =>
								structureOrCondition === "structure"
									? m.riparianMetricDataType === "Percent"
									: m.riparianMetricDataType === "MSAVI",
							)
							.map((m) => (
								<SelectItem key={m.metricId} value={m.metricId}>
									<div className="flex">{m.displayName}</div>
								</SelectItem>
							))}
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};

export default HealthNavBarControls;
