import { useAtomValue, useSetAtom } from "jotai";
import { memo, useMemo } from "react";

import { GoToAnaloguePolygon } from "@/atoms/analoguePolyAtoms";
import { MapAtom } from "@/atoms/mapAtoms";
import { DataTableCustomCell } from "@/components/data-table/data-table-cells";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import { Button } from "@/components/ui/button";
import { getFeatureByIdAndSource } from "@/helpers/map-helpers";

export const AnaloguePolygonLocationCell = memo(function ({
	id,
}: {
	id: string;
}) {
	const map = useAtomValue(MapAtom);
	const disabled = useMemo(
		() => getFeatureByIdAndSource(map, "analoguepolygon", id) == null,
		[map, id],
	);
	const goToPolygon = useSetAtom(GoToAnaloguePolygon);

	return (
		<DataTableLocationPinCell
			GoToLocation={() => goToPolygon(id)}
			disabled={disabled}
		/>
	);
});

export const AnaloguePolygonNameCell = memo(function ({
	id,
	name,
}: {
	id: string;
	name: string;
}) {
	const map = useAtomValue(MapAtom);
	const disabled = useMemo(
		() => getFeatureByIdAndSource(map, "analoguepolygon", id) == null,
		[map, id],
	);
	const goToPolygon = useSetAtom(GoToAnaloguePolygon);

	return (
		<DataTableCustomCell>
			<Button
				onClick={() => goToPolygon(id)}
				disabled={disabled}
				variant="link"
				className="truncate px-0"
			>
				<span className="truncate">{name}</span>
			</Button>
		</DataTableCustomCell>
	);
});
