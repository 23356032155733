import { atom } from "jotai";
import { atomWithImmer } from "jotai-immer";

export interface DialogProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

type DialogCallback = (props: DialogProps) => JSX.Element;

interface DialogItem {
	component: DialogCallback;
	open: boolean;
	onClose?: () => void;
}

export const _dialogsAtom = atomWithImmer<Record<string, DialogItem>>({});

export const DialogsAtom = atom(
	(get) => {
		return Object.entries(get(_dialogsAtom));
	},
	(
		_get,
		set,
		component: DialogCallback | null,
		id?: string,
		options?: { onClose?: () => void },
	) => {
		const dialogID = id ?? crypto.randomUUID();
		set(_dialogsAtom, (dialogs) => {
			// Remove old dialogs
			for (const [key, dialog] of Object.entries(dialogs)) {
				if (!dialog.open) delete dialogs[key];
			}

			// Open/close dialog
			if (component == null) {
				if (!dialogs[dialogID]) return dialogs;
				dialogs[dialogID].open = false;
				dialogs[dialogID].onClose?.();
			} else {
				dialogs[dialogID] = {
					component: component,
					open: true,
					onClose: options?.onClose,
				};
			}

			return dialogs;
		});
	},
);
