import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";

import { NumberInput, NumberInputProps } from "@/components/ui/input";
import { cn } from "@/lib/utils";

type NumberInputCellProps = {
	input: number;
	onBlurOrEnter: (value: number) => void;
	isPending?: boolean;
};

export const NumberInputCell = ({
	input,
	onBlurOrEnter,
	className,
	isPending,
	...props
}: NumberInputCellProps & NumberInputProps) => {
	const [value, setValue] = useState(input);

	useEffect(() => {
		setValue(input);
	}, [input]);

	return (
		<div className={cn("", className)}>
			<NumberInput
				value={(value * 100).toLocaleString(undefined, {
					maximumFractionDigits: 2,
				})}
				onChange={(e) => {
					setValue(e.target.valueAsNumber / 100);
				}}
				onBlur={(e) => onBlurOrEnter(e.target.valueAsNumber / 100)}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						onBlurOrEnter(value);
					}
				}}
				className="h-fit bg-background px-2 py-0.5"
				disabled={isPending || props.disabled}
				{...props}
			>
				{props.children}
			</NumberInput>
			{isPending && (
				<div className="absolute flex size-full items-center justify-center rounded blur-sm">
					<Loader2 size={16} className="animate-spin" />
				</div>
			)}
		</div>
	);
};
