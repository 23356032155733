import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { SelectedAnaloguePolygon } from "@/atoms/analoguePolyAtoms";
import { Perm_Rehab_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import { SelectedRehabPolygonAtom } from "@/atoms/rehabPolyAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const AnaloguePolySummaryCard = {
	component: lazy(
		() => import("@/components/Polygon/AnaloguePolySummaryCard"),
	),
	permission: Perm_Rehab_Std,
} as const satisfies LazyCardComponent;

export const LazyAnaloguePolySummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedRehabPoly = useAtomValue(SelectedRehabPolygonAtom);
	const selectedAnaloguePolygon = useAtomValue(SelectedAnaloguePolygon);
	const selectedSamplingSite = useAtomValue(SelectedSamplingSiteAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(AnaloguePolySummaryCard, userPerms);
		if (comp) {
			if (
				currentView?.name === "Monitoring" &&
				selectedAnaloguePolygon &&
				!selectedRehabPoly &&
				!selectedSamplingSite
			)
				return comp;
		}
		return null;
	}, [
		currentView?.name,
		selectedAnaloguePolygon,
		selectedRehabPoly,
		selectedSamplingSite,
		userPerms,
	]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"AnaloguePolySummaryCard"} />}
		</Suspense>
	);
};
