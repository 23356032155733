import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetRehabilitationPolygonIdsMutationRequest, RehabilitationPolygonsGetRehabilitationPolygonIdsMutationResponse } from "../../ts/RehabilitationPolygonsGetRehabilitationPolygonIds.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/RehabilitationPolygons/getids
 */
export async function rehabilitationPolygonsGetRehabilitationPolygonIds(data: RehabilitationPolygonsGetRehabilitationPolygonIdsMutationRequest, config: Partial<RequestConfig<RehabilitationPolygonsGetRehabilitationPolygonIdsMutationRequest>> = {}) {
    const res = await client<RehabilitationPolygonsGetRehabilitationPolygonIdsMutationResponse, Error, RehabilitationPolygonsGetRehabilitationPolygonIdsMutationRequest>({ method: "POST", url: `/api/RehabilitationPolygons/getids`, data, ...config });
    return res.data;
}