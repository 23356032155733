import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";
import { format } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { SelectAndZoomToFeatureAtom } from "@/atoms/map/mapEventAtoms";
import {
	SelectedMineSiteAtom,
	SelectedMiningRegionAtom,
} from "@/atoms/miningAtoms";
import {
	RehabPolyGeometriesAtom,
	SelectedRehabPolygonAtom,
} from "@/atoms/rehabPolyAtoms";
import {
	SelectedSiteInspectionAtom,
	SiteInspectionFiltersAtom,
} from "@/atoms/siteInspectionAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import {
	DataTableCustomCell,
	DataTableLargeTextCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import PhotoCarousel from "@/components/PhotoCarousel";
import { useInWindow } from "@/components/PoppedOutWindows";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	SiteInspectionDto,
	SiteInspectionOrderBy,
	siteInspectionsGetRehabPolySiteInspections,
} from "@/lib/gen/eis";
import {
	SITE_INSPECTION_FILTER_OPTION_MINE_SITE,
	SITE_INSPECTION_FILTER_OPTION_MINING_REGION_NAME,
	SITE_INSPECTION_FILTER_OPTION_REHAB_POLYGON_NAME,
	SITE_INSPECTION_FILTER_OPTIONS,
} from "@/types/filters/site-inspection-filters";

const SiteInspectionTableCard = () => {
	const selectFeature = useSetAtom(SelectAndZoomToFeatureAtom);
	const rehabPolys = useAtomValue(RehabPolyGeometriesAtom);
	const setSiteInspection = useSetAtom(SelectedSiteInspectionAtom);
	const [siteInspectionFilters, setSiteInspectionFilters] = useFilters(
		SiteInspectionFiltersAtom,
	);

	const siteInspections = rehabPolys.flatMap(
		(rp) => rp.siteInspections ?? [],
	);

	const [columnSort, setColumnSort] = useState<ColumnSort>({
		id: "InspectionDate" as SiteInspectionOrderBy,
		desc: false,
	});

	// Auto-set mine site filter
	const inWindow = useInWindow();
	const mineSite = useAtomValue(SelectedMineSiteAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setSiteInspectionFilters,
			SITE_INSPECTION_FILTER_OPTION_MINE_SITE,
			mineSite?.mineSiteName,
		);
	}, [inWindow, mineSite?.mineSiteName, setSiteInspectionFilters]);
	const region = useAtomValue(SelectedMiningRegionAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setSiteInspectionFilters,
			SITE_INSPECTION_FILTER_OPTION_MINING_REGION_NAME,
			region?.name,
		);
	}, [inWindow, region?.name, setSiteInspectionFilters]);

	// Auto-set rehab polygon filter on click polygon
	const selectedRehabPolygon = useAtomValue(SelectedRehabPolygonAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setSiteInspectionFilters,
			SITE_INSPECTION_FILTER_OPTION_REHAB_POLYGON_NAME,
			selectedRehabPolygon?.name,
		);
	}, [inWindow, selectedRehabPolygon, setSiteInspectionFilters]);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ["SiteInspections", columnSort, siteInspectionFilters],
		queryFn: async ({ pageParam }) => {
			return siteInspectionsGetRehabPolySiteInspections({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: columnSort.id as SiteInspectionOrderBy,
				descending: columnSort.desc,
				...parseFilterAguments(siteInspectionFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const columns = useMemo<ColumnDef<SiteInspectionDto, unknown>[]>(() => {
		return [
			{
				accessorKey: "id",
				enableGlobalFilter: true,
				header: () => <div />,
				cell: ({ row }) => {
					const siteInspection = siteInspections.find(
						(si) => si.id === row.original.id,
					);
					return (
						<DataTableLocationPinCell
							disabled={siteInspection == null}
							GoToLocation={async () => {
								if (siteInspection == null) return;
								setSiteInspection(siteInspection);
								selectFeature(
									siteInspection.id,
									"siteinspection",
								);
							}}
						/>
					);
				},
				enableSorting: false,
				enableResizing: false,
				minSize: 26,
				maxSize: 26,
				meta: {
					title: "Location",
				},
			},
			{
				accessorKey: "name",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Name"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell value={row.original?.name ?? "-"} />
				),
				enableSorting: true,
				size: 120,
			},
			{
				accessorKey: "mineSiteName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Site"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.mineSiteName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 100,
				meta: {
					title: "Site",
				},
			},
			{
				accessorKey: "miningRegionName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Region"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.miningRegionName ?? "-"}
					/>
				),

				enableSorting: true,
				enableHiding: true,
				size: 100,
				meta: {
					title: "Region",
				},
			},
			{
				accessorKey: "rehabilitationPolygonName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Polygon"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.rehabPolyName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 90,
				meta: {
					title: "Polygon",
				},
			},
			{
				accessorKey: "inspectionDate",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Inspection Date"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={
							row.original?.inspectionDate
								? format(
										row.original?.inspectionDate,
										"dd MMMM yyyy",
									)
								: "-"
						}
					/>
				),
				enableSorting: true,
				size: 100,
				meta: {
					title: "Inspection Date",
				},
			},
			{
				accessorKey: "criterion",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Category"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.criterion ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
				size: 150,
			},
			{
				accessorKey: "observation",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Observation"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableLargeTextCell
						value={row.original?.observation ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
			},
			{
				accessorKey: "recommendation",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Recommendation"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableLargeTextCell
						value={row.original?.recommendation ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
			},
			{
				accessorKey: "observerName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Observer Name"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.observerName ?? "-"}
					/>
				),
				enableSorting: true,
				enableHiding: true,
				meta: {
					title: "Observer Name",
				},
			},
			{
				accessorKey: "photos",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Photos"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableCustomCell>
						{(row.original?.siteInspectionImages?.length ?? 0) >
							0 && (
							<PhotoCarousel
								className="px-8"
								images={row.original?.siteInspectionImages?.map(
									(i) => {
										return {
											thumbnailFileName:
												i.thumbnailFileName ??
												undefined,
											fullImageFileName:
												i.fullImageFileName ??
												undefined,
											thumbnailBytes:
												i.thumbnailBytes ?? undefined,
										};
									},
								)}
							></PhotoCarousel>
						)}
					</DataTableCustomCell>
				),
				enableSorting: false,
				enableHiding: true,
				size: 200,
				minSize: 200,
			},
		];
	}, [selectFeature, setSiteInspection, siteInspections]);

	return (
		<BottomDrawDataTable
			columns={columns}
			data={rows}
			totalRows={totalRows}
			title="Site Inspections"
			isLoading={isLoading || columns == null || columns?.length <= 0}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			filteredSearchBox={{
				options: SITE_INSPECTION_FILTER_OPTIONS,
				filters: siteInspectionFilters,
				setFilters: setSiteInspectionFilters,
			}}
			exportContext="siteinspections"
			rowHeight={60}
			tabType={DrawerTabEnum.SITE_INSPECTIONS}
		/>
	);
};

export default SiteInspectionTableCard;
