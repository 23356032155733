import { ColumnDef } from "@tanstack/react-table";

import { DataTableSmallTextCell } from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { EisColumnSort } from "@/components/data-table/table-types";
import {
	LocationMetricResultComparisonDto,
	RiparianLocationComparisonDto,
} from "@/lib/gen/eis";

import {
	RiparianZoneLocationCell,
	RiparianZoneLocationMetricResultCell,
} from "./riparian-zone-table-cells";

export const getRiparianZoneColumns = (
	setColumnSort: React.Dispatch<React.SetStateAction<EisColumnSort>>,
): ColumnDef<RiparianLocationComparisonDto, unknown>[] => [
	{
		accessorKey: "id",
		enableGlobalFilter: true,
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title=""
				className="w-8"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<RiparianZoneLocationCell id={row.original.locationId} />
		),
		accessorFn: (row) => row.locationId,
		enableSorting: false,
		enableResizing: false,
		minSize: 26,
		maxSize: 26,
	},
	{
		accessorKey: "locationName",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Zone"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.locationName ?? "-"} />
		),
		enableSorting: true,
		enableHiding: false,
		size: 100,
	},
];

export function getLocationMetricResultComparisonColumn<
	T extends {
		metricComparisons?: LocationMetricResultComparisonDto[] | null;
	},
>(
	name: string,
	setColumnSort: React.Dispatch<React.SetStateAction<EisColumnSort>>,
): ColumnDef<T, unknown> {
	return {
		accessorKey: name,
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title={name ?? ""}
				columnSortSetter={setColumnSort}
				isDynamicColumn={true}
			/>
		),
		cell: ({ row }) => (
			<RiparianZoneLocationMetricResultCell
				name={name}
				metricComparisons={row.original.metricComparisons ?? []}
			/>
		),
		enableSorting: true,
		enableHiding: false,
		size: 140,
		minSize: 125,
	};
}
