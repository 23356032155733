import client from "@kubb/plugin-client/client";
import type { KeysGetApiKeysQueryResponse } from "../../ts/KeysGetApiKeys.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Keys
 */
export async function keysGetApiKeys(config: Partial<RequestConfig> = {}) {
    const res = await client<KeysGetApiKeysQueryResponse, Error, unknown>({ method: "GET", url: `/api/Keys`, ...config });
    return res.data;
}