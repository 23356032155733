import client from "@kubb/plugin-client/client";
import type { WeedApplicationsGetWeedApplicationsWithPaginationQueryResponse, WeedApplicationsGetWeedApplicationsWithPaginationQueryParams } from "../../ts/WeedApplicationsGetWeedApplicationsWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/WeedApplications
 */
export async function weedApplicationsGetWeedApplicationsWithPagination(params?: WeedApplicationsGetWeedApplicationsWithPaginationQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<WeedApplicationsGetWeedApplicationsWithPaginationQueryResponse, Error, unknown>({ method: "GET", url: `/api/WeedApplications`, params, ...config });
    return res.data;
}