import client from "@kubb/plugin-client/client";
import type { ProjectionSystemsGetSriDsWithPaginationQueryQueryResponse, ProjectionSystemsGetSriDsWithPaginationQueryQueryParams } from "../../ts/ProjectionSystemsGetSriDsWithPaginationQuery.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/ProjectionSystems/GetSRIDsWithPaginationQuery
 */
export async function projectionSystemsGetSriDsWithPaginationQuery(params?: ProjectionSystemsGetSriDsWithPaginationQueryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<ProjectionSystemsGetSriDsWithPaginationQueryQueryResponse, Error, unknown>({ method: "GET", url: `/api/ProjectionSystems/GetSRIDsWithPaginationQuery`, params, ...config });
    return res.data;
}