export const weedGridCellOrderBy = {
    "Name": "Name",
    "TotalWeeds": "TotalWeeds",
    "WeedAlert": "WeedAlert",
    "VegClassCoverDynamicColumn": "VegClassCoverDynamicColumn",
    "GridSize": "GridSize"
} as const;

 export type WeedGridCellOrderByEnum = (typeof weedGridCellOrderBy)[keyof typeof weedGridCellOrderBy];

 export type WeedGridCellOrderBy = WeedGridCellOrderByEnum;