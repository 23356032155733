import { ArrowDownIcon, ArrowUpIcon, EyeNoneIcon } from "@radix-ui/react-icons";
import { Column } from "@tanstack/react-table";
import { ArrowDown, ArrowUp, ChevronsUpDown } from "lucide-react";
import { Dispatch, SetStateAction } from "react";

import { EisColumnSort } from "@/components/data-table/table-types";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";

interface DataTableColumnHeaderProps<TData, TValue>
	extends React.HTMLAttributes<HTMLDivElement> {
	column: Column<TData, TValue>;
	columnId?: string | undefined;
	title: string;
	columnSortSetter?: Dispatch<SetStateAction<EisColumnSort>>;
	isDynamicColumn?: boolean;
	/** The column id to use for the `OrderBy` argument if this is a dynamic column. */
	orderById?: string | null;
	rightAlign?: boolean;
}

export function DataTableColumnHeader<TData, TValue>({
	column,
	columnId,
	title,
	className,
	columnSortSetter,
	isDynamicColumn,
	orderById,
	rightAlign,
}: DataTableColumnHeaderProps<TData, TValue>) {
	if (!column.getCanSort()) {
		return (
			<Button
				variant="ghost"
				size="sm"
				className={cn(
					"flex w-full justify-start px-2 data-[state=open]:bg-accent",
					rightAlign && "justify-end",
					className,
				)}
			>
				<span className="truncate" title={title}>
					{title}
				</span>
			</Button>
		);
	}

	return (
		<div className={cn("flex w-full items-center", className)}>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						size="sm"
						className={cn(
							"flex h-7 w-full justify-between px-0 data-[state=open]:bg-accent",
							rightAlign ? "flex-row-reverse pr-2" : "pl-2",
						)}
					>
						<span className={cn("truncate")} title={title}>
							{title}
						</span>

						<div className={cn("shrink-0 px-2")}>
							{column.getIsSorted() === "desc" ? (
								<ArrowDown size={12} />
							) : column.getIsSorted() === "asc" ? (
								<ArrowUp size={12} />
							) : (
								<ChevronsUpDown size={12} />
							)}
						</div>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					<DropdownMenuItem
						onClick={() => {
							if (columnSortSetter) {
								columnSortSetter({
									id: columnId ?? column.id,
									desc: false,
									isDynamicColumn: isDynamicColumn,
									orderById: orderById,
								});
								column.toggleSorting(false);
							}
						}}
						className="flex gap-2"
					>
						<ArrowUpIcon className="size-3.5 text-muted-foreground" />
						Asc
					</DropdownMenuItem>
					<DropdownMenuItem
						onClick={() => {
							if (columnSortSetter) {
								columnSortSetter({
									id: columnId ?? column.id,
									desc: true,
									isDynamicColumn: isDynamicColumn,
									orderById: orderById,
								});
								column.toggleSorting(true);
							}
						}}
						className="flex gap-2"
					>
						<ArrowDownIcon className="size-3.5 text-muted-foreground" />
						Desc
					</DropdownMenuItem>
					<DropdownMenuSeparator />
					<DropdownMenuItem
						onClick={() => column.toggleVisibility(false)}
						className="flex gap-2"
					>
						<EyeNoneIcon className="size-3.5 text-muted-foreground" />
						Hide
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}
