import { atom, WritableAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";

export function atomWithToggle(
	initialValue?: boolean,
): WritableAtom<boolean, [boolean?], void> {
	const anAtom = atom(initialValue, (get, set, nextValue?: boolean) => {
		const update = nextValue ?? !get(anAtom);
		set(anAtom, update);
	});

	return anAtom as WritableAtom<boolean, [boolean?], void>;
}

export function atomWithToggleAndStorage(
	key: string,
	initialValue?: boolean,
	storage?: SyncStorage<boolean | undefined>,
): WritableAtom<boolean, [boolean?], void> {
	const anAtom = atomWithStorage(key, initialValue, storage);
	const derivedAtom = atom(
		(get) => get(anAtom),
		(get, set, nextValue?: boolean) => {
			const update = nextValue ?? !get(anAtom);
			void set(anAtom, update);
		},
	);

	return derivedAtom as WritableAtom<boolean, [boolean?], void>;
}
