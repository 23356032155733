import client from "@kubb/plugin-client/client";
import type { SamplingSitesGetSamplingSiteObservationSummaryQueryResponse, SamplingSitesGetSamplingSiteObservationSummaryPathParams, SamplingSitesGetSamplingSiteObservationSummaryQueryParams } from "../../ts/SamplingSitesGetSamplingSiteObservationSummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/SamplingSites/:id/ObservationSummary
 */
export async function samplingSitesGetSamplingSiteObservationSummary(id: SamplingSitesGetSamplingSiteObservationSummaryPathParams["id"], params?: SamplingSitesGetSamplingSiteObservationSummaryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<SamplingSitesGetSamplingSiteObservationSummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/SamplingSites/${id}/ObservationSummary`, params, ...config });
    return res.data;
}