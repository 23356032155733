import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { Loader2, ReplaceIcon, Trash2Icon } from "lucide-react";
import { useCallback } from "react";
import { toast } from "sonner";

import {
	GetSeedApplicationForPlanQueryKey,
	SeedApplicationIdAtom,
} from "@/atoms/seedAppAtoms";
import {
	DataTableFloraSpeciesCell,
	DataTableNumberCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { Button } from "@/components/ui/button";
import { Tooltip } from "@/components/ui/tooltip";
import {
	Lifeform,
	RemoveSeedApplicationSpeciesCommand,
	seedApplicationsRemoveSeedApplicationSpecies,
	VegetationCommunityFloraSpeciesDto,
} from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

import { ReplaceSpeciesDialog } from "./replace-species-dialog";
import {
	EditEstablishmentRateCell,
	EditTargetCoverCell,
} from "./seed-app-input-cells";

type ColumnProps = {
	lf: Lifeform;
};
const columnHelper = createColumnHelper<VegetationCommunityFloraSpeciesDto>();
export const GetLifeformColumns = ({ lf }: ColumnProps) => {
	if (lf === "Sedge") return [];
	return [
		columnHelper.accessor("fullName", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Species" />
			),
			cell: ({ getValue }) => (
				<DataTableFloraSpeciesCell value={getValue()} />
			),
			enableSorting: false,
			size: 200,
			meta: {
				title: "Species",
			},
		}),
		columnHelper.accessor("targetCover", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Target Cover"
					rightAlign
				/>
			),
			cell: ({ row }) => (
				<EditTargetCoverCell floraSpecies={row.original} />
			),
			enableSorting: false,
			size: 130,
			meta: {
				title: "Target Cover",
			},
		}),
		columnHelper.accessor("establishmentRate", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Establishment Rate"
					rightAlign
				/>
			),
			cell: ({ row }) => (
				<EditEstablishmentRateCell floraSpecies={row.original} />
			),
			enableSorting: false,
			size: 130,
			meta: {
				title: "Establishment Rate",
			},
		}),
		columnHelper.accessor("estimatedQtyDetails.estimatedQuantity", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Estimated Qty (g)"
					rightAlign
				/>
			),
			cell: ({ getValue }) => (
				<DataTableNumberCell value={getValue()} isInteger />
			),
			enableSorting: false,
			size: 120,
			meta: {
				title: "Estimated Quantity",
			},
		}),
		columnHelper.accessor("availableQuantity", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Available Qty (g)"
					rightAlign
				/>
			),
			cell: ({ getValue, row }) => {
				const isAvailable =
					row.original.availableQuantity >
					(row.original.estimatedQtyDetails?.estimatedQuantity ?? 0);
				const someAvailable =
					row.original.availableQuantity > 0 && !isAvailable;
				const noneAvailable = row.original.availableQuantity === 0;
				return (
					<DataTableNumberCell value={getValue()} isInteger>
						<div
							className={cn(
								"size-2 rounded-full",
								isAvailable && "bg-card-passing",
								someAvailable && "bg-card-approaching",
								noneAvailable && "bg-card-failing",
							)}
						/>
					</DataTableNumberCell>
				);
			},
			enableSorting: false,
			size: 115,
			meta: {
				title: "Available Quantity",
			},
		}),
		columnHelper.display({
			id: "actions",
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Actions"
					className="justify-center"
				/>
			),
			cell: ({ row }) => (
				<div className="flex w-full justify-center gap-1">
					<ReplaceSpeciesDialog floraSpecies={row.original}>
						<Button variant="ghost" size="icon" className="">
							<Tooltip tip="Replace">
								<ReplaceIcon
									className="text-misc-tyton-green-primary"
									size={16}
								/>
							</Tooltip>
						</Button>
					</ReplaceSpeciesDialog>
					<RemoveSpeciesButton floraSpeciesId={row.original.id} />
				</div>
			),
			enableSorting: false,
			size: 100,
		}),
	] as ColumnDef<VegetationCommunityFloraSpeciesDto>[];
};

const RemoveSpeciesButton = ({
	floraSpeciesId,
}: {
	floraSpeciesId: string;
}) => {
	const queryClient = useQueryClient();
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const { mutate: removeSpeciesMutation, isPending } = useMutation({
		mutationFn: async (data: RemoveSeedApplicationSpeciesCommand) => {
			return seedApplicationsRemoveSeedApplicationSpecies(
				data.seedApplicationId,
				data,
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationForPlanQueryKey(seedApplicationId),
			});
		},
		onError: (error) => {
			toast.error("Remove Species Failed", {
				description: error.message,
			});
		},
	});

	const removeSpecies = useCallback(
		(floraSpeciesId: string) => {
			if (!seedApplicationId) {
				return;
			}
			removeSpeciesMutation({
				seedApplicationId: seedApplicationId,
				floraSpeciesId,
			});
		},
		[removeSpeciesMutation, seedApplicationId],
	);

	return (
		<Tooltip tip="Remove">
			<Button
				variant="ghost"
				size="icon"
				className=""
				disabled={seedApplicationId == null}
				onClick={() => removeSpecies(floraSpeciesId)}
			>
				{isPending ? (
					<Loader2 className="animate-spin" size={16} />
				) : (
					<Trash2Icon className="text-destructive" size={16} />
				)}
			</Button>
		</Tooltip>
	);
};
