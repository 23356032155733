import NavBar from "@/components/NavBar";
import PoppedOutWindows from "@/components/PoppedOutWindows";

import { MapView } from "./mapview";

export const App = () => {
	return (
		<>
			<NavBar>
				<MapView />
			</NavBar>
			<PoppedOutWindows />
		</>
	);
};
