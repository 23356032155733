import { useAtomValue, useSetAtom } from "jotai";
import { memo, useMemo } from "react";

import { GoToRehabPolygon } from "@/atoms/rehabPolyAtoms";
import { DataTableCustomCell } from "@/components/data-table/data-table-cells";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import { RehabPolyLayerClusterSourceAtom } from "@/components/MapOpenLayers/Monitoring/rehabPolyLayer";
import { Button } from "@/components/ui/button";
import { CriterionResultDto, PolygonOverallResultEnum } from "@/lib/gen/eis";
import { cn } from "@/lib/utils";
import { PolygonOverallResult } from "@/web-api-client";

const RESULT_BG_CLASS: Record<
	PolygonOverallResult | PolygonOverallResult,
	string
> = {
	[PolygonOverallResult.Failed]: "bg-card-failing",
	[PolygonOverallResult.Approaching]: "bg-card-approaching",
	[PolygonOverallResult.Passed]: "bg-card-passing",
	[PolygonOverallResult.Unassessed]: "bg-card-unassessed",
};

export const CriteriaResultTableCell = memo(function ({
	result,
}: {
	result?: CriterionResultDto;
}) {
	const overallResult = result?.result ?? PolygonOverallResult.Unassessed;
	const isPercentage = result?.completionCriterionClass !== "Erosion";

	return (
		<DataTableCustomCell className="flex items-center whitespace-nowrap">
			<span
				title={
					isPercentage
						? `${(Number(result?.outcomeValue ?? 0) * 100).toLocaleString()}%`
						: `${(result?.outcomeValue ?? 0).toLocaleString()}`
				}
				className={cn(
					"flex h-5 w-12 items-center justify-center rounded-md font-medium text-white",
					RESULT_BG_CLASS[overallResult],
				)}
			>
				{isPercentage
					? `${(Number(result?.outcomeValue ?? 0) * 100).toFixed(0)}%`
					: `${(result?.outcomeValue ?? 0).toFixed(2)}m`}
			</span>

			<span className="ml-2">
				{isPercentage ? (
					<>
						{result?.rangeMaximum
							? `≤ ${(result?.rangeMaximum ?? 0) * 100}%`
							: result?.rangeMinimum
								? `≥ ${(result?.rangeMinimum ?? 0) * 100}%`
								: "0%"}
					</>
				) : (
					<>
						{result?.rangeMaximum
							? `≤ ${result?.rangeMaximum ?? 0}m`
							: result?.rangeMinimum
								? `≥ ${result?.rangeMinimum ?? 0}m`
								: "0m"}
					</>
				)}
			</span>
		</DataTableCustomCell>
	);
});

export const CompletionStatusCell = memo(function ({
	completionStatus,
}: {
	completionStatus: PolygonOverallResultEnum;
}) {
	return (
		<DataTableCustomCell>
			<div
				className={cn(
					"flex h-6 w-24 items-center justify-center rounded-md font-dmSans font-medium text-white",
					RESULT_BG_CLASS[completionStatus],
				)}
			>
				{completionStatus}
			</div>
		</DataTableCustomCell>
	);
});

export const RehabPolygonLocationCell = memo(function ({ id }: { id: string }) {
	const source = useAtomValue(RehabPolyLayerClusterSourceAtom);
	const disabled = useMemo(() => {
		const f = source.source?.getFeatureById(id);
		return f == null;
	}, [id, source]);
	const goToPolygon = useSetAtom(GoToRehabPolygon);

	return (
		<DataTableLocationPinCell
			GoToLocation={() => goToPolygon(id)}
			disabled={disabled}
		/>
	);
});

export const RehabPolygonNameCell = memo(function ({
	id,
	name,
}: {
	id: string;
	name: string;
}) {
	const source = useAtomValue(RehabPolyLayerClusterSourceAtom);
	const disabled = useMemo(
		() => source.source?.getFeatureById(id) == null,
		[id, source],
	);
	const goToPolygon = useSetAtom(GoToRehabPolygon);

	return (
		<DataTableCustomCell>
			<Button
				onClick={() => goToPolygon(id)}
				disabled={disabled}
				variant="link"
				className="truncate px-0"
			>
				<span className="truncate">{name}</span>
			</Button>
		</DataTableCustomCell>
	);
});
