import { useQuery } from "@tanstack/react-query";
import { atom, useAtom, useSetAtom } from "jotai";
import {
	EllipsisIcon,
	FileImageIcon,
	FileSpreadsheetIcon,
	Loader2,
} from "lucide-react";
import { useEffect, useMemo, useRef } from "react";
import {
	Bar,
	BarChart,
	Cell,
	Line,
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from "recharts";

import { DrawerIsOpenAtom } from "@/atoms/bottomDrawerAtoms";
import Icon from "@/components/icons/icon";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import {
	Menubar,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarTrigger,
} from "@/components/ui/menubar";
import { Tooltip } from "@/components/ui/tooltip";
import { ExportImage } from "@/helpers/image-export-helpers";
import {
	VegCoverHistoryDto,
	VegetationClassDto,
	vegetationClassesGet,
} from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

type VegCoverDate = {
	date: string;
	vegCovers?: VegCoverHistoryDto[] | null;
};

type VegClassNodeProps = {
	id: string;
	name: string;
	displayPosition: number | undefined;
	displayColour: string | undefined;
	level: number;
	parentId: string | undefined;
	children: VegClassNodeProps[];
	className?: string;
};

type VegClass = {
	id: string;
	name: string;
	displayColour?: string;

	childIds?: string[] | null;
	hierarchyDisplayPosition?: number | null;
};

interface BarChartNodeProps {
	id: string;
	name: string;
	Cover: number;
	colour: string | undefined;
}

interface Props {
	data: VegCoverDate[];
	error?: Error;
	isLoading?: boolean;
	onExportCSV?: () => void;
	noDataMessage?: string;
	subtitle?: string | null;
	headerClassName?: string;
	/** If not set, will get classes from `/api/VegetationClasses` */
	vegClasses?: VegClass[];
}
const ActiveVegClassIdsAtom = atom<string[]>([]);

const VegCoverGraph: React.FC<Props> = (props) => {
	const {
		data,
		error,
		isLoading,
		onExportCSV,
		noDataMessage,
		subtitle,
		headerClassName,
	} = props;

	const [activeVegClassIds, setActiveVegClassIds] = useAtom(
		ActiveVegClassIdsAtom,
	);

	const vegClassQuery = useQuery({
		queryKey: ["vegetationClasses"],
		queryFn: () => vegetationClassesGet(),
		enabled: props.vegClasses == null,
	});
	const vegClasses = useMemo(() => {
		return props.vegClasses ?? vegClassQuery.data ?? [];
	}, [vegClassQuery.data, props.vegClasses]);

	const setDrawerIsOpen = useSetAtom(DrawerIsOpenAtom);

	useEffect(() => {
		setActiveVegClassIds(
			vegClasses
				?.filter(
					(vc) => !vc.childIds?.length || vc.childIds.length == 0,
				)
				?.map((vc) => vc.id) ?? [],
		);
	}, [vegClasses, setActiveVegClassIds]);

	const exportRef = useRef<HTMLDivElement | null>(null);

	const chartConfig = useMemo(() => {
		return (
			vegClasses?.reduce((config, vc) => {
				if (vc.id == null) return config;
				config[vc.id] = {
					label: vc.name,
					color: vc.displayColour ?? "",
				};
				return config;
			}, {} as ChartConfig) ?? {}
		);
	}, [vegClasses]);

	const { chartData, barChartData } = useMemo(() => {
		const chartData: {
			[key: string]: string | number;
		}[] = [];
		const barChartData: BarChartNodeProps[] = [];
		data.forEach((d) => {
			const series: { [key: string]: string | number } = { date: d.date };
			d.vegCovers
				?.sort(
					(a, b) =>
						(vegClasses.find((vc) => vc.id === a.vegetationClassId)
							?.hierarchyDisplayPosition ?? 999) -
						(vegClasses.find((vc) => vc.id === b.vegetationClassId)
							?.hierarchyDisplayPosition ?? 999),
				)
				.forEach((vc) => {
					if (vc.vegetationClassName == null) return;
					const value = (vc.coverPercentage ?? 0) * 100;
					series[vc.vegetationClassName] = value;

					if (data.length !== 1) return;
					barChartData.push({
						id: vc.vegetationClassId,
						name: vc.vegetationClassName,
						Cover: value,
						colour:
							vegClasses.find(
								(x) => x.id === vc.vegetationClassId,
							)?.displayColour ?? undefined,
					});
				});
			chartData.push(series);
		});

		return { chartData, barChartData };
	}, [data, vegClasses]);

	const classIds = useMemo(
		() => vegClasses.flatMap((vc) => vc.id),
		[vegClasses],
	);
	const nodes = useMemo(() => getVegClassNodes(vegClasses), [vegClasses]);

	if (error || vegClassQuery.isError) {
		return (
			<span>
				Error: {error?.message ?? vegClassQuery?.error?.message ?? ""}
			</span>
		);
	}

	return (
		<div className="flex h-full flex-col">
			<div
				className={cn(
					"flex flex-row items-center justify-between gap-1 rounded-t border border-module-monitoring-border bg-module-monitoring-header p-1 text-core-primary-button-text",
					headerClassName,
				)}
			>
				<div
					className={cn(
						"flex w-full cursor-pointer flex-row rounded-tl bg-white bg-opacity-0 p-1 hover:bg-opacity-15",
					)}
					onClick={() => setDrawerIsOpen(false)}
				>
					<div className="flex items-center gap-2 font-dmSans font-medium leading-4">
						<Icon name="vegcover" className="size-5 items-center" />
						<span className="text-nowrap">Vegetation Cover</span>
						{subtitle != null && (
							<span className="text-nowrap text-xs opacity-75">{`(${subtitle})`}</span>
						)}
					</div>
				</div>

				{data.length > 0 && (
					<Menubar className="mr-2 flex items-center">
						<MenubarMenu>
							<MenubarTrigger>
								<EllipsisIcon />
							</MenubarTrigger>
							<MenubarContent className="z-50 bg-core-primary-background">
								<MenubarItem
									className="flex cursor-pointer gap-1"
									onClick={async (e) => {
										e.stopPropagation();
										if (exportRef.current) {
											ExportImage(
												exportRef.current,
												"VegetationCoverHistory",
											);
										}
									}}
								>
									<FileImageIcon className="" />
									Export image
								</MenubarItem>
								{onExportCSV && (
									<MenubarItem
										className="flex cursor-pointer gap-1"
										onClick={onExportCSV}
									>
										<FileSpreadsheetIcon className="" />
										Export to CSV
									</MenubarItem>
								)}
							</MenubarContent>
						</MenubarMenu>
					</Menubar>
				)}
			</div>

			<div
				ref={exportRef}
				className="flex grow flex-row overflow-auto bg-core-primary-background"
			>
				{classIds && classIds.length > 0 ? (
					<Accordion
						className="z-30 w-[300px] overflow-auto p-2"
						type="multiple"
						defaultValue={classIds}
					>
						{nodes
							.filter((n) => !n.parentId)
							.sort(
								(a, b) =>
									(a.displayPosition ?? 999) -
									(b.displayPosition ?? 999),
							)
							.map((pn) => (
								<VegClassTreeNode
									key={pn.id}
									{...pn}
									className="ml-2 w-[245px]"
								/>
							))}
					</Accordion>
				) : (
					<div className="w-[300px]" />
				)}
				<div className="relative w-[calc(100%-300px)] border-l border-core-primary-border p-2">
					{data.length === 0 && !isLoading && (
						<div className="pointer-events-none absolute inset-0 flex items-center justify-center">
							<h1 className="text-center">
								{noDataMessage ?? "No Data"}
							</h1>
						</div>
					)}
					<div
						className={cn(
							"pointer-events-none absolute right-2 top-2 opacity-0 transition-opacity",
							(isLoading || vegClassQuery.isLoading) &&
								"opacity-100",
						)}
					>
						<Loader2 className="size-6 animate-spin" />
					</div>

					{chartData.length > 1 && (
						<ResponsiveContainer>
							<ChartContainer config={chartConfig}>
								<LineChart
									data={chartData}
									margin={{
										top: 5,
										right: 5,
										left: 45,
										bottom: 5,
									}}
								>
									<YAxis
										type="number"
										mirror
										tick={{
											dx: -15,
											style: { textAnchor: "end" },
										}}
										label={{
											value: "VEGETATION COVER (%)",
											angle: -90,
											offset: 35,
											position: "left",
											style: {
												textAnchor: "middle",
											},
										}}
									></YAxis>
									<XAxis
										dataKey="date"
										tickFormatter={(value) => {
											const date = new Date(value);
											return date.toLocaleDateString(
												"en-AU",
												{
													year: "numeric",
													month: "short",
													day: "numeric",
												},
											);
										}}
									/>

									{vegClasses
										.filter((vc) =>
											activeVegClassIds.includes(vc.id),
										)
										.map((vc) => (
											<Line
												key={vc.id}
												dataKey={vc.name ?? ""}
												stroke={`var(--color-${vc.id})`}
												fill={`var(--color-${vc.id})`}
												strokeWidth={4}
											/>
										))}
									<ChartTooltip
										cursor={false}
										content={
											<ChartTooltipContent
												indicator="line"
												labelFormatter={(value) => {
													const date = new Date(
														value,
													);
													return date.toLocaleDateString(
														"en-AU",
														{
															year: "numeric",
															month: "short",
															day: "numeric",
														},
													);
												}}
												nameKey="name"
												formatter={(value) =>
													`${(value as number).toFixed(2)}%`
												}
											/>
										}
									/>
								</LineChart>
							</ChartContainer>
						</ResponsiveContainer>
					)}
					{chartData.length === 1 && (
						<ResponsiveContainer>
							<ChartContainer config={chartConfig}>
								<BarChart
									data={barChartData.filter((x) =>
										activeVegClassIds.includes(x.id),
									)}
									margin={{
										top: 5,
										right: 5,
										left: 45,
										bottom: 5,
									}}
								>
									<YAxis
										type="number"
										mirror
										tick={{
											dx: -15,
											style: { textAnchor: "end" },
										}}
										label={{
											value: "VEGETATION COVER (%)",
											angle: -90,
											offset: 35,
											position: "left",
											style: {
												textAnchor: "middle",
											},
										}}
									></YAxis>
									<XAxis
										dataKey="name"
										tickFormatter={(str) => {
											const maxLength = 10;
											return str.length > maxLength
												? str.substring(0, maxLength) +
														"..."
												: str;
										}}
										interval={0}
									/>

									<Bar dataKey="Cover">
										{barChartData
											?.filter((vc) =>
												activeVegClassIds.includes(
													vc.id,
												),
											)
											.map((vc) => (
												<Cell
													key={vc.name}
													fill={`var(--color-${vc.id})`}
												/>
											))}
									</Bar>

									<ChartTooltip
										cursor={false}
										content={
											<ChartTooltipContent
												indicator="line"
												labelFormatter={(value) => {
													return value;
												}}
												nameKey="name"
												formatter={(value) =>
													`${(value as number).toFixed(2)}%`
												}
											/>
										}
									/>
								</BarChart>
							</ChartContainer>
						</ResponsiveContainer>
					)}
				</div>
			</div>
		</div>
	);
};

const VegClassTreeNode: React.FC<VegClassNodeProps> = ({
	id,
	name,
	displayColour,
	level,
	children,
}) => {
	const [activeVegClassIds, setActiveVegClassIds] = useAtom(
		ActiveVegClassIdsAtom,
	);

	const indent = 8 * level;
	return (
		<AccordionItem value={id} key={id}>
			<div
				className={cn(
					"items-left flex items-center gap-1 rounded px-2 hover:bg-core-secondary-background",
				)}
				style={{ marginLeft: `${indent}px` }}
			>
				<Checkbox
					key={`${id}_checkbox`}
					className={`size-4`}
					checked={activeVegClassIds.includes(id)}
					onClick={() => {
						const index = activeVegClassIds.indexOf(id);
						if (index < 0) {
							setActiveVegClassIds([...activeVegClassIds, id]);
						} else {
							const copy = activeVegClassIds.filter(
								(vc) => vc !== id,
							);
							setActiveVegClassIds(copy);
						}
					}}
				></Checkbox>
				<div className="flex w-full items-center justify-between">
					<div className="flex h-6 w-40 items-center gap-1 truncate text-nowrap font-roboto text-sm">
						<div
							style={{
								backgroundColor: displayColour,
							}}
							className="h-2 rounded-sm p-2"
						></div>
						<Tooltip tip={name} side="right">
							<Label className="ml-2 mt-2 h-6 w-auto truncate font-roboto text-sm">
								{name}
							</Label>
						</Tooltip>
					</div>
					<div>
						{children.length > 0 && (
							<AccordionTrigger className="p-0"></AccordionTrigger>
						)}
					</div>
				</div>
			</div>
			<AccordionContent>
				{children &&
					children
						.sort(
							(a, b) =>
								(a.displayPosition ?? 999) -
								(b.displayPosition ?? 999),
						)
						.map((child) => (
							<VegClassTreeNode
								key={child.id}
								id={child.id}
								name={child.name}
								displayPosition={child.displayPosition}
								displayColour={child.displayColour}
								level={level + 1}
								children={child.children}
								parentId=""
							/>
						))}
			</AccordionContent>
		</AccordionItem>
	);
};

const getVegClassNodes = (
	data?: VegetationClassDto[],
	parentId?: string | null,
): VegClassNodeProps[] => {
	if (data == null) return [];
	return data.flatMap((vc) => {
		if (vc.parentId != parentId) return [];
		return [
			{
				id: vc.id,
				name: vc.name ?? vc.id,
				displayPosition: vc.displayPosition,
				displayColour: vc.displayColour,
				level: 0,
				parentId: vc.parentId,
				children: getVegClassNodes(data, vc.id),
			},
		];
	}) as VegClassNodeProps[];
};

export default VegCoverGraph;
