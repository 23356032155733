import { cva } from "class-variance-authority";

export const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default:
					"enabled:bg-core-primary-button-fill enabled:text-white enabled:hover:shadow-md disabled:bg-neutral-200 disabled:text-neutral-400",
				destructive:
					"bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
				outline:
					"border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
				outline_primary:
					"border border-core-primary-button-fill bg-transparent text-core-primary-button-fill shadow-sm hover:bg-accent",
				secondary:
					"bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
				ghost: "hover:bg-accent hover:text-accent-foreground",
				gentleghost: "bg-white bg-opacity-0 hover:bg-opacity-50",
				link: "text-core-link-text underline-offset-4 hover:underline",
				min: "",
			},
			size: {
				default: "h-9 px-4 py-2",
				sm: "h-8 px-3 text-xs",
				lg: "h-10 px-8",
				icon: "size-8",
				min: "",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);
