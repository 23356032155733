import dayjs from "dayjs";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { SetStateAction } from "react";

import { rehabilitationSurveysGetRehabilitationSurveysWithPagination } from "@/lib/gen/eis";
import { EcologicalSurveyType } from "@/web-api-client";

import { SelectedMineSiteIdAtom } from "./miningAtoms";

const _timeSeriesDateAtom = atom<dayjs.Dayjs | undefined>(undefined);
export const TimeSeriesDateAtom = atom((get) => {
	return get(_timeSeriesDateAtom) ?? dayjs().startOf("day");
});
export const SetTimeSeriesDateAtom = atom(
	null,
	(_get, set, setter: SetStateAction<dayjs.Dayjs | undefined>) => {
		set(_timeSeriesDateAtom, setter);
	},
);

export const RemoteSensingSurveysQueryAtom = atomWithQuery(() => {
	return {
		queryKey: ["remoteSensingSurveys"],
		queryFn: async () => {
			return rehabilitationSurveysGetRehabilitationSurveysWithPagination();
		},
	};
});

export const TimeSeriesMarkersAtom = atom((get) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const { data: surveys } = get(RemoteSensingSurveysQueryAtom);
	return (
		surveys?.items?.filter((rs) => {
			return (
				rs.ecologicalSurveyType ===
					EcologicalSurveyType.Rehabilitation &&
				(!mineSiteId || rs.mineSiteId === mineSiteId)
			);
		}) ?? []
	);
});
