import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetVegetationCommunitiesQueryResponse, RehabilitationPolygonsGetVegetationCommunitiesPathParams, RehabilitationPolygonsGetVegetationCommunitiesQueryParams } from "../../ts/RehabilitationPolygonsGetVegetationCommunities.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/RehabilitationPolygons/:id/vegetationCommunities
 */
export async function rehabilitationPolygonsGetVegetationCommunities(id: RehabilitationPolygonsGetVegetationCommunitiesPathParams["id"], params?: RehabilitationPolygonsGetVegetationCommunitiesQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationPolygonsGetVegetationCommunitiesQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationPolygons/${id}/vegetationCommunities`, params, ...config });
    return res.data;
}