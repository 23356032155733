import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { SelectedAnaloguePolygon } from "@/atoms/analoguePolyAtoms";
import { Perm_Rehab_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedErosionFeatureIdAtom } from "@/atoms/erosionAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import { SelectedRehabPolygonAtom } from "@/atoms/rehabPolyAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const CompletionCriteriaCard = {
	component: lazy(
		() => import("@/components/Monitoring/CompletionCriteriaCard"),
	),
	permission: Perm_Rehab_Std,
} as const satisfies LazyCardComponent;

export const LazyCompletionCriteriaCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedRehabPoly = useAtomValue(SelectedRehabPolygonAtom);
	const selectedSamplingSite = useAtomValue(SelectedSamplingSiteAtom);
	const selectedAnaloguePolygon = useAtomValue(SelectedAnaloguePolygon);
	const selectedErosionFeatureId = useAtomValue(SelectedErosionFeatureIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(CompletionCriteriaCard, userPerms);
		if (comp) {
			if (
				currentView &&
				["Monitoring", "Erosion"].includes(currentView.name) &&
				!selectedSamplingSite &&
				!selectedRehabPoly &&
				!selectedAnaloguePolygon &&
				!selectedErosionFeatureId
			)
				return comp;
		}
		return null;
	}, [
		currentView,
		selectedAnaloguePolygon,
		selectedErosionFeatureId,
		selectedRehabPoly,
		selectedSamplingSite,
		userPerms,
	]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"CompletionCriteriaCard"} />}
		</Suspense>
	);
};
