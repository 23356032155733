import client from "@kubb/plugin-client/client";
import type { SeedApplicationsCreateMutationRequest, SeedApplicationsCreateMutationResponse } from "../../ts/SeedApplicationsCreate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/Inventory/Seeds/Applications
 */
export async function seedApplicationsCreate(data?: SeedApplicationsCreateMutationRequest, config: Partial<RequestConfig<SeedApplicationsCreateMutationRequest>> = {}) {
    const res = await client<SeedApplicationsCreateMutationResponse, Error, SeedApplicationsCreateMutationRequest>({ method: "POST", url: `/api/Inventory/Seeds/Applications`, data, ...config });
    return res.data;
}