import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	restrictToParentElement,
	restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useCallback, useEffect, useState } from "react";

import { Button } from "./button";

interface Item {
	id: string;
}

interface SortableListProps<T extends Item> {
	items: T[];
	onChangeOrder?: (ids: string[]) => void;
	itemRenderer: (item: T) => React.ReactNode;
}

export function SortableList<T extends Item>(props: SortableListProps<T>) {
	const { onChangeOrder } = props;
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const [items, setItems] = useState<T[]>(props.items);
	useEffect(() => {
		setItems(props.items);
	}, [props.items]);

	const handleDragEnd = useCallback(
		({ active, over }: DragEndEvent) => {
			if (over == null || active.id === over.id) return;
			setItems((prevItems) => {
				const oldIndex = prevItems.findIndex(
					(item) => item.id === active.id,
				);
				const newIndex = prevItems.findIndex(
					(item) => item.id === over.id,
				);

				const sorted = arrayMove(prevItems, oldIndex, newIndex);
				onChangeOrder?.(sorted.map((i) => i.id));
				return sorted;
			});
		},
		[onChangeOrder],
	);

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
			modifiers={[restrictToVerticalAxis, restrictToParentElement]}
		>
			<SortableContext
				items={items}
				strategy={verticalListSortingStrategy}
			>
				{items.map((item) => (
					<SortableItem
						key={item.id}
						item={item}
						itemRenderer={props.itemRenderer}
					/>
				))}
			</SortableContext>
		</DndContext>
	);
}

interface SortableItemProps<T extends Item> {
	item: T;
	itemRenderer: (item: T) => React.ReactNode;
}

export function SortableItem<T extends Item>(props: SortableItemProps<T>) {
	const { item, itemRenderer } = props;
	const uniqueId = item.id;
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: uniqueId });

	return (
		<div
			ref={setNodeRef}
			style={{
				transform:
					transform == null
						? ""
						: `translate3d(${transform.x ? Math.round(transform.x) : 0}px, ${
								transform.y ? Math.round(transform.y) : 0
							}px, 0)`,
				transition,
			}}
			key={uniqueId}
		>
			<div className="group relative flex items-center gap-1">
				<div className="flex items-center justify-center gap-4">
					<Button
						{...attributes}
						{...listeners}
						size="min"
						variant="ghost"
						className="size-6 cursor-grab text-core-primary-border aria-pressed:cursor-grabbing"
						aria-describedby={`DndContext-${uniqueId}`}
					>
						<svg viewBox="0 0 20 20" width="15">
							<path
								d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"
								fill="currentColor"
							></path>
						</svg>
					</Button>
				</div>
				{itemRenderer(item)}
			</div>
		</div>
	);
}
