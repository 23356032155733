import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { DarkModeAtom } from "@/atoms/configAtoms.ts";
import { WindowProvider } from "@/components/PoppedOutWindows/provider.tsx";

import { setupWindow } from "./setup";

interface PopoutWindowProps {
	onClose: () => void;
	children: React.ReactNode;
	width?: number;
	height?: number;
	title?: string;
	className?: string;
	minWidth?: number;
	minHeight?: number;
}

const PopoutWindow: React.FC<PopoutWindowProps> = ({
	onClose,
	children,
	width = 800,
	height = 600,
	title = "",
	className = "",
	minWidth,
	minHeight,
}) => {
	const loadedRef = useRef<boolean>(false);
	const popoutWindowRef = useRef<Window | null>(null);
	const [win, setWindow] = useState<Window | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const observerRef = useRef<MutationObserver | null>(null);
	const [mountNode, setMountNode] = useState<HTMLDivElement | null>(null);
	const hasSetupRef = useRef(false);
	const darkMode = useAtomValue(DarkModeAtom);

	// Handle window close
	const handleBeforeUnload = useRef(() => {
		cleanUp();
		onClose();
	});

	const setupConfig = useRef({
		className,
		darkMode,
		height,
		minHeight,
		minWidth,
		onClose,
		title,
		width,
	});

	const cleanUp = () => {
		observerRef.current?.disconnect();
		if (popoutWindowRef.current && !popoutWindowRef.current.closed) {
			popoutWindowRef.current.removeEventListener(
				"beforeunload",
				handleBeforeUnload.current,
			);
			popoutWindowRef.current.close();
		}
		popoutWindowRef.current = null;
		containerRef.current = null;
		setMountNode(null);
		hasSetupRef.current = false;
	};

	useEffect(() => {
		return () => {
			loadedRef.current = false;
			cleanUp();
		};
	}, []);

	useEffect(() => {
		const document = popoutWindowRef.current?.document;
		if (darkMode) {
			document && document.body.classList.add("dark");
		} else {
			document && document.body.classList.remove("dark");
		}
	}, [darkMode]);

	useEffect(() => {
		// Prevent double initialization
		if (loadedRef.current) {
			return;
		}

		loadedRef.current = true;

		const cfg = setupConfig.current;

		const features = [
			`width=${cfg.width}`,
			`height=${cfg.height}`,
			"menubar=no",
			"toolbar=no",
			"location=no",
			"status=no",
			"titlebar=yes",
			cfg.minWidth && `minWidth=${cfg.minWidth}`,
			cfg.minHeight && `minHeight=${cfg.minHeight}`,
			"resizable=yes",
		]
			.filter(Boolean)
			.join(",");

		const newWindow = window.open("", cfg.title, features);

		if (newWindow) {
			hasSetupRef.current = true;
			const container = setupWindow(newWindow, cfg.title, cfg.darkMode);
			if (cfg.className) {
				container.className += ` ${cfg.className}`;
			}

			popoutWindowRef.current = newWindow;
			containerRef.current = container;

			newWindow.addEventListener(
				"beforeunload",
				handleBeforeUnload.current,
			);

			observerRef.current = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === "childList") {
						mutation.addedNodes.forEach((node) => {
							if (
								node instanceof HTMLStyleElement ||
								(node instanceof HTMLLinkElement &&
									node.rel === "stylesheet")
							) {
								newWindow.document.head.appendChild(
									node.cloneNode(true),
								);
							}
						});
					}
				});
			});

			observerRef.current.observe(document.head, {
				childList: true,
				subtree: true,
			});

			setMountNode(container);
			setWindow(newWindow);
			const returnFn = handleBeforeUnload.current;

			return () => {
				newWindow.removeEventListener("beforeunload", returnFn);
			};
		}
	}, []); // Empty dependency array - only run once on mount

	// Handle prop changes without recreating the window
	useEffect(() => {
		if (!popoutWindowRef.current || !containerRef.current) return;

		// Update window properties if needed
		if (className) {
			containerRef.current.className = `h-screen w-screen bg-core-primary-background p-0 flex-1 ${className}`;
		}

		// Could add more prop updates here if needed
	}, [className]);

	if (!mountNode || !win) {
		return null;
	}

	return createPortal(
		<WindowProvider window={win}>{children}</WindowProvider>,
		mountNode,
	);
};

export default PopoutWindow;
