import { z } from "zod";

import type { VariableMatrixWidth } from "@/lib/gen/titiler/types/VariableMatrixWidth";

/**
 * @description Variable Matrix Width Definition\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/variableMatrixWidth.json
 */
export const variableMatrixWidthSchema = z
	.object({
		coalesce: z
			.number()
			.int()
			.min(2)
			.describe(
				"Number of tiles in width that coalesce in a single tile for these rows",
			),
		minTileRow: z
			.number()
			.int()
			.min(0)
			.describe(
				"First tile row where the coalescence factor applies for this tilematrix",
			),
		maxTileRow: z
			.number()
			.int()
			.min(0)
			.describe(
				"Last tile row where the coalescence factor applies for this tilematrix",
			),
	})
	.describe(
		"Variable Matrix Width Definition\n\nref: https://github.com/opengeospatial/2D-Tile-Matrix-Set/blob/master/schemas/tms/2.0/json/variableMatrixWidth.json",
	) as z.ZodType<VariableMatrixWidth>;
