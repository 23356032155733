import { Active, DataRef, Over } from "@dnd-kit/core";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { ColumnDragData } from "@/components/ui/MultiList/DraggableColumn";
import { ItemDragData } from "@/components/ui/MultiList/DraggableItem";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

type DraggableData = ColumnDragData | ItemDragData;

export function hasDraggableData<T extends Active | Over>(
	entry: T | null | undefined,
): entry is T & {
	data: DataRef<DraggableData>;
} {
	if (!entry) {
		return false;
	}

	const data = entry.data.current;

	if (data?.type === "Column" || data?.type === "Item") {
		return true;
	}

	return false;
}

export function prepareForUtcConversion(date: Date): Date {
	// This is all so that the date doesnt get changed when the call to backend auto converts it to UTC
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const dayOfMonth = date.getDate().toString().padStart(2, "0");
	const dateStr = `${date.getFullYear()}-${month}-${dayOfMonth}`;
	return new Date(dateStr);
}
