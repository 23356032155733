import type { SeedBatchesGetSeedBatchCollectionPointsQueryParams, SeedBatchesGetSeedBatchCollectionPoints200, SeedBatchesGetSeedBatchCollectionPointsQueryResponse } from "../ts/SeedBatchesGetSeedBatchCollectionPoints.ts";
import { lifeformSchema } from "./lifeformSchema.ts";
import { longevitySchema } from "./longevitySchema.ts";
import { provenanceLandformSchema } from "./provenanceLandformSchema.ts";
import { seedBatchPointDtoSchema } from "./seedBatchPointDtoSchema.ts";
import { seedBatchStatusSchema } from "./seedBatchStatusSchema.ts";
import { z } from "zod";

 export const seedBatchesGetSeedBatchCollectionPointsQueryParamsSchema = z.object({ "SeedId": z.string().nullable().nullish(), "SupplierId": z.string().nullable().nullish(), "FloraSpeciesId": z.string().nullable().nullish(), "SeedName": z.array(z.string()).nullable().nullish(), "ProvenanceZone": z.array(z.string()).nullable().nullish(), "MinAmount": z.number().nullable().nullish(), "MaxAmount": z.number().nullable().nullish(), "Lifeform": z.array(z.lazy(() => lifeformSchema)).nullable().nullish(), "Longevity": z.array(z.lazy(() => longevitySchema)).nullable().nullish(), "SpeciesFullName": z.array(z.string()).nullable().nullish(), "Family": z.array(z.string()).nullable().nullish(), "SeedBatchNo": z.array(z.string()).nullable().nullish(), "SupplierName": z.array(z.string()).nullable().nullish(), "StoreLocation": z.array(z.string()).nullable().nullish(), "ProvenanceLandform": z.array(z.lazy(() => provenanceLandformSchema)).nullable().nullish(), "PONumber": z.array(z.string()).nullable().nullish(), "Status": z.array(z.lazy(() => seedBatchStatusSchema)).nullable().nullish(), "MineSiteName": z.array(z.string()).nullable().nullish() }).optional() as z.ZodType<SeedBatchesGetSeedBatchCollectionPointsQueryParams>;

 export const seedBatchesGetSeedBatchCollectionPoints200Schema = z.array(z.lazy(() => seedBatchPointDtoSchema)) as z.ZodType<SeedBatchesGetSeedBatchCollectionPoints200>;

 export const seedBatchesGetSeedBatchCollectionPointsQueryResponseSchema = z.lazy(() => seedBatchesGetSeedBatchCollectionPoints200Schema) as z.ZodType<SeedBatchesGetSeedBatchCollectionPointsQueryResponse>;