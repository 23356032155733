import type { SeedBatchSingleDto } from "../ts/SeedBatchSingleDto.ts";
import { pointSchema } from "./pointSchema.ts";
import { provenanceLandformSchema } from "./provenanceLandformSchema.ts";
import { seedBatchGerminationTestDtoSchema } from "./seedBatchGerminationTestDtoSchema.ts";
import { seedBatchQrCodeDtoSchema } from "./seedBatchQrCodeDtoSchema.ts";
import { seedBatchSeedDtoSchema } from "./seedBatchSeedDtoSchema.ts";
import { seedBatchStatusSchema } from "./seedBatchStatusSchema.ts";
import { storageLocationDtoSchema } from "./storageLocationDtoSchema.ts";
import { supplierDtoSchema } from "./supplierDtoSchema.ts";
import { z } from "zod";

 export const seedBatchSingleDtoSchema = z.object({ "id": z.string(), "seedId": z.string(), "seed": z.lazy(() => seedBatchSeedDtoSchema).nullable().nullish(), "seedBatchNo": z.string().nullable().nullish(), "collectionDate": z.string().date().nullable().nullish(), "expiryDate": z.string().date().nullable().nullish(), "provenanceLandforms": z.array(z.lazy(() => provenanceLandformSchema)).nullable().nullish(), "collectionPoint": z.lazy(() => pointSchema).nullable().nullish(), "srid": z.number().int(), "sridX": z.number().nullable().nullish(), "sridY": z.number().nullable().nullish(), "description": z.string().nullable().nullish(), "cost": z.number(), "initialQuantity": z.number(), "costPerGram": z.number(), "availableQuantity": z.number(), "quantityInStock": z.number(), "purity": z.number().nullable().nullish(), "viability": z.number().nullable().nullish(), "supplierId": z.string().nullable().nullish(), "supplier": z.lazy(() => supplierDtoSchema).nullable().nullish(), "storageLocationId": z.string(), "storageLocation": z.lazy(() => storageLocationDtoSchema).nullable().nullish(), "seedBatchGerminationTests": z.array(z.lazy(() => seedBatchGerminationTestDtoSchema)).nullable().nullish(), "seedBatchGerminationTest": z.lazy(() => seedBatchGerminationTestDtoSchema).nullable().nullish(), "qrCode": z.lazy(() => seedBatchQrCodeDtoSchema).nullable().nullish(), "shelfLocation": z.string().nullable().nullish(), "storageMethod": z.string().nullable().nullish(), "poNumber": z.string().nullable().nullish(), "seedIdentificationConfirmed": z.boolean(), "seedSupplierIdentifier": z.string().nullable().nullish(), "status": z.lazy(() => seedBatchStatusSchema) }) as z.ZodType<SeedBatchSingleDto>;