import client from "@kubb/plugin-client/client";
import type { MiningRegionsGetAllCriteriaMetricsMutationRequest, MiningRegionsGetAllCriteriaMetricsMutationResponse } from "../../ts/MiningRegionsGetAllCriteriaMetrics.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/MiningRegions/criteria
 */
export async function miningRegionsGetAllCriteriaMetrics(data: MiningRegionsGetAllCriteriaMetricsMutationRequest, config: Partial<RequestConfig<MiningRegionsGetAllCriteriaMetricsMutationRequest>> = {}) {
    const res = await client<MiningRegionsGetAllCriteriaMetricsMutationResponse, Error, MiningRegionsGetAllCriteriaMetricsMutationRequest>({ method: "POST", url: `/api/MiningRegions/criteria`, data, ...config });
    return res.data;
}