export const floraSpeciesOrderBy = {
    "FullName": "FullName",
    "Family": "Family",
    "Genus": "Genus",
    "Lifeform": "Lifeform",
    "InfraRank": "InfraRank",
    "InfraSpecies": "InfraSpecies",
    "NaturalisedStatus": "NaturalisedStatus",
    "Longevity": "Longevity",
    "AvailableQuantity": "AvailableQuantity"
} as const;

 export type FloraSpeciesOrderByEnum = (typeof floraSpeciesOrderBy)[keyof typeof floraSpeciesOrderBy];

 export type FloraSpeciesOrderBy = FloraSpeciesOrderByEnum;