import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Collection } from "ol";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

import { MsaviUrlAtom } from "@/atoms/map/imageryUrlAtoms";
import { CreateImageryXyzLayer } from "@/atoms/map/layerAtoms";
import { ArcGisTokenQueryAtom } from "@/atoms/mapAtoms";
import {
	ImageryAtomEffectFunction,
	PopulateImageryLayer,
} from "@/components/MapOpenLayers/imagery-layer-helpers";

export const MsaviLayerGroupAtom = atom(() => {
	return new LayerGroup({ layers: new Collection<TileLayer<XYZ>>() });
});

export const MsaviLayerAtomEffect = atomEffect((get) => {
	const urls = get(MsaviUrlAtom);
	const group = get(MsaviLayerGroupAtom);

	ImageryAtomEffectFunction(get, group, urls, (get, img) => {
		const { data: arcgisToken } = get(ArcGisTokenQueryAtom);
		const { source, bounds } = PopulateImageryLayer(get, img, arcgisToken);
		if (!source) return;
		const urls = source.getUrls();
		if (!source || !urls) return;
		const newUrls = urls?.flatMap((url) => {
			let newUrl = url;
			if (img.colorMapName) {
				newUrl = newUrl + `&colormap_name=${img.colorMapName}`;
			}
			newUrl = newUrl + `&bidx=1`;
			return [newUrl];
		});
		source.setUrls(newUrls);
		return CreateImageryXyzLayer(source, {
			extent: bounds,
			preload: 2,
		});
	});
});

const _msaviLayerVisibility = atom(true);
export const MsaviLayerVisibility = atom(
	(get) => {
		return get(_msaviLayerVisibility);
	},
	(get, set, value?: boolean) => {
		const layer = get(MsaviLayerGroupAtom);
		const bool = value ?? !layer.getVisible();
		set(_msaviLayerVisibility, bool);
		layer.setVisible(bool);
	},
);
