import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Rehab_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const SamplingSiteSummaryCard = {
	component: lazy(
		() =>
			import("@/components/Monitoring/Onground/SamplingSiteSummaryCard"),
	),
	permission: Perm_Rehab_Std,
} as const satisfies LazyCardComponent;

export const LazySamplingSiteSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedSamplingSite = useAtomValue(SelectedSamplingSiteAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SamplingSiteSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Monitoring" && selectedSamplingSite)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedSamplingSite, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"SamplingSiteSummaryCard"} />}
		</Suspense>
	);
};
