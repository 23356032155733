import { axiosInstance } from "@kubb/plugin-client/client";
import axios, { InternalAxiosRequestConfig } from "axios";

import { API_URL } from "./env_variables";

const setupAuth = (config: InternalAxiosRequestConfig) => {
	const session = sessionStorage.getItem(
		`oidc.user:${API_URL}:${import.meta.env.VITE_CLIENT_ID}`,
	);
	if (session) {
		const json = JSON.parse(session);
		const token = json.access_token;
		config.headers.Authorization = token ? `Bearer ${token}` : "";
	}
	config.baseURL = `${API_URL}`;
	config.withCredentials = true;
	return config;
};

axiosInstance.interceptors.request.use(setupAuth);

const instance = axios.create({
	baseURL: `${API_URL}`,
	withCredentials: true,
});
instance.interceptors.request.use(setupAuth);

const api = () => {
	return instance;
};

export default api;
