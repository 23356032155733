import { useInfiniteQuery } from "@tanstack/react-query";
import { SortingState } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import { SeedInventoryFiltersAtom } from "@/atoms/seedAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useFilters } from "@/helpers/filter-hook";
import {
	SeedApplicationOrderBy,
	seedApplicationOrderBy,
	seedApplicationsGetSeedApplicationsWithPagination,
} from "@/lib/gen/eis";
import { SEED_APPLICATION_FILTER_OPTIONS } from "@/types/filters/seed-application-filters";

import { SeedAppColumns } from "./seed-app-columns";

const SeedApplicationCard = () => {
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: seedApplicationOrderBy.DateCreated,
			desc: true,
		},
	]);
	const [seedInventoryFilters, setSeedInventoryFilters] = useFilters(
		SeedInventoryFiltersAtom,
	);
	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [
			"seedApplicationsGetSeedApplicationsWithPagination",
			sorting,
			seedInventoryFilters,
		],
		queryFn: async ({ pageParam }) => {
			const sort = sorting?.[0];
			return seedApplicationsGetSeedApplicationsWithPagination({
				pageNumber: pageParam as number,
				pageSize: 25,
				orderBy: sort?.id as SeedApplicationOrderBy,
				descending: sort?.desc ?? true,
				...parseFilterAguments(seedInventoryFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);

	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const columns = useMemo(() => {
		return SeedAppColumns();
	}, []);

	return (
		<BottomDrawDataTable
			columns={columns}
			data={rows}
			totalRows={totalRows}
			title="Seed Applications"
			moduleType="seeds"
			icon="seed"
			isLoading={isLoading}
			sorting={sorting}
			setSorting={setSorting}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			filteredSearchBox={{
				options: [...SEED_APPLICATION_FILTER_OPTIONS],
				filters: seedInventoryFilters,
				setFilters: setSeedInventoryFilters,
			}}
			tabType={DrawerTabEnum.SEED_APP}
		/>
	);
};

export default SeedApplicationCard;
