import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { SeedAppFloraSpeciesAtom } from "@/atoms/seedAppAtoms";
import { DataTable } from "@/components/data-table/data-table";
import Icon from "@/components/icons/icon";
import { Lifeform } from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

import { GetLifeformColumns } from "./lifeform-plan-table-columns";
import { LifeformClasses } from "./lifeforms";

export const LifeformPlanTable = ({ lf }: { lf: Lifeform }) => {
	const { data, isLoading } = useAtomValue(SeedAppFloraSpeciesAtom);

	const columns = useMemo(() => {
		return GetLifeformColumns({ lf });
	}, [lf]);

	const rows = useMemo(() => {
		const dtos = data ?? [];
		return dtos.filter((dto) => dto.lifeform === lf);
	}, [lf, data]);

	return (
		<>
			{rows.length > 0 ? (
				<DataTable
					title={`${lf} Plan`}
					columns={columns}
					data={rows}
					isLoading={isLoading}
					hideTotalRows
					className="px-2"
					rowHeight={36}
				/>
			) : (
				<LifeformNoData lf={lf} />
			)}
		</>
	);
};

const LifeformNoData = ({ lf }: { lf: Lifeform }) => {
	const classes = LifeformClasses[lf];
	return (
		<div className="flex flex-col items-center justify-center gap-3 rounded-full p-4">
			<div
				className={cn(
					"flex items-center justify-center rounded-full p-4",
					classes.background,
				)}
			>
				<Icon name={lf} className={cn(classes.text)} size={48} />
			</div>
			<span className="text-lg font-semibold">No Species for {lf}</span>
			<span className="text-sm text-muted-foreground">
				{" "}
				No species for this lifeform is present is this vegetation
				community.{" "}
			</span>
		</div>
	);
};
