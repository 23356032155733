import { useMemo } from "react";

import { cn } from "@/lib/utils";

const NON_ITALICS = ["var.", "subsp.", "sp.", "aff."];

export const ItalicSpeciesName = ({
	fullName,
}: {
	fullName: string | undefined | null;
}) => {
	const sections = useMemo(() => {
		return parseSpeciesFullName(fullName);
	}, [fullName]);

	return (
		<>
			{sections.map((substr, i) => {
				return (
					<span key={i} className={cn(substr.italic && "italic")}>
						{substr.str}
					</span>
				);
			})}
		</>
	);
};

function parseSpeciesFullName(fullName: string | undefined | null) {
	if (!fullName) return [];

	const items = fullName.trim().split(" ");
	const index = items.findIndex((s) => NON_ITALICS.includes(s));

	if (index == -1) {
		const str = items.join(" ");
		return [{ str: str, italic: true }];
	}
	if (items[index] === "sp.") {
		const start = items.slice(0, index);
		const end = items.slice(index, items.length);

		const strStart = start.join(" ");
		const strEnd = end.join(" ");
		return [
			{ str: strStart, italic: true },
			{ str: strEnd, italic: false },
		];
	} else {
		const startString = items.slice(0, index);
		const endString = items.slice(index + 1, items.length);

		return [
			{ str: startString.join(" "), italic: true },
			{ str: items[index], italic: false },
			{ str: endString.join(" "), italic: true },
		];
	}
}
