import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";

import { FloraSpeciesForSeedApplicationAddFiltersAtom } from "@/atoms/floraSpeciesAtoms";
import {
	GetSeedApplicationForPlanQueryKey,
	SeedApplicationIdAtom,
} from "@/atoms/seedAppAtoms";
import { ConfirmationDialogAtom } from "@/components/dialogs/confirmation-dialog";
import { ItalicSpeciesName } from "@/components/italic-species-name";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	comparator,
	FloraSpeciesDto,
	Lifeform,
	seedApplicationsAddSpeciesToSeedApplication,
	SeedApplicationsAddSpeciesToSeedApplicationMutationRequest,
} from "@/lib/gen/eis";
import {
	FLORA_SPECIES_FILTER_OPTIONS_LIFEFORM,
	FLORA_SPECIES_FILTER_OPTIONS_QTY,
} from "@/types/filters/flora-species-filters";

import { FloraSpeciesForSeedAppTable } from "./flora-species-for-seed-app-table";

type Props = {
	lf: Lifeform;
	children: React.ReactNode;
};

export const AddSpeciesDialog = ({ lf, children }: Props) => {
	const [open, setOpen] = useState(false);
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild disabled={seedApplicationId == null}>
				{children}
			</DialogTrigger>
			<DialogContent className="w-full max-w-xl">
				<DialogHeader>
					<DialogTitle>Replace Species</DialogTitle>
					<DialogDescription />
				</DialogHeader>
				{open && <AddSpeciesForm lf={lf} setOpen={setOpen} />}
			</DialogContent>
		</Dialog>
	);
};

type FormProps = {
	lf: Lifeform;
	setOpen: (open: boolean) => void;
};

const AddSpeciesForm = ({ lf, setOpen }: FormProps) => {
	const queryClient = useQueryClient();
	const [filters, setFilters] = useFilters(
		FloraSpeciesForSeedApplicationAddFiltersAtom,
	);
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const confirm = useSetAtom(ConfirmationDialogAtom);
	const { mutate } = useMutation({
		mutationFn: async (
			data: SeedApplicationsAddSpeciesToSeedApplicationMutationRequest,
		) => {
			return seedApplicationsAddSpeciesToSeedApplication(
				data.seedApplicationId,
				data,
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationForPlanQueryKey(seedApplicationId),
			});
			setOpen(false);
		},
		onError: (error) => {
			toast.error("Replace Species Failed", {
				description: error.message,
			});
		},
	});

	const onSpeciesSelected = useCallback(
		(selected: FloraSpeciesDto) => {
			confirm({
				title: "Confirm New Species",
				description: (
					<>
						Are you sure you want to add '
						<ItalicSpeciesName fullName={selected.fullName} />' as a
						new target species?
					</>
				),
				confirmText: "Add",
			}).then((confirmed) => {
				if (confirmed) {
					if (!seedApplicationId) {
						return;
					}
					mutate({
						seedApplicationId: seedApplicationId,
						floraSpeciesId: selected.id,
					});
				}
			});
		},
		[confirm, mutate, seedApplicationId],
	);

	useEffect(() => {
		autoSetFilter(setFilters, FLORA_SPECIES_FILTER_OPTIONS_LIFEFORM, [
			{ label: lf, value: lf },
		]);
		autoSetFilter(
			setFilters,
			FLORA_SPECIES_FILTER_OPTIONS_QTY,
			"0",
			comparator.GREATER_THAN,
		);
	}, [lf, setFilters]);

	return (
		<div className="flex flex-col gap-3 overflow-hidden">
			<FloraSpeciesForSeedAppTable
				title="Add Species"
				onSpeciesSelected={(dto) => onSpeciesSelected(dto)}
				filters={filters}
				setFilters={setFilters}
			/>
		</div>
	);
};
