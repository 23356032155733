import { z } from "zod";

import type { BandMetadata } from "@/lib/gen/titiler/types/BandMetadata";

/**
 * @description Band metadata.
 */
export const bandMetadataSchema = z
	.object({
		Description: z.union([z.string(), z.null()]).optional(),
		ColorInterp: z.string(),
		Offset: z.number(),
		Scale: z.number(),
		Metadata: z.union([z.object({}), z.null()]).optional(),
	})
	.describe("Band metadata.") as z.ZodType<BandMetadata>;
