import { RouteObject } from "react-router";

import { ErrorPage } from "@/routes/error-page";

import { App } from "./app";
import { LoginRedirect, LogoutRedirect } from "./authentication";
import { Root } from "./root";
import { ROUTES } from "./route-def";

const routes: RouteObject[] = [
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: ROUTES.app.$path(),
				element: <App />,
			},
			{
				path: ROUTES.authentication.login.$path(),
				element: <LoginRedirect />,
			},
			{
				path: ROUTES.authentication.logout.$path(),
				element: <LogoutRedirect />,
			},
		],
	},
];
export default routes;
