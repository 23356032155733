export const lifeform = {
    "Shrub": "Shrub",
    "OtherGrass": "Other Grass",
    "HummockGrass": "Hummock Grass",
    "Herb": "Herb",
    "Tree": "Tree",
    "Sedge": "Sedge"
} as const;

 export type LifeformEnum = (typeof lifeform)[keyof typeof lifeform];

 export type Lifeform = LifeformEnum;