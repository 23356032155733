import client from "@kubb/plugin-client/client";
import type { RiparianSurveyGetRiparianSurveysWithPaginationQueryResponse, RiparianSurveyGetRiparianSurveysWithPaginationQueryParams } from "../../ts/RiparianSurveyGetRiparianSurveysWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * @link /api/RiparianSurvey
 */
export async function riparianSurveyGetRiparianSurveysWithPagination(params?: RiparianSurveyGetRiparianSurveysWithPaginationQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSurveyGetRiparianSurveysWithPaginationQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSurvey`, params, ...config });
    return res.data;
}