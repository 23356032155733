import { atom } from "jotai";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import { GetOLGeometry } from "@/helpers/geometry-helpers";
import { GetErosionFeaturesPaginatedQuery } from "@/lib/gen/eis";

import { SelectAndZoomToFeatureAtom } from "./map/mapEventAtoms";
import { ErosionExtentGeometryQueryAtom } from "./map/mapQueryAtoms";
import { SelectedMineSiteIdAtom } from "./miningAtoms";

export const SelectedErosionFeatureIdAtom = atom<string | undefined>(undefined);

export type ErosionFeaturesArg = keyof GetErosionFeaturesPaginatedQuery;
export const ErosionFeaturesFiltersAtom = atom<
	ColumnFilter<ErosionFeaturesArg>[]
>([]);

export const GoToErosionPolygon = atom(null, async (get, set, id: string) => {
	const { data } = get(ErosionExtentGeometryQueryAtom);
	if (!data) return;

	const ef = data.find((e) => e.id === id);
	if (ef == null) return;
	set(SelectedMineSiteIdAtom, ef.mineSiteId);
	// TODO Selecting rehab polygon will cause the erosion feature to be unselected
	// set(SelectedRehabPolygonIdAtom, ef.rehabPolygonId);
	set(SelectedErosionFeatureIdAtom, id);
	set(
		SelectAndZoomToFeatureAtom,
		id,
		"erosionvector",
		false,
		undefined,
		GetOLGeometry(ef.geometry),
	);
});
