import type { GetFloraSpeciesWithPaginationQuery } from "../ts/GetFloraSpeciesWithPaginationQuery.ts";
import { doubleFilterSchema } from "./doubleFilterSchema.ts";
import { floraSpeciesOrderBySchema } from "./floraSpeciesOrderBySchema.ts";
import { nullableEnumFilterOfLifeformSchema } from "./nullableEnumFilterOfLifeformSchema.ts";
import { nullableEnumFilterOfLongevitySchema } from "./nullableEnumFilterOfLongevitySchema.ts";
import { nullableEnumFilterOfNaturalisedStatusSchema } from "./nullableEnumFilterOfNaturalisedStatusSchema.ts";
import { nullableGuidFilterSchema } from "./nullableGuidFilterSchema.ts";
import { stringFilterSchema } from "./stringFilterSchema.ts";
import { z } from "zod";

 export const getFloraSpeciesWithPaginationQuerySchema = z.object({ "weedVegetationClassId": z.array(z.lazy(() => nullableGuidFilterSchema)).nullable().nullish(), "speciesFullName": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "family": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "genus": z.array(z.lazy(() => stringFilterSchema)).nullable().nullish(), "naturalisedStatus": z.lazy(() => nullableEnumFilterOfNaturalisedStatusSchema).nullable().nullish(), "longevity": z.lazy(() => nullableEnumFilterOfLongevitySchema).nullable().nullish(), "lifeform": z.lazy(() => nullableEnumFilterOfLifeformSchema).nullable().nullish(), "availableQuantity": z.array(z.lazy(() => doubleFilterSchema)).nullable().nullish(), "isWeed": z.boolean().nullable().nullish(), "priorityWeed": z.boolean().nullable().nullish(), "isLeguminous": z.boolean().nullable().nullish(), "orderBy": z.lazy(() => floraSpeciesOrderBySchema), "pageNumber": z.number().int(), "pageSize": z.number().int(), "descending": z.boolean() }) as z.ZodType<GetFloraSpeciesWithPaginationQuery>;