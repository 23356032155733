import saveAs from "file-saver";
import html2canvas from "html2canvas";

export async function ExportImage(
	element: HTMLDivElement,
	exportedFilename: string,
) {
	const canvas = await html2canvas(element, {
		onclone: (clonedDoc) => {
			// Dont dislpay checkboxes
			const checkboxes = clonedDoc.querySelectorAll(
				'button[role="checkbox"]',
			);
			checkboxes.forEach((checkbox) => {
				(checkbox as HTMLElement).style.display = "none";
			});

			// Move text up, because for some reason it is moved down when this is not done.
			const textElements = clonedDoc.querySelectorAll("span, h1, label");
			textElements.forEach((el) => {
				const htmlElement = el as HTMLElement;
				const computedStyle = window.getComputedStyle(htmlElement);
				if (computedStyle.position === "static") {
					htmlElement.style.position = "relative";
				}
				htmlElement.style.top = "-7px";
			});
		},
	});
	const image = canvas.toDataURL("image/png", 1.0);
	saveAs(image, `${exportedFilename}.png`);
}
