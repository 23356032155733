import type { RehabilitationPolygonPagedDto } from "../ts/RehabilitationPolygonPagedDto.ts";
import { criterionResultDtoSchema } from "./criterionResultDtoSchema.ts";
import { geometrySchema } from "./geometrySchema.ts";
import { polygonLandformTypeSchema } from "./polygonLandformTypeSchema.ts";
import { polygonOverallResultSchema } from "./polygonOverallResultSchema.ts";
import { rehabilitationPolygonStatusSchema } from "./rehabilitationPolygonStatusSchema.ts";
import { rehabilitationPolygonTagDtoSchema } from "./rehabilitationPolygonTagDtoSchema.ts";
import { rehabPolygonLabelDtoSchema } from "./rehabPolygonLabelDtoSchema.ts";
import { vegClassCoversDtoSchema } from "./vegClassCoversDtoSchema.ts";
import { z } from "zod";

 export const rehabilitationPolygonPagedDtoSchema = z.object({ "id": z.string(), "name": z.string().nullable().nullish(), "mineSite": z.string().nullable().nullish(), "miningRegion": z.string().nullable().nullish(), "status": z.lazy(() => rehabilitationPolygonStatusSchema), "geometry": z.lazy(() => geometrySchema).nullable().nullish(), "landform": z.lazy(() => polygonLandformTypeSchema), "area": z.number(), "rehabilitatedDate": z.string().date(), "quadrats": z.number().int(), "siteInspections": z.number().int(), "erosionFeatures": z.number().int(), "erosionMaxDepth": z.number(), "erosionTotalArea": z.number(), "erosionTotalVolume": z.number(), "completionStatus": z.lazy(() => polygonOverallResultSchema).nullable().nullish(), "rehabilitationPolygonTags": z.array(z.lazy(() => rehabilitationPolygonTagDtoSchema)).nullable().nullish(), "tagString": z.string().nullable().nullish(), "labels": z.array(z.lazy(() => rehabPolygonLabelDtoSchema)).nullable().nullish(), "vegClassCovers": z.array(z.lazy(() => vegClassCoversDtoSchema)).nullable().nullish(), "criterionResults": z.array(z.lazy(() => criterionResultDtoSchema)).nullable().nullish() }) as z.ZodType<RehabilitationPolygonPagedDto>;