import { axiosInstance } from "@kubb/plugin-client/client";
import { setUser as setSentryUser } from "@sentry/react";
import { AxiosError, AxiosResponse } from "axios";
import { useSetAtom } from "jotai";
import { useAuth } from "oidc-react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import api from "@/api";
import { UserAtom } from "@/atoms/authAtoms";

export function useAuthSetup() {
	const posthog = usePostHog();
	const auth = useAuth();
	const setUser = useSetAtom(UserAtom);

	useEffect(() => {
		(async () => {
			let u = await auth.userManager?.getUser();
			if (u && u.expired) {
				u = await auth.userManager.signinSilent();
			}
			if (u) {
				setUser(u);
				const name = u.profile?.name;
				if (name) {
					posthog?.identify(name);
					setSentryUser({
						id: name,
					});
				}
			} else {
				posthog?.reset();
				setSentryUser(null);
			}
		})();
	}, [auth, posthog, setUser]);

	useEffect(() => {
		const errorInterceptor = (error: AxiosError) => {
			if (error.response?.status === 401) {
				auth.userManager.removeUser().then(() => {
					auth.userManager.signinRedirect();
				});
			}
			return Promise.reject(error);
		};
		const responseInterceptor = (response: AxiosResponse) => {
			return response;
		};
		const num1 = api().interceptors.response.use(
			responseInterceptor,
			errorInterceptor,
		);
		const num2 = axiosInstance.interceptors.response.use(
			responseInterceptor,
			errorInterceptor,
		);

		return () => {
			api().interceptors.response.eject(num1);
			axiosInstance.interceptors.response.eject(num2);
		};
	}, [auth.userManager]);
}
