import {
	predicateBuilderGetTypeAheadResults,
	PredicateFilterType,
} from "@/lib/gen/eis";

export const getTypeAheadResults = async (
	filterType: PredicateFilterType,
	searchTerm?: string,
) => {
	const response = await predicateBuilderGetTypeAheadResults({
		FilterType: filterType,
		SearchTerm: searchTerm,
	});
	return response.results ?? [];
};
