import { useAtom, useSetAtom } from "jotai";
import { PictureInPicture } from "lucide-react";
import { ButtonHTMLAttributes } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import {
	addPoppedOutTabAtom,
	poppedOutTabsAtom,
} from "@/atoms/poppedOutAtoms.ts";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";

interface PopoutButtonProps
	extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> {
	className?: string;
	tabType: DrawerTabEnum;
	onBeforePopout?: () => void;
}

export const PopoutButton: React.FC<PopoutButtonProps> = ({
	className,
	tabType,
	onBeforePopout,
	...props
}) => {
	const addPoppedOutTab = useSetAtom(addPoppedOutTabAtom);
	const [poppedOutTabs] = useAtom(poppedOutTabsAtom);

	const handleClick = () => {
		onBeforePopout?.();
		addPoppedOutTab(tabType);
	};

	return (
		<Button
			variant="ghost"
			onClick={handleClick}
			className={cn(
				"flex h-6 gap-1 p-2 font-roboto font-medium text-white",
				className,
			)}
			disabled={poppedOutTabs.has(tabType)}
			{...props}
		>
			<PictureInPicture className="size-4" />
		</Button>
	);
};
